import { useState, useEffect } from "react";
import * as React from "react";
import { TokenInfo } from "@solana/spl-token-registry";
import {
  InstaSwap,
  getTokenUiBalance,
  LpTokenInfo,
} from "@ratio-finance/instaswap-core";
import { InstaSwapProps, useInstaSwapProps } from "./type";
import { ExecuteParams, executeTransactions } from "./utils";

interface InstaSwapConfig {
  instaSwap: InstaSwap | null;
  setValues: (props: useInstaSwapProps) => void;
  execute: (props: ExecuteParams) => Promise<{ err?: any; txResult?: any }>;
  estOutAmount: number;
  loading: boolean;
}

const InstaSwapContext = React.createContext<InstaSwapConfig>({
  instaSwap: null,
  setValues: (_: useInstaSwapProps) => {},
  execute: async (_: ExecuteParams) => ({}),
  estOutAmount: 0,
  loading: false,
});

export function InstaSwapProvider({
  children = undefined as any,
  connection,
}: InstaSwapProps) {
  const [instaSwap, setInstaSwap] = useState<InstaSwap | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [tokenAddress, setTokenAddress] = useState<string | null | undefined>(
    null
  );
  const [lpTokenAddress, setLpTokenAddress] = useState<
    string | null | undefined
  >(null);
  const [inAmount, setInAmount] = useState<number>(0);
  const [estOutAmount, setEstOutAmount] = useState<number>(0);
  const [slippage, setSlippage] = useState<number>(1);
  /* 
    Direction: 
    True => InstaBuy
    False => InstaSell
  */
  const [direction, setDirection] = useState<boolean>(true);

  const [preventTimer, setPreventTimer] = useState<any>(null);

  // load instaSwap
  useEffect(() => {
    setLoading(true);
    const instaSwap = new InstaSwap(connection);
    instaSwap.load().then(() => {
      setLoading(false);
      setInstaSwap(instaSwap);
    });
  }, [connection]);

  useEffect(() => {
    if (!instaSwap) return;
    if (preventTimer) {
      clearTimeout(preventTimer);
    }
    setPreventTimer(
      setTimeout(() => {
        if (direction) {
          // Estimate InstaBuy Output Amount
          setLoading(true);
          if (!tokenAddress || !lpTokenAddress) return 0;
          const inputToken = instaSwap.inputTokens.get(tokenAddress);
          if (!inputToken) return 0;
          instaSwap
            .getLpOutUiAmount(inputToken, inAmount, lpTokenAddress, slippage)
            .then((value: number) => {
              setEstOutAmount(value);
              setLoading(false);
            });
        } else {
          // Estimate InstaSell Output Amount
          setLoading(true);
          if (!tokenAddress || !lpTokenAddress) return 0;
          const inputLpToken = instaSwap.lpTokens.get(lpTokenAddress)!;
          const outputToken = instaSwap.inputTokens.get(tokenAddress)!;
          if (!inputLpToken) return 0;
          instaSwap
            .getWithdrawOutUiAmount(
              inputLpToken,
              inAmount,
              outputToken,
              slippage
            )
            .then((value: number) => {
              setEstOutAmount(value);
              setLoading(false);
            });
        }
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instaSwap, inAmount, tokenAddress, lpTokenAddress, slippage, direction]);

  const execute = async (params: ExecuteParams): Promise<{ err?: any }> => {
    setLoading(true);
    let result: any = {};
    try {
      result = await executeInternal(params);
    } catch {
      result = {
        err: new Error("Swap Rejected."),
      };
    } finally {
      setLoading(false);
    }
    return result;
  };
  const executeInternal = async (
    params: ExecuteParams
  ): Promise<{ err?: any }> => {
    let transactions: any;
    if (
      !instaSwap ||
      !params.wallet ||
      !params.wallet?.publicKey ||
      !tokenAddress ||
      !lpTokenAddress
    )
      return {
        err: new Error("Still Loading or Wallet Error"),
      };
    if (direction) {
      // Fetch InstaBuy Transactions
      const tokenBalance = await getTokenUiBalance(
        connection,
        params.wallet.publicKey,
        tokenAddress
      );
      if (tokenBalance < inAmount)
        return {
          err: new Error("Insufficient Token Amount for Swap"),
        };
      transactions = await instaSwap.getBuyTransactions(
        params.wallet.publicKey,
        tokenAddress,
        inAmount,
        lpTokenAddress,
        slippage
      );
    } else {
      // Fetch InstaSell Transactions
      const lpBalance = await getTokenUiBalance(
        connection,
        params.wallet.publicKey,
        lpTokenAddress
      );
      if (lpBalance < inAmount)
        return {
          err: new Error("Insufficient Lp Amount for Swap"),
        };
      transactions = await instaSwap.getSellTransactions(
        params.wallet.publicKey,
        lpTokenAddress,
        inAmount,
        tokenAddress,
        slippage
      );
    }
    if (!transactions)
      return {
        err: new Error("No transactions"),
      };
    const allTransaction = transactions.allTransactions;
    return await executeTransactions(
      Object.assign(params, { connection, transactions: allTransaction })
    );
  };

  const setValues = ({
    amount,
    token,
    lpToken,
    slippage,
    direction,
  }: useInstaSwapProps) => {
    setInAmount(amount ?? 0);
    setTokenAddress(token);
    setLpTokenAddress(lpToken);
    let _slippage = slippage ?? 1;
    if (_slippage < 0) _slippage = 1;
    if (_slippage > 50) _slippage = 50;
    setSlippage(_slippage);
    setDirection(direction ?? true);
  };

  return (
    <InstaSwapContext.Provider
      value={{
        instaSwap,
        setValues,
        execute,
        estOutAmount,
        loading,
      }}
    >
      {children}
    </InstaSwapContext.Provider>
  );
}

export function useInstaSwap(props: useInstaSwapProps) {
  const context = React.useContext(InstaSwapContext);

  useEffect(() => {
    context.setValues(props);
  }, [props]);

  return {
    loading: context.loading,
    estimatedOutAmount: context.estOutAmount,
    avaLpTokens: context.instaSwap?.lpTokens ?? new Map<string, LpTokenInfo>(),
    avaTokens: context.instaSwap?.inputTokens ?? new Map<string, TokenInfo>(),
    avaTokenMints: context.instaSwap?.availableTokenMints ?? [],
    avaLpMints: context.instaSwap?.availableLpMints ?? [],
    execute: context.execute,
  };
}
