import { AccountInfo, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { PlatformFee, QuoteMintToReferrer, TokenMintAddress } from '..';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import JSBI from 'jsbi';
import { chunkedGetMultipleAccountInfos } from '../utils/chunkedGetMultipleAccountInfos';
import type BN from 'bn.js';

export enum SwapMode {
  ExactIn = 'ExactIn',
  ExactOut = 'ExactOut',
}

export interface QuoteParams {
  sourceMint: PublicKey;
  destinationMint: PublicKey;
  amount: JSBI;
  swapMode: SwapMode;
}

export interface Quote {
  notEnoughLiquidity: boolean;
  minInAmount?: JSBI;
  minOutAmount?: JSBI;
  inAmount: JSBI;
  outAmount: JSBI;
  feeAmount: JSBI;
  feeMint: TokenMintAddress;
  feePct: number;
  priceImpactPct: number;
}

export interface SwapParams {
  sourceMint: PublicKey;
  destinationMint: PublicKey;
  userSourceTokenAccount: PublicKey;
  userDestinationTokenAccount: PublicKey;
  userTransferAuthority: PublicKey;
  /**
   * amount is used for instruction and can be null when it is an intermediate swap, only the first swap has an amount
   */
  amount: BN | null;
  /**
   * inAmount is the calculated amount and is not recomended to be used for constructing instruction.
   */
  inAmount: JSBI;
  otherAmountThreshold: BN;
  swapMode: SwapMode;
  tokenLedger: PublicKey;
  openOrdersAddress?: PublicKey;
  platformFee?: PlatformFee;
  quoteMintToReferrer?: QuoteMintToReferrer;
}

export type AccountInfoMap = Map<string, AccountInfo<Buffer> | null>;

export interface Amm {
  /* Label for UI usage */
  label: string;
  /* Unique id to recognize the AMM */
  id: string;
  /* Reserve token mints for the purpose of routing */
  reserveTokenMints: PublicKey[];
  /* State if we need to prefetch the accounts 1 time */
  shouldPrefetch: boolean;
  /* Exact output swap mode is supported */
  exactOutputSupported: boolean;

  getAccountsForUpdate(): PublicKey[];
  update(accountInfoMap: AccountInfoMap): void;
  getQuote(quoteParams: QuoteParams): Quote;
  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[];
}

export const mapAddressToAccountInfos = (accountInfoMap: AccountInfoMap, addresses: PublicKey[]) => {
  const accountInfos = addresses.map((address) => {
    const accountInfo = accountInfoMap.get(address.toString());
    if (!accountInfo) {
      throw new Error(`Account info ${address.toBase58()} missing`);
    }
    return accountInfo;
  });

  return accountInfos;
};

export const tokenAccountsToJSBIs = (tokenAccounts: TokenAccountInfo[]): JSBI[] => {
  return tokenAccounts.map((tokenAccount) => {
    return JSBI.BigInt(tokenAccount.amount);
  });
};

export const prefetchAmms = async (amms: Amm[], connection: Connection) => {
  const accounts = amms.map((amm) => amm.getAccountsForUpdate().map((item) => item.toBase58())).flat();
  const accountInfosMap = new Map<string, AccountInfo<Buffer>>();
  const accountInfos = await chunkedGetMultipleAccountInfos(connection, accounts);

  accountInfos.forEach((item, index) => {
    const publicKey = accounts[index];
    if (item) {
      accountInfosMap.set(publicKey, item);
    }
  });

  for (let amm of amms) {
    amm.update(accountInfosMap);
  }
};
