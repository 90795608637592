/* eslint-disable */
import { OpenOrders } from '@project-serum/serum';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import BN from 'bn.js';
import JSBI from 'jsbi';

import {
  jsonInfo2PoolKeys,
  LIQUIDITY_STATE_LAYOUT_V4,
  LIQUIDITY_STATE_LAYOUT_V5,
} from '@raydium-io/raydium-sdk';

import { LiquidityManager } from '../manage/ILiquidityManager';
import { getMintInfo } from '../utils/web3';
import BigNumber from 'bignumber.js';
import { RatioSdkProgram } from '../programs/ratio/ratio-sdk';
import fetch from "cross-fetch";

export class RaydiumLiquidityManager extends LiquidityManager {
  async getLiquidityPairRatio(lpData: any): Promise<number[]> {
    const { tokenAReserve, tokenBReserve, poolKeys } = lpData.swapInfo;
    if (poolKeys.version == 5) {  
      let ratio = new BigNumber(tokenAReserve.toString()).div(new BigNumber(tokenBReserve.toString())).toNumber();
      let baseRate = (1 / (1 + ratio)) * ratio;
      return [baseRate, 1 - baseRate];
    } else {
      return [0.5, 0.5];
    }
  }
  async loadSwapInfo(connection: Connection, lpData: any) {
    const raydiumPoolInfos = await (await fetch(`https://api.raydium.io/v2/sdk/liquidity/mainnet.json`)).json();
    const config = raydiumPoolInfos.official.find((poolInfo: any) => poolInfo.id === lpData.swapAccount);
    const poolKeys = jsonInfo2PoolKeys(config);
    const accountData = await connection.getAccountInfo(new PublicKey(poolKeys.id));
    if (accountData) {
      let parsedAmmData: any = {};
      if (poolKeys.version == 5) {
        parsedAmmData = LIQUIDITY_STATE_LAYOUT_V5.decode(accountData.data);
      } else {
        parsedAmmData = LIQUIDITY_STATE_LAYOUT_V4.decode(accountData.data);
      }

      const { baseVault, quoteVault, lpMint, openOrders, marketProgramId, baseNeedTakePnl, quoteNeedTakePnl } =
        parsedAmmData;

      const coinAmount = new BN((await connection.getTokenAccountBalance(baseVault)).value.amount);
      const pcAmount = new BN((await connection.getTokenAccountBalance(quoteVault)).value.amount);

      const ammOpenOrdersData = await connection.getAccountInfo(openOrders);
      const OPEN_ORDERS_LAYOUT = OpenOrders.getLayout(marketProgramId);
      const parsedAmmOpenOrders = OPEN_ORDERS_LAYOUT.decode(ammOpenOrdersData?.data);

      const { baseTokenTotal, quoteTokenTotal } = parsedAmmOpenOrders;

      const tokenAReserve = new BN(coinAmount).add(new BN(baseTokenTotal)).sub(new BN(baseNeedTakePnl));
      const tokenBReserve = new BN(pcAmount).add(new BN(quoteTokenTotal)).sub(new BN(quoteNeedTakePnl));

      const lpMintInfo = await getMintInfo(connection, lpMint);
      return {
        poolKeys,
        tokenAReserve,
        tokenBReserve,
        lpMintInfo,
      };
    }
    return {
      poolKeys,
    };
  }

  async addLiquidityTransaction(
    connection: Connection,
    userPublicKey: PublicKey,
    lpData: any,
    originAmounts: Array<JSBI>
  ): Promise<Transaction> {
    const { poolKeys } = lpData.swapInfo;
    const ratioProgram = RatioSdkProgram.getInstance(connection);
    const tx = await ratioProgram.makeRaydiumAddLiquidityTx(
      connection,
      userPublicKey,
      poolKeys,
      originAmounts
    );
    return tx;
  }

  async removeLiquidityTransaction(
    connection: Connection,
    userPublicKey: PublicKey,
    lpData: any,
    amountToUnwind: JSBI
  ): Promise<Transaction> {
    const { poolKeys } = lpData.swapInfo;
    const ratioProgram = RatioSdkProgram.getInstance(connection);
    const tx = await ratioProgram.makeRaydiumRemoveLiquidityTx(
      connection,
      userPublicKey,
      poolKeys,
      amountToUnwind
    );
    return tx;
  }

  async getEstimatedOutputLPAmount(connection: Connection, lpData: any, amounts: Array<JSBI>): Promise<JSBI> {
    const { tokenAReserve, tokenBReserve, lpMintInfo } = lpData.swapInfo;
    const lpRate = new BigNumber(amounts[0].toString()).div(new BigNumber(tokenAReserve.toString()));
    const lpEstimation = new BigNumber(lpMintInfo.supply.toString()).multipliedBy(lpRate).toString(10);
    return JSBI.BigInt(Number.parseInt(lpEstimation));
  }
  
  getUnderlyingMints(lpData: any): string[] {
    const { poolKeys } = lpData.swapInfo;
    return [
      poolKeys.baseMint.toString(),
      poolKeys.quoteMint.toString()
    ];
  } 

  async getEstimatedWithdrawAmount(connection: Connection, lpData: any, amount: JSBI): Promise<JSBI[]> {
    const { tokenAReserve, tokenBReserve, lpMintInfo } = lpData.swapInfo;
    const estimateA = new BigNumber(tokenAReserve.toString()).multipliedBy(new BigNumber(amount.toString()).div(new BigNumber(lpMintInfo.supply.toString()))).toString();
    const estimateB = new BigNumber(tokenBReserve.toString()).multipliedBy(new BigNumber(amount.toString()).div(new BigNumber(lpMintInfo.supply.toString()))).toString();
    return [
      JSBI.BigInt(Number.parseInt(estimateA)),
      JSBI.BigInt(Number.parseInt(estimateB)),
    ]
  }
}
