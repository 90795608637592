import { calculateAmpFactor, StableSwap } from '@saberhq/stableswap-sdk';
import { PublicKey } from '@solana/web3.js';
import { Fraction, ONE, Stable, TWO } from '@jup-ag/math';
import JSBI from 'jsbi';
import Decimal from 'decimal.js';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { createSaberSwapInstruction } from '../jupiterInstruction';
import { deserializeAccount } from '@mercurial-finance/optimist';
import { AccountInfo } from '@solana/spl-token';

export class SaberAmm implements Amm {
  id: string;
  label = 'Saber' as const;
  shouldPrefetch = false;
  exactOutputSupported = false;

  private tokenAccounts: AccountInfo[] = [];
  private calculator: Stable;

  constructor(private stableSwap: StableSwap) {
    this.id = stableSwap.config.swapAccount.toBase58();

    this.calculator = new Stable(
      TWO,
      calculateAmpFactor(this.stableSwap.state),
      [ONE, ONE],
      new Fraction(this.stableSwap.state.fees.trade.numerator, this.stableSwap.state.fees.trade.denominator),
    );
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.stableSwap.state.tokenA.reserve, this.stableSwap.state.tokenB.reserve];
  }

  update(accountInfoMap: AccountInfoMap) {
    let tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) {
        throw new Error('Invalid token account data');
      }
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, destinationMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    const feePct = new Decimal(this.stableSwap.state.fees.trade.asFraction.toFixed(4));

    const [inputIndex, outputIndex] = this.tokenAccounts[0].mint.equals(sourceMint) ? [0, 1] : [1, 0];

    this.calculator.setAmp(calculateAmpFactor(this.stableSwap.state));
    const result = this.calculator.exchange(tokenAccountsToJSBIs(this.tokenAccounts), amount, inputIndex, outputIndex);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: result.expectedOutputAmount,
      feeAmount: result.fees,
      feeMint: destinationMint.toBase58(),
      feePct: feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    return [
      createSaberSwapInstruction({
        stableSwap: this.stableSwap,
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.stableSwap.state.tokenA.mint, this.stableSwap.state.tokenB.mint];
  }
}
