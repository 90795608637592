import {
  ORCA_PROGRAM_ID,
  PENGUIN_PROGRAM_ID,
  SAROS_PROGRAM_ID,
  STEPN_PROGRAM_ID,
  STEP_TOKEN_SWAP_PROGRAM_ID,
} from '../../constants';

export const PROGRAM_ID_TO_LABEL = new Map<string, string>([
  [ORCA_PROGRAM_ID.toString(), 'Orca'],
  [STEP_TOKEN_SWAP_PROGRAM_ID.toString(), 'Step'],
  [PENGUIN_PROGRAM_ID.toString(), 'Penguin'],
  [SAROS_PROGRAM_ID.toString(), 'Saros'],
  [STEPN_PROGRAM_ID.toString(), 'Stepn'],
]);
