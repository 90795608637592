import type { Provider } from '@project-serum/anchor';
import { Program } from '@project-serum/anchor';
import { createProgramAddressSync, findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { Market } from '@project-serum/serum';
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { AccountMeta, PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY, TransactionInstruction } from '@solana/web3.js';
import BN from 'bn.js';
import { Jupiter as JupiterIDL, IDL } from './idl/jupiter';
import type { RaydiumAmm } from './raydium/raydiumAmm';
import { StableSwap } from '@saberhq/stableswap-sdk';
import {
  ALDRIN_SWAP_PROGRAM_ID,
  ALDRIN_SWAP_V2_PROGRAM_ID,
  RAYDIUM_AMM_V4_PROGRAM_ID,
  SABER_ADD_DECIMALS_PROGRAM_ID,
  MERCURIAL_SWAP_PROGRAM_ID,
  CYKURA_PROGRAM_ID,
  CYKURA_FACTORY_STATE_ADDRESS,
  WHIRLPOOL_PROGRAM_ID,
  MARINADE_PROGRAM_ID,
} from '../constants';
import { AldrinPoolState } from './aldrin/poolState';
import type { TokenSwapState } from './spl-token-swap/tokenSwapLayout';
import { PlatformFee } from './types';
import type { AddDecimals } from './saber/saberAddDecimalsAmm';
import { CropperPoolState, CROPPER_STATE_ADDRESS } from './cropper/swapLayout';
import { SenchaPoolState } from './sencha/swapLayout';
import { CremaPoolState } from './crema/swapLayout';
import { MercurialSwapLayoutState } from './mercurial/swapLayout';
import { LifinitySwapLayoutState } from './lifinity/swapLayout';
import { MarinadeStateResponse } from './marinade/marinade-state.types';
import JSBI from 'jsbi';

// Side rust enum used for the program's RPC API.
const Side = {
  Bid: { bid: {} },
  Ask: { ask: {} },
};

export const JUPITER_PROGRAM_ID_STAGING = new PublicKey('JUPSjgjMFjU4453KMgxhqVmzep6W352bQpE4RsNqXAx');
export const JUPITER_PROGRAM_ID_PRODUCTION = new PublicKey('JUP3c2Uh3WA4Ng34tw6kPd2G4C5BB21Xo36Je1s32Ph');

export const JUPITER_PROGRAM_ID = JUPITER_PROGRAM_ID_PRODUCTION; // JUPITER_PROGRAM_ID_PRODUCTION;

const JUPITER_PROGRAM = new Program<JupiterIDL>(IDL, JUPITER_PROGRAM_ID, {} as Provider);

export const PRODUCTION_TOKEN_LEDGERS = [
  new PublicKey('755CiAfB63jK8DTZSM38ZRBTjf1inGM4QfLJTfpPM9x3'),
  new PublicKey('5ZZ7w2C1c348nQm4zaYgrgb8gfyyqQNzH61zPwGvEQK9'),
  new PublicKey('H4K65yLyYqVsDxgNCVGqK7MqrpKFLZjmqf95ZvmfyVDx'),
  new PublicKey('HE4STzYv5dzw2G374ynt4EYvzuKLG41P2xnNffzpdWnG'),
  new PublicKey('3HmXTbZf6G2oEjN3bPreZmF7YGLbbEXFkgAbVFPaimwU'),
  new PublicKey('CUNMrNvGNh1aWR6cVzAQekdsW2dfacnQicyfvgvrN5ap'),
  new PublicKey('6Q6vMHsUFA7kuwdkG9vm7gByMfk151Z9eMSwE14fHcrG'),
];
export const STAGING_TOKEN_LEDGERS = [new PublicKey('755CiAfB63jK8DTZSM38ZRBTjf1inGM4QfLJTfpPM9x3')];

export const TOKEN_LEDGER: PublicKey =
  PRODUCTION_TOKEN_LEDGERS[Math.floor(Math.random() * PRODUCTION_TOKEN_LEDGERS.length)];

type CreateSwapInstructionParams = {
  sourceMint: PublicKey;
  userSourceTokenAccount: PublicKey;
  userDestinationTokenAccount: PublicKey;
  userTransferAuthority: PublicKey;
  inAmount: BN | null;
  minimumOutAmount: BN;
  tokenLedger: PublicKey;
  platformFee?: PlatformFee;
};

type CreateSwapExactOutputInstructionParams = {
  sourceMint: PublicKey;
  userSourceTokenAccount: PublicKey;
  userDestinationTokenAccount: PublicKey;
  userTransferAuthority: PublicKey;
  outAmount: BN;
  maximumInAmount: BN;
  tokenLedger: PublicKey;
  platformFee?: PlatformFee;
};

function stableSwapNPoolIntoMercurialExchange(
  swayLayout: MercurialSwapLayoutState,
  sourceTokenAccount: PublicKey,
  destinationTokenAccount: PublicKey,
  userTransferAuthority: PublicKey,
) {
  return {
    swapProgram: MERCURIAL_SWAP_PROGRAM_ID,
    swapState: swayLayout.ammId,
    tokenProgram: TOKEN_PROGRAM_ID,
    poolAuthority: swayLayout.authority,
    userTransferAuthority: userTransferAuthority,

    sourceTokenAccount,
    destinationTokenAccount,
  };
}

const [ammAuthority] = findProgramAddressSync(
  [new Uint8Array(Buffer.from('amm authority'.replace('\u00A0', ' '), 'utf-8'))],
  RAYDIUM_AMM_V4_PROGRAM_ID,
);

function raydiumAmmToRaydiumSwap(
  raydiumAmm: RaydiumAmm,
  userSourceTokenAccount: PublicKey,
  userDestinationTokenAccount: PublicKey,
  userTransferAuthority: PublicKey,
) {
  if (!raydiumAmm.serumMarketKeys) {
    throw new Error('RaydiumAmm is missing serumMarketKeys');
  }

  return {
    swapProgram: RAYDIUM_AMM_V4_PROGRAM_ID,
    tokenProgram: TOKEN_PROGRAM_ID,
    ammId: raydiumAmm.ammId,
    ammAuthority,
    ammOpenOrders: raydiumAmm.ammOpenOrders,
    poolCoinTokenAccount: raydiumAmm.poolCoinTokenAccount,
    poolPcTokenAccount: raydiumAmm.poolPcTokenAccount,
    serumProgramId: raydiumAmm.serumProgramId,
    serumMarket: raydiumAmm.serumMarket,
    serumBids: raydiumAmm.serumMarketKeys.serumBids,
    serumAsks: raydiumAmm.serumMarketKeys.serumAsks,
    serumEventQueue: raydiumAmm.serumMarketKeys.serumEventQueue,
    serumCoinVaultAccount: raydiumAmm.serumMarketKeys.serumCoinVaultAccount,
    serumPcVaultAccount: raydiumAmm.serumMarketKeys.serumPcVaultAccount,
    serumVaultSigner: raydiumAmm.serumMarketKeys.serumVaultSigner,
    userSourceTokenAccount: userSourceTokenAccount,
    userDestinationTokenAccount: userDestinationTokenAccount,
    userSourceOwner: userTransferAuthority,
  };
}

function marketIntoSerumSwap(
  market: Market,
  openOrdersAddress: PublicKey,
  orderPayerTokenAccountAddress: PublicKey,
  coinWallet: PublicKey,
  pcWallet: PublicKey,
  userTransferAuthority: PublicKey,
) {
  const vaultSigner = createProgramAddressSync(
    [market.address.toBuffer(), market.decoded.vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
    market.programId,
  );

  return {
    market: {
      market: market.address,
      openOrders: openOrdersAddress,
      requestQueue: market.decoded.requestQueue,
      eventQueue: market.decoded.eventQueue,
      bids: market.bidsAddress,
      asks: market.asksAddress,
      coinVault: market.decoded.baseVault,
      pcVault: market.decoded.quoteVault,
      vaultSigner,
    },
    authority: userTransferAuthority,
    orderPayerTokenAccount: orderPayerTokenAccountAddress,
    coinWallet,
    pcWallet,
    // Programs.
    dexProgram: market.programId,
    tokenProgram: TOKEN_PROGRAM_ID,
    // Sysvars.
    rent: SYSVAR_RENT_PUBKEY,
  };
}

export function createMercurialExchangeInstruction({
  swapLayout,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { swapLayout: MercurialSwapLayoutState } & CreateSwapInstructionParams): TransactionInstruction {
  const remainingAccounts: AccountMeta[] = [];

  for (const swapTokenAccount of swapLayout.tokenAccounts) {
    remainingAccounts.push({
      pubkey: swapTokenAccount,
      isSigner: false,
      isWritable: true,
    });
  }
  remainingAccounts.push(...prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount));

  return JUPITER_PROGRAM.instruction.mercurialExchange(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: stableSwapNPoolIntoMercurialExchange(
      swapLayout,
      userSourceTokenAccount,
      userDestinationTokenAccount,
      userTransferAuthority,
    ),
    remainingAccounts,
  });
}

export function createSerumSwapInstruction({
  market,
  sourceMint,
  openOrdersAddress,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
  referrer,
}: {
  market: Market;
  openOrdersAddress: PublicKey;
  referrer: PublicKey | undefined;
} & CreateSwapInstructionParams): TransactionInstruction {
  const { side, coinWallet, pcWallet } = sourceMint.equals(market.baseMintAddress)
    ? {
        side: Side.Ask,
        coinWallet: userSourceTokenAccount,
        pcWallet: userDestinationTokenAccount,
      }
    : {
        side: Side.Bid,
        coinWallet: userDestinationTokenAccount,
        pcWallet: userSourceTokenAccount,
      };

  let remainingAccounts = prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount);

  if (referrer) {
    remainingAccounts.push({
      pubkey: referrer,
      isSigner: false,
      isWritable: true,
    });
  }

  return JUPITER_PROGRAM.instruction.serumSwap(side, inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: marketIntoSerumSwap(
      market,
      openOrdersAddress,
      userSourceTokenAccount,
      coinWallet,
      pcWallet,
      userTransferAuthority,
    ),
    remainingAccounts,
  });
}

export function createTokenSwapInstruction({
  tokenSwapState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
  isStep,
}: { tokenSwapState: TokenSwapState; isStep: boolean } & CreateSwapInstructionParams): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(tokenSwapState.mintA)
    ? [tokenSwapState.tokenAccountA, tokenSwapState.tokenAccountB]
    : [tokenSwapState.tokenAccountB, tokenSwapState.tokenAccountA];

  return (isStep ? JUPITER_PROGRAM.instruction.stepTokenSwap : JUPITER_PROGRAM.instruction.tokenSwap)(
    inAmount,
    minimumOutAmount,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        tokenSwapProgram: tokenSwapState.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        swap: tokenSwapState.address,
        authority: tokenSwapState.authority,
        userTransferAuthority: userTransferAuthority,
        source: userSourceTokenAccount,
        swapSource,
        swapDestination,
        destination: userDestinationTokenAccount,
        poolMint: tokenSwapState.poolToken,
        poolFee: tokenSwapState.feeAccount,
      },
      remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
    },
  );
}

export function createSenchaSwapInstruction({
  poolState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { poolState: SenchaPoolState } & CreateSwapInstructionParams): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(poolState.token0Mint)
    ? [poolState.token0Reserves, poolState.token1Reserves]
    : [poolState.token1Reserves, poolState.token0Reserves];

  const [feesSource, feesDestination] = sourceMint.equals(poolState.token0Mint)
    ? [poolState.token0Fees, poolState.token1Fees]
    : [poolState.token1Fees, poolState.token0Fees];

  return JUPITER_PROGRAM.instruction.senchaExchange(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: poolState.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      swap: poolState.ammId,
      userAuthority: userTransferAuthority,
      inputUserAccount: userSourceTokenAccount,
      inputTokenAccount: swapSource,
      inputFeesAccount: feesSource,
      outputUserAccount: userDestinationTokenAccount,
      outputTokenAccount: swapDestination,
      outputFeesAccount: feesDestination,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createCropperSwapInstruction({
  poolState,
  feeAccount,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { poolState: CropperPoolState; feeAccount: PublicKey } & CreateSwapInstructionParams): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(poolState.mintA)
    ? [poolState.tokenAAccount, poolState.tokenBAccount]
    : [poolState.tokenBAccount, poolState.tokenAAccount];

  return JUPITER_PROGRAM.instruction.cropperTokenSwap(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      tokenSwapProgram: poolState.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      swap: poolState.ammId,
      swapState: CROPPER_STATE_ADDRESS,
      authority: poolState.authority,
      userTransferAuthority: userTransferAuthority,
      source: userSourceTokenAccount,
      swapSource,
      swapDestination,
      destination: userDestinationTokenAccount,
      poolMint: poolState.poolMint,
      poolFee: feeAccount,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createRaydiumSwapInstruction({
  raydiumAmm,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { raydiumAmm: RaydiumAmm } & CreateSwapInstructionParams): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.raydiumSwapV2(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: raydiumAmmToRaydiumSwap(
      raydiumAmm,
      userSourceTokenAccount,
      userDestinationTokenAccount,
      userTransferAuthority,
    ),
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createAldrinSwapInstruction({
  poolState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: {
  poolState: AldrinPoolState;
} & CreateSwapInstructionParams): TransactionInstruction {
  const [side, userBaseTokenAccount, userQuoteTokenAccount] = sourceMint.equals(poolState.baseTokenMint)
    ? [Side.Ask, userSourceTokenAccount, userDestinationTokenAccount]
    : [Side.Bid, userDestinationTokenAccount, userSourceTokenAccount];

  return JUPITER_PROGRAM.instruction.aldrinSwap(inAmount, minimumOutAmount, side, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: ALDRIN_SWAP_PROGRAM_ID,
      pool: poolState.address,
      poolSigner: poolState.poolSigner,
      poolMint: poolState.poolMint,
      baseTokenVault: poolState.baseTokenVault,
      quoteTokenVault: poolState.quoteTokenVault,
      feePoolTokenAccount: poolState.feePoolTokenAccount,
      walletAuthority: userTransferAuthority,
      userBaseTokenAccount,
      userQuoteTokenAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createAldrinV2SwapInstruction({
  poolState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  curve,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { poolState: AldrinPoolState; curve: PublicKey } & CreateSwapInstructionParams): TransactionInstruction {
  const [side, userBaseTokenAccount, userQuoteTokenAccount] = sourceMint.equals(poolState.baseTokenMint)
    ? [Side.Ask, userSourceTokenAccount, userDestinationTokenAccount]
    : [Side.Bid, userDestinationTokenAccount, userSourceTokenAccount];

  return JUPITER_PROGRAM.instruction.aldrinV2Swap(inAmount, minimumOutAmount, side, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: ALDRIN_SWAP_V2_PROGRAM_ID,
      pool: poolState.address,
      poolSigner: poolState.poolSigner,
      poolMint: poolState.poolMint,
      baseTokenVault: poolState.baseTokenVault,
      quoteTokenVault: poolState.quoteTokenVault,
      feePoolTokenAccount: poolState.feePoolTokenAccount,
      walletAuthority: userTransferAuthority,
      userBaseTokenAccount,
      userQuoteTokenAccount,
      curve,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createCremaSwapInstruction({
  poolState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { poolState: CremaPoolState } & CreateSwapInstructionParams): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(poolState.mintA)
    ? [poolState.tokenAAccount, poolState.tokenBAccount]
    : [poolState.tokenBAccount, poolState.tokenAAccount];

  return JUPITER_PROGRAM.instruction.cremaTokenSwap(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: poolState.programId,
      pool: poolState.ammId,
      poolSigner: poolState.authority,
      userSourceTokenAccount: userSourceTokenAccount,
      userDestinationTokenAccount: userDestinationTokenAccount,
      poolSourceTokenAccount: swapSource,
      poolDestinationTokenAccount: swapDestination,
      poolTicksAccount: poolState.ticksKey,
      walletAuthority: userTransferAuthority,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

export function createRiskCheckAndFeeInstruction(
  userDestinationTokenAccount: PublicKey,
  userTransferAuthority: PublicKey,
  minimumOutAmount: BN,
  tokenLedger: PublicKey,
  platformFee: PlatformFee | undefined,
): TransactionInstruction {
  const remainingAccounts: AccountMeta[] = [];

  if (platformFee?.feeAccount) {
    remainingAccounts.push({
      pubkey: platformFee.feeAccount,
      isSigner: false,
      isWritable: true,
    });
  }

  return JUPITER_PROGRAM.instruction.riskCheckAndFee(minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      tokenLedger,
      userDestinationTokenAccount,
      userTransferAuthority,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts,
  });
}

export function createSetTokenLedgerInstruction(
  tokenLedger: PublicKey,
  tokenAccountAddress: PublicKey,
): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.setTokenLedger({
    accounts: {
      tokenLedger,
      tokenAccount: tokenAccountAddress,
    },
  });
}

export function createInitializeTokenLedgerInstruction(
  tokenLedger: PublicKey,
  payer: PublicKey,
): TransactionInstruction {
  return JUPITER_PROGRAM.instruction.initializeTokenLedger({
    accounts: {
      tokenLedger,
      payer,
      systemProgram: SystemProgram.programId,
    },
  });
}

export function createOpenOrdersInstruction(
  market: Market,
  userTransferAuthority: PublicKey,
): [PublicKey, TransactionInstruction] {
  const [openOrders] = findProgramAddressSync(
    [Buffer.from('open_orders'), market.publicKey.toBuffer(), userTransferAuthority.toBuffer()],
    JUPITER_PROGRAM_ID,
  );

  const ix = JUPITER_PROGRAM.instruction.createOpenOrders({
    accounts: {
      openOrders,
      payer: userTransferAuthority,
      dexProgram: market.programId,
      systemProgram: SystemProgram.programId,
      rent: SYSVAR_RENT_PUBKEY,
      market: market.publicKey,
    },
  });
  return [openOrders, ix];
}

function saberPoolIntoSaberSwap(
  saberPool: StableSwap,
  sourceMintAddress: PublicKey,
  userSourceTokenAccount: PublicKey,
  userDestinationTokenAccount: PublicKey,
  userTransferAuthority: PublicKey,
) {
  const feesTokenAccount = sourceMintAddress.equals(saberPool.state.tokenA.mint)
    ? saberPool.state.tokenB.adminFeeAccount
    : saberPool.state.tokenA.adminFeeAccount;
  const [inputTokenAccount, outputTokenAccount] = sourceMintAddress.equals(saberPool.state.tokenA.mint)
    ? [saberPool.state.tokenA.reserve, saberPool.state.tokenB.reserve]
    : [saberPool.state.tokenB.reserve, saberPool.state.tokenA.reserve];

  return {
    swapProgram: saberPool.config.swapProgramID,
    tokenProgram: TOKEN_PROGRAM_ID,
    swap: saberPool.config.swapAccount,
    swapAuthority: saberPool.config.authority,
    userAuthority: userTransferAuthority,
    inputUserAccount: userSourceTokenAccount,
    inputTokenAccount,
    outputUserAccount: userDestinationTokenAccount,
    outputTokenAccount,
    feesTokenAccount,
  };
}

export function createSaberSwapInstruction({
  stableSwap,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { stableSwap: StableSwap } & CreateSwapInstructionParams): TransactionInstruction {
  const remainingAccounts = prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberSwap(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: saberPoolIntoSaberSwap(
      stableSwap,
      sourceMint,
      userSourceTokenAccount,
      userDestinationTokenAccount,
      userTransferAuthority,
    ),
    remainingAccounts,
  });
}

export function createSaberAddDecimalsDepositInstruction({
  addDecimals,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { addDecimals: AddDecimals } & CreateSwapInstructionParams) {
  const remainingAccounts = prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberAddDecimalsDeposit(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      addDecimalsProgram: SABER_ADD_DECIMALS_PROGRAM_ID,
      wrapper: addDecimals.wrapper,
      wrapperMint: addDecimals.mint,
      wrapperUnderlyingTokens: addDecimals.wrapperUnderlyingTokens,
      owner: userTransferAuthority,
      userUnderlyingTokens: userSourceTokenAccount,
      userWrappedTokens: userDestinationTokenAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts,
  });
}

export function createSaberAddDecimalsWithdrawInstruction({
  addDecimals,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { addDecimals: AddDecimals } & CreateSwapInstructionParams) {
  const remainingAccounts = prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount);
  return JUPITER_PROGRAM.instruction.saberAddDecimalsWithdraw(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      addDecimalsProgram: SABER_ADD_DECIMALS_PROGRAM_ID,
      wrapper: addDecimals.wrapper,
      wrapperMint: addDecimals.mint,
      wrapperUnderlyingTokens: addDecimals.wrapperUnderlyingTokens,
      owner: userTransferAuthority,
      userUnderlyingTokens: userDestinationTokenAccount,
      userWrappedTokens: userSourceTokenAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts,
  });
}

export function createLifinitySwapInstruction({
  swapState,
  sourceMint,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { swapState: LifinitySwapLayoutState } & CreateSwapInstructionParams): TransactionInstruction {
  const [swapSource, swapDestination] = sourceMint.equals(swapState.tokenAMint)
    ? [swapState.poolCoinTokenAccount, swapState.poolPcTokenAccount]
    : [swapState.poolPcTokenAccount, swapState.poolCoinTokenAccount];

  return JUPITER_PROGRAM.instruction.lifinityTokenSwap(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: swapState.programId,
      authority: swapState.authority,
      amm: swapState.amm,
      userTransferAuthority: userTransferAuthority,
      sourceInfo: userSourceTokenAccount,
      destinationInfo: userDestinationTokenAccount,
      swapSource,
      swapDestination,
      poolMint: swapState.poolMint,
      feeAccount: swapState.feeAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
      pythAccount: swapState.pythAccount,
      pythPcAccount: swapState.pythPcAccount,
      configAccount: swapState.configAccount,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

type CykuraSwapInstructionArgs = {
  poolAddress: PublicKey;
  inputVault: PublicKey;
  outputVault: PublicKey;
  nextObservationState: PublicKey;
  lastObservationState: PublicKey;
  swapAccountMetas: AccountMeta[];
};

export function createCykuraSwapInstruction({
  additionalArgs,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { additionalArgs: CykuraSwapInstructionArgs } & CreateSwapInstructionParams): TransactionInstruction {
  const remainingAccounts = prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount);

  return JUPITER_PROGRAM.instruction.cykuraSwap(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      swapProgram: CYKURA_PROGRAM_ID,
      signer: userTransferAuthority,
      factoryState: CYKURA_FACTORY_STATE_ADDRESS,
      poolState: additionalArgs.poolAddress,
      inputTokenAccount: userSourceTokenAccount,
      outputTokenAccount: userDestinationTokenAccount,
      inputVault: additionalArgs.inputVault,
      outputVault: additionalArgs.outputVault,
      lastObservationState: additionalArgs.lastObservationState,
      coreProgram: CYKURA_PROGRAM_ID, // Duplicated as in Cykura accounts
      tokenProgram: TOKEN_PROGRAM_ID,
    },
    remainingAccounts: remainingAccounts.concat([
      ...additionalArgs.swapAccountMetas,
      { pubkey: additionalArgs.nextObservationState, isSigner: false, isWritable: true },
    ]),
  });
}

type WhirlpoolSwapInstructionArgs = {
  aToB: boolean;
  whirlpool: PublicKey;
  tokenVaultA: PublicKey;
  tokenVaultB: PublicKey;
  tickArray0: PublicKey;
  tickArray1: PublicKey;
  tickArray2: PublicKey;
  oracle: PublicKey;
};

export function createWhirlpoolSwapInstruction({
  additionalArgs,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { additionalArgs: WhirlpoolSwapInstructionArgs } & CreateSwapInstructionParams): TransactionInstruction {
  const [tokenOwnerAccountA, tokenOwnerAccountB] = additionalArgs.aToB
    ? [userSourceTokenAccount, userDestinationTokenAccount]
    : [userDestinationTokenAccount, userSourceTokenAccount];

  return JUPITER_PROGRAM.instruction.whirlpoolSwap(
    inAmount,
    minimumOutAmount,
    additionalArgs.aToB,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        swapProgram: WHIRLPOOL_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenAuthority: userTransferAuthority,
        whirlpool: additionalArgs.whirlpool,
        tokenOwnerAccountA,
        tokenVaultA: additionalArgs.tokenVaultA,
        tokenOwnerAccountB,
        tokenVaultB: additionalArgs.tokenVaultB,
        tickArray0: additionalArgs.tickArray0,
        tickArray1: additionalArgs.tickArray1,
        tickArray2: additionalArgs.tickArray2,
        oracle: additionalArgs.oracle,
      },
      remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
    },
  );
}

type MarinadeFinanceDepositInstructionArgs = {
  address: PublicKey;
  marinadeStateResponse: MarinadeStateResponse;
  liqPoolSolLegPda: PublicKey;
  liqPoolMsolLegAuthority: PublicKey;
  reservePda: PublicKey;
  msolMintAuthority: PublicKey;
};

export function createMarinadeFinanceDepositInstruction({
  additionalArgs,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: { additionalArgs: MarinadeFinanceDepositInstructionArgs } & CreateSwapInstructionParams): TransactionInstruction {
  const transferFrom = userTransferAuthority;
  const tempWsolTokenAccount = findProgramAddressSync(
    [Buffer.from('temp-wsol-token-account'), transferFrom.toBuffer()],
    JUPITER_PROGRAM_ID,
  )[0];
  const tempSolPda = findProgramAddressSync(
    [Buffer.from('temp-sol-pda'), userTransferAuthority.toBuffer()],
    JUPITER_PROGRAM_ID,
  )[0];

  return JUPITER_PROGRAM.instruction.marinadeFinanceDeposit(inAmount, minimumOutAmount, platformFee?.feeBps ?? 0, {
    accounts: {
      marinadeFinanceProgram: MARINADE_PROGRAM_ID,
      state: additionalArgs.address,
      userTransferAuthority,
      msolMint: additionalArgs.marinadeStateResponse.msolMint,
      liqPoolSolLegPda: additionalArgs.liqPoolSolLegPda,
      liqPoolMsolLeg: additionalArgs.marinadeStateResponse.liqPool.msolLeg,
      liqPoolMsolLegAuthority: additionalArgs.liqPoolMsolLegAuthority,
      reservePda: additionalArgs.reservePda,
      transferFrom: tempSolPda,
      mintTo: userDestinationTokenAccount,
      msolMintAuthority: additionalArgs.msolMintAuthority,
      systemProgram: SystemProgram.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      userWsolTokenAccount: userSourceTokenAccount,
      tempWsolTokenAccount,
      wsolMint: NATIVE_MINT,
      rent: SYSVAR_RENT_PUBKEY,
    },
    remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
  });
}

type MarinadeFinanceLiquidUnstakeInstructionArgs = {
  address: PublicKey;
  marinadeStateResponse: MarinadeStateResponse;
  liqPoolSolLegPda: PublicKey;
};

export function createMarinadeFinanceLiquidUnstakeInstruction({
  additionalArgs,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  inAmount,
  minimumOutAmount,
  tokenLedger,
  platformFee,
}: {
  additionalArgs: MarinadeFinanceLiquidUnstakeInstructionArgs;
} & CreateSwapInstructionParams): TransactionInstruction {
  const tempSolPda = findProgramAddressSync(
    [Buffer.from('temp-sol-pda'), userTransferAuthority.toBuffer()],
    JUPITER_PROGRAM_ID,
  )[0];

  return JUPITER_PROGRAM.instruction.marinadeFinanceLiquidUnstake(
    inAmount,
    minimumOutAmount,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        marinadeFinanceProgram: MARINADE_PROGRAM_ID,
        state: additionalArgs.address,
        msolMint: additionalArgs.marinadeStateResponse.msolMint,
        liqPoolSolLegPda: additionalArgs.liqPoolSolLegPda,
        liqPoolMsolLeg: additionalArgs.marinadeStateResponse.liqPool.msolLeg,
        treasuryMsolAccount: additionalArgs.marinadeStateResponse.treasuryMsolAccount,
        getMsolFrom: userSourceTokenAccount,
        getMsolFromAuthority: userTransferAuthority,
        transferSolTo: tempSolPda,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        userWsolTokenAccount: userDestinationTokenAccount,
      },
      remainingAccounts: prepareRemainingAccounts(inAmount, tokenLedger, platformFee?.feeAccount),
    },
  );
}

export function createWhirlpoolSwapExactOutputInstruction({
  additionalArgs,
  userSourceTokenAccount,
  userDestinationTokenAccount,
  userTransferAuthority,
  outAmount,
  maximumInAmount,
  tokenLedger,
  platformFee,
}: { additionalArgs: WhirlpoolSwapInstructionArgs } & CreateSwapExactOutputInstructionParams): TransactionInstruction {
  const [tokenOwnerAccountA, tokenOwnerAccountB] = additionalArgs.aToB
    ? [userSourceTokenAccount, userDestinationTokenAccount]
    : [userDestinationTokenAccount, userSourceTokenAccount];

  return JUPITER_PROGRAM.instruction.whirlpoolSwapExactOutput(
    outAmount,
    maximumInAmount,
    additionalArgs.aToB,
    platformFee?.feeBps ?? 0,
    {
      accounts: {
        swapProgram: WHIRLPOOL_PROGRAM_ID,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenAuthority: userTransferAuthority,
        whirlpool: additionalArgs.whirlpool,
        tokenOwnerAccountA,
        tokenVaultA: additionalArgs.tokenVaultA,
        tokenOwnerAccountB,
        tokenVaultB: additionalArgs.tokenVaultB,
        tickArray0: additionalArgs.tickArray0,
        tickArray1: additionalArgs.tickArray1,
        tickArray2: additionalArgs.tickArray2,
        oracle: additionalArgs.oracle,
      },
      remainingAccounts: prepareRemainingAccounts(new BN(0), tokenLedger, platformFee?.feeAccount),
    },
  );
}

function prepareRemainingAccounts(
  inAmount: BN | null,
  tokenLedger: PublicKey,
  feeAccount: PublicKey | undefined,
): AccountMeta[] {
  const remainingAccounts = [];

  if (inAmount === null) {
    remainingAccounts.push({
      pubkey: tokenLedger,
      isSigner: false,
      isWritable: true,
    });
  }
  if (feeAccount) {
    remainingAccounts.push({
      pubkey: feeAccount,
      isSigner: false,
      isWritable: true,
    });
  }

  return remainingAccounts;
}
