/* eslint-disable */
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import JSBI from 'jsbi';

export interface ILiquidityManager {
  getLiquidityPairRatio(param: any, slippage?: number): Promise<Array<number>>;
  addLiquidityTransaction(
    con: Connection,
    wallet: any,
    lpData: any,
    originAmounts: Array<JSBI>,
    inAmounts: Array<JSBI>
  ): Promise<Transaction>;
  removeLiquidityTransaction(
    connection: Connection,
    wallet: any,
    lpData: any,
    amountToUnwind: JSBI,
  ): Promise<Transaction>;
  getEstimatedOutputLPAmount(connection: Connection, lpData: any, amounts: Array<JSBI>): Promise<any>;
  getEstimatedWithdrawAmount(connection: Connection, lpData: any, amount: JSBI): Promise<JSBI[]>;
  loadSwapInfo(con: Connection, lpData: any): Promise<any>;
  getUnderlyingMints(lpData: any): string[];
}

export abstract class LiquidityManager implements ILiquidityManager {
  abstract getLiquidityPairRatio(param: any, slippage?: number): Promise<number[]>;
  abstract addLiquidityTransaction(
    con: Connection,
    wallet: any,
    lpData: any,
    originAmounts: Array<JSBI>,
    inAmounts: Array<JSBI>
  ): Promise<Transaction>;
  abstract removeLiquidityTransaction(
    connection: Connection,
    wallet: any,
    lpData: any,
    amountToUnwind: JSBI,
  ): Promise<Transaction>;
  abstract getEstimatedOutputLPAmount(connection: Connection, lpData: any, amounts: Array<JSBI>): Promise<any>;
  abstract getEstimatedWithdrawAmount(connection: Connection, lpData: any, amounts: JSBI): Promise<JSBI[]>;
  abstract loadSwapInfo(con: Connection, lpData: any): Promise<any>;
  abstract getUnderlyingMints(lpData: any): string[];
}
