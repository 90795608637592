// https://github.com/raydium-io/raydium-ui/blob/4048286f79fc4b71c3ffbfd9095470ab0c7d3862/src/utils/liquidity.ts#L30-L82

import { Fraction, TokenSwapConstantProduct, ZERO_FRACTION } from '@jup-ag/math';
import { Market, OpenOrders } from '@project-serum/serum';
import { u64 } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';
import JSBI from 'jsbi';
import { createProgramAddressSyncUnsafe } from '../../utils/pda';
import { AccountInfoMap, Amm, mapAddressToAccountInfos, Quote, QuoteParams, SwapParams } from '../amm';
import { createRaydiumSwapInstruction } from '../jupiterInstruction';
import { AMM_INFO_LAYOUT_V4 } from './pools';

// Explained here
// https://discordapp.com/channels/813741812598439958/813750197423308820/900288485028683776
// total_pc = amminfo.pc_vault.balance + amminfo.openorder.total_quote - amminfo.need_taken_pnl_pc
// total_coin = amminfo.coin_vault.balance + amminfo.openorder.total_base - amminfo.need_taken_pnl_coin

type SerumMarketKeys = {
  serumBids: PublicKey;
  serumAsks: PublicKey;
  serumEventQueue: PublicKey;
  serumCoinVaultAccount: PublicKey;
  serumPcVaultAccount: PublicKey;
  serumVaultSigner: PublicKey;
};

type SerumMarketKeysString = Record<keyof SerumMarketKeys, string>;
export class RaydiumAmm implements Amm {
  id: string;
  label = 'Raydium' as const;
  shouldPrefetch = false;
  exactOutputSupported = false;

  coinMint: PublicKey;
  pcMint: PublicKey;

  status: number;
  serumProgramId: PublicKey;
  serumMarket: PublicKey;
  ammOpenOrders: PublicKey;
  ammTargetOrders: PublicKey;
  poolCoinTokenAccount: PublicKey;
  poolPcTokenAccount: PublicKey;

  serumMarketKeys: SerumMarketKeys;

  coinReserve: u64 | undefined;
  pcReserve: u64 | undefined;

  private feePct: Decimal;
  private calculator: TokenSwapConstantProduct;

  constructor(public ammId: PublicKey, ammAccountInfo: AccountInfo<Buffer>, params: SerumMarketKeysString) {
    this.id = ammId.toBase58();
    const decoded = AMM_INFO_LAYOUT_V4.decode(ammAccountInfo.data);

    this.status = decoded.status;
    this.coinMint = new PublicKey(decoded.coinMintAddress);
    this.pcMint = new PublicKey(decoded.pcMintAddress);

    this.poolCoinTokenAccount = new PublicKey(decoded.poolCoinTokenAccount);
    this.poolPcTokenAccount = new PublicKey(decoded.poolPcTokenAccount);

    this.serumProgramId = new PublicKey(decoded.serumProgramId);
    this.serumMarket = new PublicKey(decoded.serumMarket);
    this.ammOpenOrders = new PublicKey(decoded.ammOpenOrders);
    this.ammTargetOrders = new PublicKey(decoded.ammTargetOrders);

    this.serumMarketKeys = (Object.keys(params) as Array<keyof SerumMarketKeysString>).reduce((acc, item) => {
      const pk = params[item];
      if (!pk) throw new Error(`Could not find ${item} in params`);
      acc[item] = new PublicKey(params[item]);
      return acc;
    }, {} as SerumMarketKeys);

    const swapFeeNumerator = decoded.swapFeeNumerator;
    const swapFeeDenominator = decoded.swapFeeDenominator;

    this.feePct = new Decimal(swapFeeNumerator.toString()).div(swapFeeDenominator.toString());

    this.calculator = new TokenSwapConstantProduct(
      new Fraction(JSBI.BigInt(swapFeeNumerator), JSBI.BigInt(swapFeeDenominator)),
      ZERO_FRACTION,
    );
  }

  static decodeSerumMarketKeysString(
    serumProgramId: PublicKey,
    serumMarket: PublicKey,
    serumMarketInfo: AccountInfo<Buffer>,
  ): SerumMarketKeysString {
    const decodedMarket = Market.getLayout(serumProgramId).decode(serumMarketInfo.data);
    const serumVaultSigner = createProgramAddressSyncUnsafe(
      [serumMarket.toBuffer(), decodedMarket.vaultSignerNonce.toArrayLike(Buffer, 'le', 8)],
      serumProgramId,
    );

    return {
      serumBids: decodedMarket.bids.toBase58(),
      serumAsks: decodedMarket.asks.toBase58(),
      serumEventQueue: decodedMarket.eventQueue.toBase58(),
      serumCoinVaultAccount: decodedMarket.baseVault.toBase58(),
      serumPcVaultAccount: decodedMarket.quoteVault.toBase58(),
      serumVaultSigner: serumVaultSigner.toBase58(),
    };
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.ammId, this.poolCoinTokenAccount, this.poolPcTokenAccount, this.ammOpenOrders];
  }

  update(accountInfoMap: AccountInfoMap) {
    const [ammAccountInfo, poolCoinTokenAccountInfo, poolPcTokenAccountInfo, ammOpenOrdersAccountInfo] =
      mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    const [coinAmount, pcAmount] = [
      RaydiumAmm.tokenAmountAccessor(poolCoinTokenAccountInfo),
      RaydiumAmm.tokenAmountAccessor(poolPcTokenAccountInfo),
    ];

    const openOrders = OpenOrders.fromAccountInfo(
      this.ammOpenOrders,
      ammOpenOrdersAccountInfo,
      ammOpenOrdersAccountInfo.owner,
    );

    const decoded = AMM_INFO_LAYOUT_V4.decode(ammAccountInfo.data);

    this.coinReserve = coinAmount.add(openOrders.baseTokenTotal).sub(new u64(String(decoded.needTakePnlCoin)));

    this.pcReserve = pcAmount.add(openOrders.quoteTokenTotal).sub(new u64(String(decoded.needTakePnlPc)));
  }

  private static tokenAmountAccessor(tokenAccountInfo: AccountInfo<Buffer>): u64 {
    return u64.fromBuffer(tokenAccountInfo.data.slice(64, 64 + 8));
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    const { coinReserve, pcReserve } = this;
    if (!coinReserve || !pcReserve) {
      throw new Error('Pool token accounts balances not refreshed or empty');
    }

    const outputIndex = this.coinMint.equals(sourceMint) ? 1 : 0;
    const result = this.calculator.exchange([JSBI.BigInt(coinReserve), JSBI.BigInt(pcReserve)], amount, outputIndex);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: result.expectedOutputAmount,
      feeAmount: result.fees,
      feeMint: sourceMint.toBase58(),
      feePct: this.feePct.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    return [
      createRaydiumSwapInstruction({
        raydiumAmm: this,
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.coinMint, this.pcMint];
  }
}
