export type RatioSdk = {
  version: "0.1.0";
  name: "ratio_sdk";
  instructions: [
    {
      name: "addLiquidityToSaber";
      docs: ["* Add liqudity to saber, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataTokenATreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenBTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "swapAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapAccount";
          isMut: false;
          isSigner: false;
        },
        {
          name: "saberStableProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmountA";
          type: "u64";
        },
        {
          name: "oldAmountB";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromSaber";
      docs: ["* Remove liqudity to saber, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberSwapAccount";
          accounts: [
            {
              name: "ammId";
              isMut: false;
              isSigner: false;
            },
            {
              name: "authority";
              isMut: false;
              isSigner: false;
            },
            {
              name: "reserveA";
              isMut: true;
              isSigner: false;
            },
            {
              name: "reserveB";
              isMut: true;
              isSigner: false;
            },
            {
              name: "lpMint";
              isMut: true;
              isSigner: false;
            },
            {
              name: "feeAccountA";
              isMut: true;
              isSigner: false;
            },
            {
              name: "feeAccountB";
              isMut: true;
              isSigner: false;
            }
          ];
        },
        {
          name: "saberStableProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "addLiquidityToRaydium";
      docs: ["* Add liqudity to raydium"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataTokenATreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenBTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "modelData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumEventQueue";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumSwapProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "version";
          type: "u8";
        },
        {
          name: "oldAmountA";
          type: "u64";
        },
        {
          name: "oldAmountB";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromRaydiumV5";
      docs: ["* Remove Liquidity from Raydium v5, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "modelData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammLpMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumPcVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumCoinVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumEventQ";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumBids";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumAsks";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumVaultSigner";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumStableAmmProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromRaydiumV4";
      docs: ["* Remove Liquidity from Raydium v4, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammWithdrawQueue";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTempLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammLpMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumEventQueue";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumBids";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumAsks";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumPcVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumCoinVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumVaultSigner";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumAmmProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "wrapDecimalsToken";
      docs: ["* adding decimals via saber add decimals program"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ataUserUnderlyingToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserWrappedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "underlyingMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberDecimalsProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "unwrapDecimalsToken";
      docs: ["* removing decimals via saber add decimals program"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ataUserUnderlyingToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserWrappedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "underlyingMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberDecimalsProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmount";
          type: "u64";
        }
      ];
    }
  ];
  accounts: [
    {
      name: "globalState";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["Bump/nonce for the global state pda"];
            type: "u8";
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "treasury";
            docs: ["Public key for the treasury account"];
            type: "publicKey";
          },
          {
            name: "oracleReporter";
            docs: [
              "Public key for the account that can report new prices to the oracle accounts"
            ];
            type: "publicKey";
          },
          {
            name: "mintUsdr";
            docs: ["Mint address for USDr"];
            type: "publicKey";
          },
          {
            name: "tvlCollatCeilingUsd";
            docs: [
              "aliases: tvlCollatCeilingUsd;  prev: tvl_limit, tvl_limit_usd, tvlLimit"
            ];
            type: "u128";
          },
          {
            name: "tvlUsd";
            docs: ["total collateral amount in usd locked in the RatioLending"];
            type: "u128";
          },
          {
            name: "tvlCollat";
            docs: ["total collateral amount in usd per risk level"];
            type: {
              array: ["u128", 4];
            };
          },
          {
            name: "paused";
            docs: ["Is contract paused"];
            type: "u8";
          },
          {
            name: "totalDebt";
            docs: [
              "The total amount of debt minted via the Ratio platform, in USDr"
            ];
            type: "u64";
          },
          {
            name: "debtCeilingGlobal";
            docs: ["The limit on the global mintable debt, in USDr"];
            type: "u64";
          },
          {
            name: "debtCeilingUser";
            docs: ["The limit on the mintable debt per user, in USDr"];
            type: "u64";
          },
          {
            name: "unusedHarvestFeeNumer";
            docs: [
              "The numerator for calculating the fee, deprecated, use pool state value instead"
            ];
            type: "u64";
          },
          {
            name: "feeDeno";
            docs: ["The denomenator for calculating the fee"];
            type: "u64";
          },
          {
            name: "collPerRisklv";
            docs: ["The collateral per risk"];
            type: {
              array: ["u64", 10];
            };
          },
          {
            name: "ratioMint";
            type: "publicKey";
          },
          {
            name: "fundingWallet";
            type: "publicKey";
          },
          {
            name: "unusedBorrowFeeNumer";
            docs: ["deprecated, use pool state state value instead"];
            type: "u64";
          },
          {
            name: "instaswapFeeNumer";
            type: "u64";
          },
          {
            name: "liquidateCount";
            type: "u64";
          },
          {
            name: "pegTreasury";
            type: "publicKey";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 15];
            };
          }
        ];
      };
    }
  ];
  types: [
    {
      name: "PlatformType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Raydium";
          },
          {
            name: "Saber";
          },
          {
            name: "Swim";
          },
          {
            name: "Unknown";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "InstaswapOutputEvent";
      fields: [
        {
          name: "userWallet";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenAMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenBMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "feeAmountTokenA";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenB";
          type: "u64";
          index: false;
        },
        {
          name: "poolMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "outputLpAmount";
          type: "u64";
          index: false;
        },
        {
          name: "platformName";
          type: "string";
          index: false;
        }
      ];
    },
    {
      name: "InstaswapReverseEvent";
      fields: [
        {
          name: "userWallet";
          type: "publicKey";
          index: false;
        },
        {
          name: "poolMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "inputLpAmount";
          type: "u64";
          index: false;
        },
        {
          name: "tokenAMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenBMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "outputAAmount";
          type: "u64";
          index: false;
        },
        {
          name: "outputBAmount";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenA";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenB";
          type: "u64";
          index: false;
        },
        {
          name: "platformName";
          type: "string";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "You are not authorized to perform this action.";
    },
    {
      code: 6001;
      name: "AlreadyInUse";
      msg: "AlreadyInUse";
    },
    {
      code: 6002;
      name: "InvalidProgramAddress";
      msg: "InvalidProgramAddress";
    },
    {
      code: 6003;
      name: "InvalidState";
      msg: "InvalidState";
    },
    {
      code: 6004;
      name: "InvalidOwner";
      msg: "InvalidOwner";
    },
    {
      code: 6005;
      name: "NotAllowed";
      msg: "NotAllowed";
    },
    {
      code: 6006;
      name: "MathOverflow";
      msg: "Math operation overflow";
    },
    {
      code: 6007;
      name: "InvalidOracleConfig";
      msg: "InvalidOracleConfig";
    },
    {
      code: 6008;
      name: "InvalidAccountInput";
      msg: "InvalidAccountInput";
    },
    {
      code: 6009;
      name: "InvalidCluster";
      msg: "This function works on devnet only";
    },
    {
      code: 6010;
      name: "InvalidTransferAmount";
      msg: "Transfer amount is invalid";
    },
    {
      code: 6011;
      name: "InvalidPlatformType";
      msg: "Invalid platform type";
    },
    {
      code: 6012;
      name: "InvalidSaberPlatformType";
      msg: "Invalid platform, should be Saber";
    }
  ];
};

export const IDL: RatioSdk = {
  version: "0.1.0",
  name: "ratio_sdk",
  instructions: [
    {
      name: "addLiquidityToSaber",
      docs: ["* Add liqudity to saber, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataTokenATreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenBTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "swapAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapAccount",
          isMut: false,
          isSigner: false,
        },
        {
          name: "saberStableProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmountA",
          type: "u64",
        },
        {
          name: "oldAmountB",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromSaber",
      docs: ["* Remove liqudity to saber, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberSwapAccount",
          accounts: [
            {
              name: "ammId",
              isMut: false,
              isSigner: false,
            },
            {
              name: "authority",
              isMut: false,
              isSigner: false,
            },
            {
              name: "reserveA",
              isMut: true,
              isSigner: false,
            },
            {
              name: "reserveB",
              isMut: true,
              isSigner: false,
            },
            {
              name: "lpMint",
              isMut: true,
              isSigner: false,
            },
            {
              name: "feeAccountA",
              isMut: true,
              isSigner: false,
            },
            {
              name: "feeAccountB",
              isMut: true,
              isSigner: false,
            },
          ],
        },
        {
          name: "saberStableProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "addLiquidityToRaydium",
      docs: ["* Add liqudity to raydium"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataTokenATreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenBTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "modelData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumEventQueue",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumSwapProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "version",
          type: "u8",
        },
        {
          name: "oldAmountA",
          type: "u64",
        },
        {
          name: "oldAmountB",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromRaydiumV5",
      docs: ["* Remove Liquidity from Raydium v5, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "modelData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammLpMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumPcVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumCoinVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumEventQ",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumBids",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumAsks",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumVaultSigner",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumStableAmmProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromRaydiumV4",
      docs: ["* Remove Liquidity from Raydium v4, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammWithdrawQueue",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTempLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammLpMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumEventQueue",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumBids",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumAsks",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumPcVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumCoinVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumVaultSigner",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumAmmProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "wrapDecimalsToken",
      docs: ["* adding decimals via saber add decimals program"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ataUserUnderlyingToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserWrappedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "underlyingMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberDecimalsProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "unwrapDecimalsToken",
      docs: ["* removing decimals via saber add decimals program"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ataUserUnderlyingToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserWrappedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "underlyingMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberDecimalsProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmount",
          type: "u64",
        },
      ],
    },
  ],
  accounts: [
    {
      name: "globalState",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["Bump/nonce for the global state pda"],
            type: "u8",
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "treasury",
            docs: ["Public key for the treasury account"],
            type: "publicKey",
          },
          {
            name: "oracleReporter",
            docs: [
              "Public key for the account that can report new prices to the oracle accounts",
            ],
            type: "publicKey",
          },
          {
            name: "mintUsdr",
            docs: ["Mint address for USDr"],
            type: "publicKey",
          },
          {
            name: "tvlCollatCeilingUsd",
            docs: [
              "aliases: tvlCollatCeilingUsd;  prev: tvl_limit, tvl_limit_usd, tvlLimit",
            ],
            type: "u128",
          },
          {
            name: "tvlUsd",
            docs: ["total collateral amount in usd locked in the RatioLending"],
            type: "u128",
          },
          {
            name: "tvlCollat",
            docs: ["total collateral amount in usd per risk level"],
            type: {
              array: ["u128", 4],
            },
          },
          {
            name: "paused",
            docs: ["Is contract paused"],
            type: "u8",
          },
          {
            name: "totalDebt",
            docs: [
              "The total amount of debt minted via the Ratio platform, in USDr",
            ],
            type: "u64",
          },
          {
            name: "debtCeilingGlobal",
            docs: ["The limit on the global mintable debt, in USDr"],
            type: "u64",
          },
          {
            name: "debtCeilingUser",
            docs: ["The limit on the mintable debt per user, in USDr"],
            type: "u64",
          },
          {
            name: "unusedHarvestFeeNumer",
            docs: [
              "The numerator for calculating the fee, deprecated, use pool state value instead",
            ],
            type: "u64",
          },
          {
            name: "feeDeno",
            docs: ["The denomenator for calculating the fee"],
            type: "u64",
          },
          {
            name: "collPerRisklv",
            docs: ["The collateral per risk"],
            type: {
              array: ["u64", 10],
            },
          },
          {
            name: "ratioMint",
            type: "publicKey",
          },
          {
            name: "fundingWallet",
            type: "publicKey",
          },
          {
            name: "unusedBorrowFeeNumer",
            docs: ["deprecated, use pool state state value instead"],
            type: "u64",
          },
          {
            name: "instaswapFeeNumer",
            type: "u64",
          },
          {
            name: "liquidateCount",
            type: "u64",
          },
          {
            name: "pegTreasury",
            type: "publicKey",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 15],
            },
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "PlatformType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Raydium",
          },
          {
            name: "Saber",
          },
          {
            name: "Swim",
          },
          {
            name: "Unknown",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "InstaswapOutputEvent",
      fields: [
        {
          name: "userWallet",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenAMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenBMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "feeAmountTokenA",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenB",
          type: "u64",
          index: false,
        },
        {
          name: "poolMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "outputLpAmount",
          type: "u64",
          index: false,
        },
        {
          name: "platformName",
          type: "string",
          index: false,
        },
      ],
    },
    {
      name: "InstaswapReverseEvent",
      fields: [
        {
          name: "userWallet",
          type: "publicKey",
          index: false,
        },
        {
          name: "poolMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "inputLpAmount",
          type: "u64",
          index: false,
        },
        {
          name: "tokenAMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenBMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "outputAAmount",
          type: "u64",
          index: false,
        },
        {
          name: "outputBAmount",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenA",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenB",
          type: "u64",
          index: false,
        },
        {
          name: "platformName",
          type: "string",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "You are not authorized to perform this action.",
    },
    {
      code: 6001,
      name: "AlreadyInUse",
      msg: "AlreadyInUse",
    },
    {
      code: 6002,
      name: "InvalidProgramAddress",
      msg: "InvalidProgramAddress",
    },
    {
      code: 6003,
      name: "InvalidState",
      msg: "InvalidState",
    },
    {
      code: 6004,
      name: "InvalidOwner",
      msg: "InvalidOwner",
    },
    {
      code: 6005,
      name: "NotAllowed",
      msg: "NotAllowed",
    },
    {
      code: 6006,
      name: "MathOverflow",
      msg: "Math operation overflow",
    },
    {
      code: 6007,
      name: "InvalidOracleConfig",
      msg: "InvalidOracleConfig",
    },
    {
      code: 6008,
      name: "InvalidAccountInput",
      msg: "InvalidAccountInput",
    },
    {
      code: 6009,
      name: "InvalidCluster",
      msg: "This function works on devnet only",
    },
    {
      code: 6010,
      name: "InvalidTransferAmount",
      msg: "Transfer amount is invalid",
    },
    {
      code: 6011,
      name: "InvalidPlatformType",
      msg: "Invalid platform type",
    },
    {
      code: 6012,
      name: "InvalidSaberPlatformType",
      msg: "Invalid platform, should be Saber",
    },
  ],
};
