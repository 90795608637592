import {
  Keypair,
  Commitment,
  Connection,
  PublicKey,
  Transaction,
  VersionedTransaction,
  TransactionSignature,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
} from "@solana/spl-token";
import { deserializeMint, MintLayout } from "@saberhq/token-utils";
import { toJSBI } from "./math-utils";
import JSBI from "jsbi";

export const getPda = (seeds: Buffer[], programId: PublicKey | string) => {
  return anchor.utils.publicKey.findProgramAddressSync(
    seeds,
    new PublicKey(programId)
  );
};

export const getAta = (mint: string | PublicKey, owner: string | PublicKey) => {
  const [ata] = getPda(
    [
      new PublicKey(owner).toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      new PublicKey(mint).toBuffer(),
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID
  );
  return ata;
};
export const getTokenBalance = async (
  connection: Connection,
  walletKey: string | PublicKey,
  tokenAddress: string | PublicKey
): Promise<JSBI> => {
  const ataKey = getAta(tokenAddress, walletKey);
  try {
    return toJSBI(
      (await connection.getTokenAccountBalance(ataKey)).value.amount ?? 0
    );
  } catch {
    return toJSBI(0);
  }
};
export const getTokenUiBalance = async (
  connection: Connection,
  walletKey: string | PublicKey,
  tokenAddress: string | PublicKey
) => {
  if (NATIVE_MINT.equals(new PublicKey(tokenAddress))) {
    return (
      (await connection.getBalance(new PublicKey(walletKey))) / LAMPORTS_PER_SOL
    );
  }
  const ataKey = getAta(tokenAddress, walletKey);
  try {
    return (
      (await connection.getTokenAccountBalance(ataKey)).value.uiAmount ?? 0
    );
  } catch {
    return 0;
  }
};
export const getMintInfo = async (connection: Connection, key: any) => {
  try {
    const info = await connection.getAccountInfo(key);
    if (!info) return null;
    if (info.data.length !== MintLayout.span) {
      throw new Error("Not a valid Mint");
    }
    return deserializeMint(info?.data);
  } catch {
    return null;
  }
};

export const simulateTransaction = async (
  connection: Connection,
  tx: Transaction,
  userPublicKey: PublicKey
) => {
  const blockhashInfo = await connection.getLatestBlockhash();
  tx.recentBlockhash = blockhashInfo.blockhash;
  tx.feePayer = userPublicKey;
  const simulationResult = await connection.simulateTransaction(
    tx.compileMessage()
  );
  return simulationResult;
};
