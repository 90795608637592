import { Cluster, PublicKey } from '@solana/web3.js';
import { IDL } from './lib/idl/jupiter';

export const WRAPPED_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112');

export const MAINNET_SERUM_DEX_PROGRAM = new PublicKey('9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin');

export const DEVNET_SERUM_DEX_PROGRAM = new PublicKey('DESVgJVGajEgKGXhb6XmqDHGz3VjdgP7rEVESBgxmroY');

export const MARKETS_URL: Record<Cluster, string> = {
  devnet: 'https://api.jup.ag/api/markets/cache/devnet',
  'mainnet-beta': 'https://cache.jup.ag/markets?v=3',
  testnet: 'https://api.jup.ag/api/markets/cache/devnet',
};

export const INDEXED_ROUTE_MAP_URL = 'https://cache.jup.ag/indexed-route-map';

export const TOKEN_LIST_URL: Record<Cluster, string> = {
  devnet: 'https://api.jup.ag/api/tokens/devnet',
  testnet: 'https://api.jup.ag/api/markets/devnet',
  'mainnet-beta': 'https://cache.jup.ag/tokens',
};

export const LAMPORTS_PER_SIGNATURE = 5000;

export const RAYDIUM_AMM_V4_PROGRAM_ID = new PublicKey('675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8');

export const ALDRIN_SWAP_PROGRAM_ID = new PublicKey('AMM55ShdkoGRB5jVYPjWziwk8m5MpwyDgsMWHaMSQWH6');

// https://github.com/aldrin-exchange/aldrin-sdk/blob/f93fe3f2d847d79d8ddff507d8d4f62fd803421b/src/v2.json
export const ALDRIN_SWAP_V2_PROGRAM_ID = new PublicKey('CURVGoZn8zycx6FXwwevgBTB2gVvdbGTEpvMJDbgs2t4');

export const SABER_ADD_DECIMALS_PROGRAM_ID = new PublicKey('DecZY86MU5Gj7kppfUCEmd4LbXXuyZH1yHaP2NTqdiZB');

export const CROPPER_PROGRAM_ID = new PublicKey('CTMAxxk34HjKWxQ3QLZK1HpaLXmBveao3ESePXbiyfzh');

export const SENCHA_PROGRAM_ID = new PublicKey('SCHAtsf8mbjyjiv4LkhLKutTf6JnZAbdJKFkXQNMFHZ');

export const LIFINITY_PROGRAM_ID = new PublicKey('EewxydAPCCVuNEyrVN68PuSYdQ7wKn27V9Gjeoi8dy3S');

export const CREMA_PROGRAM_ID = new PublicKey('6MLxLqiXaaSUpkgMnWDTuejNZEz3kE7k2woyHGVFw319');

export const JUPITER_WALLET = new PublicKey('BUX7s2ef2htTGb2KKoPHWkmzxPj4nTWMWRgs5CSbQxf9');

export const MERCURIAL_SWAP_PROGRAM_ID = new PublicKey('MERLuDFBMmsHnsBPZw2sDQZHvXFMwp8EdjudcU2HKky');

export const WHIRLPOOL_PROGRAM_ID = new PublicKey('whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc');

export const CYKURA_PROGRAM_ID = new PublicKey('cysPXAjehMpVKUapzbMCCnpFxUFFryEWEaLgnb9NrR8');

export const CYKURA_FACTORY_STATE_ADDRESS = new PublicKey('DBsMwKfeoUHhxMi9x6wd2AsT12UwUCssjNbUzu1aKgqj');

export const MARINADE_PROGRAM_ID = new PublicKey('MarBmsSgKXdrN1egZf5sqe1TMai9K1rChYNDJgjq7aD');

export const STEPN_PROGRAM_ID = new PublicKey('Dooar9JkhdZ7J3LHN3A7YCuoGRUggXhQaG4kijfLGU2j');
export const ORCA_PROGRAM_ID = new PublicKey('9W959DqEETiGZocYWCQPaJ6sBmUzgfxXfqGeTEdp3aQP');
export const STEP_TOKEN_SWAP_PROGRAM_ID = new PublicKey('SSwpMgqNDsyV7mAgN9ady4bDVu5ySjmmXejXvy2vLt1');

export const PENGUIN_PROGRAM_ID = new PublicKey('PSwapMdSai8tjrEXcxFeQth87xC4rRsa4VA5mhGhXkP');
export const SAROS_PROGRAM_ID = new PublicKey('SSwapUtytfBdBn1b9NUGG6foMVPtcWgpRU32HToDUZr');

/** Tokens which are fundamental pivot in certain protocols */
export const SWAP_PROTOCOL_TOKENS = [
  'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT', // STEP
  'DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz', // CRP
];

export interface ErrorDetails {
  code: number;
  name: string;
  msg: string;
}

type JUPITER_ERROR_TYPES =
  | 'TransactionNotConfirmed'
  | 'BalancesNotExtractedProperly'
  | 'InsufficientSOL'
  | typeof IDL.errors[number]['name'];

export const JUPITER_ERRORS: Record<JUPITER_ERROR_TYPES, ErrorDetails> = Object.freeze({
  AlreadyInUse: {
    code: 1,
    name: 'AlreadyInUsed',
    msg: 'Account already In Used',
  },
  InsufficientSOL: {
    code: 1,
    name: 'InsufficientSOL',
    msg: 'Insufficient SOL',
  },
  TransactionNotConfirmed: {
    code: 7000, // a code that is not in the list of errors
    name: 'TransactionNotConfirmed',
    msg: 'Transaction was not confirmed',
  },
  BalancesNotExtractedProperly: {
    code: 7001, // a code that is not in the list of errors
    name: 'BalancesNotExtractedProperly',
    msg: 'Balances cannot be extracted properly',
  },
  ...IDL.errors.reduce((accErrors, error) => {
    return { ...accErrors, [error.name]: error };
  }, {} as Record<typeof IDL.errors[number]['name'], ErrorDetails>),
});

export const IS_DEV = process.env.NODE_ENV === 'development';

export const MIN_SEGMENT_SIZE_FOR_INTERMEDIATE_MINTS = 100;
