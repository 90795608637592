import { generateErrorMap } from "@saberhq/anchor-contrib";

export type RedeemerIDL = {
  version: "1.1.2";
  name: "redeemer";
  instructions: [
    {
      name: "createRedeemer";
      accounts: [
        {
          name: "redeemer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokens";
          accounts: [
            {
              name: "iouMint";
              isMut: false;
              isSigner: false;
            },
            {
              name: "redemptionMint";
              isMut: false;
              isSigner: false;
            },
            {
              name: "redemptionVault";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "bump";
          type: "u8";
        }
      ];
    },
    {
      name: "redeemTokens";
      accounts: [
        {
          name: "redeemer";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokens";
          accounts: [
            {
              name: "iouMint";
              isMut: true;
              isSigner: false;
            },
            {
              name: "redemptionMint";
              isMut: true;
              isSigner: false;
            },
            {
              name: "redemptionVault";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            }
          ];
        },
        {
          name: "sourceAuthority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "iouSource";
          isMut: true;
          isSigner: false;
        },
        {
          name: "redemptionDestination";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "redeemTokensFromMintProxy";
      accounts: [
        {
          name: "redeemCtx";
          accounts: [
            {
              name: "redeemer";
              isMut: false;
              isSigner: false;
            },
            {
              name: "tokens";
              accounts: [
                {
                  name: "iouMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "redemptionMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "redemptionVault";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "tokenProgram";
                  isMut: false;
                  isSigner: false;
                }
              ];
            },
            {
              name: "sourceAuthority";
              isMut: false;
              isSigner: true;
            },
            {
              name: "iouSource";
              isMut: true;
              isSigner: false;
            },
            {
              name: "redemptionDestination";
              isMut: true;
              isSigner: false;
            }
          ];
        },
        {
          name: "mintProxyState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proxyMintAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintProxyProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "minterInfo";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "redeemAllTokensFromMintProxy";
      accounts: [
        {
          name: "redeemCtx";
          accounts: [
            {
              name: "redeemer";
              isMut: false;
              isSigner: false;
            },
            {
              name: "tokens";
              accounts: [
                {
                  name: "iouMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "redemptionMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "redemptionVault";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "tokenProgram";
                  isMut: false;
                  isSigner: false;
                }
              ];
            },
            {
              name: "sourceAuthority";
              isMut: false;
              isSigner: true;
            },
            {
              name: "iouSource";
              isMut: true;
              isSigner: false;
            },
            {
              name: "redemptionDestination";
              isMut: true;
              isSigner: false;
            }
          ];
        },
        {
          name: "mintProxyState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "proxyMintAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintProxyProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "minterInfo";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "Redeemer";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "iouMint";
            type: "publicKey";
          },
          {
            name: "redemptionMint";
            type: "publicKey";
          },
          {
            name: "redemptionVault";
            type: "publicKey";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "RedeemTokensEvent";
      fields: [
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "iouMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "destinationMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "amount";
          type: "u64";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "Unauthorized.";
    },
    {
      code: 6001;
      name: "DecimalsMismatch";
      msg: "Redemption token and IOU token decimals must match";
    }
  ];
};
export const RedeemerJSON: RedeemerIDL = {
  version: "1.1.2",
  name: "redeemer",
  instructions: [
    {
      name: "createRedeemer",
      accounts: [
        {
          name: "redeemer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokens",
          accounts: [
            {
              name: "iouMint",
              isMut: false,
              isSigner: false,
            },
            {
              name: "redemptionMint",
              isMut: false,
              isSigner: false,
            },
            {
              name: "redemptionVault",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "bump",
          type: "u8",
        },
      ],
    },
    {
      name: "redeemTokens",
      accounts: [
        {
          name: "redeemer",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokens",
          accounts: [
            {
              name: "iouMint",
              isMut: true,
              isSigner: false,
            },
            {
              name: "redemptionMint",
              isMut: true,
              isSigner: false,
            },
            {
              name: "redemptionVault",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
          ],
        },
        {
          name: "sourceAuthority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "iouSource",
          isMut: true,
          isSigner: false,
        },
        {
          name: "redemptionDestination",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "redeemTokensFromMintProxy",
      accounts: [
        {
          name: "redeemCtx",
          accounts: [
            {
              name: "redeemer",
              isMut: false,
              isSigner: false,
            },
            {
              name: "tokens",
              accounts: [
                {
                  name: "iouMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "redemptionMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "redemptionVault",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "tokenProgram",
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: "sourceAuthority",
              isMut: false,
              isSigner: true,
            },
            {
              name: "iouSource",
              isMut: true,
              isSigner: false,
            },
            {
              name: "redemptionDestination",
              isMut: true,
              isSigner: false,
            },
          ],
        },
        {
          name: "mintProxyState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proxyMintAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintProxyProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "minterInfo",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "redeemAllTokensFromMintProxy",
      accounts: [
        {
          name: "redeemCtx",
          accounts: [
            {
              name: "redeemer",
              isMut: false,
              isSigner: false,
            },
            {
              name: "tokens",
              accounts: [
                {
                  name: "iouMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "redemptionMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "redemptionVault",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "tokenProgram",
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: "sourceAuthority",
              isMut: false,
              isSigner: true,
            },
            {
              name: "iouSource",
              isMut: true,
              isSigner: false,
            },
            {
              name: "redemptionDestination",
              isMut: true,
              isSigner: false,
            },
          ],
        },
        {
          name: "mintProxyState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "proxyMintAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintProxyProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "minterInfo",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "Redeemer",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            type: "u8",
          },
          {
            name: "iouMint",
            type: "publicKey",
          },
          {
            name: "redemptionMint",
            type: "publicKey",
          },
          {
            name: "redemptionVault",
            type: "publicKey",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "RedeemTokensEvent",
      fields: [
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "iouMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "destinationMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "amount",
          type: "u64",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "Unauthorized.",
    },
    {
      code: 6001,
      name: "DecimalsMismatch",
      msg: "Redemption token and IOU token decimals must match",
    },
  ],
};
export const RedeemerErrors = generateErrorMap(RedeemerJSON);
