import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import {
  AccountInfoMap,
  Amm,
  mapAddressToAccountInfos,
  Quote,
  QuoteParams,
  tokenAccountsToJSBIs,
  SwapParams,
} from '../amm';
import { accountInfoToSenchaPoolState, SenchaPoolState } from './swapLayout';
import { AccountInfo as TokenAccountInfo } from '@solana/spl-token';
import { deserializeAccount } from '@mercurial-finance/optimist';
import { createSenchaSwapInstruction } from '../jupiterInstruction';
import { Fraction, TokenSwapConstantProduct, ZERO } from '@jup-ag/math';
import JSBI from 'jsbi';

export class SenchaAmm implements Amm {
  id: string;
  label = 'Sencha' as const;
  shouldPrefetch = false;
  exactOutputSupported = false;

  poolState: SenchaPoolState;
  private calculator: TokenSwapConstantProduct;
  private tokenAccounts: TokenAccountInfo[] = [];

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>) {
    this.id = address.toBase58();
    this.poolState = accountInfoToSenchaPoolState(address, accountInfo);
    this.calculator = new TokenSwapConstantProduct(
      new Fraction(
        JSBI.BigInt(this.poolState.tradeFeeKbps),
        JSBI.BigInt(10000000), // 100% = 10000000kbps
      ),
      new Fraction(ZERO, ZERO),
      false,
    );
  }

  get isPaused() {
    return this.poolState.isPaused;
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.poolState.token0Reserves, this.poolState.token1Reserves];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const tokenAccountInfos = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    this.tokenAccounts = tokenAccountInfos.map((info) => {
      const tokenAccount = deserializeAccount(info.data);
      if (!tokenAccount) {
        throw new Error('Invalid token account');
      }
      return tokenAccount;
    });
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (this.tokenAccounts.length === 0) {
      throw new Error('Unable to fetch accounts for specified tokens.');
    }

    const outputIndex = this.tokenAccounts[0].mint.equals(sourceMint) ? 1 : 0;
    let result = this.calculator.exchange(tokenAccountsToJSBIs(this.tokenAccounts), amount, outputIndex);

    let feePct = this.poolState.tradeFeeKbps / 10000000; // 100% kbps

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: result.expectedOutputAmount,
      feeAmount: result.fees,
      feeMint: sourceMint.toBase58(),
      feePct,
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[] {
    return [
      createSenchaSwapInstruction({
        poolState: this.poolState,
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.poolState.token0Mint, this.poolState.token1Mint];
  }
}
