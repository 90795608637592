import { generateErrorMap } from "@saberhq/anchor-contrib";

export type MintProxyIDL = {
  version: "1.1.2";
  name: "mint_proxy";
  instructions: [];
  state: {
    struct: {
      name: "MintProxy";
      type: {
        kind: "struct";
        fields: [
          {
            name: "nonce";
            type: "u8";
          },
          {
            name: "hardCap";
            type: "u64";
          },
          {
            name: "proxyMintAuthority";
            type: "publicKey";
          },
          {
            name: "owner";
            type: "publicKey";
          },
          {
            name: "pendingOwner";
            type: "publicKey";
          },
          {
            name: "stateAssociatedAccount";
            type: "publicKey";
          },
          {
            name: "tokenMint";
            type: "publicKey";
          }
        ];
      };
    };
    methods: [
      {
        name: "new";
        accounts: [
          {
            name: "mintAuthority";
            isMut: false;
            isSigner: true;
          },
          {
            name: "proxyMintAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "owner";
            isMut: false;
            isSigner: false;
          },
          {
            name: "tokenMint";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "nonce";
            type: "u8";
          },
          {
            name: "hardCap";
            type: "u64";
          }
        ];
      },
      {
        name: "transferOwnership";
        accounts: [
          {
            name: "owner";
            isMut: false;
            isSigner: true;
          }
        ];
        args: [
          {
            name: "nextOwner";
            type: "publicKey";
          }
        ];
      },
      {
        name: "acceptOwnership";
        accounts: [
          {
            name: "owner";
            isMut: false;
            isSigner: true;
          }
        ];
        args: [];
      },
      {
        name: "minterAdd";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "minter";
            isMut: false;
            isSigner: false;
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          },
          {
            name: "payer";
            isMut: true;
            isSigner: true;
          },
          {
            name: "rent";
            isMut: false;
            isSigner: false;
          },
          {
            name: "systemProgram";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "allowance";
            type: "u64";
          }
        ];
      },
      {
        name: "minterUpdate";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "allowance";
            type: "u64";
          }
        ];
      },
      {
        name: "minterRemove";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "minter";
            isMut: false;
            isSigner: false;
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          },
          {
            name: "payer";
            isMut: true;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "performMint";
        accounts: [
          {
            name: "proxyMintAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "minter";
            isMut: false;
            isSigner: true;
          },
          {
            name: "tokenMint";
            isMut: true;
            isSigner: false;
          },
          {
            name: "destination";
            isMut: true;
            isSigner: false;
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "amount";
            type: "u64";
          }
        ];
      },
      {
        name: "setMintAuthority";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "proxyMintAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "tokenMint";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "newAuthority";
            type: "publicKey";
          }
        ];
      }
    ];
  };
  accounts: [
    {
      name: "MinterInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "minter";
            type: "publicKey";
          },
          {
            name: "allowance";
            type: "u64";
          },
          {
            name: "nonce";
            type: "u8";
          }
        ];
      };
    },
    {
      name: "MintProxyInfo";
      type: {
        kind: "struct";
        fields: [
          {
            name: "nonce";
            type: "u8";
          },
          {
            name: "hardCap";
            type: "u64";
          },
          {
            name: "proxyMintAuthority";
            type: "publicKey";
          },
          {
            name: "owner";
            type: "publicKey";
          },
          {
            name: "pendingOwner";
            type: "publicKey";
          },
          {
            name: "stateAssociatedAccount";
            type: "publicKey";
          },
          {
            name: "tokenMint";
            type: "publicKey";
          }
        ];
      };
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "You are not authorized to perform this action.";
    },
    {
      code: 6001;
      name: "HardcapExceeded";
      msg: "Cannot mint over hard cap.";
    },
    {
      code: 6002;
      name: "InvalidFreezeAuthority";
      msg: "Provided token mint has a freeze authority";
    },
    {
      code: 6003;
      name: "InvalidTokenMint";
      msg: "Provided token mint was invalid.";
    },
    {
      code: 6004;
      name: "InvalidProxyAuthority";
      msg: "Provided proxy authority was invalid.";
    },
    {
      code: 6005;
      name: "NotEnoughAccounts";
      msg: "Not enough remaining accounts in relay context.";
    },
    {
      code: 6006;
      name: "WhitelistEntryAlreadyExists";
      msg: "Whitelist entry already exists.";
    },
    {
      code: 6007;
      name: "WhitelistEntryNotFound";
      msg: "Whitelist entry not found.";
    },
    {
      code: 6008;
      name: "WhitelistFull";
      msg: "Whitelist is full.";
    },
    {
      code: 6009;
      name: "TokenProgramIDMismatch";
      msg: "Invalid token program ID.";
    },
    {
      code: 6010;
      name: "PendingOwnerMismatch";
      msg: "Pending owner mismatch.";
    },
    {
      code: 6011;
      name: "MinterAllowanceExceeded";
      msg: "Minter allowance exceeded.";
    },
    {
      code: 6012;
      name: "U64Overflow";
      msg: "U64 overflow.";
    }
  ];
};
export const MintProxyJSON: MintProxyIDL = {
  version: "1.1.2",
  name: "mint_proxy",
  instructions: [],
  state: {
    struct: {
      name: "MintProxy",
      type: {
        kind: "struct",
        fields: [
          {
            name: "nonce",
            type: "u8",
          },
          {
            name: "hardCap",
            type: "u64",
          },
          {
            name: "proxyMintAuthority",
            type: "publicKey",
          },
          {
            name: "owner",
            type: "publicKey",
          },
          {
            name: "pendingOwner",
            type: "publicKey",
          },
          {
            name: "stateAssociatedAccount",
            type: "publicKey",
          },
          {
            name: "tokenMint",
            type: "publicKey",
          },
        ],
      },
    },
    methods: [
      {
        name: "new",
        accounts: [
          {
            name: "mintAuthority",
            isMut: false,
            isSigner: true,
          },
          {
            name: "proxyMintAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "owner",
            isMut: false,
            isSigner: false,
          },
          {
            name: "tokenMint",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "nonce",
            type: "u8",
          },
          {
            name: "hardCap",
            type: "u64",
          },
        ],
      },
      {
        name: "transferOwnership",
        accounts: [
          {
            name: "owner",
            isMut: false,
            isSigner: true,
          },
        ],
        args: [
          {
            name: "nextOwner",
            type: "publicKey",
          },
        ],
      },
      {
        name: "acceptOwnership",
        accounts: [
          {
            name: "owner",
            isMut: false,
            isSigner: true,
          },
        ],
        args: [],
      },
      {
        name: "minterAdd",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "minter",
            isMut: false,
            isSigner: false,
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
          {
            name: "payer",
            isMut: true,
            isSigner: true,
          },
          {
            name: "rent",
            isMut: false,
            isSigner: false,
          },
          {
            name: "systemProgram",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "allowance",
            type: "u64",
          },
        ],
      },
      {
        name: "minterUpdate",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "allowance",
            type: "u64",
          },
        ],
      },
      {
        name: "minterRemove",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "minter",
            isMut: false,
            isSigner: false,
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
          {
            name: "payer",
            isMut: true,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "performMint",
        accounts: [
          {
            name: "proxyMintAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "minter",
            isMut: false,
            isSigner: true,
          },
          {
            name: "tokenMint",
            isMut: true,
            isSigner: false,
          },
          {
            name: "destination",
            isMut: true,
            isSigner: false,
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "amount",
            type: "u64",
          },
        ],
      },
      {
        name: "setMintAuthority",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "proxyMintAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "tokenMint",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "newAuthority",
            type: "publicKey",
          },
        ],
      },
    ],
  },
  accounts: [
    {
      name: "MinterInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "minter",
            type: "publicKey",
          },
          {
            name: "allowance",
            type: "u64",
          },
          {
            name: "nonce",
            type: "u8",
          },
        ],
      },
    },
    {
      name: "MintProxyInfo",
      type: {
        kind: "struct",
        fields: [
          {
            name: "nonce",
            type: "u8",
          },
          {
            name: "hardCap",
            type: "u64",
          },
          {
            name: "proxyMintAuthority",
            type: "publicKey",
          },
          {
            name: "owner",
            type: "publicKey",
          },
          {
            name: "pendingOwner",
            type: "publicKey",
          },
          {
            name: "stateAssociatedAccount",
            type: "publicKey",
          },
          {
            name: "tokenMint",
            type: "publicKey",
          },
        ],
      },
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "You are not authorized to perform this action.",
    },
    {
      code: 6001,
      name: "HardcapExceeded",
      msg: "Cannot mint over hard cap.",
    },
    {
      code: 6002,
      name: "InvalidFreezeAuthority",
      msg: "Provided token mint has a freeze authority",
    },
    {
      code: 6003,
      name: "InvalidTokenMint",
      msg: "Provided token mint was invalid.",
    },
    {
      code: 6004,
      name: "InvalidProxyAuthority",
      msg: "Provided proxy authority was invalid.",
    },
    {
      code: 6005,
      name: "NotEnoughAccounts",
      msg: "Not enough remaining accounts in relay context.",
    },
    {
      code: 6006,
      name: "WhitelistEntryAlreadyExists",
      msg: "Whitelist entry already exists.",
    },
    {
      code: 6007,
      name: "WhitelistEntryNotFound",
      msg: "Whitelist entry not found.",
    },
    {
      code: 6008,
      name: "WhitelistFull",
      msg: "Whitelist is full.",
    },
    {
      code: 6009,
      name: "TokenProgramIDMismatch",
      msg: "Invalid token program ID.",
    },
    {
      code: 6010,
      name: "PendingOwnerMismatch",
      msg: "Pending owner mismatch.",
    },
    {
      code: 6011,
      name: "MinterAllowanceExceeded",
      msg: "Minter allowance exceeded.",
    },
    {
      code: 6012,
      name: "U64Overflow",
      msg: "U64 overflow.",
    },
  ],
};
export const MintProxyErrors = generateErrorMap(MintProxyJSON);
