import { deserializeAccount } from '@mercurial-finance/optimist';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, PublicKey, SystemProgram, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import { WRAPPED_SOL_MINT } from '../constants';
import { getEmptyInstruction, Instruction } from './instruction';
import { Owner } from './Owner';

// Leverage the existing ATA when present
export async function createAndCloseWSOLAccount({
  connection,
  amount,
  owner: { publicKey },
}: {
  connection: Connection;
  owner: Owner;
  amount: JSBI;
}): Promise<Instruction & { address: PublicKey }> {
  const result = getEmptyInstruction();
  result.instructions = [];

  const toAccount = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    WRAPPED_SOL_MINT,
    publicKey,
    true,
  );

  const info = await connection.getAccountInfo(toAccount);

  if (info === null) {
    result.instructions.push(
      createAssociatedTokenAccountInstruction(publicKey, toAccount, publicKey, WRAPPED_SOL_MINT),
    );
  }

  // Fund account and sync
  result.instructions.push(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: toAccount,
      lamports: JSBI.toNumber(amount),
    }),
  );
  result.instructions.push(
    // This is not exposed by the types, but indeed it exists
    (Token as any).createSyncNativeInstruction(TOKEN_PROGRAM_ID, toAccount),
  );

  result.cleanupInstructions = [
    Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, toAccount, publicKey, publicKey, []),
  ];

  return {
    address: toAccount,
    ...result,
  };
}

export async function findOrCreateAssociatedAccountByMint({
  connection,
  payer,
  owner: { publicKey },
  mintAddress,
  unwrapSOL,
}: {
  connection: Connection;
  payer: PublicKey;
  owner: Owner;
  mintAddress: PublicKey | string;
  unwrapSOL: boolean;
}): Promise<Instruction & { address: PublicKey }> {
  const mint = typeof mintAddress === 'string' ? new PublicKey(mintAddress) : mintAddress;
  const toAccount = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mint,
    publicKey,
    true,
  );
  const cleanupInstructions: TransactionInstruction[] = [];
  const instructions: TransactionInstruction[] = [];

  const info = await connection.getAccountInfo(toAccount);

  if (info === null) {
    instructions.push(createAssociatedTokenAccountInstruction(payer, toAccount, publicKey, mint));
  } else {
    const tokenAccountInfo = deserializeAccount(info.data);

    if (tokenAccountInfo && !tokenAccountInfo.owner.equals(publicKey)) {
      // What to do at the top level in UIs and SDK?
      throw new Error(`/!\ ATA ${toAccount.toBase58()} is not owned by ${publicKey.toBase58()}`);
    }
  }

  // We close it when wrapped SOL
  if (mint.equals(WRAPPED_SOL_MINT) && unwrapSOL) {
    cleanupInstructions.push(
      Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, toAccount, publicKey, publicKey, []),
    );
  }

  return {
    address: toAccount,
    instructions: instructions,
    cleanupInstructions,
    signers: [],
  };
}

// 0.1.x @solana/spl-token does not have the version without the rent sysvar
// Source: https://github.com/solana-labs/solana-program-library/blob/dc5684445f0b42ba36a0157f06c561d967a7cb34/associated-token-account/program/src/instruction.rs#L16-L25
export function createAssociatedTokenAccountInstruction(
  payer: PublicKey,
  associatedToken: PublicKey,
  owner: PublicKey,
  mint: PublicKey,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID,
): TransactionInstruction {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedToken, isSigner: false, isWritable: true },
    { pubkey: owner, isSigner: false, isWritable: false },
    { pubkey: mint, isSigner: false, isWritable: false },
    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
    { pubkey: programId, isSigner: false, isWritable: false },
  ];

  return new TransactionInstruction({
    keys,
    programId: associatedTokenProgramId,
    data: Buffer.alloc(0),
  });
}
