import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { chunks } from './chunks';

interface Result {
  jsonrpc: string;
  result: {
    context: { slot: number };
    value: (AccountInfo<string[]> | null)[];
  };
}

export async function chunkedGetMultipleAccountInfos(
  connection: Connection,
  pks: string[],
  batchChunkSize: number = 1000,
  maxAccountsChunkSize: number = 100,
): Promise<Array<AccountInfo<Buffer> | null>> {
  return (
    await Promise.all(
      chunks(pks, batchChunkSize).map(async (batchPubkeys) => {
        const batch = chunks(batchPubkeys, maxAccountsChunkSize).map((pubkeys) => ({
          methodName: 'getMultipleAccounts',
          args: connection._buildArgs([pubkeys], connection.commitment, 'base64'),
        }));

        return (
          // getMultipleAccounts is quite slow, so we use fetch directly
          connection
            // @ts-ignore
            ._rpcBatchRequest(batch)
            .then((batchResults: Result[]) => {
              const accounts = batchResults.reduce((acc, res) => {
                res.result.value.forEach((item) => {
                  if (item) {
                    const value = item as unknown as AccountInfo<Buffer>;
                    value.data = Buffer.from(item.data[0], item.data[1] as 'base64');
                    value.owner = new PublicKey(item.owner);
                    acc.push(value);
                  } else {
                    acc.push(null);
                  }
                });
                return acc;
              }, [] as (AccountInfo<Buffer> | null)[]);

              return accounts;
            })
            .catch((e: any) => {
              console.error('Failed to fetch account infos', e);
              return batchPubkeys.map(() => null);
            })
        );
      }),
    )
  ).flat();
}
