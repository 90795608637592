import { Connection, PublicKey } from '@solana/web3.js';
import { chunkedGetMultipleAccountInfos } from '../utils/chunkedGetMultipleAccountInfos';
import { Amm, SwapMode, Quote } from './amm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { isValidRoute, MarketInfo } from './market';
import { ammCrossProtocolPairs, isPlatformFeeSupported, Route, RouteInfo, TransactionFeeInfo } from './routes';
import { TokenRouteSegments } from './types';
import { IS_DEV } from '../constants';
import fastCartesian from '../utils/cartesian';
import JSBI from 'jsbi';
import { ZERO } from '@jup-ag/math';
import Decimal from 'decimal.js';

const PLATFORM_FEE_DENOMINATOR = JSBI.BigInt(10000);

export async function fetchAccountInfos(connection: Connection, routes: TokenRouteSegments): Promise<void> {
  const accountInfosMap = new Map();

  const accountsToFetchSet = new Set<string>();
  const ammMap = new Map<string, Amm>();
  routes.forEach((innerMap) => {
    innerMap.forEach((amms) => {
      amms.forEach((amm) => {
        ammMap.set(amm.id, amm);
        amm.getAccountsForUpdate().forEach((account) => {
          // Only add accountInfos that is not in the Map
          accountsToFetchSet.add(account.toBase58());
        });
      });
    });
  });

  const accountsToFetch = Array.from(accountsToFetchSet);

  if (accountsToFetch.length > 0) {
    const accountInfos = await chunkedGetMultipleAccountInfos(connection, accountsToFetch);

    accountInfos.forEach((item, index) => {
      const publicKey = accountsToFetch[index];
      if (item) {
        accountInfosMap.set(publicKey, item);
      }
    });

    ammMap.forEach((amm) => {
      amm.update(accountInfosMap);
    });
  }
}

interface GetQuotesParams {
  inputRouteSegment: TokenRouteSegments;
  amount: JSBI;
  inputMint: PublicKey;
  outputMint: PublicKey;
  platformFeeBps: number;
  slippage: number;
  filterTopNResult?: number;
  onlyDirectRoutes?: boolean;
  swapMode: SwapMode;
  getDepositAndFeeForRoute: (params: {
    marketInfos: RouteInfo['marketInfos'];
  }) => Promise<TransactionFeeInfo | undefined>;
}

function getInputOutputId({ inputMint, outputMint }: { inputMint: string; outputMint: string }) {
  return `${inputMint}-${outputMint}`;
}

function getQuoteId({ ammId, amount }: { ammId: string; amount: JSBI }) {
  return `${ammId}-${amount.toString()}`;
}

type QuoteMap = Map<string, Quote>;

function getQuoteAndSortBasedOnOutAmount({
  amms,
  inputMint,
  outputMint,
  amount,
  swapMode,
}: {
  amms: Amm[];
  inputMint: string;
  outputMint: string;
  amount: JSBI;
  swapMode: SwapMode;
}): Array<{ amm: Amm; quote: Quote }> {
  const quotes = amms
    .map((amm) => {
      try {
        const quote = amm.getQuote({
          amount,
          sourceMint: new PublicKey(inputMint),
          destinationMint: new PublicKey(outputMint),
          swapMode,
        });
        return { quote, amm: amm };
      } catch (e) {
        if (IS_DEV) {
          console.error(e);
        }
        return undefined;
      }
    })
    .filter(Boolean)
    .sort((a, b) =>
      JSBI.greaterThanOrEqual(b?.quote.outAmount || ZERO, a?.quote.outAmount || ZERO) ? 1 : -1,
    ) as Array<{
    amm: Amm;
    quote: Quote;
  }>;

  return quotes;
}

// Change this to support N-1 level of hops
const MAX_LEVEL = 2;

export function processInputRouteSegmentToRoutesInfos({
  inputRouteSegment,
  inputMint,
  outputMint,
  amount,
  getDepositAndFeeForRoute,
  platformFeeBps,
  slippage,
  filterTopNResult = 3,
  onlyDirectRoutes,
  swapMode,
}: GetQuotesParams) {
  const inputMintString = inputMint.toBase58();
  const outputMintString = outputMint.toBase58();
  // (InputMint-OutputMint) map to (AmmId-InputAmount) map to Quote from the amm with the inputAmount
  // this is used to prevent calculation being repeated later on.
  const tradeIdQuoteMap = new Map<string, Map<string, Quote>>();
  const inputMintInnerMap = inputRouteSegment.get(inputMintString);

  const routes: Route[] = [];

  if (!inputMintInnerMap) {
    throw new Error('No routes found for the input and output mints');
  }

  const maxLevel = onlyDirectRoutes ? 0 : MAX_LEVEL;
  /*
   * It get the rate of all single pair that is linked to the inputMint
   * Example: SOL => USDC, will have direct pair, while
   *          SOL => USDT, USDT => SOL will have a hop
   *
   * So we go through each of the hop and get the top 3 rate and drop others
   * This will eventually reduce the needs to compute bad rate for the same pair
   *
   * The loop below is doing for the inputMint, while the one after is doing for the outputMint.
   */
  const walkTheTree = ({
    inputMint,
    level = 0,
    walked = [inputMint],
  }: {
    inputMint: string;
    amount: JSBI;
    level?: number;
    walked?: string[];
  }) => {
    const inputMintInnerMap = inputRouteSegment.get(inputMint);

    if (inputMintInnerMap) {
      inputMintInnerMap.forEach((amms, outMint) => {
        const tradeId = getInputOutputId({
          inputMint,
          outputMint: outMint,
        });

        const sortedQuotesWithAmms = getQuoteAndSortBasedOnOutAmount({
          amms,
          inputMint,
          outputMint: outMint,
          amount,
          swapMode,
        });

        const { filteredAmms, quoteMap } = sortedQuotesWithAmms.reduce(
          (result, item, idx) => {
            if (idx < filterTopNResult) {
              result.filteredAmms.push(item.amm);
            }
            result.quoteMap.set(getQuoteId({ ammId: item.amm.id, amount }), item.quote);
            return result;
          },
          { filteredAmms: [] as Amm[], quoteMap: new Map() as QuoteMap },
        );

        const splitTradeAmms: SplitTradeAmm[] = [];
        // add split trade in when outputMint match and it's not direct only routes
        if (outMint === outputMintString && !onlyDirectRoutes) {
          ammCrossProtocolPairs(filteredAmms.slice(), (firstAmm, secondAmm) => {
            const splitTradeAmm = SplitTradeAmm.create(firstAmm, secondAmm);
            if (splitTradeAmm) {
              splitTradeAmms.push(splitTradeAmm);
            }
          });
        }

        inputMintInnerMap.set(outMint, filteredAmms.concat(splitTradeAmms));

        tradeIdQuoteMap.set(tradeId, quoteMap);

        // keep looping if not walked and not reached max level
        if (outMint !== outputMintString && quoteMap.size && !walked.includes(outMint) && level < maxLevel - 1) {
          walkTheTree({
            inputMint: outMint,
            amount: quoteMap.values().next().value.outAmount,
            level: level + 1,
            walked: walked.concat(outMint),
          });
        } else if (outMint === outputMintString) {
          if (level === 0) {
            // we need to add the direct routes as it is computed instead of using filteredAmms
            inputMintInnerMap.set(outMint, sortedQuotesWithAmms.map((item) => item.amm).concat(splitTradeAmms));
          }

          // if output reached, we add the route
          const mints = walked.concat(outMint);
          const _mints = mints.map((i) => new PublicKey(i));
          const ammsArr = mints.reduce((amms, _, index) => {
            if (index < mints.length - 1) {
              amms.push(inputRouteSegment.get(mints[index])?.get(mints[index + 1])!);
            }
            return amms;
          }, [] as Amm[][]);

          const permutations: Amm[][] = fastCartesian(ammsArr);

          permutations.forEach((item) => {
            if (item.length === 1 || isValidRoute(item[0], item[1])) {
              routes.push({
                amms: item,
                mints: _mints,
              });
            }
          });
        }
      });
    }
  };

  walkTheTree({
    inputMint: inputMintString,
    amount,
  });

  const routesInfo: RouteInfo[] = routes
    .map((route) => {
      const { amms, mints } = route;

      // Chain all amms
      let marketInfos: MarketInfo[] = [];
      let intermediateAmount = amount;
      let otherAmountThreshold = ZERO;
      const platformFeeSupported = isPlatformFeeSupported(swapMode, amms);
      const tokenMints: PublicKey[] = mints;

      const legs = amms.length;
      for (const [i, amm] of amms.entries()) {
        try {
          const sourceMint = tokenMints[i];
          const destinationMint = tokenMints[i + 1];

          const tradeId = getInputOutputId({
            inputMint: sourceMint.toBase58(),
            outputMint: destinationMint.toBase58(),
          });

          const cacheQuote = tradeIdQuoteMap
            .get(tradeId)
            ?.get(getQuoteId({ ammId: amm.id, amount: intermediateAmount }));

          const quote =
            cacheQuote ||
            amm.getQuote({
              sourceMint,
              destinationMint,
              amount: intermediateAmount,
              swapMode,
            });

          // Platform fee applicable only on last leg
          const isLastLeg = legs - 1 === i;
          const platformFee =
            isLastLeg && platformFeeSupported
              ? {
                  amount: JSBI.divide(
                    JSBI.multiply(quote.outAmount, JSBI.BigInt(platformFeeBps)),
                    PLATFORM_FEE_DENOMINATOR,
                  ),
                  mint: destinationMint.toBase58(),
                  pct: platformFeeBps / 100,
                }
              : { amount: ZERO, mint: destinationMint.toBase58(), pct: 0 };

          const amountForFees = swapMode === SwapMode.ExactIn ? quote.outAmount : quote.inAmount;
          let amountAfterFees =
            swapMode === SwapMode.ExactIn
              ? JSBI.subtract(amountForFees, platformFee.amount)
              : JSBI.add(amountForFees, platformFee.amount);

          if (JSBI.lessThan(amountAfterFees, ZERO)) {
            amountAfterFees = ZERO;
          }

          const legOtherAmountThreshold = JSBI.BigInt(
            swapMode === SwapMode.ExactIn
              ? new Decimal(amountAfterFees.toString()).mul(1 - slippage / 100).ceil()
              : new Decimal(amountAfterFees.toString()).mul(1 + slippage / 100).floor(),
          );

          const [inAmount, outAmount] =
            swapMode === SwapMode.ExactIn ? [quote.inAmount, amountAfterFees] : [amountAfterFees, intermediateAmount];

          marketInfos.push({
            amm,
            inputMint: sourceMint,
            outputMint: destinationMint,
            notEnoughLiquidity: quote.notEnoughLiquidity,
            minInAmount: quote.minInAmount,
            minOutAmount: quote.minOutAmount,
            inAmount,
            outAmount,
            priceImpactPct: quote.priceImpactPct,
            lpFee: {
              amount: quote.feeAmount,
              mint: quote.feeMint,
              pct: quote.feePct,
            },
            platformFee,
          });

          intermediateAmount = swapMode === SwapMode.ExactIn ? amountAfterFees : amount;
          otherAmountThreshold = legOtherAmountThreshold;
        } catch (e: any) {
          if (IS_DEV) {
            console.error(e);
          }

          return undefined;
        }
      }

      return {
        marketInfos,
        getDepositAndFee: () => getDepositAndFeeForRoute({ marketInfos }),
        inAmount: marketInfos[0].inAmount,
        outAmount: intermediateAmount,
        amount,
        otherAmountThreshold,
        swapMode,
        priceImpactPct:
          1 -
          marketInfos.reduce((priceFactor, marketInfo) => {
            priceFactor *= 1 - marketInfo.priceImpactPct;
            return priceFactor;
          }, 1),
      };
    })
    .filter((item): item is RouteInfo => item !== undefined)
    .sort((a, b) => (JSBI.greaterThanOrEqual(b.outAmount, a.outAmount) ? 1 : -1)); // sort based on which one have better output

  return routesInfo;
}
