import { ILiquidityManager } from "./ILiquidityManager";
import { SaberLiquidityManager } from "../platforms/saber";
import { RaydiumLiquidityManager } from "../platforms/raydium";

// Allows to obtain the specific provider related to the vault's platform.
export class LiquidityManagerFactory {
  private static instance: LiquidityManagerFactory;
  private static providers: {
    [Key in string]: ILiquidityManager;
  };
  private constructor() {
    LiquidityManagerFactory.providers = {};
    LiquidityManagerFactory.providers["RAYDIUM"] =
      new RaydiumLiquidityManager();
    LiquidityManagerFactory.providers["SABER"] = new SaberLiquidityManager();
  }
  get(platform: string): ILiquidityManager {
    return LiquidityManagerFactory.providers[platform];
  }
  static getLiquidityManager = () => {
    if (!LiquidityManagerFactory.instance) {
      LiquidityManagerFactory.instance = new LiquidityManagerFactory();
    }
    return LiquidityManagerFactory.instance;
  };
}
