import { Market as SerumMarket } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import { StableSwap } from '@saberhq/stableswap-sdk';
import { SaberAddDecimalsAmm } from './saber/saberAddDecimalsAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { RaydiumAmm } from './raydium/raydiumAmm';
import { SerumAmm } from './serum/serumAmm';
import { Amm } from './amm';
import JSBI from 'jsbi';

export interface Fee {
  amount: JSBI;
  mint: string;
  pct: number;
}

export { SerumMarket, StableSwap };

export interface MarketInfo {
  amm: Amm; //name: string, // pair for serum, pool name for mer pool
  inputMint: PublicKey;
  outputMint: PublicKey;
  minInAmount?: JSBI;
  minOutAmount?: JSBI;
  inAmount: JSBI;
  outAmount: JSBI;
  notEnoughLiquidity: boolean;
  priceImpactPct: number;
  lpFee: Fee;
  platformFee: Fee;
}

// This is to check if the two markets are supported by the aggregator
export const isValidRoute = (ammA: Amm, ammB: Amm) => {
  // dont match the same amm together
  if (ammA.id === ammB.id) {
    return false;
  }
  // don't show decimal as input or output
  else if (ammA instanceof SaberAddDecimalsAmm && ammB instanceof SaberAddDecimalsAmm) {
    return false;
  } else if (ammA instanceof SplitTradeAmm || ammB instanceof SplitTradeAmm) {
    return false;
  }

  return true;
};

export function isSerumAndRaydium(marketInfos: MarketInfo[]) {
  if (marketInfos.length < 2) return false;

  const [firstAmm, secondAmm] = marketInfos.map((mi) => mi.amm);

  return (
    (firstAmm instanceof RaydiumAmm && secondAmm instanceof SerumAmm) ||
    (firstAmm instanceof SerumAmm && secondAmm instanceof RaydiumAmm)
  );
}
