export type RatioLending = {
  version: "0.1.0";
  name: "ratio_lending";
  instructions: [
    {
      name: "createGlobalState";
      docs: [
        "* Create global state account\n     * This account holds all of the global platform variables\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: create_global_state, CreateGlobalState, createGlobalState"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintUsdr";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ratioMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "tvlLimit";
          type: "u128";
        },
        {
          name: "globalDebtCeiling";
          type: "u64";
        },
        {
          name: "debtCeilingUser";
          type: "u64";
        },
        {
          name: "oracleReporter";
          type: "publicKey";
        }
      ];
    },
    {
      name: "createPool";
      docs: [
        "* Create a pool for a given collateral\n     * The Ratio platform supports multiple collateral types-\n     * Each pool account holds variables that regulate user activity\n     * Pool accounts for various collateral types may have different values for different variables\n     * Pool accounts need to hold the mint and oracle values for each of its underlying tokens\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: create_pool, CreatePool, createPool\n     * aliases: create_vault, CreateVault, createVault"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
          docs: [
            "The authority for this pool, should be the same auth as global state"
          ];
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
          docs: ["The pool account"];
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
          docs: ["The global state"];
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
          docs: ["The mint account for collateral token"];
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintReward";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "riskLevel";
          type: "u8";
        },
        {
          name: "debtCeiling";
          type: "u64";
        },
        {
          name: "platformType";
          type: "u8";
        }
      ];
    },
    {
      name: "createUserState";
      docs: [
        "*\n     * Create a user-generated, user-authorized, state account\n     *\n     * aliases: create_user_state, CreateUserState, createUserState"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "createVault";
      docs: [
        "*\n     * Create a user-generated, user-authorized, single-collateral token repository\n     *\n     * aliases: create_vault, CreateVault, createVault\n     * aliases: create_trove, CreateTrove, createTrove"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "depositCollateral";
      docs: [
        "* Deposit collateral to a vault\n     * When collateral sits in a vault, it is able to be staked/farmed out and generate rewards\n     * (sidenote) Collateral in itself generates yield from the platform it originated from\n     *\n     * aliases: deposit_collateral, DepositCollateral, depositCollateral"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatUser";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "depositAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "withdrawCollateral";
      docs: [
        "*\n     * Withdraw collateral from vault\n     * Collateral token goes from Vault -> User ATA\n     *\n     * aliases: withdraw_collateral, WithdrawCollateral, withdrawCollateral"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "blacklist";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatUser";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "withdrawAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "distributeReward";
      docs: [
        "*\n     * Send reward harvested from yield farm\n     * Collateral token goes from Vault -> User ATA\n     *"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRewardVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRewardUser";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "harvestRatio";
      docs: [
        "*\n     * Send Ratio reward\n     * Collateral token goes from Vault -> User ATA\n     *"
      ];
      accounts: [
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRatioGlobal";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRatioVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRatioTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "borrowUsdr";
      docs: [
        "* THIS IS NOT COMPLETE, please see note on the contract fxn (search `BorrowUsdr<'info>`)\n     *\n     * Create the Saber liquidity miner account set by the Quarry framework/standard\n     *\n     * Take out debt in the form of USDr\n     * Must be overcollateralized according to the LTV (collateralization ratio) set by the pool\n     * Must not exceed the global debt limit\n     * Must not exceed the pool debt limit\n     * Must not exceed the user debt limit\n     *\n     * aliases: borrow_usdr, BorrowUsdr, borrowUsdr"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "blacklist";
          isMut: false;
          isSigner: false;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUsdr";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUsdrTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintUsdr";
          isMut: true;
          isSigner: false;
        },
        {
          name: "oracleA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "borrowAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "repayUsdr";
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintUsdr";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUsdr";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "repayAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "createOracle";
      docs: [
        "* Create the account that holds the active USD price for a given single asset (i.e. USDC)\n     *\n     * aliases: create_oracle, CreateOracle, createOracle\n     * previous aliases: create_price_feed, CreatePriceFeed, createPriceFeed"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
          docs: ["The super owner is the authority"];
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracle";
          isMut: true;
          isSigner: false;
          docs: [
            "The oracle account for a single token - holds the USD price of a token"
          ];
        },
        {
          name: "mint";
          isMut: false;
          isSigner: false;
          docs: ["The mint account for the collateral token"];
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "reportPriceToOracle";
      docs: [
        "* Report the current price of a token in USD to on-chain oracle account.\n     * Price of a token comes from authorized reporter (backend)\n     * Account should correspond only to the token being reported on, and should include the time of update\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: ReportPriceToOracle, report_price_to_oracle, reportPriceToOracle\n     * prev: ReportPrice, report_price, reportPrice\n     * prev: UpdatePriceFeed, update_price_feed, updatePriceFeed"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracle";
          isMut: true;
          isSigner: false;
          docs: [
            "The oracle account for a single token - holds the USD price of a token"
          ];
        }
      ];
      args: [
        {
          name: "marketPrice";
          type: "u64";
        },
        {
          name: "fairPrice";
          type: "u64";
        }
      ];
    },
    {
      name: "setGlobalTvlLimit";
      docs: [
        '* Update the global state variable "global_tvl_limit"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "tvlLimit";
          type: "u128";
        }
      ];
    },
    {
      name: "setCollateralRatios";
      docs: [
        '* Update the global state variable "coll_per_risklv"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "ratios";
          type: {
            array: ["u64", 10];
          };
        }
      ];
    },
    {
      name: "setGlobalDebtCeiling";
      docs: [
        '* Update the global state variable "global_debt_ceiling"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "ceiling";
          type: "u64";
        }
      ];
    },
    {
      name: "setUserDebtCeiling";
      docs: [
        '* Update the global state variable "debt_ceiling_user" prev: "user_debt_ceiling"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "ceiling";
          type: "u64";
        }
      ];
    },
    {
      name: "setPoolDebtCeiling";
      docs: [
        '* Update the pool variable "debt_ceiling"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "ceiling";
          type: "u64";
        }
      ];
    },
    {
      name: "setPoolPaused";
      docs: [
        '* Update the pool variable "debt_ceiling"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "isPaused";
          type: "u8";
        }
      ];
    },
    {
      name: "updatePool";
      docs: [
        '* Update the pool infos except mint address & debt"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
          docs: [
            "The authority for this pool, should be the same auth as global state"
          ];
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
          docs: ["The pool account"];
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
          docs: ["The global state"];
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintReward";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "riskLevel";
          type: "u8";
        },
        {
          name: "platformType";
          type: "u8";
        }
      ];
    },
    {
      name: "setHarvestFee";
      docs: [
        "*\n     * This function updates the harvest fee on the global state"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "feeNum";
          type: "u64";
        }
      ];
    },
    {
      name: "setBorrowFee";
      docs: [
        "*\n     * This function updates the borrow fee on the global state"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "feeNum";
          type: "u64";
        }
      ];
    },
    {
      name: "setInstaswapFee";
      docs: [
        "*\n     * This function updates the Instaswap fee on the global state"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "feeNum";
          type: "u64";
        }
      ];
    },
    {
      name: "toggleEmerState";
      docs: [
        '* Update the global state variable "paused"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "newState";
          type: "u8";
        }
      ];
    },
    {
      name: "changeTreasuryWallet";
      docs: [
        '* Update the global state variable "treasury"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "treasury";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "changeFundingWallet";
      docs: [
        '* Update the global state variable "funding_wallet"\n     * Should only be called by the program super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "fundingWallet";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "changeAuthority";
      docs: [
        '* Update the global state variable "authority"\n     * Should only be called by the current authority in global state'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "newAuthority";
          type: "publicKey";
        }
      ];
    },
    {
      name: "changeOracleReporter";
      docs: [
        '* Update the global state variable "oracle_reporter"\n     * Should only be called by the current authority in global state'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "oracleReporter";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "setRatioMint";
      docs: [
        '* Update the global state variable "ratio_mint" & create ratio_vault with the transfer authority, global state\n     * Should only be called by the current authority in global state'
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ratioVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ratioMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "fundRatioToken";
      docs: ["* Fund Ratio Token for the ratio apy"];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ratioVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        },
        {
          name: "duration";
          type: {
            option: "i64";
          };
        }
      ];
    },
    {
      name: "createRaydiumLedger";
      docs: ["* Create Raydium Ledger account"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "raydiumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakePool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "stakerInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "stakeCollateralToRaydium";
      docs: [
        "* stake_collateral_to_raydium, StakeCollateralToRaydium, stakeCollateralToRaydium"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRewardTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "raydiumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakePool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakerInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultLpToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clock";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amtToStake";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "unstakeCollateralFromRaydium";
      docs: [
        "* aliases: unstake_collateral_from_raydium, UnstakeCollateralFromRaydium, unstakeCollateralFromRaydium"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRewardTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "raydiumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakePool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakerInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultLpToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clock";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "harvestRewardsFromRaydium";
      accounts: [
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "raydiumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakePool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "stakerInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultLpToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "destRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vaultRewardTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRatioTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clock";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "createSaberQuarryMiner";
      docs: [
        "* Create the Saber liquidity miner account set by the Quarry framework/standard\n     *\n     * aliases: create_saber_quarry_miner, CreateSaberQuarryMiner, createSaberQuarryMiner\n     * prev aliases: create_quarry_miner, CreateQuarryMiner, createQuarryMiner"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "miner";
          isMut: true;
          isSigner: false;
          docs: ["[Miner] to be created."];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
          docs: ["what constraints"];
        },
        {
          name: "mintCollat";
          isMut: true;
          isSigner: false;
          docs: ["the collateral"];
        },
        {
          name: "ataCollatMiner";
          isMut: true;
          isSigner: false;
        },
        {
          name: "quarryProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "minerBump";
          type: "u8";
        }
      ];
    },
    {
      name: "stakeCollateralToSaber";
      docs: [
        "* stake_collateral_to_saber, StakeCollateralToSaber, stakeCollateralToSaber"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatMiner";
          isMut: true;
          isSigner: false;
          docs: [
            "* the miner (miner-vault's auth is miner, miner's auth is user), this is implemented as an ATA\n     * alias: miner_vault"
          ];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "miner";
          isMut: true;
          isSigner: false;
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "quarryProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amtToStake";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "unstakeCollateralFromSaber";
      docs: [
        "* aliases: unstake_collateral_from_saber, UnstakeCollateralFromSaber, unstakeCollateralFromSaber"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
          docs: [
            "The client user account requesting to unstake or starting liquidation"
          ];
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
          docs: ["This is the collateral's A.T.A. for the user's vault"];
        },
        {
          name: "ataCollatMiner";
          isMut: true;
          isSigner: false;
          docs: ["This is the collateral's A.T.A. for the user"];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
        },
        {
          name: "miner";
          isMut: true;
          isSigner: false;
        },
        {
          name: "rewarder";
          isMut: true;
          isSigner: false;
        },
        {
          name: "quarryProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amount";
          type: "u64";
        }
      ];
    },
    {
      name: "harvestRewardsFromSaber";
      docs: [
        "* aliases: harvest_rewards_from_saber, HarvestRewardsFromSaber, harvestRewardsFromSaber"
      ];
      accounts: [
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
          docs: ["The user's vault"];
        },
        {
          name: "miner";
          isMut: true;
          isSigner: false;
          docs: ["The miner (auth is user)"];
        },
        {
          name: "quarry";
          isMut: true;
          isSigner: false;
          docs: ["Saber farm to stake to"];
        },
        {
          name: "rewarder";
          isMut: false;
          isSigner: false;
          docs: ["Saber farm reward account"];
        },
        {
          name: "mintWrapper";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintWrapperProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "minter";
          isMut: true;
          isSigner: false;
        },
        {
          name: "claimFeeTokenAccount";
          isMut: true;
          isSigner: false;
          docs: [
            "Quarry: Token account in which the rewards token fees are collected."
          ];
        },
        {
          name: "ataRewardVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRatioTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatMiner";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
          docs: ["A.T.A. for collateral token for vault"];
        },
        {
          name: "mintReward";
          isMut: true;
          isSigner: false;
          docs: ["The mint account for reward token"];
        },
        {
          name: "quarryProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "redeemIouTokens";
      docs: ["* aliases: redeem_iou_tokens, RedeemIouTokens, redeemIouTokens"];
      accounts: [
        {
          name: "vault";
          isMut: true;
          isSigner: false;
          docs: ["The user's vault"];
        },
        {
          name: "ataIouRewardVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataRewardVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "iouMintReward";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintReward";
          isMut: true;
          isSigner: false;
        },
        {
          name: "redeemer";
          isMut: true;
          isSigner: false;
        },
        {
          name: "redemptionVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintProxyState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "proxyMintAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "minterInfo";
          isMut: true;
          isSigner: false;
        },
        {
          name: "redeemerProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mintProxyProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amtToRedeemReq";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "wrapDecimalsToken";
      docs: ["* adding decimals via saber add decimals program"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ataUserUnderlyingToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserWrappedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "underlyingMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberDecimalsProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "unwrapDecimalsToken";
      docs: ["* removing decimals via saber add decimals program"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ataUserUnderlyingToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserWrappedToken";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "underlyingMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberDecimalsProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "addLiquidityToSaber";
      docs: ["* Add liqudity to saber, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenATreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenBTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "swapAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapAccount";
          isMut: false;
          isSigner: false;
        },
        {
          name: "saberStableProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "oldAmountA";
          type: "u64";
        },
        {
          name: "oldAmountB";
          type: "u64";
        }
      ];
    },
    {
      name: "addLiquidityToRaydium";
      docs: ["* Add liqudity to raydium"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenATreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTokenBTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "reserveTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserTokenLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "poolMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "modelData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumSwapProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "version";
          type: "u8";
        },
        {
          name: "oldAmountA";
          type: "u64";
        },
        {
          name: "oldAmountB";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromSaber";
      docs: ["* Remove liqudity to saber, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "saberSwapAccount";
          accounts: [
            {
              name: "ammId";
              isMut: false;
              isSigner: false;
            },
            {
              name: "authority";
              isMut: false;
              isSigner: false;
            },
            {
              name: "reserveA";
              isMut: true;
              isSigner: false;
            },
            {
              name: "reserveB";
              isMut: true;
              isSigner: false;
            },
            {
              name: "lpMint";
              isMut: true;
              isSigner: false;
            },
            {
              name: "feeAccountA";
              isMut: true;
              isSigner: false;
            },
            {
              name: "feeAccountB";
              isMut: true;
              isSigner: false;
            }
          ];
        },
        {
          name: "saberStableProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromRaydiumV5";
      docs: ["* Remove Liquidity from Raydium v5, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "modelData";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammLpMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumPcVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumCoinVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumEventQ";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumBids";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumAsks";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumVaultSigner";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumStableAmmProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "removeLiquidityFromRaydiumV4";
      docs: ["* Remove Liquidity from Raydium v4, cut fee here."];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataTreasuryB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataUserB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammId";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammAuthority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ammOpenOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTargetOrders";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammWithdrawQueue";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammTempLp";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammLpMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveA";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ammReserveB";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumMarket";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumEventQueue";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumBids";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumAsks";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumPcVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumCoinVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "serumVaultSigner";
          isMut: false;
          isSigner: false;
        },
        {
          name: "raydiumAmmProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "serumProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "lpAmountToUnwind";
          type: "u64";
        }
      ];
    },
    {
      name: "syncGlobalDebt";
      docs: [
        '* Update the global state & pool state variable "total_debt"\n     * Should only be called by the super owner'
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintUsdr";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "poolDebt";
          type: "u64";
        },
        {
          name: "globalDebt";
          type: {
            option: "u64";
          };
        }
      ];
    },
    {
      name: "createBlacklist";
      docs: [
        "* Create blacklist account\n     * Should only be called by the super owner"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
          docs: ["The super owner is the authority"];
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "blacklist";
          isMut: true;
          isSigner: false;
          docs: ["The blacklist account"];
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "addToBlacklist";
      docs: [
        "* Add wallet to blacklist account\n     * Should only be called by the super owner"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
          docs: ["The super owner is the authority"];
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "blacklist";
          isMut: true;
          isSigner: false;
          docs: ["The blacklist account"];
        }
      ];
      args: [
        {
          name: "wallet";
          type: "publicKey";
        }
      ];
    },
    {
      name: "removeFromBlacklist";
      docs: [
        "* Remove wallet to blacklist account\n     * Should only be called by the super owner"
      ];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
          docs: ["The super owner is the authority"];
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "blacklist";
          isMut: true;
          isSigner: false;
          docs: ["The blacklist account"];
        }
      ];
      args: [
        {
          name: "wallet";
          type: "publicKey";
        }
      ];
    },
    {
      name: "startLiquidation";
      docs: ["* Trigger Liquidation"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pool";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "liquidateAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintCollat";
          isMut: false;
          isSigner: false;
        },
        {
          name: "ataCollatVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataCollatLiq";
          isMut: true;
          isSigner: false;
        },
        {
          name: "oracleA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "oracleB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenA";
          isMut: false;
          isSigner: false;
        },
        {
          name: "swapTokenB";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "liquidateAmount";
          type: "u64";
        },
        {
          name: "liquidateBurn";
          type: "u64";
        }
      ];
    },
    {
      name: "liquidateAsset";
      docs: ["* transfer asset to liquidate"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: false;
          isSigner: false;
        },
        {
          name: "liquidateAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataAssetVault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataAssetLiq";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "executeInternalIx";
      docs: [
        "* liquidate: convert all assets into USDr or USDC(unwind, jup-swap)"
      ];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: false;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: false;
          isSigner: false;
        },
        {
          name: "liquidateAuthority";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "data";
          type: "bytes";
        }
      ];
    },
    {
      name: "endLiquidation";
      docs: ["* End Liquidation with burning & sending with fee"];
      accounts: [
        {
          name: "authority";
          isMut: true;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "vault";
          isMut: true;
          isSigner: false;
        },
        {
          name: "liquidateAuthority";
          isMut: true;
          isSigner: false;
        },
        {
          name: "mintUsdr";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataStableLiq";
          isMut: true;
          isSigner: false;
        },
        {
          name: "ataStableTreasury";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "changePegTreasuryWallet";
      docs: ["* Set Treasury for Peg of Liquidation"];
      accounts: [
        {
          name: "authority";
          isMut: false;
          isSigner: true;
        },
        {
          name: "globalState";
          isMut: true;
          isSigner: false;
        },
        {
          name: "pegTreasury";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "blackList";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["The blacklist account's bump seed"];
            type: "u8";
          },
          {
            name: "reserved";
            type: "publicKey";
          },
          {
            name: "addresses";
            docs: ["The list of wallets should be blocked"];
            type: {
              vec: "publicKey";
            };
          }
        ];
      };
    },
    {
      name: "globalState";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["Bump/nonce for the global state pda"];
            type: "u8";
          },
          {
            name: "authority";
            type: "publicKey";
          },
          {
            name: "treasury";
            docs: ["Public key for the treasury account"];
            type: "publicKey";
          },
          {
            name: "oracleReporter";
            docs: [
              "Public key for the account that can report new prices to the oracle accounts"
            ];
            type: "publicKey";
          },
          {
            name: "mintUsdr";
            docs: ["Mint address for USDr"];
            type: "publicKey";
          },
          {
            name: "tvlCollatCeilingUsd";
            docs: [
              "aliases: tvlCollatCeilingUsd;  prev: tvl_limit, tvl_limit_usd, tvlLimit"
            ];
            type: "u128";
          },
          {
            name: "tvlUsd";
            docs: ["total collateral amount in usd locked in the RatioLending"];
            type: "u128";
          },
          {
            name: "tvlCollat";
            docs: ["total collateral amount in usd per risk level"];
            type: {
              array: ["u128", 4];
            };
          },
          {
            name: "paused";
            docs: ["Is contract paused"];
            type: "u8";
          },
          {
            name: "totalDebt";
            docs: [
              "The total amount of debt minted via the Ratio platform, in USDr"
            ];
            type: "u64";
          },
          {
            name: "debtCeilingGlobal";
            docs: ["The limit on the global mintable debt, in USDr"];
            type: "u64";
          },
          {
            name: "debtCeilingUser";
            docs: ["The limit on the mintable debt per user, in USDr"];
            type: "u64";
          },
          {
            name: "harvestFeeNumer";
            docs: ["The numerator for calculating the fee"];
            type: "u64";
          },
          {
            name: "feeDeno";
            docs: ["The denomenator for calculating the fee"];
            type: "u64";
          },
          {
            name: "collPerRisklv";
            docs: ["The collateral per risk"];
            type: {
              array: ["u64", 10];
            };
          },
          {
            name: "ratioMint";
            type: "publicKey";
          },
          {
            name: "fundingWallet";
            type: "publicKey";
          },
          {
            name: "borrowFeeNumer";
            type: "u64";
          },
          {
            name: "instaswapFeeNumer";
            type: "u64";
          },
          {
            name: "liquidateCount";
            type: "u64";
          },
          {
            name: "pegTreasury";
            type: "publicKey";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 15];
            };
          }
        ];
      };
    },
    {
      name: "oracle";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["The oracle account's bump seed"];
            type: "u8";
          },
          {
            name: "authority";
            docs: ["The authority account for this oracle"];
            type: "publicKey";
          },
          {
            name: "mint";
            docs: ["The token mint for this oracle"];
            type: "publicKey";
          },
          {
            name: "marketPrice";
            docs: [
              "The current price of a given token, reported by report_price_to_oracle"
            ];
            type: "u64";
          },
          {
            name: "decimals";
            docs: ["The number of decimals, the precision for the price value"];
            type: "u8";
          },
          {
            name: "lastUpdatedTime";
            docs: ["The last time that"];
            type: "u64";
          },
          {
            name: "fairPrice";
            docs: ["Fair Price, reported by report_price_to_oracle"];
            type: "u64";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 29];
            };
          }
        ];
      };
    },
    {
      name: "pool";
      docs: ["This is a pool for a 2-token LP collateral type"];
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["the nonce/bump seed for the pool"];
            type: "u8";
          },
          {
            name: "mintCollat";
            docs: [
              "the mint account for the collateral token that represents this pool"
            ];
            type: "publicKey";
          },
          {
            name: "mintReward";
            type: "publicKey";
          },
          {
            name: "tvlUsd";
            docs: [
              "total USD value locked across Ratio platform for this pool's collateral class"
            ];
            type: "u128";
          },
          {
            name: "totalColl";
            docs: [
              "total amount of collateral tokens deposited to vaults of the same collateral type as this pool"
            ];
            type: "u64";
          },
          {
            name: "totalDebt";
            docs: ["total amount of debt"];
            type: "u64";
          },
          {
            name: "debtCeiling";
            docs: [
              "max amount of debt able to be taken on this collateral class"
            ];
            type: "u64";
          },
          {
            name: "riskLevel";
            type: "u8";
          },
          {
            name: "platformType";
            docs: ["represents an enum"];
            type: "u8";
          },
          {
            name: "farmId";
            docs: ["this is not implemented correctly.", "Yield Farm address"];
            type: "publicKey";
          },
          {
            name: "ammId";
            docs: ["AMM address for liquidation"];
            type: "publicKey";
          },
          {
            name: "swapTokenA";
            type: "publicKey";
          },
          {
            name: "swapTokenB";
            type: "publicKey";
          },
          {
            name: "swapMintA";
            type: "publicKey";
          },
          {
            name: "swapMintB";
            type: "publicKey";
          },
          {
            name: "isPaused";
            type: "u8";
          },
          {
            name: "tokenPerSecond";
            type: "u64";
          },
          {
            name: "accRewardPerShare";
            type: "u128";
          },
          {
            name: "lastRewardTime";
            type: "i64";
          },
          {
            name: "lastRewardFundStart";
            type: "i64";
          },
          {
            name: "lastRewardFundAmount";
            type: "u64";
          },
          {
            name: "lastRewardFundEnd";
            type: "i64";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 23];
            };
          }
        ];
      };
    },
    {
      name: "userState";
      type: {
        kind: "struct";
        fields: [
          {
            name: "authority";
            docs: ["authority/owner of this vault"];
            type: "publicKey";
          },
          {
            name: "totalDebt";
            docs: [
              "The amount of USDr borrowed off of the collateral by this user"
            ];
            type: "u64";
          },
          {
            name: "tvlUsd";
            docs: [
              "the amount of collateral (any kind) deposited in all of a user's vaults"
            ];
            type: "u128";
          },
          {
            name: "activeVaults";
            docs: ["the pool count which is token locked"];
            type: "u64";
          },
          {
            name: "bump";
            type: "u8";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 29];
            };
          }
        ];
      };
    },
    {
      name: "vault";
      type: {
        kind: "struct";
        fields: [
          {
            name: "bump";
            docs: ["vault bump"];
            type: "u8";
          },
          {
            name: "authority";
            docs: ["authority/owner of this vault"];
            type: "publicKey";
          },
          {
            name: "pool";
            docs: ["The associated pool's pubkey"];
            type: "publicKey";
          },
          {
            name: "mintCollat";
            type: "publicKey";
          },
          {
            name: "unusedPubkey";
            docs: [
              "The vault's A.T.A. for collat token, mint acct pubkey stored at pool acct"
            ];
            type: {
              array: ["publicKey", 3];
            };
          },
          {
            name: "totalColl";
            type: "u64";
          },
          {
            name: "tvlUsd";
            type: "u128";
          },
          {
            name: "debt";
            docs: [
              "The amount of USDr borrowed off of the collateral in this vault"
            ];
            type: "u64";
          },
          {
            name: "lastMintTime";
            docs: ["The last recorded time USDr was minted through this vault"];
            type: "u64";
          },
          {
            name: "walletNonce";
            docs: ["Unknown what this is for"];
            type: "u8";
          },
          {
            name: "ratioRewardAmount";
            docs: ["ratio reward"];
            type: "u128";
          },
          {
            name: "ratioRewardDebt";
            type: "u128";
          },
          {
            name: "liquidateAmount";
            type: "u64";
          },
          {
            name: "liquidateBurn";
            type: "u64";
          },
          {
            name: "liquidateId";
            type: "u64";
          },
          {
            name: "reserved";
            docs: ["extra space"];
            type: {
              array: ["u64", 23];
            };
          }
        ];
      };
    }
  ];
  types: [
    {
      name: "PlatformType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "Raydium";
          },
          {
            name: "Saber";
          },
          {
            name: "Swim";
          },
          {
            name: "Unknown";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "UserEvent";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "amount";
          type: "u64";
          index: false;
        },
        {
          name: "collPrice";
          type: "u64";
          index: false;
        },
        {
          name: "totalColl";
          type: "u64";
          index: false;
        },
        {
          name: "totalDebt";
          type: "u64";
          index: false;
        },
        {
          name: "action";
          type: "string";
          index: true;
        }
      ];
    },
    {
      name: "LiquidationStarted";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "liquidateId";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateAmount";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateBurn";
          type: "u64";
          index: false;
        },
        {
          name: "collPrice";
          type: "u64";
          index: false;
        },
        {
          name: "totalColl";
          type: "u64";
          index: false;
        },
        {
          name: "totalDebt";
          type: "u64";
          index: false;
        },
        {
          name: "timestamp";
          type: "i64";
          index: false;
        }
      ];
    },
    {
      name: "LiquidatedAsset";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "liquidateId";
          type: "u64";
          index: false;
        },
        {
          name: "assetMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "assetAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "ExecutedInternalIx";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "liquidateId";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "InsufficientLiquidation";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "liquidateId";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateAmount";
          type: "u64";
          index: false;
        },
        {
          name: "assetAmount";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateBurn";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateMint";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "LiquidationEnded";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "liquidateId";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateFee";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateAmount";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateBurn";
          type: "u64";
          index: false;
        },
        {
          name: "liquidateMint";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "HarvestEvent";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "user";
          type: "publicKey";
          index: false;
        },
        {
          name: "reward";
          type: "publicKey";
          index: true;
        },
        {
          name: "amount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "ReportedPrice";
      fields: [
        {
          name: "mint";
          type: "publicKey";
          index: false;
        },
        {
          name: "marketPrice";
          type: "u64";
          index: false;
        },
        {
          name: "fairPrice";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "FundedRatio";
      fields: [
        {
          name: "amount";
          type: "u64";
          index: false;
        },
        {
          name: "duration";
          type: "i64";
          index: false;
        }
      ];
    },
    {
      name: "InstaswapOutputEvent";
      fields: [
        {
          name: "userWallet";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenAMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenBMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "feeAmountTokenA";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenB";
          type: "u64";
          index: false;
        },
        {
          name: "poolMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "outputLpAmount";
          type: "u64";
          index: false;
        },
        {
          name: "platformName";
          type: "string";
          index: false;
        }
      ];
    },
    {
      name: "InstaswapReverseEvent";
      fields: [
        {
          name: "userWallet";
          type: "publicKey";
          index: false;
        },
        {
          name: "poolMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "inputLpAmount";
          type: "u64";
          index: false;
        },
        {
          name: "tokenAMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "tokenBMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "outputAAmount";
          type: "u64";
          index: false;
        },
        {
          name: "outputBAmount";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenA";
          type: "u64";
          index: false;
        },
        {
          name: "feeAmountTokenB";
          type: "u64";
          index: false;
        },
        {
          name: "platformName";
          type: "string";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "Unauthorized";
      msg: "You are not authorized to perform this action.";
    },
    {
      code: 6001;
      name: "AlreadyInUse";
      msg: "AlreadyInUse";
    },
    {
      code: 6002;
      name: "InvalidProgramAddress";
      msg: "InvalidProgramAddress";
    },
    {
      code: 6003;
      name: "InvalidState";
      msg: "InvalidState";
    },
    {
      code: 6004;
      name: "InvalidOwner";
      msg: "InvalidOwner";
    },
    {
      code: 6005;
      name: "NotAllowed";
      msg: "NotAllowed";
    },
    {
      code: 6006;
      name: "MathOverflow";
      msg: "Math operation overflow";
    },
    {
      code: 6007;
      name: "InvalidOracleConfig";
      msg: "InvalidOracleConfig";
    },
    {
      code: 6008;
      name: "InvalidAccountInput";
      msg: "InvalidAccountInput";
    },
    {
      code: 6009;
      name: "InvalidCluster";
      msg: "This function works on devnet only";
    },
    {
      code: 6010;
      name: "GlobalTVLExceeded";
      msg: "Global TVL Exceeded";
    },
    {
      code: 6011;
      name: "LTVExceeded";
      msg: "LTV Exceeded";
    },
    {
      code: 6012;
      name: "GlobalDebtCeilingExceeded";
      msg: "Global Debt Ceiling Exceeded";
    },
    {
      code: 6013;
      name: "PoolDebtCeilingExceeded";
      msg: "Pool Debt Ceiling Exceeded";
    },
    {
      code: 6014;
      name: "UserDebtCeilingExceeded";
      msg: "User Debt Ceiling Exceeded";
    },
    {
      code: 6015;
      name: "WithdrawNotAllowedWithDebt";
      msg: "Can't withdraw due to debt";
    },
    {
      code: 6016;
      name: "InvalidTransferAmount";
      msg: "Transfer amount is invalid";
    },
    {
      code: 6017;
      name: "InvalidPlatformType";
      msg: "Invalid platform type";
    },
    {
      code: 6018;
      name: "InvalidSaberPlatformType";
      msg: "Invalid platform, should be Saber";
    },
    {
      code: 6019;
      name: "RepayingMoreThanBorrowed";
      msg: "Attempting to repay more than the amount originally borrowed";
    },
    {
      code: 6020;
      name: "RewardMintMismatch";
      msg: "Reward mint account mismatch";
    },
    {
      code: 6021;
      name: "PoolPaused";
      msg: "The pool is paused by admin";
    },
    {
      code: 6022;
      name: "LedgerNotCreated";
      msg: "Create Raydium Ledger first";
    },
    {
      code: 6023;
      name: "InvalidFundingWallet";
      msg: "Invalid Funding Wallet";
    },
    {
      code: 6024;
      name: "AlreadyAddedBlackList";
      msg: "Already added in BlackList";
    },
    {
      code: 6025;
      name: "ReachedBlacklistLimit";
      msg: "Can't add to blacklist anymore";
    },
    {
      code: 6026;
      name: "NotFoundBlackList";
      msg: "Not found in BlackList";
    },
    {
      code: 6027;
      name: "BlockedFromRatioLending";
      msg: "You are blocked from our smart contract";
    },
    {
      code: 6028;
      name: "LiquidationAlreadyStarted";
      msg: "Liquidation is already started";
    },
    {
      code: 6029;
      name: "InvalidInternalProgramToPass";
      msg: "Invalid Interal Program To Pass";
    },
    {
      code: 6030;
      name: "InvalidUSDrAmountToBurn";
      msg: "Invalid USDr amount to burn";
    },
    {
      code: 6031;
      name: "NoLiquidation";
      msg: "Liquidation isn't started";
    },
    {
      code: 6032;
      name: "DebtExceededLiquidation";
      msg: "Debt exceeds Liquidation";
    },
    {
      code: 6033;
      name: "RaydiumLedgerNotInitialized";
      msg: "RaydiumLedger should be created before User Vault";
    }
  ];
};

export const IDL: RatioLending = {
  version: "0.1.0",
  name: "ratio_lending",
  instructions: [
    {
      name: "createGlobalState",
      docs: [
        "* Create global state account\n     * This account holds all of the global platform variables\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: create_global_state, CreateGlobalState, createGlobalState",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintUsdr",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ratioMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "tvlLimit",
          type: "u128",
        },
        {
          name: "globalDebtCeiling",
          type: "u64",
        },
        {
          name: "debtCeilingUser",
          type: "u64",
        },
        {
          name: "oracleReporter",
          type: "publicKey",
        },
      ],
    },
    {
      name: "createPool",
      docs: [
        "* Create a pool for a given collateral\n     * The Ratio platform supports multiple collateral types-\n     * Each pool account holds variables that regulate user activity\n     * Pool accounts for various collateral types may have different values for different variables\n     * Pool accounts need to hold the mint and oracle values for each of its underlying tokens\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: create_pool, CreatePool, createPool\n     * aliases: create_vault, CreateVault, createVault",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
          docs: [
            "The authority for this pool, should be the same auth as global state",
          ],
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
          docs: ["The pool account"],
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
          docs: ["The global state"],
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
          docs: ["The mint account for collateral token"],
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintReward",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "riskLevel",
          type: "u8",
        },
        {
          name: "debtCeiling",
          type: "u64",
        },
        {
          name: "platformType",
          type: "u8",
        },
      ],
    },
    {
      name: "createUserState",
      docs: [
        "*\n     * Create a user-generated, user-authorized, state account\n     *\n     * aliases: create_user_state, CreateUserState, createUserState",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "createVault",
      docs: [
        "*\n     * Create a user-generated, user-authorized, single-collateral token repository\n     *\n     * aliases: create_vault, CreateVault, createVault\n     * aliases: create_trove, CreateTrove, createTrove",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "depositCollateral",
      docs: [
        "* Deposit collateral to a vault\n     * When collateral sits in a vault, it is able to be staked/farmed out and generate rewards\n     * (sidenote) Collateral in itself generates yield from the platform it originated from\n     *\n     * aliases: deposit_collateral, DepositCollateral, depositCollateral",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatUser",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "depositAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "withdrawCollateral",
      docs: [
        "*\n     * Withdraw collateral from vault\n     * Collateral token goes from Vault -> User ATA\n     *\n     * aliases: withdraw_collateral, WithdrawCollateral, withdrawCollateral",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "blacklist",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatUser",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "withdrawAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "distributeReward",
      docs: [
        "*\n     * Send reward harvested from yield farm\n     * Collateral token goes from Vault -> User ATA\n     *",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRewardVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRewardUser",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "harvestRatio",
      docs: [
        "*\n     * Send Ratio reward\n     * Collateral token goes from Vault -> User ATA\n     *",
      ],
      accounts: [
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRatioGlobal",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRatioVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRatioTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "borrowUsdr",
      docs: [
        "* THIS IS NOT COMPLETE, please see note on the contract fxn (search `BorrowUsdr<'info>`)\n     *\n     * Create the Saber liquidity miner account set by the Quarry framework/standard\n     *\n     * Take out debt in the form of USDr\n     * Must be overcollateralized according to the LTV (collateralization ratio) set by the pool\n     * Must not exceed the global debt limit\n     * Must not exceed the pool debt limit\n     * Must not exceed the user debt limit\n     *\n     * aliases: borrow_usdr, BorrowUsdr, borrowUsdr",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "blacklist",
          isMut: false,
          isSigner: false,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUsdr",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUsdrTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintUsdr",
          isMut: true,
          isSigner: false,
        },
        {
          name: "oracleA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "borrowAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "repayUsdr",
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintUsdr",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUsdr",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "repayAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "createOracle",
      docs: [
        "* Create the account that holds the active USD price for a given single asset (i.e. USDC)\n     *\n     * aliases: create_oracle, CreateOracle, createOracle\n     * previous aliases: create_price_feed, CreatePriceFeed, createPriceFeed",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
          docs: ["The super owner is the authority"],
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracle",
          isMut: true,
          isSigner: false,
          docs: [
            "The oracle account for a single token - holds the USD price of a token",
          ],
        },
        {
          name: "mint",
          isMut: false,
          isSigner: false,
          docs: ["The mint account for the collateral token"],
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "reportPriceToOracle",
      docs: [
        "* Report the current price of a token in USD to on-chain oracle account.\n     * Price of a token comes from authorized reporter (backend)\n     * Account should correspond only to the token being reported on, and should include the time of update\n     *\n     * Should only be called by the super owner\n     *\n     * aliases: ReportPriceToOracle, report_price_to_oracle, reportPriceToOracle\n     * prev: ReportPrice, report_price, reportPrice\n     * prev: UpdatePriceFeed, update_price_feed, updatePriceFeed",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracle",
          isMut: true,
          isSigner: false,
          docs: [
            "The oracle account for a single token - holds the USD price of a token",
          ],
        },
      ],
      args: [
        {
          name: "marketPrice",
          type: "u64",
        },
        {
          name: "fairPrice",
          type: "u64",
        },
      ],
    },
    {
      name: "setGlobalTvlLimit",
      docs: [
        '* Update the global state variable "global_tvl_limit"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "tvlLimit",
          type: "u128",
        },
      ],
    },
    {
      name: "setCollateralRatios",
      docs: [
        '* Update the global state variable "coll_per_risklv"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "ratios",
          type: {
            array: ["u64", 10],
          },
        },
      ],
    },
    {
      name: "setGlobalDebtCeiling",
      docs: [
        '* Update the global state variable "global_debt_ceiling"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "ceiling",
          type: "u64",
        },
      ],
    },
    {
      name: "setUserDebtCeiling",
      docs: [
        '* Update the global state variable "debt_ceiling_user" prev: "user_debt_ceiling"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "ceiling",
          type: "u64",
        },
      ],
    },
    {
      name: "setPoolDebtCeiling",
      docs: [
        '* Update the pool variable "debt_ceiling"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "ceiling",
          type: "u64",
        },
      ],
    },
    {
      name: "setPoolPaused",
      docs: [
        '* Update the pool variable "debt_ceiling"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "isPaused",
          type: "u8",
        },
      ],
    },
    {
      name: "updatePool",
      docs: [
        '* Update the pool infos except mint address & debt"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
          docs: [
            "The authority for this pool, should be the same auth as global state",
          ],
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
          docs: ["The pool account"],
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
          docs: ["The global state"],
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintReward",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "riskLevel",
          type: "u8",
        },
        {
          name: "platformType",
          type: "u8",
        },
      ],
    },
    {
      name: "setHarvestFee",
      docs: [
        "*\n     * This function updates the harvest fee on the global state",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "feeNum",
          type: "u64",
        },
      ],
    },
    {
      name: "setBorrowFee",
      docs: [
        "*\n     * This function updates the borrow fee on the global state",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "feeNum",
          type: "u64",
        },
      ],
    },
    {
      name: "setInstaswapFee",
      docs: [
        "*\n     * This function updates the Instaswap fee on the global state",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "feeNum",
          type: "u64",
        },
      ],
    },
    {
      name: "toggleEmerState",
      docs: [
        '* Update the global state variable "paused"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "newState",
          type: "u8",
        },
      ],
    },
    {
      name: "changeTreasuryWallet",
      docs: [
        '* Update the global state variable "treasury"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "changeFundingWallet",
      docs: [
        '* Update the global state variable "funding_wallet"\n     * Should only be called by the program super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "fundingWallet",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "changeAuthority",
      docs: [
        '* Update the global state variable "authority"\n     * Should only be called by the current authority in global state',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "newAuthority",
          type: "publicKey",
        },
      ],
    },
    {
      name: "changeOracleReporter",
      docs: [
        '* Update the global state variable "oracle_reporter"\n     * Should only be called by the current authority in global state',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "oracleReporter",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "setRatioMint",
      docs: [
        '* Update the global state variable "ratio_mint" & create ratio_vault with the transfer authority, global state\n     * Should only be called by the current authority in global state',
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ratioVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ratioMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "fundRatioToken",
      docs: ["* Fund Ratio Token for the ratio apy"],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ratioVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
        {
          name: "duration",
          type: {
            option: "i64",
          },
        },
      ],
    },
    {
      name: "createRaydiumLedger",
      docs: ["* Create Raydium Ledger account"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "raydiumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakePool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "stakerInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "stakeCollateralToRaydium",
      docs: [
        "* stake_collateral_to_raydium, StakeCollateralToRaydium, stakeCollateralToRaydium",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRewardTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "raydiumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakePool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakerInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultLpToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clock",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amtToStake",
          type: {
            option: "u64",
          },
        },
      ],
    },
    {
      name: "unstakeCollateralFromRaydium",
      docs: [
        "* aliases: unstake_collateral_from_raydium, UnstakeCollateralFromRaydium, unstakeCollateralFromRaydium",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRewardTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "raydiumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakePool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakerInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultLpToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clock",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "harvestRewardsFromRaydium",
      accounts: [
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "raydiumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakePool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "stakerInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultLpToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "destRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vaultRewardTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRatioTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clock",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "createSaberQuarryMiner",
      docs: [
        "* Create the Saber liquidity miner account set by the Quarry framework/standard\n     *\n     * aliases: create_saber_quarry_miner, CreateSaberQuarryMiner, createSaberQuarryMiner\n     * prev aliases: create_quarry_miner, CreateQuarryMiner, createQuarryMiner",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "miner",
          isMut: true,
          isSigner: false,
          docs: ["[Miner] to be created."],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
          docs: ["what constraints"],
        },
        {
          name: "mintCollat",
          isMut: true,
          isSigner: false,
          docs: ["the collateral"],
        },
        {
          name: "ataCollatMiner",
          isMut: true,
          isSigner: false,
        },
        {
          name: "quarryProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "minerBump",
          type: "u8",
        },
      ],
    },
    {
      name: "stakeCollateralToSaber",
      docs: [
        "* stake_collateral_to_saber, StakeCollateralToSaber, stakeCollateralToSaber",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatMiner",
          isMut: true,
          isSigner: false,
          docs: [
            "* the miner (miner-vault's auth is miner, miner's auth is user), this is implemented as an ATA\n     * alias: miner_vault",
          ],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "miner",
          isMut: true,
          isSigner: false,
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "quarryProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amtToStake",
          type: {
            option: "u64",
          },
        },
      ],
    },
    {
      name: "unstakeCollateralFromSaber",
      docs: [
        "* aliases: unstake_collateral_from_saber, UnstakeCollateralFromSaber, unstakeCollateralFromSaber",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
          docs: [
            "The client user account requesting to unstake or starting liquidation",
          ],
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
          docs: ["This is the collateral's A.T.A. for the user's vault"],
        },
        {
          name: "ataCollatMiner",
          isMut: true,
          isSigner: false,
          docs: ["This is the collateral's A.T.A. for the user"],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
        },
        {
          name: "miner",
          isMut: true,
          isSigner: false,
        },
        {
          name: "rewarder",
          isMut: true,
          isSigner: false,
        },
        {
          name: "quarryProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amount",
          type: "u64",
        },
      ],
    },
    {
      name: "harvestRewardsFromSaber",
      docs: [
        "* aliases: harvest_rewards_from_saber, HarvestRewardsFromSaber, harvestRewardsFromSaber",
      ],
      accounts: [
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
          docs: ["The user's vault"],
        },
        {
          name: "miner",
          isMut: true,
          isSigner: false,
          docs: ["The miner (auth is user)"],
        },
        {
          name: "quarry",
          isMut: true,
          isSigner: false,
          docs: ["Saber farm to stake to"],
        },
        {
          name: "rewarder",
          isMut: false,
          isSigner: false,
          docs: ["Saber farm reward account"],
        },
        {
          name: "mintWrapper",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintWrapperProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "minter",
          isMut: true,
          isSigner: false,
        },
        {
          name: "claimFeeTokenAccount",
          isMut: true,
          isSigner: false,
          docs: [
            "Quarry: Token account in which the rewards token fees are collected.",
          ],
        },
        {
          name: "ataRewardVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRatioTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatMiner",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
          docs: ["A.T.A. for collateral token for vault"],
        },
        {
          name: "mintReward",
          isMut: true,
          isSigner: false,
          docs: ["The mint account for reward token"],
        },
        {
          name: "quarryProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "redeemIouTokens",
      docs: ["* aliases: redeem_iou_tokens, RedeemIouTokens, redeemIouTokens"],
      accounts: [
        {
          name: "vault",
          isMut: true,
          isSigner: false,
          docs: ["The user's vault"],
        },
        {
          name: "ataIouRewardVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataRewardVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "iouMintReward",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintReward",
          isMut: true,
          isSigner: false,
        },
        {
          name: "redeemer",
          isMut: true,
          isSigner: false,
        },
        {
          name: "redemptionVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintProxyState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "proxyMintAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "minterInfo",
          isMut: true,
          isSigner: false,
        },
        {
          name: "redeemerProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mintProxyProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amtToRedeemReq",
          type: {
            option: "u64",
          },
        },
      ],
    },
    {
      name: "wrapDecimalsToken",
      docs: ["* adding decimals via saber add decimals program"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ataUserUnderlyingToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserWrappedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "underlyingMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberDecimalsProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "unwrapDecimalsToken",
      docs: ["* removing decimals via saber add decimals program"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ataUserUnderlyingToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserWrappedToken",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "underlyingMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberDecimalsProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "addLiquidityToSaber",
      docs: ["* Add liqudity to saber, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenATreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenBTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "swapAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapAccount",
          isMut: false,
          isSigner: false,
        },
        {
          name: "saberStableProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "oldAmountA",
          type: "u64",
        },
        {
          name: "oldAmountB",
          type: "u64",
        },
      ],
    },
    {
      name: "addLiquidityToRaydium",
      docs: ["* Add liqudity to raydium"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenATreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTokenBTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "reserveTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserTokenLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "poolMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "modelData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumSwapProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "version",
          type: "u8",
        },
        {
          name: "oldAmountA",
          type: "u64",
        },
        {
          name: "oldAmountB",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromSaber",
      docs: ["* Remove liqudity to saber, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "saberSwapAccount",
          accounts: [
            {
              name: "ammId",
              isMut: false,
              isSigner: false,
            },
            {
              name: "authority",
              isMut: false,
              isSigner: false,
            },
            {
              name: "reserveA",
              isMut: true,
              isSigner: false,
            },
            {
              name: "reserveB",
              isMut: true,
              isSigner: false,
            },
            {
              name: "lpMint",
              isMut: true,
              isSigner: false,
            },
            {
              name: "feeAccountA",
              isMut: true,
              isSigner: false,
            },
            {
              name: "feeAccountB",
              isMut: true,
              isSigner: false,
            },
          ],
        },
        {
          name: "saberStableProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromRaydiumV5",
      docs: ["* Remove Liquidity from Raydium v5, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "modelData",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammLpMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumPcVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumCoinVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumEventQ",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumBids",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumAsks",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumVaultSigner",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumStableAmmProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "removeLiquidityFromRaydiumV4",
      docs: ["* Remove Liquidity from Raydium v4, cut fee here."],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataTreasuryB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataUserB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammId",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammAuthority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ammOpenOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTargetOrders",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammWithdrawQueue",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammTempLp",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammLpMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveA",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ammReserveB",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumMarket",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumEventQueue",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumBids",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumAsks",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumPcVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumCoinVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "serumVaultSigner",
          isMut: false,
          isSigner: false,
        },
        {
          name: "raydiumAmmProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "serumProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "lpAmountToUnwind",
          type: "u64",
        },
      ],
    },
    {
      name: "syncGlobalDebt",
      docs: [
        '* Update the global state & pool state variable "total_debt"\n     * Should only be called by the super owner',
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintUsdr",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "poolDebt",
          type: "u64",
        },
        {
          name: "globalDebt",
          type: {
            option: "u64",
          },
        },
      ],
    },
    {
      name: "createBlacklist",
      docs: [
        "* Create blacklist account\n     * Should only be called by the super owner",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
          docs: ["The super owner is the authority"],
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "blacklist",
          isMut: true,
          isSigner: false,
          docs: ["The blacklist account"],
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "addToBlacklist",
      docs: [
        "* Add wallet to blacklist account\n     * Should only be called by the super owner",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
          docs: ["The super owner is the authority"],
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "blacklist",
          isMut: true,
          isSigner: false,
          docs: ["The blacklist account"],
        },
      ],
      args: [
        {
          name: "wallet",
          type: "publicKey",
        },
      ],
    },
    {
      name: "removeFromBlacklist",
      docs: [
        "* Remove wallet to blacklist account\n     * Should only be called by the super owner",
      ],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
          docs: ["The super owner is the authority"],
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "blacklist",
          isMut: true,
          isSigner: false,
          docs: ["The blacklist account"],
        },
      ],
      args: [
        {
          name: "wallet",
          type: "publicKey",
        },
      ],
    },
    {
      name: "startLiquidation",
      docs: ["* Trigger Liquidation"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pool",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "liquidateAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintCollat",
          isMut: false,
          isSigner: false,
        },
        {
          name: "ataCollatVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataCollatLiq",
          isMut: true,
          isSigner: false,
        },
        {
          name: "oracleA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "oracleB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenA",
          isMut: false,
          isSigner: false,
        },
        {
          name: "swapTokenB",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "liquidateAmount",
          type: "u64",
        },
        {
          name: "liquidateBurn",
          type: "u64",
        },
      ],
    },
    {
      name: "liquidateAsset",
      docs: ["* transfer asset to liquidate"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: false,
          isSigner: false,
        },
        {
          name: "liquidateAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataAssetVault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataAssetLiq",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "executeInternalIx",
      docs: [
        "* liquidate: convert all assets into USDr or USDC(unwind, jup-swap)",
      ],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: false,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: false,
          isSigner: false,
        },
        {
          name: "liquidateAuthority",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "data",
          type: "bytes",
        },
      ],
    },
    {
      name: "endLiquidation",
      docs: ["* End Liquidation with burning & sending with fee"],
      accounts: [
        {
          name: "authority",
          isMut: true,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "vault",
          isMut: true,
          isSigner: false,
        },
        {
          name: "liquidateAuthority",
          isMut: true,
          isSigner: false,
        },
        {
          name: "mintUsdr",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataStableLiq",
          isMut: true,
          isSigner: false,
        },
        {
          name: "ataStableTreasury",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "changePegTreasuryWallet",
      docs: ["* Set Treasury for Peg of Liquidation"],
      accounts: [
        {
          name: "authority",
          isMut: false,
          isSigner: true,
        },
        {
          name: "globalState",
          isMut: true,
          isSigner: false,
        },
        {
          name: "pegTreasury",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "blackList",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["The blacklist account's bump seed"],
            type: "u8",
          },
          {
            name: "reserved",
            type: "publicKey",
          },
          {
            name: "addresses",
            docs: ["The list of wallets should be blocked"],
            type: {
              vec: "publicKey",
            },
          },
        ],
      },
    },
    {
      name: "globalState",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["Bump/nonce for the global state pda"],
            type: "u8",
          },
          {
            name: "authority",
            type: "publicKey",
          },
          {
            name: "treasury",
            docs: ["Public key for the treasury account"],
            type: "publicKey",
          },
          {
            name: "oracleReporter",
            docs: [
              "Public key for the account that can report new prices to the oracle accounts",
            ],
            type: "publicKey",
          },
          {
            name: "mintUsdr",
            docs: ["Mint address for USDr"],
            type: "publicKey",
          },
          {
            name: "tvlCollatCeilingUsd",
            docs: [
              "aliases: tvlCollatCeilingUsd;  prev: tvl_limit, tvl_limit_usd, tvlLimit",
            ],
            type: "u128",
          },
          {
            name: "tvlUsd",
            docs: ["total collateral amount in usd locked in the RatioLending"],
            type: "u128",
          },
          {
            name: "tvlCollat",
            docs: ["total collateral amount in usd per risk level"],
            type: {
              array: ["u128", 4],
            },
          },
          {
            name: "paused",
            docs: ["Is contract paused"],
            type: "u8",
          },
          {
            name: "totalDebt",
            docs: [
              "The total amount of debt minted via the Ratio platform, in USDr",
            ],
            type: "u64",
          },
          {
            name: "debtCeilingGlobal",
            docs: ["The limit on the global mintable debt, in USDr"],
            type: "u64",
          },
          {
            name: "debtCeilingUser",
            docs: ["The limit on the mintable debt per user, in USDr"],
            type: "u64",
          },
          {
            name: "harvestFeeNumer",
            docs: ["The numerator for calculating the fee"],
            type: "u64",
          },
          {
            name: "feeDeno",
            docs: ["The denomenator for calculating the fee"],
            type: "u64",
          },
          {
            name: "collPerRisklv",
            docs: ["The collateral per risk"],
            type: {
              array: ["u64", 10],
            },
          },
          {
            name: "ratioMint",
            type: "publicKey",
          },
          {
            name: "fundingWallet",
            type: "publicKey",
          },
          {
            name: "borrowFeeNumer",
            type: "u64",
          },
          {
            name: "instaswapFeeNumer",
            type: "u64",
          },
          {
            name: "liquidateCount",
            type: "u64",
          },
          {
            name: "pegTreasury",
            type: "publicKey",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 15],
            },
          },
        ],
      },
    },
    {
      name: "oracle",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["The oracle account's bump seed"],
            type: "u8",
          },
          {
            name: "authority",
            docs: ["The authority account for this oracle"],
            type: "publicKey",
          },
          {
            name: "mint",
            docs: ["The token mint for this oracle"],
            type: "publicKey",
          },
          {
            name: "marketPrice",
            docs: [
              "The current price of a given token, reported by report_price_to_oracle",
            ],
            type: "u64",
          },
          {
            name: "decimals",
            docs: ["The number of decimals, the precision for the price value"],
            type: "u8",
          },
          {
            name: "lastUpdatedTime",
            docs: ["The last time that"],
            type: "u64",
          },
          {
            name: "fairPrice",
            docs: ["Fair Price, reported by report_price_to_oracle"],
            type: "u64",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 29],
            },
          },
        ],
      },
    },
    {
      name: "pool",
      docs: ["This is a pool for a 2-token LP collateral type"],
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["the nonce/bump seed for the pool"],
            type: "u8",
          },
          {
            name: "mintCollat",
            docs: [
              "the mint account for the collateral token that represents this pool",
            ],
            type: "publicKey",
          },
          {
            name: "mintReward",
            type: "publicKey",
          },
          {
            name: "tvlUsd",
            docs: [
              "total USD value locked across Ratio platform for this pool's collateral class",
            ],
            type: "u128",
          },
          {
            name: "totalColl",
            docs: [
              "total amount of collateral tokens deposited to vaults of the same collateral type as this pool",
            ],
            type: "u64",
          },
          {
            name: "totalDebt",
            docs: ["total amount of debt"],
            type: "u64",
          },
          {
            name: "debtCeiling",
            docs: [
              "max amount of debt able to be taken on this collateral class",
            ],
            type: "u64",
          },
          {
            name: "riskLevel",
            type: "u8",
          },
          {
            name: "platformType",
            docs: ["represents an enum"],
            type: "u8",
          },
          {
            name: "farmId",
            docs: ["this is not implemented correctly.", "Yield Farm address"],
            type: "publicKey",
          },
          {
            name: "ammId",
            docs: ["AMM address for liquidation"],
            type: "publicKey",
          },
          {
            name: "swapTokenA",
            type: "publicKey",
          },
          {
            name: "swapTokenB",
            type: "publicKey",
          },
          {
            name: "swapMintA",
            type: "publicKey",
          },
          {
            name: "swapMintB",
            type: "publicKey",
          },
          {
            name: "isPaused",
            type: "u8",
          },
          {
            name: "tokenPerSecond",
            type: "u64",
          },
          {
            name: "accRewardPerShare",
            type: "u128",
          },
          {
            name: "lastRewardTime",
            type: "i64",
          },
          {
            name: "lastRewardFundStart",
            type: "i64",
          },
          {
            name: "lastRewardFundAmount",
            type: "u64",
          },
          {
            name: "lastRewardFundEnd",
            type: "i64",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 23],
            },
          },
        ],
      },
    },
    {
      name: "userState",
      type: {
        kind: "struct",
        fields: [
          {
            name: "authority",
            docs: ["authority/owner of this vault"],
            type: "publicKey",
          },
          {
            name: "totalDebt",
            docs: [
              "The amount of USDr borrowed off of the collateral by this user",
            ],
            type: "u64",
          },
          {
            name: "tvlUsd",
            docs: [
              "the amount of collateral (any kind) deposited in all of a user's vaults",
            ],
            type: "u128",
          },
          {
            name: "activeVaults",
            docs: ["the pool count which is token locked"],
            type: "u64",
          },
          {
            name: "bump",
            type: "u8",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 29],
            },
          },
        ],
      },
    },
    {
      name: "vault",
      type: {
        kind: "struct",
        fields: [
          {
            name: "bump",
            docs: ["vault bump"],
            type: "u8",
          },
          {
            name: "authority",
            docs: ["authority/owner of this vault"],
            type: "publicKey",
          },
          {
            name: "pool",
            docs: ["The associated pool's pubkey"],
            type: "publicKey",
          },
          {
            name: "mintCollat",
            type: "publicKey",
          },
          {
            name: "unusedPubkey",
            docs: [
              "The vault's A.T.A. for collat token, mint acct pubkey stored at pool acct",
            ],
            type: {
              array: ["publicKey", 3],
            },
          },
          {
            name: "totalColl",
            type: "u64",
          },
          {
            name: "tvlUsd",
            type: "u128",
          },
          {
            name: "debt",
            docs: [
              "The amount of USDr borrowed off of the collateral in this vault",
            ],
            type: "u64",
          },
          {
            name: "lastMintTime",
            docs: ["The last recorded time USDr was minted through this vault"],
            type: "u64",
          },
          {
            name: "walletNonce",
            docs: ["Unknown what this is for"],
            type: "u8",
          },
          {
            name: "ratioRewardAmount",
            docs: ["ratio reward"],
            type: "u128",
          },
          {
            name: "ratioRewardDebt",
            type: "u128",
          },
          {
            name: "liquidateAmount",
            type: "u64",
          },
          {
            name: "liquidateBurn",
            type: "u64",
          },
          {
            name: "liquidateId",
            type: "u64",
          },
          {
            name: "reserved",
            docs: ["extra space"],
            type: {
              array: ["u64", 23],
            },
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "PlatformType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "Raydium",
          },
          {
            name: "Saber",
          },
          {
            name: "Swim",
          },
          {
            name: "Unknown",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "UserEvent",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "amount",
          type: "u64",
          index: false,
        },
        {
          name: "collPrice",
          type: "u64",
          index: false,
        },
        {
          name: "totalColl",
          type: "u64",
          index: false,
        },
        {
          name: "totalDebt",
          type: "u64",
          index: false,
        },
        {
          name: "action",
          type: "string",
          index: true,
        },
      ],
    },
    {
      name: "LiquidationStarted",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "liquidateId",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateAmount",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateBurn",
          type: "u64",
          index: false,
        },
        {
          name: "collPrice",
          type: "u64",
          index: false,
        },
        {
          name: "totalColl",
          type: "u64",
          index: false,
        },
        {
          name: "totalDebt",
          type: "u64",
          index: false,
        },
        {
          name: "timestamp",
          type: "i64",
          index: false,
        },
      ],
    },
    {
      name: "LiquidatedAsset",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "liquidateId",
          type: "u64",
          index: false,
        },
        {
          name: "assetMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "assetAmount",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "ExecutedInternalIx",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "liquidateId",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "InsufficientLiquidation",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "liquidateId",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateAmount",
          type: "u64",
          index: false,
        },
        {
          name: "assetAmount",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateBurn",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateMint",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "LiquidationEnded",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "liquidateId",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateFee",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateAmount",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateBurn",
          type: "u64",
          index: false,
        },
        {
          name: "liquidateMint",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "HarvestEvent",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "user",
          type: "publicKey",
          index: false,
        },
        {
          name: "reward",
          type: "publicKey",
          index: true,
        },
        {
          name: "amount",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "ReportedPrice",
      fields: [
        {
          name: "mint",
          type: "publicKey",
          index: false,
        },
        {
          name: "marketPrice",
          type: "u64",
          index: false,
        },
        {
          name: "fairPrice",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "FundedRatio",
      fields: [
        {
          name: "amount",
          type: "u64",
          index: false,
        },
        {
          name: "duration",
          type: "i64",
          index: false,
        },
      ],
    },
    {
      name: "InstaswapOutputEvent",
      fields: [
        {
          name: "userWallet",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenAMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenBMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "feeAmountTokenA",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenB",
          type: "u64",
          index: false,
        },
        {
          name: "poolMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "outputLpAmount",
          type: "u64",
          index: false,
        },
        {
          name: "platformName",
          type: "string",
          index: false,
        },
      ],
    },
    {
      name: "InstaswapReverseEvent",
      fields: [
        {
          name: "userWallet",
          type: "publicKey",
          index: false,
        },
        {
          name: "poolMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "inputLpAmount",
          type: "u64",
          index: false,
        },
        {
          name: "tokenAMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "tokenBMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "outputAAmount",
          type: "u64",
          index: false,
        },
        {
          name: "outputBAmount",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenA",
          type: "u64",
          index: false,
        },
        {
          name: "feeAmountTokenB",
          type: "u64",
          index: false,
        },
        {
          name: "platformName",
          type: "string",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "Unauthorized",
      msg: "You are not authorized to perform this action.",
    },
    {
      code: 6001,
      name: "AlreadyInUse",
      msg: "AlreadyInUse",
    },
    {
      code: 6002,
      name: "InvalidProgramAddress",
      msg: "InvalidProgramAddress",
    },
    {
      code: 6003,
      name: "InvalidState",
      msg: "InvalidState",
    },
    {
      code: 6004,
      name: "InvalidOwner",
      msg: "InvalidOwner",
    },
    {
      code: 6005,
      name: "NotAllowed",
      msg: "NotAllowed",
    },
    {
      code: 6006,
      name: "MathOverflow",
      msg: "Math operation overflow",
    },
    {
      code: 6007,
      name: "InvalidOracleConfig",
      msg: "InvalidOracleConfig",
    },
    {
      code: 6008,
      name: "InvalidAccountInput",
      msg: "InvalidAccountInput",
    },
    {
      code: 6009,
      name: "InvalidCluster",
      msg: "This function works on devnet only",
    },
    {
      code: 6010,
      name: "GlobalTVLExceeded",
      msg: "Global TVL Exceeded",
    },
    {
      code: 6011,
      name: "LTVExceeded",
      msg: "LTV Exceeded",
    },
    {
      code: 6012,
      name: "GlobalDebtCeilingExceeded",
      msg: "Global Debt Ceiling Exceeded",
    },
    {
      code: 6013,
      name: "PoolDebtCeilingExceeded",
      msg: "Pool Debt Ceiling Exceeded",
    },
    {
      code: 6014,
      name: "UserDebtCeilingExceeded",
      msg: "User Debt Ceiling Exceeded",
    },
    {
      code: 6015,
      name: "WithdrawNotAllowedWithDebt",
      msg: "Can't withdraw due to debt",
    },
    {
      code: 6016,
      name: "InvalidTransferAmount",
      msg: "Transfer amount is invalid",
    },
    {
      code: 6017,
      name: "InvalidPlatformType",
      msg: "Invalid platform type",
    },
    {
      code: 6018,
      name: "InvalidSaberPlatformType",
      msg: "Invalid platform, should be Saber",
    },
    {
      code: 6019,
      name: "RepayingMoreThanBorrowed",
      msg: "Attempting to repay more than the amount originally borrowed",
    },
    {
      code: 6020,
      name: "RewardMintMismatch",
      msg: "Reward mint account mismatch",
    },
    {
      code: 6021,
      name: "PoolPaused",
      msg: "The pool is paused by admin",
    },
    {
      code: 6022,
      name: "LedgerNotCreated",
      msg: "Create Raydium Ledger first",
    },
    {
      code: 6023,
      name: "InvalidFundingWallet",
      msg: "Invalid Funding Wallet",
    },
    {
      code: 6024,
      name: "AlreadyAddedBlackList",
      msg: "Already added in BlackList",
    },
    {
      code: 6025,
      name: "ReachedBlacklistLimit",
      msg: "Can't add to blacklist anymore",
    },
    {
      code: 6026,
      name: "NotFoundBlackList",
      msg: "Not found in BlackList",
    },
    {
      code: 6027,
      name: "BlockedFromRatioLending",
      msg: "You are blocked from our smart contract",
    },
    {
      code: 6028,
      name: "LiquidationAlreadyStarted",
      msg: "Liquidation is already started",
    },
    {
      code: 6029,
      name: "InvalidInternalProgramToPass",
      msg: "Invalid Interal Program To Pass",
    },
    {
      code: 6030,
      name: "InvalidUSDrAmountToBurn",
      msg: "Invalid USDr amount to burn",
    },
    {
      code: 6031,
      name: "NoLiquidation",
      msg: "Liquidation isn't started",
    },
    {
      code: 6032,
      name: "DebtExceededLiquidation",
      msg: "Debt exceeds Liquidation",
    },
    {
      code: 6033,
      name: "RaydiumLedgerNotInitialized",
      msg: "RaydiumLedger should be created before User Vault",
    },
  ],
};
