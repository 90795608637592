import { generateErrorMap } from "@saberhq/anchor-contrib";

export type AddDecimalsIDL = {
  version: "1.1.2";
  name: "add_decimals";
  instructions: [
    {
      name: "initializeWrapper";
      accounts: [
        {
          name: "wrapper";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: false;
          isSigner: false;
        },
        {
          name: "underlyingMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "nonce";
          type: "u8";
        }
      ];
    },
    {
      name: "deposit";
      accounts: [
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "userUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userWrappedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "depositAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "withdraw";
      accounts: [
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "userUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userWrappedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "maxBurnAmount";
          type: "u64";
        }
      ];
    },
    {
      name: "withdrawAll";
      accounts: [
        {
          name: "wrapper";
          isMut: false;
          isSigner: false;
        },
        {
          name: "wrapperMint";
          isMut: true;
          isSigner: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "userUnderlyingTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "userWrappedTokens";
          isMut: true;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "WrappedToken";
      type: {
        kind: "struct";
        fields: [
          {
            name: "decimals";
            type: "u8";
          },
          {
            name: "multiplier";
            type: "u64";
          },
          {
            name: "wrapperUnderlyingMint";
            type: "publicKey";
          },
          {
            name: "wrapperUnderlyingTokens";
            type: "publicKey";
          },
          {
            name: "wrapperMint";
            type: "publicKey";
          },
          {
            name: "nonce";
            type: "u8";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "InitEvent";
      fields: [
        {
          name: "payer";
          type: "publicKey";
          index: false;
        },
        {
          name: "decimals";
          type: "u8";
          index: false;
        },
        {
          name: "multiplier";
          type: "u64";
          index: false;
        },
        {
          name: "wrapperUnderlyingMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "wrapperUnderlyingTokens";
          type: "publicKey";
          index: false;
        },
        {
          name: "wrapperMint";
          type: "publicKey";
          index: false;
        }
      ];
    },
    {
      name: "DepositEvent";
      fields: [
        {
          name: "owner";
          type: "publicKey";
          index: false;
        },
        {
          name: "underlyingMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "wrappedMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "depositAmount";
          type: "u64";
          index: false;
        },
        {
          name: "mintAmount";
          type: "u64";
          index: false;
        }
      ];
    },
    {
      name: "WithdrawEvent";
      fields: [
        {
          name: "owner";
          type: "publicKey";
          index: false;
        },
        {
          name: "underlyingMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "wrappedMint";
          type: "publicKey";
          index: false;
        },
        {
          name: "withdrawAmount";
          type: "u64";
          index: false;
        },
        {
          name: "burnAmount";
          type: "u64";
          index: false;
        },
        {
          name: "dustAmount";
          type: "u64";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "InitNonEmptyAccount";
      msg: "Wrapper underlying tokens account must be empty.";
    },
    {
      code: 6001;
      name: "InitWrapperSupplyNonZero";
      msg: "Supply of the wrapper mint is non-zero";
    },
    {
      code: 6002;
      name: "InitWrapperUnderlyingOwnerMismatch";
      msg: "Owner of the wrapper underlying tokens account must be the wrapper";
    },
    {
      code: 6003;
      name: "InitWrapperUnderlyingMintMismatch";
      msg: "Underlying mint does not match underlying tokens account mint";
    },
    {
      code: 6004;
      name: "InitMintAuthorityMismatch";
      msg: "Mint authority mismatch";
    },
    {
      code: 6005;
      name: "InitMultiplierOverflow";
      msg: "Initial decimals too high";
    },
    {
      code: 6006;
      name: "InitWrapperDecimalsTooLow";
      msg: "The number of target decimals must be greater than or equal to the underlying asset's decimals.";
    },
    {
      code: 6007;
      name: "MintAmountOverflow";
      msg: "Mint amount overflow. This error happens when the token cannot support this many decimals added to the token.";
    },
    {
      code: 6008;
      name: "InvalidBurnAmount";
      msg: "Failed to convert burn amount from withdraw amount.";
    },
    {
      code: 6009;
      name: "InvalidWithdrawAmount";
      msg: "Failed to convert withdraw amount from wrapped amount.";
    },
    {
      code: 6010;
      name: "InsufficientUnderlyingBalance";
      msg: "User does not have enough underlying tokens";
    },
    {
      code: 6011;
      name: "InsufficientWrappedBalance";
      msg: "User does not have enough wrapped tokens";
    },
    {
      code: 6012;
      name: "ZeroAmount";
      msg: "Cannot send zero tokens";
    },
    {
      code: 6013;
      name: "UnknownAction";
      msg: "Unknown router action";
    },
    {
      code: 6014;
      name: "InitFreezeAuthorityMismatch";
      msg: "Freeze authority mismatch";
    }
  ];
};
export const AddDecimalsJSON: AddDecimalsIDL = {
  version: "1.1.2",
  name: "add_decimals",
  instructions: [
    {
      name: "initializeWrapper",
      accounts: [
        {
          name: "wrapper",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: false,
          isSigner: false,
        },
        {
          name: "underlyingMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "nonce",
          type: "u8",
        },
      ],
    },
    {
      name: "deposit",
      accounts: [
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "userUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userWrappedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "depositAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "withdraw",
      accounts: [
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "userUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userWrappedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "maxBurnAmount",
          type: "u64",
        },
      ],
    },
    {
      name: "withdrawAll",
      accounts: [
        {
          name: "wrapper",
          isMut: false,
          isSigner: false,
        },
        {
          name: "wrapperMint",
          isMut: true,
          isSigner: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "userUnderlyingTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "userWrappedTokens",
          isMut: true,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "WrappedToken",
      type: {
        kind: "struct",
        fields: [
          {
            name: "decimals",
            type: "u8",
          },
          {
            name: "multiplier",
            type: "u64",
          },
          {
            name: "wrapperUnderlyingMint",
            type: "publicKey",
          },
          {
            name: "wrapperUnderlyingTokens",
            type: "publicKey",
          },
          {
            name: "wrapperMint",
            type: "publicKey",
          },
          {
            name: "nonce",
            type: "u8",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "InitEvent",
      fields: [
        {
          name: "payer",
          type: "publicKey",
          index: false,
        },
        {
          name: "decimals",
          type: "u8",
          index: false,
        },
        {
          name: "multiplier",
          type: "u64",
          index: false,
        },
        {
          name: "wrapperUnderlyingMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "wrapperUnderlyingTokens",
          type: "publicKey",
          index: false,
        },
        {
          name: "wrapperMint",
          type: "publicKey",
          index: false,
        },
      ],
    },
    {
      name: "DepositEvent",
      fields: [
        {
          name: "owner",
          type: "publicKey",
          index: false,
        },
        {
          name: "underlyingMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "wrappedMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "depositAmount",
          type: "u64",
          index: false,
        },
        {
          name: "mintAmount",
          type: "u64",
          index: false,
        },
      ],
    },
    {
      name: "WithdrawEvent",
      fields: [
        {
          name: "owner",
          type: "publicKey",
          index: false,
        },
        {
          name: "underlyingMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "wrappedMint",
          type: "publicKey",
          index: false,
        },
        {
          name: "withdrawAmount",
          type: "u64",
          index: false,
        },
        {
          name: "burnAmount",
          type: "u64",
          index: false,
        },
        {
          name: "dustAmount",
          type: "u64",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "InitNonEmptyAccount",
      msg: "Wrapper underlying tokens account must be empty.",
    },
    {
      code: 6001,
      name: "InitWrapperSupplyNonZero",
      msg: "Supply of the wrapper mint is non-zero",
    },
    {
      code: 6002,
      name: "InitWrapperUnderlyingOwnerMismatch",
      msg: "Owner of the wrapper underlying tokens account must be the wrapper",
    },
    {
      code: 6003,
      name: "InitWrapperUnderlyingMintMismatch",
      msg: "Underlying mint does not match underlying tokens account mint",
    },
    {
      code: 6004,
      name: "InitMintAuthorityMismatch",
      msg: "Mint authority mismatch",
    },
    {
      code: 6005,
      name: "InitMultiplierOverflow",
      msg: "Initial decimals too high",
    },
    {
      code: 6006,
      name: "InitWrapperDecimalsTooLow",
      msg: "The number of target decimals must be greater than or equal to the underlying asset's decimals.",
    },
    {
      code: 6007,
      name: "MintAmountOverflow",
      msg: "Mint amount overflow. This error happens when the token cannot support this many decimals added to the token.",
    },
    {
      code: 6008,
      name: "InvalidBurnAmount",
      msg: "Failed to convert burn amount from withdraw amount.",
    },
    {
      code: 6009,
      name: "InvalidWithdrawAmount",
      msg: "Failed to convert withdraw amount from wrapped amount.",
    },
    {
      code: 6010,
      name: "InsufficientUnderlyingBalance",
      msg: "User does not have enough underlying tokens",
    },
    {
      code: 6011,
      name: "InsufficientWrappedBalance",
      msg: "User does not have enough wrapped tokens",
    },
    {
      code: 6012,
      name: "ZeroAmount",
      msg: "Cannot send zero tokens",
    },
    {
      code: 6013,
      name: "UnknownAction",
      msg: "Unknown router action",
    },
    {
      code: 6014,
      name: "InitFreezeAuthorityMismatch",
      msg: "Freeze authority mismatch",
    },
  ],
};
export const AddDecimalsErrors = generateErrorMap(AddDecimalsJSON);
