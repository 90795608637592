import { parseErrorForTransaction, TransactionError, UNKNOWN_ERROR } from '@mercurial-finance/optimist';
import { TransactionResponse } from '@solana/web3.js';
import { ErrorDetails, JUPITER_ERRORS } from '../../constants';
import { JUPITER_PROGRAM_ID } from '../../lib/jupiterInstruction';

const codeToErrorRecord = Object.values(JUPITER_ERRORS).reduce((record, error) => {
  record[error.code] = error;
  return record;
}, {} as Record<number, ErrorDetails>);

// Throws when not found or contains error to satisfy upper control flow
export async function validateTransactionResponse({
  txid,
  transactionResponse,
}: {
  txid: string;
  transactionResponse: TransactionResponse | null;
}): Promise<TransactionResponse | TransactionError | null> {
  if (!transactionResponse) {
    return new TransactionError('Transaction was not confirmed', txid);
  }
  if (transactionResponse?.meta?.err) {
    let { message, programId, code } = parseErrorForTransaction(transactionResponse);

    if (programId === JUPITER_PROGRAM_ID.toBase58() && code) {
      message = codeToErrorRecord[code]?.msg;
    }

    if (!message) {
      message = UNKNOWN_ERROR;
    }

    return new TransactionError(message || '', txid, code);
  }
  return transactionResponse;
}
