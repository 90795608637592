import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { AccountInfoMap, Amm, mapAddressToAccountInfos, Quote, QuoteParams, SwapParams } from '../amm';
import {
  createMarinadeFinanceDepositInstruction,
  createMarinadeFinanceLiquidUnstakeInstruction,
} from '../jupiterInstruction';
import { deserializeAccount } from '@mercurial-finance/optimist';
import { NATIVE_MINT } from '@solana/spl-token';
import BN from 'bn.js';
import { Idl, Program } from '@project-serum/anchor';
import * as marinadeFinanceIdlSchema from './idl/marinade-finance-idl.json';
import { MarinadeStateResponse, ProgramDerivedAddressSeed } from './marinade-state.types';
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { proportionalBN, unstakeNowFeeBp } from './helpers';
import { MARINADE_PROGRAM_ID } from '../../constants';
import JSBI from 'jsbi';

export class MarinadeAmm implements Amm {
  address: PublicKey;
  id: string;
  label = 'Marinade';
  shouldPrefetch = true; // Pricing is very state dependent and using stale data will lead to a stale quote
  exactOutputSupported = false;

  marinadeFinanceProgram: Program;
  marinadeStateResponse: MarinadeStateResponse;
  liqPoolSolLegPdaAddress: PublicKey;
  marinadeState: MarinadeState | undefined;

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>) {
    this.id = address.toBase58();
    this.marinadeFinanceProgram = new Program(marinadeFinanceIdlSchema as Idl, MARINADE_PROGRAM_ID, {} as any);
    this.marinadeStateResponse = this.marinadeFinanceProgram.coder.accounts.decode('State', accountInfo.data);
    this.address = address;

    this.liqPoolSolLegPdaAddress = this.findProgramDerivedAddress(ProgramDerivedAddressSeed.LIQ_POOL_SOL_ACCOUNT);
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.address, this.liqPoolSolLegPdaAddress, this.marinadeStateResponse.liqPool.msolLeg];
  }

  update(accountInfoMap: AccountInfoMap) {
    const [stateAccountInfo, liqPoolSolLegPda, liqPoolMSOLLegAccountInfo] = mapAddressToAccountInfos(
      accountInfoMap,
      this.getAccountsForUpdate(),
    );

    this.marinadeStateResponse = this.marinadeFinanceProgram.coder.accounts.decode('State', stateAccountInfo.data);
    const liqPoolMSOLLeg = deserializeAccount(liqPoolMSOLLegAccountInfo.data);
    if (!liqPoolMSOLLeg)
      throw new Error(
        `liqPoolMSOLLeg token account cannot be deserialized ${this.marinadeStateResponse.liqPool.msolLeg.toBase58()}`,
      );

    this.marinadeState = new MarinadeState(
      this.marinadeStateResponse,
      new BN(liqPoolSolLegPda.lamports),
      liqPoolMSOLLeg.amount,
    );
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (!this.marinadeState) throw new Error('Update was not run to create a complete marinadeState');

    const amountBN = new BN(amount.toString());
    const result = sourceMint.equals(NATIVE_MINT)
      ? this.marinadeState.depositQuote(amountBN)
      : this.marinadeState.liquidUnstakeQuote(amountBN);

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.BigInt(result.outAmount.toString()),
      feeAmount: JSBI.BigInt(result.feeAmount.toString()),
      feeMint: this.marinadeStateResponse.msolMint.toBase58(),
      feePct: result.feePct,
      priceImpactPct: 0,
    };
  }

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[] {
    return [
      swapParams.sourceMint.equals(NATIVE_MINT)
        ? createMarinadeFinanceDepositInstruction({
            ...swapParams,
            additionalArgs: {
              address: this.address,
              marinadeStateResponse: this.marinadeStateResponse,
              liqPoolSolLegPda: this.liqPoolSolLegPdaAddress,
              liqPoolMsolLegAuthority: this.findProgramDerivedAddress(
                ProgramDerivedAddressSeed.LIQ_POOL_MSOL_AUTHORITY,
              ),
              reservePda: this.findProgramDerivedAddress(ProgramDerivedAddressSeed.RESERVE_ACCOUNT),
              msolMintAuthority: this.findProgramDerivedAddress(ProgramDerivedAddressSeed.LIQ_POOL_MSOL_MINT_AUTHORITY),
            },
            inAmount: swapParams.amount,
            minimumOutAmount: swapParams.otherAmountThreshold,
          })
        : createMarinadeFinanceLiquidUnstakeInstruction({
            ...swapParams,
            additionalArgs: {
              address: this.address,
              marinadeStateResponse: this.marinadeStateResponse,
              liqPoolSolLegPda: this.liqPoolSolLegPdaAddress,
            },
            inAmount: swapParams.amount,
            minimumOutAmount: swapParams.otherAmountThreshold,
          }),
    ];
  }

  get reserveTokenMints() {
    return [NATIVE_MINT, this.marinadeStateResponse.msolMint];
  }

  private findProgramDerivedAddress(seed: ProgramDerivedAddressSeed, extraSeeds: Buffer[] = []): PublicKey {
    const seeds = [this.address.toBuffer(), Buffer.from(seed), ...extraSeeds];
    const [result] = findProgramAddressSync(seeds, this.marinadeFinanceProgram.programId);
    return result;
  }
}

class MarinadeState {
  constructor(
    private state: MarinadeStateResponse,
    private liqPoolSolLegPdaLamports: BN,
    private liqPoolMSOLLegAmount: BN,
  ) {}

  // https://github.com/marinade-finance/liquid-staking-program/blob/main/programs/marinade-finance/src/state/deposit.rs#L61-L170
  depositQuote(lamports: BN) {
    let userLamports = lamports;
    const userMSOLBuyOrder = this.calcMSOLFromLamports(userLamports);
    const swapMSOLMax = BN.min(userMSOLBuyOrder, this.liqPoolMSOLLegAmount);

    let outAmountBN = new BN(0);

    // if we can sell from the LiqPool
    userLamports = (() => {
      if (swapMSOLMax.gt(new BN(0))) {
        const lamportsForTheLiqPool = userMSOLBuyOrder.eq(swapMSOLMax)
          ? userLamports
          : this.calcLamportsFromMSOLAmount(swapMSOLMax);

        // transfered mSOL to the user
        outAmountBN = outAmountBN.add(swapMSOLMax);

        return saturatingSub(userLamports, lamportsForTheLiqPool);
      } else {
        return userLamports;
      }
    })();

    // check if we have more lamports from the user
    if (userLamports.gt(new BN(0))) {
      this.checkStakingCap(userLamports);
      const MSOLToMint = this.calcMSOLFromLamports(userLamports);
      outAmountBN = outAmountBN.add(MSOLToMint);
    }

    return {
      outAmount: outAmountBN,
      feeAmount: 0,
      feePct: 0,
      priceImpactPct: 0,
    };
  }

  private checkStakingCap(transferingLamports: BN) {
    const resultAmount = this.totalLamportsUnderControl().add(transferingLamports);

    if (resultAmount.gt(this.state.stakingSolCap)) throw new Error('Staking cap reached');
  }

  private calcMSOLFromLamports(stakeLamports: BN) {
    return sharesFromValue(stakeLamports, this.totalVirtualStakedLamports(), this.state.msolSupply);
  }

  private calcLamportsFromMSOLAmount(msolAmount: BN) {
    return valueFromShares(msolAmount, this.totalVirtualStakedLamports(), this.state.msolSupply);
  }

  private totalVirtualStakedLamports() {
    return saturatingSub(this.totalLamportsUnderControl(), this.state.circulatingTicketBalance);
  }

  private totalLamportsUnderControl() {
    return this.state.validatorSystem.totalActiveBalance
      .add(this.totalCoolingDown())
      .add(this.state.availableReserveBalance);
  }

  private totalCoolingDown() {
    return this.state.stakeSystem.delayedUnstakeCoolingDown.add(this.state.emergencyCoolingDown);
  }

  // https://github.com/marinade-finance/liquid-staking-program/blob/main/programs/marinade-finance/src/state/liquid_unstake.rs#L68-L171
  liquidUnstakeQuote(msolAmount: BN) {
    const maxLamports = saturatingSub(this.liqPoolSolLegPdaLamports, this.state.rentExemptForTokenAcc);

    const lamportsToObtain = this.calcLamportsFromMSOLAmount(msolAmount);
    const liquidUnstakeFeeBp = unstakeNowFeeBp(
      this.state.liqPool.lpMinFee.basisPoints,
      this.state.liqPool.lpMaxFee.basisPoints,
      this.state.liqPool.lpLiquidityTarget,
      maxLamports,
      lamportsToObtain,
    );

    const msolFee = msolAmount.mul(new BN(liquidUnstakeFeeBp)).div(new BN(10_000));
    const workingLamportsValue = this.calcLamportsFromMSOLAmount(msolAmount.sub(msolFee));
    if (workingLamportsValue.add(this.state.rentExemptForTokenAcc).gt(this.liqPoolSolLegPdaLamports))
      throw new Error('Insufficient liquidity');

    return {
      outAmount: workingLamportsValue,
      feeAmount: msolFee,
      feePct: liquidUnstakeFeeBp / 10_000,
      priceImpactPct: 0,
    };
  }
}
function valueFromShares(shares: BN, totalValue: BN, totalShares: BN) {
  return proportionalBN(shares, totalValue, totalShares);
}

function sharesFromValue(value: BN, totalValue: BN, totalShares: BN) {
  return totalShares.eq(new BN(0)) ? value : proportionalBN(value, totalShares, totalValue);
}

function saturatingSub(left: BN, right: BN): BN {
  return left.gt(right) ? left.sub(right) : new BN(0);
}
