import { MAINNET_LP_TOKENS, MAINNET_SPL_TOKENS } from "../token";

import { FarmPoolBaseInfo } from "./type";

export const MAINNET_FARM_POOLS: FarmPoolBaseInfo[] = [
  {
    id: "AvbVWpBi2e4C9HPmZgShGdPoNydG4Yw8GJvG9HUcLgce",
    lp: MAINNET_LP_TOKENS.RAY_USDT_V4,
    version: 3,
  },
  {
    id: "4EwbZo8BZXP5313z5A2H11MRBP15M5n6YxfmkjXESKAW",
    lp: MAINNET_SPL_TOKENS.RAY,
    version: 3,
  },
  {
    id: "XnRBbNMf6YcWvC1u2vBXXuMcagmRBRLu1y84mpqnKwW",
    lp: MAINNET_LP_TOKENS.xCOPE_USDC_V4,
    version: 5,
  },
  {
    id: "8xhjCzfzVcP79jE7jXR2xtNaSL6aJYoDRLVT9FMjpRTC",
    lp: MAINNET_LP_TOKENS.STEP_USDC_V4,
    version: 5,
  },
  {
    id: "Ef1gD9JMzWF6PNw2uc4744zouh57GyWAeVTjHHbQ2nsu",
    lp: MAINNET_LP_TOKENS.MEDIA_USDC_V4,
    version: 5,
  },
  {
    id: "AxVvbT9fDFEkmdLwKUJRY5HsG2RXAZbe1dRAgJ2bDDwg",
    lp: MAINNET_LP_TOKENS.COPE_USDC_V4,
    version: 5,
  },
  {
    id: "D4pYuD4tbir9KBsb7Kr63v9e86JY2UoUZeFK9eHKQFZM",
    lp: MAINNET_LP_TOKENS.MER_USDC_V4,
    version: 5,
  },
  {
    id: "BLy8KuRck5bcJkQdMDLSZnL1Ka4heAZSGiwTJfEfY727",
    lp: MAINNET_LP_TOKENS.ROPE_USDC_V4,
    version: 5,
  },
  {
    id: "JAP8SFagJBm6vt2LoFGNeSJ1hKDZ2p3yXb3CvBx11How",
    lp: MAINNET_LP_TOKENS.ALEPH_USDC_V4,
    version: 5,
  },
  {
    id: "7U8Z6TWQMtsMcHV2htALnF9VQonnD1MrVm17YtmGEGEw",
    lp: MAINNET_LP_TOKENS.TULIP_USDC_V4,
    version: 5,
  },
  {
    id: "31QSh1TwgoA9GbvkgfEEwKEm11t8CR4KiQr6WCyJr7EN",
    lp: MAINNET_LP_TOKENS.SNY_USDC_V4,
    version: 5,
  },
  {
    id: "EEe8b72w5q6T86nYRNJdFcY25tznPzrd1jGjuxZ7f9mX",
    lp: MAINNET_LP_TOKENS.BOP_RAY_V4,
    version: 5,
  },
  {
    id: "5PVVwSqwzkCvuiKEZwWkM35ApBnoWqF8XopsVZjPwA8z",
    lp: MAINNET_LP_TOKENS.SLRS_USDC_V4,
    version: 5,
  },
  {
    id: "Bw932pURVJRYjEJwRZGWjfUNpeyz18kjMNdb833eMxoj",
    lp: MAINNET_LP_TOKENS.SAMO_RAY_V4,
    version: 5,
  },
  {
    id: "BRM5bdX2mjmFGg2RAent1Whd61o9asQD16BXsC6QvEni",
    lp: MAINNET_LP_TOKENS.LIKE_USDC_V4,
    version: 5,
  },
  {
    id: "HUDr9BDaAGqi37xbQHzxCyXvfMCKPTPNF8g9c9bPu1Fu",
    lp: MAINNET_LP_TOKENS.RAY_SOL_V4,
    version: 3,
  },
  {
    id: "CHYrUBX2RKX8iBg7gYTkccoGNBzP44LdaazMHCLcdEgS",
    lp: MAINNET_LP_TOKENS.RAY_USDC_V4,
    version: 3,
  },
  {
    id: "B6fbnZZ7sbKHR18ffEDD5Nncgp54iKN1GbCgjTRdqhS1",
    lp: MAINNET_LP_TOKENS.RAY_ETH_V4,
    version: 3,
  },
  {
    id: "5DFbcYNLLy5SJiBpCCDzNSs7cWCsUbYnCkLXzcPQiKnR",
    lp: MAINNET_LP_TOKENS.RAY_SRM_V4,
    version: 3,
  },
  {
    id: "GzEDEkHSFFfxKMu3Toww1nrEjtbQGJKRPNRK1Pfd59Zn",
    lp: MAINNET_LP_TOKENS.MNGO_USDC_V4,
    version: 5,
  },
  {
    id: "CM9XTJfXEHceGPXhmXxheR87Ng9CZ4jiBoTVQHhs9DVN",
    lp: MAINNET_LP_TOKENS.COPE_RAY_V4,
    version: 5,
  },
  {
    id: "AMcVWK66iexwwCHn8drxywdNr2UgH3vmRzLXQFdErGmL",
    lp: MAINNET_LP_TOKENS.LIKE_RAY_V4,
    version: 5,
  },
  {
    id: "DJfvL6srBht8XFMWYuuKHYGainqvwXyA5icVsDne3pwN",
    lp: MAINNET_LP_TOKENS.MEDIA_RAY_V4,
    version: 5,
  },
  {
    id: "21xhrT4j8QnaBvj3QjhP5kZu8sXJMCE7hzHKGtWEkdKr",
    lp: MAINNET_LP_TOKENS.MER_RAY_V4,
    version: 5,
  },
  {
    id: "J61AnYYSwjtJ4wDqEqqWSBuZbiR2SDDrtF7FFobutM6a",
    lp: MAINNET_LP_TOKENS.SLRS_RAY_V4,
    version: 5,
  },
  {
    id: "JEC3P83x2GEijYDwXiksuh5H6YrQt5xW6MC2GDKkMoe",
    lp: MAINNET_LP_TOKENS.SNY_RAY_V4,
    version: 5,
  },
  {
    id: "BmbG9hv5PazcW3rYWvatA6HpNPkozEdkWBiU64pZxuwr",
    lp: MAINNET_LP_TOKENS.TULIP_RAY_V4,
    version: 5,
  },
  {
    id: "4wvZ9SwWaHKTpshQbCSKQoPosZp9KGwUzuQdESi39qPn",
    lp: MAINNET_LP_TOKENS.ALEPH_RAY_V4,
    version: 5,
  },
  {
    id: "BnYoq5y2MoH4TsBHeEZrEPowhwebHxQq7nJW1vTjPTWu",
    lp: MAINNET_LP_TOKENS.RAY_SRM_V4,
    version: 5,
  },
  {
    id: "93wRz2LeQ3TJoair827VTng62MjCzYDgJjG9Q5GmQ3Pd",
    lp: MAINNET_LP_TOKENS.ATLAS_USDC_V4,
    version: 5,
  },
  {
    id: "7qcihXTsRW5wS5BgK7iuD84W43ECByoJP45R3hu2r6mF",
    lp: MAINNET_LP_TOKENS.POLIS_USDC_V4,
    version: 5,
  },
  {
    id: "BHHhNLdJn69K1XPJcpcw4MBY3TPetpLxhj8s4K4ydsDV",
    lp: MAINNET_LP_TOKENS.ATLAS_RAY_V4,
    version: 5,
  },
  {
    id: "HHm8Pgnzc56fTUYkicPv4DqGYp5fcPZFV1V1uhixSrMk",
    lp: MAINNET_LP_TOKENS.POLIS_RAY_V4,
    version: 5,
  },
  {
    id: "8GBa1cK1NxevoxiRNK6YW9tWuo2xftcA3as9Cu4nhFL7",
    lp: MAINNET_LP_TOKENS.GRAPE_USDC_V4,
    version: 5,
  },
  {
    id: "HzxveT6pUMwYByqnScvTbpUv4avzkUDrDpS9D7DToEry",
    lp: MAINNET_LP_TOKENS.LARIX_USDC_V4,
    version: 5,
  },
  {
    id: "Fbwy4XWMjXuP1nXg4xph4RJ9E9twVXeknXokF38PVgG1",
    lp: MAINNET_LP_TOKENS.LARIX_RAY_V4,
    version: 5,
  },
  {
    id: "5gzQgxaKAU13SZeffmjjE2y9HwMJ42FxQkqJhJ5nqHeh",
    lp: MAINNET_LP_TOKENS.stSOL_USDC_V4,
    version: 5,
  },
  {
    id: "914jyHBQFiroKFVCpKkzjGSaZyr4gMwgxE7snbNfGjnL",
    lp: MAINNET_LP_TOKENS.BTC_mSOL_V4,
    version: 5,
  },
  {
    id: "54vUWjEmg9wfCsZF7wwq2HJu5BU3cfDFAQQQgXPECcwE",
    lp: MAINNET_LP_TOKENS.ETH_mSOL_V4,
    version: 5,
  },
  {
    id: "HxhxYASqdLcR6yehT9hB9HUpgcF1R2t9HtkHdngGZ2Dh",
    lp: MAINNET_LP_TOKENS.mSOL_USDT_V4,
    version: 5,
  },
  {
    id: "97N6tPMVCrAunC9embwTcffye9xC95fA5y3LauhNZ444",
    lp: MAINNET_LP_TOKENS.MNDE_mSOL_V4,
    version: 5,
  },
  {
    id: "DjtZxyFBgifzpaZEzfsWXogNX5zUCnTRXJqarGe9CiSv",
    lp: MAINNET_LP_TOKENS.mSOL_USDC_V4,
    version: 5,
  },
  {
    id: "7wNhbTS6XQczXs52wcVmfiodRMPfycB3YaG52dWWY6SD",
    lp: MAINNET_LP_TOKENS.mSOL_RAY_V4,
    version: 5,
  },
  {
    id: "CzKUrVbP7hH8EjcHNc55ZFW33rJyLQ2r52bxCzaGTpz",
    lp: MAINNET_LP_TOKENS.LIQ_USDC_V4,
    version: 5,
  },
  {
    id: "5XdeFdcJoCAPMfgYndMPcsijFBKFp2cPSCjrCZzmpTE5",
    lp: MAINNET_LP_TOKENS.LIQ_RAY_V4,
    version: 5,
  },
  {
    id: "Gf3qFzKnGvMCVMQZERW2Qso5uEYxd9B9kWZZHsrMmmHj",
    lp: MAINNET_LP_TOKENS.SYP_RAY_V4,
    version: 5,
  },
  {
    id: "2pQQnoNpm5LoG6sZs5toNBXi4m1Pj3ExXdggPQYyiP2x",
    lp: MAINNET_LP_TOKENS.SYP_SOL_V4,
    version: 5,
  },
  {
    id: "Byt2kL5qi45pMpdAsNNciKZ8HLp7oU5jizCbyARLtQJy",
    lp: MAINNET_LP_TOKENS.SYP_USDC_V4,
    version: 5,
  },
  {
    id: "8W2TqGCiFiqR1JD4sbW8uTRjV2HvFjLhkZ2tAQTYE4Gc",
    lp: MAINNET_LP_TOKENS.WOOF_RAY_V4,
    version: 5,
  },
  {
    id: "FgApVk6mASrkuWNxmsFvsaAYkFKqdiwMTvYZK36A2DaC",
    lp: MAINNET_LP_TOKENS.KIN_RAY_V4,
    version: 5,
  },
  {
    id: "AwUDfg4NYbLQRAcFipoJwyZTpqNvw5v6C7EypryL12Y6",
    lp: MAINNET_LP_TOKENS.STARS_USDC_V4,
    version: 5,
  },
  {
    id: "Gi3Z6TXeH1ZhCCbwg6oJL8SE4LcmxmGRNhhfA6NZhwTK",
    lp: MAINNET_LP_TOKENS.weWETH_SOL_V4,
    version: 5,
  },
  {
    id: "8JJSdD1ca5SDtGCEm3yBbQKek2FvJ1EbNt9q2ET3E9Jt",
    lp: MAINNET_LP_TOKENS.weWETH_USDC_V4,
    version: 5,
  },
  {
    id: "6X495xkPWkw9SQFYf7yL1K8QooZyaeEQ6u7yMWNNZxNV",
    lp: MAINNET_LP_TOKENS.weUNI_USDC_V4,
    version: 5,
  },
  {
    id: "AuyqPBiY6sNUpH6jx415NGcdvNdYbkbYsyVabUqEVdkj",
    lp: MAINNET_LP_TOKENS.weSUSHI_USDC_V4,
    version: 5,
  },
  {
    id: "7fe8QcJ6W2kHKL1h1HMYYJoGXz2LUcwCjkxX6MX35orK",
    lp: MAINNET_LP_TOKENS.weAXS_USDC_V4,
    version: 5,
  },
  {
    id: "C8BjS9DGDvC2zS3n6fTvm1rjPbA33uZ7CAvEUZ3tg7aM",
    lp: MAINNET_LP_TOKENS.weDYDX_USDC_V4,
    version: 5,
  },
  {
    id: "CZZnmfvSgNVUiDBG4wN2NNcaYbsKDN4kLsc3SN8DMw6i",
    lp: MAINNET_LP_TOKENS.weSHIB_USDC_V4,
    version: 5,
  },
  {
    id: "FAKzZoGVCEBDRuHN4gDswAx7PsocCorDqH6dQaxnyorT",
    lp: MAINNET_LP_TOKENS.weSAND_USDC_V4,
    version: 5,
  },
  {
    id: "EBS8tc4proQE2Fj6HxU4piiZP8oiDrvyJUijDCX7P7QN",
    lp: MAINNET_LP_TOKENS.weMANA_USDC_V4,
    version: 5,
  },
  {
    id: "6AxxjJhAz6APspTQM4vVCHgfzEyZgBTCogJLdai7bXYE",
    lp: MAINNET_LP_TOKENS.wbWBNB_USDC_V4,
    version: 5,
  },
  {
    id: "3HGPRHH3XFFu972MR1EdS65qc1nN9sM7miZtFTi6QcEd",
    lp: MAINNET_LP_TOKENS.wePEOPLE_USDC_V4,
    version: 5,
  },
  {
    id: "27bysJaX5eu5Urb5kftR66otiVc6DKK7TnifKwnpNzYu",
    lp: MAINNET_LP_TOKENS.SRM_USDC_V4,
    version: 5,
  },
  {
    id: "FDnxy4NkJVG3GNMMrtUZmUmoYeYE34YRDwCYTi1yBTM",
    lp: MAINNET_LP_TOKENS.CAVE_USDC_V4,
    version: 5,
  },
  {
    id: "DDRNVVJBEXEemcprVVUcrTbYnR88JyN6jjT2ypgAQHC8",
    lp: MAINNET_LP_TOKENS.GENE_USDC_V4,
    version: 5,
  },
  {
    id: "GVfLbXA3dpEHPvc4do9HvMZ8TACxm3x54BVrHPMEixcr",
    lp: MAINNET_LP_TOKENS.GENE_RAY_V4,
    version: 5,
  },
  {
    id: "7NZ18KhsSdJBQkJEJwhEHfdaPRNdbMd17CMoxsKxavEo",
    lp: MAINNET_LP_TOKENS.CWAR_USDC_V4,
    version: 5,
  },
  {
    id: "5Mdq5o3KKPyeVVBsbnivVk9qCATjfEQ22oysXVsd2DvJ",
    lp: MAINNET_LP_TOKENS.SONAR_USDC_V4,
    version: 5,
  },
  {
    id: "7W8BKbMgcVpGYvTgEK758pJgDRZJ9WafKfgkV1XCkP33",
    lp: MAINNET_LP_TOKENS.APT_USDC_V4,
    version: 5,
  },
  {
    id: "CgokwBwwdYsgo8hbUMtJ3GoNM3bVjvMcmaPrVvCw4sBi",
    lp: MAINNET_LP_TOKENS.SHILL_USDC_V4,
    version: 5,
  },
  {
    id: "AWbmotuJS7NLBmra9ctbfVR1BnoHmiG1HGW6hm49TuRZ",
    lp: MAINNET_LP_TOKENS.DFL_USDC_V4,
    version: 5,
  },
  {
    id: "665VvECKsLpXN4fLy8GYbyQpGJRNkxRx56X93U9FVSbv",
    lp: MAINNET_LP_TOKENS.BOKU_USDC_V4,
    version: 5,
  },
  {
    id: "7yk1XUWmZpWMCoFpUT985z61UNTypyBGgZF3JpKgcwmL",
    lp: MAINNET_LP_TOKENS.MIMO_SOL_V4,
    version: 5,
  },
  {
    id: "E5L4Bx3Lsif7bDb9XMWi5guHUt4kkYpbqQ4CbmnRjHs1",
    lp: MAINNET_LP_TOKENS.XTAG_USDC_V4,
    version: 5,
  },
  {
    id: "HtvQ5AEvu8sDM7C1oB4r5fJ5E2Q1wsJ4TADwNMqR9ccF",
    lp: MAINNET_LP_TOKENS.TTT_USDC_V4,
    version: 5,
  },
  {
    id: "GUzaohfNuFbBqQTnPgPSNciv3aUvriXYjQduRE3ZkqFw",
    lp: MAINNET_LP_TOKENS.SOL_USDC_V4,
    version: 5,
  },
  {
    id: "5r878BSWPtoXgnqaeFJi7BCycKZ5CodBB2vS9SeiV8q",
    lp: MAINNET_LP_TOKENS.SOL_USDT_V4,
    version: 5,
  },
  {
    id: "3J3SYLeFZWKnUCsrPzikw9bcD9vRs7YNGfmg7ZSg3tsK",
    lp: MAINNET_LP_TOKENS.RUN_USDC_V4,
    version: 5,
  },
  {
    id: "BYmeWrwA4ixvJhNrxWzQsA3Fsz6EtUDJTo39WYZ6o1FS",
    lp: MAINNET_LP_TOKENS.CRWNY_RAY_V4,
    version: 5,
  },
  {
    id: "6VNF4rF7ESUohzNeRf3aTg61dyFjbab749RGUHCTDFQL",
    lp: MAINNET_LP_TOKENS.CRWNY_USDC_V4,
    version: 5,
  },
  {
    id: "7RQDGZ1cvHcREu211R35WSKHFjTxM5dmJHeFAWag29BA",
    lp: MAINNET_LP_TOKENS.REAL_USDC_V4,
    version: 5,
  },
  {
    id: "ELovJ3jDKMiWhCUCdZzPfTeVmQwhj4cvYuGwS8FfhEtJ",
    lp: MAINNET_LP_TOKENS.BLOCK_USDC_V4,
    version: 5,
  },
  {
    id: "36Rf8dzq5gy4Ew2bLQEgKoCKVTku4EJPLqFYG6mzFrkr",
    lp: MAINNET_LP_TOKENS.FRKT_SOL_V4,
    version: 5,
  },
  {
    id: "HXpQJeAcBCKfGY6YhZgBocZGgV4xApPVK7r8CPXfvCin",
    lp: MAINNET_LP_TOKENS.FRKT_USDC_V4,
    version: 5,
  },
  {
    id: "Eqn7unnxmneDuTuXQ7EAN1wBkHMFD4LgCCAkCdPA9KbV",
    lp: MAINNET_LP_TOKENS.MBS_USDC_V4,
    version: 5,
  },
  {
    id: "DtU5pZB99rVtxe5qe5hQa2rRpw7poFiqaHvMAbPgT2TM",
    lp: MAINNET_LP_TOKENS.PRISM_USDC_V4,
    version: 5,
  },
  {
    id: "Gue2c9J2aRtY3aJ1HEwabQznwg2MUt9d9kUyr6otujR",
    lp: MAINNET_LP_TOKENS.CHICKS_USDC_V4,
    version: 5,
  },
  {
    id: "Ej7iBkW79EdF26RxaRQfGo58PqRdzHzRmE4C2e3z3dPY",
    lp: MAINNET_LP_TOKENS.MEAN_RAY_V4,
    version: 5,
  },
  {
    id: "Bd1hAsMAghtCjK38Ut4m938Ep7zyEp2TydQ7G35nPvUj",
    lp: MAINNET_LP_TOKENS.TINY_USDC_V4,
    version: 5,
  },
  {
    id: "2G7qTQzw3KXm7WEMJf2izqFQWh8CLZ1wFbQVP5qo3ME9",
    lp: MAINNET_LP_TOKENS.SCY_USDC_V4,
    version: 5,
  },
];

export const TESTNET_FARM_POOLS: FarmPoolBaseInfo[] = [];

export const DEVNET_FARM_POOLS: FarmPoolBaseInfo[] = [];
