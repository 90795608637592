/**
 * Derive a program address from seeds and a program ID.
 */
import { sha256 } from 'js-sha256';
import { MAX_SEED_LENGTH, PublicKey } from '@solana/web3.js';
import BN from 'bn.js';

export const toBuffer = (arr: Buffer | Uint8Array | Array<number>): Buffer => {
  if (Buffer.isBuffer(arr)) {
    return arr;
  } else if (arr instanceof Uint8Array) {
    return Buffer.from(arr.buffer, arr.byteOffset, arr.byteLength);
  } else {
    return Buffer.from(arr);
  }
};

/**
 * Derive a program address from seeds and a program ID.
 * Without checking the resulting address is off curve to save computation
 */
export function createProgramAddressSyncUnsafe(seeds: Array<Buffer | Uint8Array>, programId: PublicKey): PublicKey {
  let buffer = Buffer.alloc(0);
  seeds.forEach(function (seed) {
    if (seed.length > MAX_SEED_LENGTH) {
      throw new TypeError(`Max seed length exceeded`);
    }
    buffer = Buffer.concat([buffer, toBuffer(seed)]);
  });
  buffer = Buffer.concat([buffer, programId.toBuffer(), Buffer.from('ProgramDerivedAddress')]);
  let hash = sha256(new Uint8Array(buffer));
  let publicKeyBytes = new BN(hash, 16).toArray(undefined, 32);
  // if (PublicKey.isOnCurve(new Uint8Array(publicKeyBytes))) {
  //   throw new Error(`Invalid seeds, address must fall off the curve`);
  // }
  return new PublicKey(publicKeyBytes);
}
