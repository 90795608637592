import { getCurveAmount, getParsedData, IAmmData, ICurveAmount, IPoolInfo, TradeDirection } from '@jup-ag/lifinity-sdk';
import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { AccountInfoMap, Amm, Quote, QuoteParams, SwapParams } from '../amm';
import { accountInfoLifinitySwapLayout, LifinitySwapLayoutState, swapStateToPoolInfo } from './swapLayout';
import { createLifinitySwapInstruction } from '../jupiterInstruction';
import Decimal from 'decimal.js';
import JSBI from 'jsbi';

export class LifinityAmm implements Amm {
  id: string;
  label = 'Lifinity' as const;
  shouldPrefetch = false;
  exactOutputSupported = false;

  private swapState: LifinitySwapLayoutState;
  private poolInfo: IPoolInfo;
  private accountInfos: Array<{ publicKey: PublicKey; account: AccountInfo<Buffer> }> = [];

  constructor(address: PublicKey, private ammAccountInfo: AccountInfo<Buffer>) {
    this.id = address.toBase58();
    this.swapState = accountInfoLifinitySwapLayout(address, ammAccountInfo);
    this.poolInfo = swapStateToPoolInfo(this.swapState);
  }

  getAccountsForUpdate(): PublicKey[] {
    return [
      this.swapState.poolCoinTokenAccount,
      this.swapState.poolPcTokenAccount,
      this.swapState.configAccount,
      this.swapState.pythAccount,
      this.swapState.pythPcAccount,
    ];
  }

  update(accountInfoMap: AccountInfoMap): void {
    this.getAccountsForUpdate().forEach((publicKey, idx) => {
      const account = accountInfoMap.get(publicKey.toBase58());
      if (account) {
        this.accountInfos[idx] = {
          publicKey,
          account,
        };
      }
    });
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (this.accountInfos.length !== this.getAccountsForUpdate().length) {
      throw new Error('Accounts not loaded');
    }

    const tradeDirection = this.swapState.poolCoinMint.equals(sourceMint) ? TradeDirection.AtoB : TradeDirection.BtoA;
    const { amm, pyth, pythPc, fees, coinBalance, pcBalance, config }: IAmmData = getParsedData(
      [{ publicKey: this.swapState.amm, account: this.ammAccountInfo }, ...this.accountInfos],
      this.poolInfo,
    );

    if (
      !pyth.status.equals(1) ||
      // pythPc can be undefined from the lifinity SDK
      (pythPc && !pythPc.status.equals(1))
    ) {
      throw new Error('Pyth accounts are outdated');
    }

    const amountIn = new Decimal(amount.toString());
    const result: ICurveAmount = getCurveAmount(
      amountIn,
      pyth.publishSlot.toNumber(), // Use pyth publish slot to not throw error
      amm,
      fees,
      coinBalance,
      pcBalance,
      config,
      pyth,
      pythPc,
      tradeDirection,
    );

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.BigInt(result.amountSwapped.toString()),
      feeAmount: JSBI.BigInt(result.fee.ceil().toString()),
      feeMint: sourceMint.toBase58(),
      feePct: result.feePercent.toNumber(),
      priceImpactPct: result.priceImpact.toNumber(),
    };
  }

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[] {
    return [
      createLifinitySwapInstruction({
        swapState: this.swapState,
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.swapState.poolCoinMint, this.swapState.poolPcMint];
  }
}
