import type { SVGProps } from 'react';

const SvgResend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={17}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 9.049c0 .783.144 1.515.432 2.196a5.753 5.753 0 0 0 1.206 1.79 5.59 5.59 0 0 0 1.783 1.2 5.571 5.571 0 0 0 2.19.431 5.57 5.57 0 0 0 3.967-1.631 5.612 5.612 0 0 0 1.207-1.79 5.587 5.587 0 0 0 .431-2.196.507.507 0 0 0-.526-.527.456.456 0 0 0-.362.152.534.534 0 0 0-.134.375c0 .639-.118 1.238-.355 1.796a4.679 4.679 0 0 1-.984 1.466 4.516 4.516 0 0 1-1.46.978 4.491 4.491 0 0 1-1.784.356 4.507 4.507 0 0 1-1.79-.356 4.516 4.516 0 0 1-1.46-.977 4.678 4.678 0 0 1-.984-1.467 4.551 4.551 0 0 1-.355-1.796c0-.635.118-1.23.355-1.784a4.602 4.602 0 0 1 2.444-2.444 4.506 4.506 0 0 1 1.79-.355c.242 0 .472.01.692.032.225.02.43.052.616.095L6.021 6.472a.559.559 0 0 0-.146.361c0 .144.049.265.146.362a.484.484 0 0 0 .355.146c.157 0 .28-.048.369-.146L9.36 4.567a.53.53 0 0 0 0-.761L6.745 1.152A.476.476 0 0 0 6.376 1a.481.481 0 0 0-.361.152.511.511 0 0 0-.14.369.6.6 0 0 0 .032.19c.025.063.061.12.108.171l1.688 1.67a4.772 4.772 0 0 0-.527-.076 5.5 5.5 0 0 0-2.755.406 5.588 5.588 0 0 0-1.783 1.2 5.656 5.656 0 0 0-1.206 1.79A5.508 5.508 0 0 0 1 9.049Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgResend;
