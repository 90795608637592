import { generateErrorMap } from "@saberhq/anchor-contrib";

export type ContinuationRouterIDL = {
  version: "1.1.2";
  name: "continuation_router";
  instructions: [
    {
      name: "createAtaIfNotExists";
      accounts: [
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "ata";
          isMut: true;
          isSigner: false;
        },
        {
          name: "authority";
          isMut: false;
          isSigner: false;
        },
        {
          name: "mint";
          isMut: false;
          isSigner: false;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "tokenProgram";
          isMut: false;
          isSigner: false;
        },
        {
          name: "associatedTokenProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "begin";
      accounts: [
        {
          name: "continuation";
          isMut: true;
          isSigner: false;
        },
        {
          name: "random";
          isMut: false;
          isSigner: false;
        },
        {
          name: "input";
          isMut: false;
          isSigner: false;
        },
        {
          name: "output";
          isMut: false;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: true;
        },
        {
          name: "rent";
          isMut: false;
          isSigner: false;
        },
        {
          name: "systemProgram";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [
        {
          name: "amountIn";
          type: "u64";
        },
        {
          name: "minimumAmountOut";
          type: "u64";
        },
        {
          name: "numSteps";
          type: "u16";
        }
      ];
    },
    {
      name: "beginV2";
      accounts: [
        {
          name: "continuation";
          isMut: true;
          isSigner: false;
        },
        {
          name: "input";
          isMut: false;
          isSigner: false;
        },
        {
          name: "output";
          isMut: false;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        }
      ];
      args: [
        {
          name: "amountIn";
          type: "u64";
        },
        {
          name: "minimumAmountOut";
          type: "u64";
        },
        {
          name: "numSteps";
          type: "u16";
        }
      ];
    },
    {
      name: "end";
      accounts: [
        {
          name: "continuation";
          isMut: true;
          isSigner: false;
        },
        {
          name: "output";
          isMut: false;
          isSigner: false;
        },
        {
          name: "owner";
          isMut: false;
          isSigner: true;
        },
        {
          name: "payer";
          isMut: true;
          isSigner: false;
        }
      ];
      args: [];
    },
    {
      name: "ssSwap";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "swap";
              accounts: [
                {
                  name: "swap";
                  isMut: false;
                  isSigner: false;
                },
                {
                  name: "swapAuthority";
                  isMut: false;
                  isSigner: false;
                },
                {
                  name: "clock";
                  isMut: false;
                  isSigner: false;
                }
              ];
            },
            {
              name: "input";
              accounts: [
                {
                  name: "user";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "reserve";
                  isMut: true;
                  isSigner: false;
                }
              ];
            },
            {
              name: "output";
              accounts: [
                {
                  name: "userToken";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "fees";
                  isMut: true;
                  isSigner: false;
                }
              ];
            }
          ];
        }
      ];
      args: [];
    },
    {
      name: "ssWithdrawOne";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "swap";
              accounts: [
                {
                  name: "swap";
                  isMut: false;
                  isSigner: false;
                },
                {
                  name: "swapAuthority";
                  isMut: false;
                  isSigner: false;
                },
                {
                  name: "clock";
                  isMut: false;
                  isSigner: false;
                }
              ];
            },
            {
              name: "poolMint";
              isMut: true;
              isSigner: false;
            },
            {
              name: "inputLp";
              isMut: true;
              isSigner: false;
            },
            {
              name: "quoteReserves";
              isMut: true;
              isSigner: false;
            },
            {
              name: "output";
              accounts: [
                {
                  name: "userToken";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "fees";
                  isMut: true;
                  isSigner: false;
                }
              ];
            }
          ];
        }
      ];
      args: [];
    },
    {
      name: "ssDepositA";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "inner";
              accounts: [
                {
                  name: "swap";
                  accounts: [
                    {
                      name: "swap";
                      isMut: false;
                      isSigner: false;
                    },
                    {
                      name: "swapAuthority";
                      isMut: false;
                      isSigner: false;
                    },
                    {
                      name: "clock";
                      isMut: false;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "inputA";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "inputB";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "poolMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "outputLp";
                  isMut: true;
                  isSigner: false;
                }
              ];
            }
          ];
        }
      ];
      args: [];
    },
    {
      name: "ssDepositB";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "inner";
              accounts: [
                {
                  name: "swap";
                  accounts: [
                    {
                      name: "swap";
                      isMut: false;
                      isSigner: false;
                    },
                    {
                      name: "swapAuthority";
                      isMut: false;
                      isSigner: false;
                    },
                    {
                      name: "clock";
                      isMut: false;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "inputA";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "inputB";
                  accounts: [
                    {
                      name: "user";
                      isMut: true;
                      isSigner: false;
                    },
                    {
                      name: "reserve";
                      isMut: true;
                      isSigner: false;
                    }
                  ];
                },
                {
                  name: "poolMint";
                  isMut: true;
                  isSigner: false;
                },
                {
                  name: "outputLp";
                  isMut: true;
                  isSigner: false;
                }
              ];
            }
          ];
        }
      ];
      args: [];
    },
    {
      name: "adWithdraw";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "input";
              isMut: false;
              isSigner: false;
            },
            {
              name: "output";
              isMut: false;
              isSigner: false;
            }
          ];
        }
      ];
      args: [];
    },
    {
      name: "adDeposit";
      accounts: [
        {
          name: "continuation";
          accounts: [
            {
              name: "continuation";
              isMut: true;
              isSigner: false;
            },
            {
              name: "tokenProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "swapProgram";
              isMut: false;
              isSigner: false;
            },
            {
              name: "owner";
              isMut: false;
              isSigner: true;
            }
          ];
        },
        {
          name: "action";
          accounts: [
            {
              name: "input";
              isMut: false;
              isSigner: false;
            },
            {
              name: "output";
              isMut: false;
              isSigner: false;
            }
          ];
        }
      ];
      args: [];
    }
  ];
  accounts: [
    {
      name: "Continuation";
      type: {
        kind: "struct";
        fields: [
          {
            name: "owner";
            type: "publicKey";
          },
          {
            name: "payer";
            type: "publicKey";
          },
          {
            name: "initialAmountIn";
            type: {
              defined: "TokenAmount";
            };
          },
          {
            name: "input";
            type: "publicKey";
          },
          {
            name: "amountIn";
            type: {
              defined: "TokenAmount";
            };
          },
          {
            name: "stepsLeft";
            type: "u16";
          },
          {
            name: "output";
            type: "publicKey";
          },
          {
            name: "outputInitialBalance";
            type: "u64";
          },
          {
            name: "minimumAmountOut";
            type: {
              defined: "TokenAmount";
            };
          },
          {
            name: "nonce";
            type: "u8";
          }
        ];
      };
    }
  ];
  types: [
    {
      name: "TokenAmount";
      type: {
        kind: "struct";
        fields: [
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "amount";
            type: "u64";
          }
        ];
      };
    },
    {
      name: "ActionType";
      type: {
        kind: "enum";
        variants: [
          {
            name: "SSSwap";
          },
          {
            name: "SSWithdrawOne";
          },
          {
            name: "SSDepositA";
          },
          {
            name: "SSDepositB";
          },
          {
            name: "ADWithdraw";
          },
          {
            name: "ADDeposit";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "SwapActionEvent";
      fields: [
        {
          name: "actionType";
          type: {
            defined: "ActionType";
          };
          index: false;
        },
        {
          name: "owner";
          type: "publicKey";
          index: false;
        },
        {
          name: "inputAmount";
          type: {
            defined: "TokenAmount";
          };
          index: false;
        },
        {
          name: "outputAccount";
          type: "publicKey";
          index: false;
        },
        {
          name: "outputAmount";
          type: {
            defined: "TokenAmount";
          };
          index: false;
        }
      ];
    },
    {
      name: "SwapCompleteEvent";
      fields: [
        {
          name: "owner";
          type: "publicKey";
          index: false;
        },
        {
          name: "amountIn";
          type: {
            defined: "TokenAmount";
          };
          index: false;
        },
        {
          name: "amountOut";
          type: {
            defined: "TokenAmount";
          };
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "PathInputOutputMismatch";
      msg: "Path input does not match prior output.";
    },
    {
      code: 6001;
      name: "TransitiveSwapCalculationError";
      msg: "Error in a transitive swap input/output calculation.";
    },
    {
      code: 6002;
      name: "OverflowSwapResult";
      msg: "Swap result overflowed when checking balance difference.";
    },
    {
      code: 6003;
      name: "BalanceLower";
      msg: "Swap resulted in a balance lower than the original balance.";
    },
    {
      code: 6004;
      name: "ZeroSwap";
      msg: "Cannot perform a zero swap.";
    },
    {
      code: 6005;
      name: "InputOwnerMismatch";
      msg: "Input owner does not match continuation owner.";
    },
    {
      code: 6006;
      name: "InputMintMismatch";
      msg: "Input mint does not match continuation input mint.";
    },
    {
      code: 6007;
      name: "OutputOwnerMismatch";
      msg: "Output owner does not match continuation owner.";
    },
    {
      code: 6008;
      name: "NoMoreSteps";
      msg: "No more steps to process.";
    },
    {
      code: 6009;
      name: "InsufficientInputBalance";
      msg: "Insufficient input balance";
    },
    {
      code: 6010;
      name: "EndIncomplete";
      msg: "Not all steps were processed.";
    },
    {
      code: 6011;
      name: "MinimumOutNotMet";
      msg: "Minimum amount out not met.";
    },
    {
      code: 6012;
      name: "OutputMintMismatch";
      msg: "Output mint does not match continuation output mint.";
    }
  ];
};
export const ContinuationRouterJSON: ContinuationRouterIDL = {
  version: "1.1.2",
  name: "continuation_router",
  instructions: [
    {
      name: "createAtaIfNotExists",
      accounts: [
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "ata",
          isMut: true,
          isSigner: false,
        },
        {
          name: "authority",
          isMut: false,
          isSigner: false,
        },
        {
          name: "mint",
          isMut: false,
          isSigner: false,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false,
        },
        {
          name: "associatedTokenProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "begin",
      accounts: [
        {
          name: "continuation",
          isMut: true,
          isSigner: false,
        },
        {
          name: "random",
          isMut: false,
          isSigner: false,
        },
        {
          name: "input",
          isMut: false,
          isSigner: false,
        },
        {
          name: "output",
          isMut: false,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true,
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false,
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: "amountIn",
          type: "u64",
        },
        {
          name: "minimumAmountOut",
          type: "u64",
        },
        {
          name: "numSteps",
          type: "u16",
        },
      ],
    },
    {
      name: "beginV2",
      accounts: [
        {
          name: "continuation",
          isMut: true,
          isSigner: false,
        },
        {
          name: "input",
          isMut: false,
          isSigner: false,
        },
        {
          name: "output",
          isMut: false,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
      ],
      args: [
        {
          name: "amountIn",
          type: "u64",
        },
        {
          name: "minimumAmountOut",
          type: "u64",
        },
        {
          name: "numSteps",
          type: "u16",
        },
      ],
    },
    {
      name: "end",
      accounts: [
        {
          name: "continuation",
          isMut: true,
          isSigner: false,
        },
        {
          name: "output",
          isMut: false,
          isSigner: false,
        },
        {
          name: "owner",
          isMut: false,
          isSigner: true,
        },
        {
          name: "payer",
          isMut: true,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: "ssSwap",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "swap",
              accounts: [
                {
                  name: "swap",
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: "swapAuthority",
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: "clock",
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: "input",
              accounts: [
                {
                  name: "user",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "reserve",
                  isMut: true,
                  isSigner: false,
                },
              ],
            },
            {
              name: "output",
              accounts: [
                {
                  name: "userToken",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "fees",
                  isMut: true,
                  isSigner: false,
                },
              ],
            },
          ],
        },
      ],
      args: [],
    },
    {
      name: "ssWithdrawOne",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "swap",
              accounts: [
                {
                  name: "swap",
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: "swapAuthority",
                  isMut: false,
                  isSigner: false,
                },
                {
                  name: "clock",
                  isMut: false,
                  isSigner: false,
                },
              ],
            },
            {
              name: "poolMint",
              isMut: true,
              isSigner: false,
            },
            {
              name: "inputLp",
              isMut: true,
              isSigner: false,
            },
            {
              name: "quoteReserves",
              isMut: true,
              isSigner: false,
            },
            {
              name: "output",
              accounts: [
                {
                  name: "userToken",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "fees",
                  isMut: true,
                  isSigner: false,
                },
              ],
            },
          ],
        },
      ],
      args: [],
    },
    {
      name: "ssDepositA",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "inner",
              accounts: [
                {
                  name: "swap",
                  accounts: [
                    {
                      name: "swap",
                      isMut: false,
                      isSigner: false,
                    },
                    {
                      name: "swapAuthority",
                      isMut: false,
                      isSigner: false,
                    },
                    {
                      name: "clock",
                      isMut: false,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "inputA",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "inputB",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "poolMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "outputLp",
                  isMut: true,
                  isSigner: false,
                },
              ],
            },
          ],
        },
      ],
      args: [],
    },
    {
      name: "ssDepositB",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "inner",
              accounts: [
                {
                  name: "swap",
                  accounts: [
                    {
                      name: "swap",
                      isMut: false,
                      isSigner: false,
                    },
                    {
                      name: "swapAuthority",
                      isMut: false,
                      isSigner: false,
                    },
                    {
                      name: "clock",
                      isMut: false,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "inputA",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "inputB",
                  accounts: [
                    {
                      name: "user",
                      isMut: true,
                      isSigner: false,
                    },
                    {
                      name: "reserve",
                      isMut: true,
                      isSigner: false,
                    },
                  ],
                },
                {
                  name: "poolMint",
                  isMut: true,
                  isSigner: false,
                },
                {
                  name: "outputLp",
                  isMut: true,
                  isSigner: false,
                },
              ],
            },
          ],
        },
      ],
      args: [],
    },
    {
      name: "adWithdraw",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "input",
              isMut: false,
              isSigner: false,
            },
            {
              name: "output",
              isMut: false,
              isSigner: false,
            },
          ],
        },
      ],
      args: [],
    },
    {
      name: "adDeposit",
      accounts: [
        {
          name: "continuation",
          accounts: [
            {
              name: "continuation",
              isMut: true,
              isSigner: false,
            },
            {
              name: "tokenProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "swapProgram",
              isMut: false,
              isSigner: false,
            },
            {
              name: "owner",
              isMut: false,
              isSigner: true,
            },
          ],
        },
        {
          name: "action",
          accounts: [
            {
              name: "input",
              isMut: false,
              isSigner: false,
            },
            {
              name: "output",
              isMut: false,
              isSigner: false,
            },
          ],
        },
      ],
      args: [],
    },
  ],
  accounts: [
    {
      name: "Continuation",
      type: {
        kind: "struct",
        fields: [
          {
            name: "owner",
            type: "publicKey",
          },
          {
            name: "payer",
            type: "publicKey",
          },
          {
            name: "initialAmountIn",
            type: {
              defined: "TokenAmount",
            },
          },
          {
            name: "input",
            type: "publicKey",
          },
          {
            name: "amountIn",
            type: {
              defined: "TokenAmount",
            },
          },
          {
            name: "stepsLeft",
            type: "u16",
          },
          {
            name: "output",
            type: "publicKey",
          },
          {
            name: "outputInitialBalance",
            type: "u64",
          },
          {
            name: "minimumAmountOut",
            type: {
              defined: "TokenAmount",
            },
          },
          {
            name: "nonce",
            type: "u8",
          },
        ],
      },
    },
  ],
  types: [
    {
      name: "TokenAmount",
      type: {
        kind: "struct",
        fields: [
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "amount",
            type: "u64",
          },
        ],
      },
    },
    {
      name: "ActionType",
      type: {
        kind: "enum",
        variants: [
          {
            name: "SSSwap",
          },
          {
            name: "SSWithdrawOne",
          },
          {
            name: "SSDepositA",
          },
          {
            name: "SSDepositB",
          },
          {
            name: "ADWithdraw",
          },
          {
            name: "ADDeposit",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "SwapActionEvent",
      fields: [
        {
          name: "actionType",
          type: {
            defined: "ActionType",
          },
          index: false,
        },
        {
          name: "owner",
          type: "publicKey",
          index: false,
        },
        {
          name: "inputAmount",
          type: {
            defined: "TokenAmount",
          },
          index: false,
        },
        {
          name: "outputAccount",
          type: "publicKey",
          index: false,
        },
        {
          name: "outputAmount",
          type: {
            defined: "TokenAmount",
          },
          index: false,
        },
      ],
    },
    {
      name: "SwapCompleteEvent",
      fields: [
        {
          name: "owner",
          type: "publicKey",
          index: false,
        },
        {
          name: "amountIn",
          type: {
            defined: "TokenAmount",
          },
          index: false,
        },
        {
          name: "amountOut",
          type: {
            defined: "TokenAmount",
          },
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "PathInputOutputMismatch",
      msg: "Path input does not match prior output.",
    },
    {
      code: 6001,
      name: "TransitiveSwapCalculationError",
      msg: "Error in a transitive swap input/output calculation.",
    },
    {
      code: 6002,
      name: "OverflowSwapResult",
      msg: "Swap result overflowed when checking balance difference.",
    },
    {
      code: 6003,
      name: "BalanceLower",
      msg: "Swap resulted in a balance lower than the original balance.",
    },
    {
      code: 6004,
      name: "ZeroSwap",
      msg: "Cannot perform a zero swap.",
    },
    {
      code: 6005,
      name: "InputOwnerMismatch",
      msg: "Input owner does not match continuation owner.",
    },
    {
      code: 6006,
      name: "InputMintMismatch",
      msg: "Input mint does not match continuation input mint.",
    },
    {
      code: 6007,
      name: "OutputOwnerMismatch",
      msg: "Output owner does not match continuation owner.",
    },
    {
      code: 6008,
      name: "NoMoreSteps",
      msg: "No more steps to process.",
    },
    {
      code: 6009,
      name: "InsufficientInputBalance",
      msg: "Insufficient input balance",
    },
    {
      code: 6010,
      name: "EndIncomplete",
      msg: "Not all steps were processed.",
    },
    {
      code: 6011,
      name: "MinimumOutNotMet",
      msg: "Minimum amount out not met.",
    },
    {
      code: 6012,
      name: "OutputMintMismatch",
      msg: "Output mint does not match continuation output mint.",
    },
  ],
};
export const ContinuationRouterErrors = generateErrorMap(
  ContinuationRouterJSON
);
