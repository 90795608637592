import {
  Connection,
  PublicKey,
  Transaction,
  Signer,
  TransactionInstruction,
  BlockhashWithExpiryBlockHeight,
  TransactionBlockhashCtor,
} from '@solana/web3.js';
import { Instruction, TransactionPayload } from './instruction';
import { Owner } from './Owner';

export class TransactionBuilder {
  private connection: Connection;
  private feePayer: PublicKey;
  private instructions: Instruction[];
  private owner: Owner;

  constructor(connection: Connection, feePayer: PublicKey, owner: Owner) {
    this.connection = connection;
    this.feePayer = feePayer;
    this.instructions = [];
    this.owner = owner;
  }

  addInstruction(instruction: Instruction): TransactionBuilder {
    this.instructions.push(instruction);
    return this;
  }

  async build(blockhashWithExpiryBlockHeight?: BlockhashWithExpiryBlockHeight): Promise<TransactionPayload> {
    if (!blockhashWithExpiryBlockHeight) {
      blockhashWithExpiryBlockHeight = await this.connection.getLatestBlockhash('confirmed');
    }

    const txFields: TransactionBlockhashCtor = {
      ...blockhashWithExpiryBlockHeight,
      feePayer: this.feePayer,
    };

    let instructions: TransactionInstruction[] = [];
    let cleanupInstructions: TransactionInstruction[] = [];
    let signers: Signer[] = [];
    this.instructions.forEach((curr) => {
      instructions = instructions.concat(curr.instructions);
      cleanupInstructions = cleanupInstructions.concat(curr.cleanupInstructions);
      signers = signers.concat(curr.signers);
    });

    const transaction = new Transaction(txFields);
    instructions.concat(cleanupInstructions).forEach((ix) => transaction.add(ix));
    transaction.feePayer = this.feePayer;

    return {
      transaction: transaction,
      signers: signers,
      execute: this.owner.isKeyPair
        ? () => {
            return this.connection.sendTransaction(transaction, signers);
          }
        : async () => {
            throw new Error('Please use a Keypair for the owner parameter to enable the execute function');
          },
    };
  }
}
