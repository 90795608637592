import { Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { isPlatformFeeSupported, RouteInfo } from './routes';
import { getEmptyInstruction, Instruction } from '../utils/instruction';
import { findOrCreateAssociatedAccountByMint } from '../utils/token';
import { createSetTokenLedgerInstruction } from './jupiterInstruction';
import { Owner } from '../utils/Owner';
import { PlatformFee } from './types';
import { QuoteMintToReferrer } from '..';
import { isSerumAndRaydium } from './market';
import { ZERO } from '@jup-ag/math';
import { BN } from 'bn.js';

type RouteToInstructionsParams = {
  user: Owner;
  tokenLedger: PublicKey;
  openOrdersAddresses: (PublicKey | undefined)[];
  userSourceTokenAccountAddress: PublicKey;
  userIntermediaryTokenAccountAddress: PublicKey | undefined;
  userDestinationTokenAccountAddress: PublicKey;
  routeInfo: RouteInfo;
  platformFee: PlatformFee | undefined;
  quoteMintToReferrer: QuoteMintToReferrer;
};

async function routeToInstructions({
  user,
  tokenLedger,
  openOrdersAddresses,
  userSourceTokenAccountAddress,
  userIntermediaryTokenAccountAddress,
  userDestinationTokenAccountAddress,
  routeInfo,
  platformFee,
  quoteMintToReferrer,
}: RouteToInstructionsParams): Promise<Instruction> {
  const otherAmountThreshold = routeInfo.otherAmountThreshold;
  const amount = routeInfo.amount;

  const legs = routeInfo.marketInfos.length;
  if (legs === 2 && !userIntermediaryTokenAccountAddress) {
    throw new Error('Missing intermediary token account');
  }

  // Drop referrer if space is scarce
  const effectiveQuoteMintToReferrer =
    platformFee && isSerumAndRaydium(routeInfo.marketInfos) ? undefined : quoteMintToReferrer;

  const userIntermediateTokenAccountAddresses = userIntermediaryTokenAccountAddress
    ? [userIntermediaryTokenAccountAddress]
    : [];
  const userTokenAccountAddresses = [
    userSourceTokenAccountAddress,
    ...userIntermediateTokenAccountAddresses,
    userDestinationTokenAccountAddress,
  ];

  const platformFeeSupported = isPlatformFeeSupported(
    routeInfo.swapMode,
    routeInfo.marketInfos.map((mi) => mi.amm),
  );

  const instructions: TransactionInstruction[] = [
    createSetTokenLedgerInstruction(tokenLedger, userTokenAccountAddresses[1]),
  ];

  for (const [index, marketInfo] of routeInfo.marketInfos.entries()) {
    const amm = marketInfo.amm;
    const legAmount = index === 0 ? new BN(amount.toString()) : null;
    const isLastLeg = index === legs - 1;
    const legOtherAmountThreshold = new BN((isLastLeg ? otherAmountThreshold : ZERO).toString());
    const legPlatformFee = isLastLeg && platformFeeSupported ? platformFee : undefined;

    const [userSourceTokenAccount, userDestinationTokenAccount] = userTokenAccountAddresses.slice(index);

    instructions.push(
      ...amm.createSwapInstructions({
        sourceMint: marketInfo.inputMint,
        destinationMint: marketInfo.outputMint,
        userSourceTokenAccount,
        userDestinationTokenAccount,
        userTransferAuthority: user.publicKey,
        inAmount: marketInfo.inAmount,
        amount: legAmount,
        otherAmountThreshold: legOtherAmountThreshold,
        swapMode: routeInfo.swapMode,
        tokenLedger,
        openOrdersAddress: openOrdersAddresses[index],
        platformFee: legPlatformFee,
        quoteMintToReferrer: effectiveQuoteMintToReferrer,
      }),
    );
  }

  const { signers, cleanupInstructions } = getEmptyInstruction();

  if (user.isKeyPair && user.signer) {
    signers.push(user.signer);
  }
  return {
    signers,
    cleanupInstructions,
    instructions,
  };
}

export const routeAtaInstructions = async ({
  connection,
  marketInfos,
  owner,
  unwrapSOL,
}: {
  connection: Connection;
  marketInfos: RouteInfo['marketInfos'];
  owner: Owner;
  unwrapSOL: boolean;
}) => {
  const getUserIntermediateTokenAccountAddress = async () => {
    const userIntermediateTokenAccountAddress =
      marketInfos.length === 2
        ? await findOrCreateAssociatedAccountByMint({
            connection,
            owner: owner,
            payer: owner.publicKey,
            mintAddress: marketInfos[0].outputMint,
            unwrapSOL,
          })
        : undefined;
    return userIntermediateTokenAccountAddress;
  };

  const getUserDestinationTokenAccountAddress = () => {
    return findOrCreateAssociatedAccountByMint({
      connection,
      owner: owner,
      payer: owner.publicKey,
      mintAddress: marketInfos.length === 2 ? marketInfos[1].outputMint : marketInfos[0].outputMint,
      unwrapSOL,
    });
  };

  const [userIntermediaryTokenAccountResult, userDestinationTokenAccountResult] = await Promise.all([
    getUserIntermediateTokenAccountAddress(),
    getUserDestinationTokenAccountAddress(),
  ]);

  return {
    userIntermediaryTokenAccountResult,
    userDestinationTokenAccountResult,
  };
};

export default routeToInstructions;
