import { ZERO } from '@jup-ag/math';
import { Market, Orderbook } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import BN from 'bn.js';
import Decimal from 'decimal.js';
import JSBI from 'jsbi';

const TAKER_FEE_PCT = 0.0004;
const STABLE_TAKER_FEE_PCT = 0.0001;

// Stable markets are hardcoded in the program
const STABLE_MARKET_ADDRESSES = [
  '77quYg4MGneUdjgXCunt9GgM1usmrxKY31twEy3WHwcS', // USDT/USDC
  '5cLrMai1DsLRYc1Nio9qMTicsWtvzjzZfJPXyAoF4t1Z', // mSOL/SOL
  'EERNEEnBqdGzBS8dd46wwNY5F2kwnaCQ3vsq2fNKGogZ', // UST/USDC
  '8sFf9TW3KzxLiBXcDcjAxqabEsRroo4EiRr3UG1xbJ9m', // UST/USDT
  '2iDSTGhjJEiRxNaLF27CY6daMYPs5hgYrP2REHd5YD62', // stSOL/SOL
];

interface IMarketMeta {
  /** buy or sell side */
  side: 'buy' | 'sell';
  /** indicate that your order is too huge for the market */
  notEnoughLiquidity: boolean;
  /** minimum in amount and the corresponding out amount */
  minimum: {
    in: JSBI;
    out: JSBI;
  };
  /** amount in taken for the trade */
  inAmount: JSBI;
  /** the amount out for the trade */
  outAmount: JSBI;
  /** the total fee amount */
  feeAmount: JSBI;
  /** price impact percentage */
  priceImpactPct: number;
  /** fee percentage */
  feePct: number;
}

// Provides swap like out amount, with slippage and corresponding minimum amount out
export function getOutAmountMeta({
  market,
  asks,
  bids,
  fromAmount,
  fromMint,
  toMint,
}: {
  market: Market;
  asks: Orderbook;
  bids: Orderbook;
  fromMint: PublicKey;
  toMint: PublicKey;
  fromAmount: JSBI;
}) {
  const takerFeePct = STABLE_MARKET_ADDRESSES.includes(market.address.toBase58())
    ? STABLE_TAKER_FEE_PCT
    : TAKER_FEE_PCT;

  if (fromMint.equals(market.quoteMintAddress) && toMint.equals(market.baseMintAddress)) {
    // buy
    return forecastBuy(market, asks, fromAmount, takerFeePct);
  } else {
    return forecastSell(market, bids, fromAmount, takerFeePct);
  }
}

export function forecastBuy(market: Market, orderbook: Orderbook, pcIn: JSBI, takerFeePct: number): IMarketMeta {
  let coinOut = ZERO;
  let bestPrice: JSBI = ZERO;
  let worstPrice: JSBI = ZERO;
  // total base price
  let totalCost = ZERO;
  let totalCoins = ZERO;

  // might be decimal, e.g: 0.001
  const quoteSizeLots = market.quoteSizeLotsToNumber(new BN(1));

  // Serum buy order take fee in quote tokens
  let availablePc = quoteSizeLots
    ? JSBI.BigInt(
        new Decimal(pcIn.toString())
          .div(1 + takerFeePct)
          .div(quoteSizeLots)
          .floor(),
      )
    : ZERO;
  const baseSizeLots = JSBI.BigInt(market.baseSizeLotsToNumber(new BN(1)).toString());

  for (let [lotPrice, lotQuantity] of getL2(orderbook)) {
    if (JSBI.equal(bestPrice, ZERO)) {
      bestPrice = lotPrice;
    }

    worstPrice = lotPrice;

    const orderCoinAmount = JSBI.multiply(lotQuantity, baseSizeLots);
    const orderPcAmount = JSBI.multiply(lotQuantity, lotPrice);

    totalCoins = JSBI.add(totalCoins, orderCoinAmount);

    if (JSBI.greaterThanOrEqual(orderPcAmount, availablePc)) {
      const numberLotsPurchasable = JSBI.divide(availablePc, lotPrice);

      totalCost = JSBI.add(totalCost, JSBI.multiply(lotPrice, numberLotsPurchasable));
      coinOut = JSBI.add(coinOut, JSBI.multiply(baseSizeLots, numberLotsPurchasable));
      availablePc = ZERO;
      break;
    } else {
      totalCost = JSBI.add(totalCost, JSBI.multiply(lotPrice, lotQuantity));
      coinOut = JSBI.add(coinOut, orderCoinAmount);
      availablePc = JSBI.subtract(availablePc, orderPcAmount);
    }
  }

  const bestPriceDecimal = new Decimal(bestPrice.toString());
  const worstPriceDecimal = new Decimal(worstPrice.toString());

  const priceImpactPct = worstPriceDecimal.sub(bestPriceDecimal).div(bestPriceDecimal).toNumber();

  const bestPriceSizeLots = priceLotsToDecimal(market, new BN(bestPrice.toString()));
  const totalCostSizeLots = priceLotsToDecimal(market, new BN(totalCost.toString()));
  const inAmountWithoutFee = totalCostSizeLots.mul(baseSizeLots.toString()).ceil();
  const fee = totalCostSizeLots.mul(baseSizeLots.toString()).mul(takerFeePct).ceil();

  return {
    side: 'buy',
    notEnoughLiquidity: JSBI.lessThanOrEqual(totalCoins, coinOut),
    minimum: {
      in: JSBI.BigInt(
        bestPriceSizeLots
          .mul(baseSizeLots.toString())
          .mul(1 + takerFeePct)
          .ceil(),
      ),
      out: baseSizeLots,
    },
    inAmount: JSBI.BigInt(inAmountWithoutFee.add(fee)),
    outAmount: coinOut,
    feeAmount: JSBI.BigInt(fee),
    priceImpactPct,
    feePct: takerFeePct,
  };
}

export function forecastSell(market: Market, orderbook: Orderbook, coinIn: JSBI, takerFeePct: number): IMarketMeta {
  let pcOut = JSBI.BigInt(0);
  let bestPrice = JSBI.BigInt(0);
  let worstPrice = JSBI.BigInt(0);
  let totalCoin = JSBI.BigInt(0);
  let availableCoin = coinIn;
  let inAmount = JSBI.BigInt(0);

  const baseSizeLots = JSBI.BigInt(market.baseSizeLotsToNumber(new BN(1)));
  const quoteSizeLots = JSBI.BigInt(market.quoteSizeLotsToNumber(new BN(1)));

  for (const [lotPrice, lotQuantity] of getL2(orderbook)) {
    if (JSBI.equal(bestPrice, ZERO)) {
      bestPrice = lotPrice;
    }

    worstPrice = lotPrice;

    const orderCoinAmount = JSBI.multiply(baseSizeLots, lotQuantity);
    const orderPcAmount = JSBI.multiply(lotQuantity, JSBI.multiply(lotPrice, quoteSizeLots));
    totalCoin = JSBI.add(totalCoin, orderCoinAmount);

    if (JSBI.greaterThanOrEqual(orderCoinAmount, availableCoin)) {
      const numberLotsCanSell = JSBI.divide(availableCoin, baseSizeLots);
      const totalCoinAmountToSell = JSBI.multiply(numberLotsCanSell, lotPrice);
      pcOut = JSBI.add(pcOut, JSBI.multiply(totalCoinAmountToSell, quoteSizeLots));
      availableCoin = JSBI.subtract(availableCoin, totalCoinAmountToSell);
      inAmount = JSBI.add(inAmount, JSBI.multiply(numberLotsCanSell, baseSizeLots));
      break;
    } else {
      pcOut = JSBI.add(pcOut, orderPcAmount);
      availableCoin = JSBI.subtract(availableCoin, orderCoinAmount);
      inAmount = JSBI.add(inAmount, orderCoinAmount);
    }
  }

  let pcOutAfterFee = new Decimal(pcOut.toString()).mul(1 - takerFeePct).floor();

  const bestPriceDecimal = priceLotsToDecimal(market, new BN(bestPrice.toString()));
  const worstPriceDecimal = priceLotsToDecimal(market, new BN(worstPrice.toString()));

  const priceImpactPct = bestPriceDecimal.minus(worstPriceDecimal).div(bestPriceDecimal).toNumber();

  return {
    side: 'sell',
    notEnoughLiquidity: JSBI.greaterThan(JSBI.BigInt(coinIn), totalCoin),
    minimum: {
      in: baseSizeLots,
      out: JSBI.BigInt(
        bestPriceDecimal
          .mul(JSBI.toNumber(baseSizeLots))
          .mul(1 - takerFeePct)
          .floor()
          .toString(),
      ),
    },
    inAmount: inAmount,
    outAmount: JSBI.BigInt(pcOutAfterFee),
    feeAmount: JSBI.BigInt(new Decimal(pcOut.toString()).mul(takerFeePct).round()),
    priceImpactPct,
    feePct: takerFeePct,
  };
}

export function* getL2(orderbook: Orderbook): Generator<[JSBI, JSBI]> {
  const descending = orderbook.isBids;
  for (const { key, quantity } of orderbook.slab.items(descending)) {
    const price = JSBI.BigInt(key.ushrn(64).toString());
    yield [price, JSBI.BigInt(quantity.toString())];
  }
}

function divideBnToDecimal(numerator: BN, denominator: BN): Decimal {
  const quotient = new Decimal(numerator.div(denominator).toString());
  const rem = numerator.umod(denominator);
  const gcd = rem.gcd(denominator);
  return quotient.add(new Decimal(rem.div(gcd).toString()).div(new Decimal(denominator.div(gcd).toString())));
}

function priceLotsToDecimal(market: Market, price: BN) {
  // @ts-expect-error _decoded
  const baseLotSize = market._decoded.baseLotSize;
  if (baseLotSize.isZero()) return new Decimal(0);

  return divideBnToDecimal(
    // @ts-expect-error _decoded _baseSplTokenMultiplier is private
    price.mul(market._decoded.quoteLotSize).mul(market._baseSplTokenMultiplier),
    // @ts-expect-error _quoteSplTokenMultiplier is private
    baseLotSize.mul(market._quoteSplTokenMultiplier),
  );
}
