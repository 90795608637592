import { Orderbook } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import { AccountInfoMap, Amm, mapAddressToAccountInfos, Quote, QuoteParams, SwapParams } from '../amm';
import { createSerumSwapInstruction } from '../jupiterInstruction';
import { SerumMarket } from '../market';
import { getL2, getOutAmountMeta } from './market';

export class SerumAmm implements Amm {
  id: string;
  label = 'Serum' as const;
  shouldPrefetch = false;
  exactOutputSupported = false;

  private _orderbooks: { asks: Orderbook; bids: Orderbook } | undefined;

  constructor(public market: SerumMarket) {
    this.id = market.address.toBase58();
  }

  get orderbooks() {
    return this._orderbooks;
  }

  static getL2 = getL2;

  getAccountsForUpdate(): PublicKey[] {
    return [this.market.asksAddress, this.market.bidsAddress];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const [asksAccountInfo, bidsAccountInfo] = mapAddressToAccountInfos(accountInfoMap, this.getAccountsForUpdate());

    const asks = Orderbook.decode(this.market, asksAccountInfo.data);
    const bids = Orderbook.decode(this.market, bidsAccountInfo.data);

    this._orderbooks = {
      asks,
      bids,
    };
  }

  getQuote({ sourceMint, destinationMint, amount }: QuoteParams): Quote {
    if (!this.orderbooks) {
      throw new Error('Failed to find orderbooks');
    }

    const outAmountMeta = getOutAmountMeta({
      market: this.market,
      asks: this.orderbooks.asks,
      bids: this.orderbooks.bids,
      fromMint: sourceMint,
      toMint: destinationMint,
      fromAmount: amount,
    });

    return {
      notEnoughLiquidity: outAmountMeta.notEnoughLiquidity,
      minInAmount: outAmountMeta.minimum.in,
      minOutAmount: outAmountMeta.minimum.out,
      inAmount: outAmountMeta.inAmount,
      outAmount: outAmountMeta.outAmount,
      feeAmount: outAmountMeta.feeAmount,
      feeMint: this.market.quoteMintAddress.toBase58(),
      feePct: outAmountMeta.feePct,
      priceImpactPct: outAmountMeta.priceImpactPct,
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    if (!swapParams.openOrdersAddress) {
      throw new Error('Missing open orders');
    }

    return [
      createSerumSwapInstruction({
        market: this.market,
        openOrdersAddress: swapParams.openOrdersAddress,
        referrer: swapParams?.quoteMintToReferrer?.get(this.market.quoteMintAddress.toBase58()),
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.market.baseMintAddress, this.market.quoteMintAddress];
  }
}
