import { AccountInfo, PublicKey } from '@solana/web3.js';
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { LIFINITY_AMM_LAYOUT, IPoolInfo } from '@jup-ag/lifinity-sdk';

export interface LifinitySwapLayoutState {
  programId: PublicKey;
  authority: PublicKey;
  amm: PublicKey;
  tokenAMint: PublicKey;
  tokenBMint: PublicKey;
  poolMint: PublicKey;
  feeAccount: PublicKey;
  pythAccount: PublicKey;
  pythPcAccount: PublicKey;
  configAccount: PublicKey;
  poolCoinTokenAccount: PublicKey;
  poolCoinMint: PublicKey;
  poolPcTokenAccount: PublicKey;
  poolPcMint: PublicKey;
}

export const swapStateToPoolInfo = (state: LifinitySwapLayoutState): IPoolInfo => {
  return {
    amm: state.amm.toBase58(),
    configAccount: state.configAccount.toBase58(),
    feeAccount: state.feeAccount.toBase58(),
    pythAccount: state.pythAccount.toBase58(),
    pythPcAccount: state.pythPcAccount.toBase58(),
    poolCoinMint: state.poolCoinMint.toBase58(),
    poolCoinTokenAccount: state.poolCoinTokenAccount.toBase58(),
    poolMint: state.poolMint.toBase58(),
    poolPcTokenAccount: state.poolPcTokenAccount.toBase58(),
    poolPcMint: state.poolPcMint.toBase58(),
    // We don't use decimals at the moment, so default to 0, if we need to use it later, we can add it from API
    poolCoinDecimal: 0,
    poolPcDecimal: 0,
    poolMintDecimal: 0,
    pythBaseDecimal: 0,
  };
};

export const accountInfoLifinitySwapLayout = (
  address: PublicKey,
  accountInfo: AccountInfo<Buffer>,
): LifinitySwapLayoutState => {
  const programId = accountInfo.owner;
  const decoded = LIFINITY_AMM_LAYOUT.decode(accountInfo.data);
  const [authority] = findProgramAddressSync([address.toBuffer()], programId);

  return {
    programId,
    authority,
    amm: address,
    tokenAMint: decoded.tokenAMint,
    tokenBMint: decoded.tokenBMint,
    poolMint: decoded.poolMint,
    feeAccount: decoded.poolFeeAccount,
    pythAccount: decoded.pythAccount,
    pythPcAccount: decoded.pythPcAccount,
    configAccount: decoded.configAccount,
    poolCoinTokenAccount: decoded.tokenAAccount,
    poolCoinMint: decoded.tokenAMint,
    poolPcTokenAccount: decoded.tokenBAccount,
    poolPcMint: decoded.tokenBMint,
  };
};
