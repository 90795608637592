export const MAINNET_OFFICIAL_LIQUIDITY_POOLS = [
  /* ================= v4 ================= */
  // FIDA-RAY
  "2dRNngAm729NzLbb1pzgHtfHvPqR4XHFmFyYK78EfEeX",
  // OXY-RAY
  "B5ZguAWAGC3GXVtJZVfoMtzvEvDnDKBPCevsUKMy4DTZ",
  // MAPS-RAY
  "5VyLSjUvaRxsubirbvbfJMbrKZRx1b7JZzuCAfyqgimf",
  // KIN-RAY
  "6kmMMacvoCKBkBrqssLEdFuEZu2wqtLdNQxh9VjtzfwT",
  // RAY-USDT
  "DVa7Qmb5ct9RCpaU7UTpSaf3GVMYz17vNVU67XpdCRut",
  // SOL-USDC
  "58oQChx4yWmvKdwLLZzBi4ChoCc2fqCUWBkwMihLYQo2",
  // YFI-USDC
  "83xxjVczDseaCzd7D61BRo7LcP7cMXut5n7thhB4rL4d",
  // SRM-USDC
  "8tzS7SkUZyHPQY7gLqsMCXZ5EDCgjESUHcB17tiR1h3Z",
  // FTT-USDC
  "4C2Mz1bVqe42QDDTyJ4HFCFFGsH5YDzo91Cen5w5NGun",
  // BTC-USDC
  "6kbC5epG18DF2DwPEW34tBy5pGFS7pEGALR3v5MGxgc5",
  // SUSHI-USDC
  "5dHEPTgvscKkAc54R77xUeGdgShdG9Mf6gJ9bwBqyb3V",
  // TOMO-USDC
  "8mBJC9qdPNDyrpAbrdwGbBpEAjPqwtvZQVmbnKFXXY2P",
  // LINK-USDC
  "Hr8i6MAm4W5Lwb2fB2CD44A2t3Ag3gGc1rmd6amrWsWC",
  // ETH-USDC
  "AoPebtuJC4f2RweZSxcVCcdeTgaEXY64Uho8b5HdPxAR",
  // xCOPE-USDC
  "3mYsmBQLB8EZSjRwtWjPbbE8LiM1oCCtNZZKiVBKsePa",
  // SOL-USDT
  "7XawhbbxtsRcQA8KTkHT9f9nc6d69UwqCDh6U5EEbEmX",
  // YFI-USDT
  "81PmLJ8j2P8CC5EJAAhWGYA4HgJvoKs4Y94ALZF2uKKG",
  // SRM-USDT
  "af8HJg2ffWoKJ6vKvkWJUJ9iWbRR83WgXs8HPs26WGr",
  // FTT-USDT
  "4fgubfZVL6L8tc5x1j65S14P2Tnxr1YayKtKavQV5MBo",
  // BTC-USDT
  "AMMwkf57c7ZsbbDCXvBit9zFehMr1xRn8ZzaT1iDF18o",
  // SUSHI-USDT
  "DWvhPYVogsEKEsehHApUtjhP1UFtApkAPFJqFh2HPmWz",
  // TOMO-USDT
  "GjrXcSvwzGrz1RwKYGVWdbZyXzyotgichSHB95moDmf8",
  // LINK-USDT
  "E9EvurfzdSQaqCFBUaD4MgV93htuRQ93sghm922Pik88",
  // ETH-USDT
  "He3iAEV5rYjv6Xf7PxKro19eVrC3QAcdic5CF2D2obPt",
  // YFI-SRM
  "GDVhJmDTdSExwHeMT5RvUBUNKLwwXNKhH8ndm1tpTv6B",
  // FTT-SRM
  "21r2zeCacmm5YvbGoPZh9ZoGREuodhcbQHaP5tZmzY14",
  // BTC-SRM
  "DvxLb4NnQUYq1gErk35HVt9g8kxjNbviJfiZX1wqraMv",
  // SUSHI-SRM
  "BLVjPTgzyfiKSgDujTNKKNzW2GXx7HhdMxgr2LQ2g83s",
  // TOMO-SRM
  "DkMAuUCQHC6BNgVnjtM5ZTKm1T8MsriQ6bL3Umi6NBtG",
  // LINK-SRM
  "796pvggjoDCPUtUSVFSCLqPRyes5YPvRiu4zFWX582wf",
  // ETH-SRM
  "3XwxHcbyqcd1xkdczaPv3TNCZsevELD4Zux3pu4sF2D8",
  // SRM-SOL
  "EvWJC2mnmu9C9aQrsJLXw8FhUcwBzFEUQsP1E5Y6a5N7",
  // STEP-USDC
  "4Sx1NLrQiK4b9FdLKe2DhQ9FHvRzJhzKN3LoD6BrEPnf",
  // MEDIA-USDC
  "94CQopiGxxUXf2avyMZhAFaBdNatd62ttYGoTVQBRGdi",
  // ROPE-USDC
  "BuS4ScFcZjEBixF1ceCTiXs4rqt4WDfXLoth7VcM2Eoj",
  // MER-USDC
  "BkfGDk676QFtTiGxn7TtEpHayJZRr6LgNk9uTV2MH4bR",
  // COPE-USDC
  "DiWxV1SPXPNJRCt5Ao1mJRAxjw97hJVyj8qGzZwFbAFb",
  // ALEPH-USDC
  "GDHXjn9wF2zxW35DBkCegWQdoTfFBC9LXt7D5ovJxQ5B",
  // TULIP-USDC
  "96hPvuJ3SRT82m7BAc7G1AUVPVcoj8DABAa5gT7wjgzX",
  // WOO-USDC
  "DSkXJYPZqJ3yHQECyVyh3xiE3HBrt7ARmepwNDA9rREn",
  // SNY-USDC
  "5TgJXpv6H3KJhHCuP7KoDLSCmi8sM8nABizP7CmYAKm1",
  // BOP-RAY
  "SJmR8rJgzzCi4sPjGnrNsqY4akQb3jn5nsxZBhyEifC",
  // SLRS-USDC
  "7XXKU8oGDbeGrkPyK5yHKzdsrMJtB7J2TMugjbrXEhB5",
  // SAMO-RAY
  "EyDgEU9BdG7m6ZK4bYERxbN4NCJ129WzPtv23dBkfsLg",
  // renBTC-USDC
  "61JtCkTQKSeBU8ztEScByZiBhS6KAHSXfQduVyA4s1h7",
  // renDOGE-USDC
  "34oD4akb2DeNcCw1smKHPsD3iqQQQWmNy3cY81nz7HP8",
  // RAY-USDC
  "6UmmUiYoBjSrhakAobJw8BvkmJtDVxaeBtbt7rxWo1mg",
  // RAY-SRM
  "GaqgfieVmnmY4ZsZHHA6L5RSVzCGL3sKx4UgHBaYNy8m",
  // RAY-ETH
  "8iQFhWyceGREsWnLM8NkG9GC8DvZunGZyMzuyUScgkMK",
  // RAY-SOL
  "AVs9TA4nWDzfPJE9gGVNJMVhcQy3V9PGazuz33BfG2RA",
  // DXL-USDC
  "asdEJnE7osjgnSyQkSZJ3e5YezbmXuDQPiyeyiBxoUm",
  // LIKE-USDC
  "GmaDNMWsTYWjaXVBjJTHNmCWAKU6cn5hhtWWYEZt4odo",
  // mSOL-USDC
  "ZfvDXXUhZDzDVsapffUyXHj9ByCoPjP4thL6YXcZ9ix",
  // mSOL-SOL
  "EGyhb2uLAsRUbRx9dNFBjMVYnFaASWMvD6RE1aEf2LxL",
  // MER-PAI
  "6GUF8Qb5FWmifzYpRdKomFNbSQAsLShhT45GbTGg34VJ",
  // PORT-USDC
  "6nJes56KF999Q8VtQTrgWEHJGAfGMuJktGb8x2uWff2u",
  // MNGO-USDC
  "34tFULRrRwh4bMcBLPtJaNqqe5pVgGZACi5sR8Xz95KC",
  // ATLAS-USDC
  "2bnZ1edbvK3CK3LTNZ5jH9anvXYCmzPR4W2HQ6Ngsv5K",
  // POLIS-USDC
  "9xyCzsHi1wUWva7t5Z8eAvZDRmUCVhRrbaFfm3VbU4Mf",
  // ATLAS-RAY
  "F73euqPynBwrgcZn3fNSEneSnYasDQohPM5aZazW9hp2",
  // POLIS-RAY
  "5tho4By9RsqTF1rbm9Akiepik3kZBT7ffUzGg8bL1mD",
  // ALEPH-RAY
  "8Fr3wxZXLtiSozqms5nF4XXGHNSNqcMC6K6MvRqEfk4a",
  // TULIP-RAY
  "Dm1Q15216uRARmQTbo6VfnyEGVzRvLTm4TfCWWX4MF3F",
  // SLRS-RAY
  "J3CoGcJqHquUdSgS7qAwdGbp3so4EpLX8eVDdGuauvi",
  // MER-RAY
  "BKLCqnuk4qc5iHWuJuewMxuvsNZXuTBSUyRT5ftnRb6H",
  // MEDIA-RAY
  "5ZPBHzMr19iQjBaDgFDYGAx2bxaQ3TzWmSS7zAGrHtQJ",
  // SNY-RAY
  "Am9FpX73ctZ3HzohcRdyCCv84iT7nugevqLjY5yTSUQP",
  // LIKE-RAY
  "DGSnfcE1kw4uDC6jgrsZ3s5CMfsWKN7JNjDNasHdvKfq",
  // COPE-RAY
  "8hvVAhShYLPThcxrxwMNAWmgRCSjtxygj11EGHp2WHz8",
  // ETH-SOL
  "9Hm8QX7ZhE9uB8L2arChmmagZZBtBmnzBbpfxzkQp85D",
  // stSOL-USDC
  "6a1CsrpeZubDjEJE9s1CMVheB6HWM5d7m1cj2jkhyXhj",
  // GRAPE-USDC
  "vVXfY15WdPsCmLvbiP4hWWECPFeAvPTuPNq3Q4BXfhy",
  // LARIX-USDC
  "A21ui9aYTSs3CbkscaY6irEMQx3Z59dLrRuZQTt2hJwQ",
  // RIN-USDC
  "7qZJTK5NatxQJRTxZvHi3gRu4cZZsKr8ZPzs7BA5JMTC",
  // APEX-USDC
  "43UHp4TuwQ7BYsaULN1qfpktmg7GWs9GpR8TDb8ovu9c",
  // mSOL-RAY
  "6gpZ9JkLoYvpA5cwdyPZFsDw6tkbPyyXM5FqRqHxMCny",
  // MNDE-mSOL
  "2kPA9XUuHUifcCYTnjSuN7ZrC3ma8EKPrtzUhC86zj3m",
  // LARIX-RAY
  "EBqQdu9rGe6j3WGJQSyTvDjUMWcRd6uLcxSS4TbFT31t",
  // LIQ-USDC
  "33dWwj33J3NUzoTmkMAUq1VdXZL89qezxkdaHdN88vK2",
  // WAG-USDC
  "FEFzBbbEK8yDigqyJPgJKMR5X1xZARC25QTCskvudjuK",
  // ETH-mSOL
  "Ghj3v2qYbSp6XqmH4NV4KRu4Rrgqoh2Ra7L9jEdsbNzF",
  // mSOL-USDT
  "BhuMVCzwFVZMSuc1kBbdcAnXwFg9p4HJp7A9ddwYjsaF",
  // BTC-mSOL
  "ynV2H2b7FcRBho2TvE25Zc4gDeuu2N45rUw9DuJYjJ9",
  // SLIM-SOL
  "8idN93ZBpdtMp4672aS4GGMDy7LdVWCCXH7FKFdMw9P4",
  // AURY-USDC
  "Ek8uoHjADzbNk2yr2HysybwFk1h2j9XXDsWAjAJN38n1",
  // PRT-SOL
  "7rVAbPFzqaBmydukTDFAuBiuyBrTVhpa5LpfDRrjX9mr",
  // LIQ-RAY
  "HuMDhYhW1BmBjXoJZBdjqaqoD3ehQeCUMbDSiZsaXSDU",
  // SYP-SOL
  "D95EzH4ZsGLikvYzp7kmz1RM1xNMo1MXXiXaedQesA2m",
  // SYP-RAY
  "3hhSfFhbk7Kd8XrRYKCcGAyUVYRaW9MLhcqAaU9kx6SA",
  // SYP-USDC
  "2Tv6eMih3iqxHrLAWn372Nba4A8FT8AxFSbowBmmTuAd",
  // FAB-USDC
  "7eM9KWYiJmNfDfeztMoEZE1KPyWD54LRxM9GmRY9ske6",
  // WOOF-RAY
  "3HYhQC6ne6SAPVT5sPTKawRUxv9ZpYyLuk1ifrw8baov",
  // WOOF-USDC
  "EZRHhpvAP4zEX1wZtTQcf6NP4FLWjs9c6tMRBqfrXgFD",
  // SLND-USDC
  "GRM4jGMtx64sEocBFz6ZgdogF2fyTWiixht8thZoHjkK",
  // FRKT-SOL
  "H3dhkXcC5MRN7VRXNbWVSvogH8mUQPzpn8PYQL7HfBVg",
  // weWETH-SOL
  "4yrHms7ekgTBgJg77zJ33TsWrraqHsCXDtuSZqUsuGHb",
  // weWETH-USDC
  "EoNrn8iUhwgJySD1pHu8Qxm5gSQqLK3za4m8xzD2RuEb",
  // weUNI-USDC
  "8J5fa8WBGaDSv8AUpgtqdh9HM5AZuSf2ijvSkKoaCXCi",
  // weSUSHI-USDC
  "9SWy6nbSVZ44XuixEvHpona663pZPpVgzXQ3N7muG4ou",
  // CYS-USDC
  "661trVCzDWp114gy4PEK4etbjb3u3RNaP4aENa5uN8Vp",
  // SAMO-USDC
  "7oYaghDwJ6ZbZwzdzcPqQtW6r4cojSLJDKB6U7tqAK1x",
  // ABR-USDC
  "GQJjrG6f8HbxkE3ZVSRpzoyWhQ2RiivT68BybVK9DxME",
  // IN-USDC
  "5DECiJuqwmeCptoBEpyJtXKrVfiUrG9nBbBGkxGkPYyF",
  // weDYDX-USDC
  "CbGQojcizFEHn3woL7NPu3P9BLL1SWz5a8zkL9gks24q",
  // STARS-USDC
  "CWQVga1qUbpZXjrWQRj6U6tmL3HhrFiAT11VYnB8d3CF",
  // weAXS-USDC
  "HopVRTvFvRe1ED3dRCQrt1h5onkMvY3tKUHRVQMc7MMH",
  // weSHIB-USDC
  "SU7vPveBjEuR5tgQwidRqqTxn1WwraHpydHHBpM2W96",
  // SBR-USDC
  "5cmAS6Mj4pG2Vp9hhyu3kpK9yvC7P6ejh9HiobpTE6Jc",
  // OXS-USDC
  "8ekXiGjEjtWzd2us3rAsusKv7kKEhPENV7nvzS7RGRYY",
  // CWAR-USDC
  "13uCPybNakXHGVd2DDVB7o2uwXuf9GqPFkvJMVgKy6UJ",
  // UPS-USDC
  "FSSRqrGrDjDXnojhSDrDBknJeQ83pyACemnaMLaZDD1U",
  // weSAND-USDC
  "3cmPpX8kKzEra2umtLCDxMfjma82ELtAMaSYVmdaNLxi",
  // weMANA-USDC
  "7Z1c6GHutf3q2MNheyFE8KMNVEALuiPaqoEMyjbCbuku",
  // CAVE-USDC
  "2PfKnjEfoUoVDbDS1YwvZ8HuPGBCpN831mnTuqTAJZjH",
  // GENE-USDC
  "Enq8vJucRbkzKA1i1PahJNhMyUTzoVL5Cs8n5rC3NLGn",
  // GENE-RAY
  "8FrCybrh7UFznP1hVHg8kXZ8bhii37c7BGzmjkdcsGJp",
  // APT-USDC
  "4crhN3D8R5rnZd66q9b32P7K649e5XdzCfPMPiTzBceH",
  // GOFX-USDC
  "zoouer92idprkptX76yvhp4stK2keTzJpMNkeLqtxAx",
  // SONAR-USDC
  "CrWbfKwyAaUfYctXWF9iaDUP4AH5t6k6bbaWnXBL8nHm",
  // JSOL-SOL
  "D8pasgJWjP9wy39fzeD8BUjQMvYCZxABzPcnuoDSLHBB",
  // JSOL-USDC
  "7e8GrkwsRm5sS5UaKobLJUNu9esmrzg37dqX6aQyuver",
  // SHILL-USDC
  "FTyJCLwQ3YvVfanJp8mtC2eqmvZPpzSpmNLqWpaPaXbC",
  // DFL-USDC
  "8GJdzPuEBPP3BHJpcspBcfpRZV4moZMFwhTAuXebaPL8",
  // BOKU-USDC
  "FsERtRjCEQNyND3ccnMGrd61ntPmJ3tbZs4vvcCzMr1L",
  // MIMO-SOL
  "DqYSvijBXydSx9GfvVDjEzUg5StLLrkqZVPzsU2FeVZ2",
  // wbWBNB-USDC
  "Fb1WR1kYvG1tHu4pwAxXQpdKT8Grh9i7ES9rZusLg7D6",
  // wePEOPLE-USDC
  "GfvqUB36CPfqZDz5ntQ2YsoKRwg1MCewmurhc7jw3P5s",
  // XTAG-USDC
  "Hv1BFhyADPjYXTqEeMgwzoybnNwYrHXNv7U2VjcAuEDr",
  // KKO-USDC
  "FvN7dJz7GX1XB1BTk6jD5rEKRxQc3ZwNkWJKai5sBJWS",
  // VI-USDC
  "MphbxYtji1FSkm7G2FNGpUUz5AWn7iRPNFAvEqD4mzE",
  // SOLC-USDT
  "783kPvwHwDXaU32kV8NK5dB4JVeMWQwe8a3WUNZFpupr",
  // STR-USDC
  "E9Z2JeEKS2WGGyA18mGU33rnQskK9moPhM4tdzrv24fh",
  // SPWN-USDC
  "Bhw7DbVwWMcTBXoKaWgsCaofL6QqmQQ65FCSGfgCEawm",
  // ISOLA-USDT
  "69Mo81rUPDgru4UbigPQovx7cYBxpEm44qQok8wcut4M",
  // TTT-USDC
  "HcqHvH27wk42L1ND5YPhLDJu7oGsU7HGSreMiXdq5LNK",
  // RUN-USDC
  "zuivKkgkNFFkV9jfNpsU1p5tWNbDWUEx5XX16m4k2Ej",
  // CRWNY-USDC
  "4ELBQuq3ivhLamfCT36As5sXLkQDWRJw1pJ9JVFLp6gK",
  // CRWNY-RAY
  "HARRXESCwid3xMi2qThag1PXzmp6rDhAzMR9THhFRQGf",
  // BLOCK-USDC
  "CfBSfVTcYFJsD8vZ2fTiMGkUYFim2rv8weAoqHxUU2pn",
  // REAL-USDC
  "A7ZxDrK9LSkVXhfRTu2pRCinwYfdxW2kK6DaJk12jRWw",
  // PRISM-USDC
  "4ZYiiVakejxGyJ3tuBzYWoHyEV1rk7Hm7viydG6DNaUN",
  // MBS-USDC
  "6eRECBcCVP82AvAd6Di4rZApa2btLf8RDUqrTigt4hS4",
];

export const TESTNET_OFFICIAL_LIQUIDITY_POOLS = [];

export const DEVNET_OFFICIAL_LIQUIDITY_POOLS = [];
