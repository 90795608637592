import { TransactionError } from '@mercurial-finance/optimist';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import {
  ConfirmedTransactionMeta,
  Connection,
  PublicKey,
  SendOptions,
  Transaction,
  TransactionResponse,
  TransactionSignature,
} from '@solana/web3.js';
import Decimal from 'decimal.js';
import promiseRetry from 'promise-retry';
import { WRAPPED_SOL_MINT, JUPITER_ERRORS } from '../constants';
import { wait } from './wait';

type ResponseMeta = NonNullable<TransactionResponse['meta']>;
type ReponseTransaction = TransactionResponse['transaction'];

function diffTokenBalance(accountKeyIndex: number, meta: ConfirmedTransactionMeta): number | undefined {
  const postBalance = meta.postTokenBalances?.find(
    (postTokenBalance) => postTokenBalance.accountIndex === accountKeyIndex,
  )?.uiTokenAmount.amount;
  const preBalance = meta.preTokenBalances?.find((preTokenBalance) => preTokenBalance.accountIndex === accountKeyIndex)
    ?.uiTokenAmount.amount;

  // When token account is created it isn't present in preBalance
  if (!postBalance) return;
  return Math.abs(parseInt(postBalance) - (preBalance !== undefined ? parseInt(preBalance) : 0));
}

export function extractTokenBalanceChangeFromTransaction(
  meta: ResponseMeta,
  transaction: ReponseTransaction,
  tokenAccountAddress: PublicKey,
): number | undefined {
  const message = transaction.message;

  if (!meta) {
    return;
  }
  const index = message.accountKeys.findIndex((p) => p.equals(tokenAccountAddress));

  return diffTokenBalance(index, meta);
}

export function extractSOLChangeFromTransaction(
  meta: ResponseMeta,
  transaction: ReponseTransaction,
  user: PublicKey,
): number {
  let accountKeyIndex = transaction.message.accountKeys.findIndex((p) => p.equals(user));

  if (accountKeyIndex !== -1) {
    return Math.abs(meta.postBalances[accountKeyIndex] - meta.preBalances[accountKeyIndex]);
  }

  // if 0 is returned it will throw error in the caller function
  return 0;
}

export function getWritableKeys(transaction: Transaction) {
  return [
    ...new Set(
      transaction.instructions
        .map((inst) => inst.keys.filter((key) => key.isWritable).map((k) => k.pubkey))
        .reduce((acc, el) => acc.concat(el)),
    ).values(),
  ];
}

export function getTokenBalanceChangesFromTransactionResponse({
  txid,
  inputMint,
  outputMint,
  user,
  sourceAddress,
  destinationAddress,
  transactionResponse,
  hasWrappedSOL,
}: {
  txid: TransactionSignature;
  inputMint: PublicKey;
  outputMint: PublicKey;
  user: PublicKey;
  sourceAddress: PublicKey;
  destinationAddress: PublicKey;
  transactionResponse: TransactionResponse | null;
  hasWrappedSOL: boolean;
}) {
  let sourceTokenBalanceChange: number | undefined;
  let destinationTokenBalanceChange: number | undefined;

  if (transactionResponse) {
    let { meta, transaction } = transactionResponse;
    if (meta) {
      sourceTokenBalanceChange =
        inputMint.equals(WRAPPED_SOL_MINT) && !hasWrappedSOL
          ? extractSOLChangeFromTransaction(meta, transaction, user)
          : extractTokenBalanceChangeFromTransaction(meta, transaction, sourceAddress);
      destinationTokenBalanceChange =
        outputMint.equals(WRAPPED_SOL_MINT) && !hasWrappedSOL
          ? extractSOLChangeFromTransaction(meta, transaction, user)
          : extractTokenBalanceChangeFromTransaction(meta, transaction, destinationAddress);
    }
  }

  if (!(sourceTokenBalanceChange && destinationTokenBalanceChange)) {
    throw new TransactionError(
      'Cannot find source or destination token account balance change',
      txid,
      JUPITER_ERRORS['BalancesNotExtractedProperly'].code,
    );
  }

  return [sourceTokenBalanceChange, destinationTokenBalanceChange];
}

export function getUnixTs() {
  return new Date().getTime();
}

const SEND_OPTIONS: SendOptions = { skipPreflight: true, maxRetries: 2 };

/**
 * awaits confirmation while resending the transaction periodically
 *
 * Our RPC node settings
 * solana_send_leader_count: 8
 * solana_send_retry_ms: 15000
 **/
export async function transactionSenderAndConfirmationWaiter(
  connection: Connection,
  signedTransaction: Transaction,
  timeout = 120_000, // 2 minutes, (sendInterval * sendRetries) = 80_000 + extra wait 40_000
  pollInterval = 500,
  sendInterval = 2_000,
  sendRetries = 40,
): Promise<{ txid: TransactionSignature; transactionResponse: TransactionResponse | null }> {
  const rawTransaction = signedTransaction.serialize();
  const txid = await connection.sendRawTransaction(rawTransaction, SEND_OPTIONS);

  const start = getUnixTs();
  let lastSendTimestamp = getUnixTs();
  let retries = 0;

  while (getUnixTs() - start < timeout) {
    const timestamp = getUnixTs();
    if (retries < sendRetries && timestamp - lastSendTimestamp > sendInterval) {
      lastSendTimestamp = timestamp;
      retries += 1;
      await connection.sendRawTransaction(rawTransaction, SEND_OPTIONS);
    }
    const response = await Promise.any([
      connection.getTransaction(txid, {
        commitment: 'confirmed',
      }),
      wait(5000),
    ]);
    if (response) return { txid, transactionResponse: response };
    await wait(pollInterval);
  }
  return { txid, transactionResponse: null };
}

export function getSignature(transaction: Transaction) {
  const signature = transaction.signature;
  if (!signature) {
    throw new Error('Transaction has no signature');
  }
  return bs58.encode(signature);
}
