import {
  SignerWalletAdapter,
  WalletPublicKeyError,
} from "@solana/wallet-adapter-base";
import {
  Connection,
  Transaction,
  PublicKey,
  Keypair,
  Commitment,
} from "@solana/web3.js";

export const enum ConfirmTypes {
  Confirmed,
  Processed,
  ConfirmLastTx,
}

export type ExecuteParams = {
  wallet: Pick<
    SignerWalletAdapter,
    "signAllTransactions" | "publicKey" | "sendTransaction" | "signTransaction"
  >;
  beforeSend?: (txTotalCnt: number, txIdx: number) => void;
  afterSend?: (txTotalCnt: number, txIdx: number, txHash: string) => void;
  onSuccess?: (txResult: string[]) => void;
  onFail?: (txResult: string[]) => void;
  confirmType?: ConfirmTypes;
};

export type ExecuteTransactionParams = ExecuteParams & {
  connection: Connection;
  transactions: Array<Transaction>;
};

export const executeTransactions = async ({
  connection,
  transactions,
  wallet,
  beforeSend,
  afterSend,
  onSuccess,
  onFail,
  confirmType,
}: ExecuteTransactionParams): Promise<{ err?: any; txResult?: any }> => {
  if (!wallet || !wallet?.publicKey)
    return {
      err: new WalletPublicKeyError("Wallet publicKey is null"),
    };
  // merging transactions
  const mergedTransactions: Transaction[] = [];
  let mergedTx = new Transaction();
  const blockhash = (await connection.getLatestBlockhash()).blockhash;
  for (let i = 0; ; ) {
    if (isAppendable(mergedTx, transactions[i], wallet.publicKey, blockhash)) {
      mergedTx.add(transactions[i]);
      i++;
      if (i === transactions.length) {
        mergedTransactions.push(mergedTx);
        break;
      }
    } else {
      mergedTransactions.push(mergedTx);
      mergedTx = new Transaction();
    }
  }

  const signedTransactions = await signAllTransaction(
    connection,
    wallet,
    mergedTransactions
  );

  const txResult: string[] = [];

  let commitment: Commitment = "confirmed";
  if (
    confirmType === ConfirmTypes.Processed ||
    confirmType === ConfirmTypes.ConfirmLastTx
  ) {
    commitment = "processed";
  }

  let lastTxHash = "";
  for (let i = 0; i < signedTransactions.length; i++) {
    beforeSend && beforeSend(signedTransactions.length, i);
    const txHash = await sendSignedTransaction(
      connection,
      signedTransactions[i]
    );
    txResult.push(txHash);
    lastTxHash = txHash;
    const res = await connection.confirmTransaction(txHash, commitment);
    afterSend && afterSend(signedTransactions.length, i, txHash);
    if (res.value.err) {
      onFail && onFail(txResult);
      return { err: res.value.err, txResult };
    }
  }
  if (confirmType === ConfirmTypes.ConfirmLastTx && lastTxHash !== "") {
    await connection.confirmTransaction(lastTxHash, "confirmed");
  }
  onSuccess && onSuccess(txResult);
  return { txResult };
};

const signAllTransaction = async (
  connection: Connection,
  wallet: any,
  transactions: Transaction[],
  signers: Array<Keypair> = []
): Promise<Transaction[]> => {
  for (const transaction of transactions) {
    transaction.recentBlockhash = (
      await connection.getRecentBlockhash()
    ).blockhash;
    transaction.setSigners(
      wallet.publicKey,
      ...signers.map((s) => s.publicKey)
    );
    if (signers.length > 0) {
      transaction.partialSign(...signers);
    }
  }
  return await wallet.signAllTransactions(transactions);
};

const sendSignedTransaction = async (
  connection: Connection,
  signedTransaction: Transaction
): Promise<string> => {
  const rawTransaction = signedTransaction.serialize();

  const txid = await connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    preflightCommitment: "confirmed",
  });

  return txid;
};

const sleep = async (ms: number) => {
  return new Promise((r) => setTimeout(r, ms));
};

export const isAppendable = (
  src: Transaction,
  newOne: Transaction,
  feePayer: PublicKey,
  blockHash: string
): boolean => {
  const PACKET_DATA_SIZE = 1280 - 40 - 8;
  const res = new Transaction().add(src);
  res.add(newOne);
  res.feePayer = feePayer;
  res.recentBlockhash = blockHash;
  return getTransactionSize(res) < PACKET_DATA_SIZE;
};

const getTransactionSize = (
  transaction: Transaction,
  signers: any = [],
  hasWallet = true
) => {
  const signData = transaction.serializeMessage();
  const signatureCount: number[] = [];
  encodeLength(signatureCount, signers.length);
  const transactionLength =
    signatureCount.length +
    (signers.length + (hasWallet ? 1 : 0)) * 64 +
    signData.length;
  return transactionLength;
};

const encodeLength = (bytes: Array<number>, len: number) => {
  let rem_len = len;
  for (;;) {
    let elem = rem_len & 0x7f;
    rem_len >>= 7;
    if (rem_len === 0) {
      bytes.push(elem);
      break;
    } else {
      elem |= 0x80;
      bytes.push(elem);
    }
  }
};
