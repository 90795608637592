import { PublicKey } from '@solana/web3.js';
import { getTwoPermutations } from '../../utils/getTwoPermutations';
import { AccountInfoMap, Amm, Quote, QuoteParams, SwapParams } from '../amm';
import { RaydiumAmm } from '../raydium/raydiumAmm';
import { createRiskCheckAndFeeInstruction } from '../jupiterInstruction';
import { SerumAmm } from '../serum/serumAmm';
import { SerumMarket } from '../market';
import JSBI from 'jsbi';
import { ZERO } from '@jup-ag/math';
import { BN } from 'bn.js';

interface SplitSolution {
  outAmount: JSBI;
  portion: number;
  firstQuote: Quote | undefined;
  secondQuote: Quote | undefined;
}

function isSplitSupported(firstAmm: Amm, secondAmm: Amm) {
  if (
    (firstAmm instanceof SerumAmm && secondAmm instanceof RaydiumAmm) ||
    (firstAmm instanceof RaydiumAmm && secondAmm instanceof SerumAmm) ||
    (firstAmm instanceof SerumAmm && secondAmm instanceof SerumAmm)
  ) {
    return false;
  }
  return true;
}

const HUNDRED = JSBI.BigInt(100);

// Create an iteration to quote with a stepped split
export class SplitTradeAmm implements Amm {
  market: SerumMarket | null;
  shouldPrefetch = false;
  exactOutputSupported = false;
  private portion1: number = 0;
  private portion2: number = 0;

  constructor(public firstAmm: Amm, public secondAmm: Amm, public reserveTokenMints: PublicKey[]) {
    this.market =
      firstAmm instanceof SerumAmm ? firstAmm.market : secondAmm instanceof SerumAmm ? secondAmm.market : null;
  }

  static getAmmIdsFromSplitTradeAmmId(id: string): string[] {
    const ammIds = id.split('-');

    return ammIds.length > 1 ? ammIds : [];
  }

  static create(firstAmm: Amm, secondAmm: Amm) {
    if (!isSplitSupported(firstAmm, secondAmm)) return;

    const firstAmmTwoPermutations = getTwoPermutations(firstAmm.reserveTokenMints);
    const secondAmmTwoPermutations = getTwoPermutations(secondAmm.reserveTokenMints);

    for (const firstAmmTwoPermutation of firstAmmTwoPermutations) {
      for (const secondAmmTwoPermutation of secondAmmTwoPermutations) {
        if (firstAmmTwoPermutation.every((value, index) => value.equals(secondAmmTwoPermutation[index]))) {
          return new SplitTradeAmm(firstAmm, secondAmm, firstAmmTwoPermutation);
        }
      }
    }
  }

  setPortions(portion1: number, portion2: number) {
    if (portion1 + portion2 !== 100) {
      throw new Error('Split trade portions must sum to 100');
    }

    this.portion1 = portion1;
    this.portion2 = portion2;
  }

  get id() {
    return `${this.firstAmm.id}-${this.secondAmm.id}`;
  }

  get label() {
    const labelWithPortions = [
      { label: this.firstAmm.label, portion: this.portion1 },
      { label: this.secondAmm.label, portion: this.portion2 },
    ].sort((a, b) => b.portion - a.portion);

    return labelWithPortions.map(({ label, portion }) => `${label} (${portion}%)`).join(' + ');
  }

  getAccountsForUpdate() {
    return [];
  }

  update(_accountInfoMap: AccountInfoMap) {
    // Underlying amms are updated
  }

  getQuote(quoteParams: QuoteParams): Quote {
    const sourceMintString = quoteParams.sourceMint.toBase58();
    const amount = quoteParams.amount;
    // Portion in % directly to please the UI
    let bestSolution: SplitSolution = {
      outAmount: ZERO,
      portion: 0,
      firstQuote: undefined,
      secondQuote: undefined,
    };

    // Increase portion until 100
    for (let p = 100; (p -= 5); p > 0) {
      const firstAmount = JSBI.divide(JSBI.multiply(amount, JSBI.BigInt(p)), HUNDRED);
      const secondAmount = JSBI.subtract(amount, firstAmount);

      const firstQuote = this.firstAmm.getQuote({
        ...quoteParams,
        amount: firstAmount,
      });
      const secondQuote = this.secondAmm.getQuote({
        ...quoteParams,
        amount: secondAmount,
      });
      const outAmount = JSBI.add(firstQuote.outAmount, secondQuote.outAmount);

      if (JSBI.lessThan(outAmount, bestSolution.outAmount)) {
        break;
      }

      bestSolution = {
        outAmount,
        portion: p,
        firstQuote,
        secondQuote,
      };
    }

    if (!bestSolution.firstQuote || !bestSolution.secondQuote) {
      throw new Error('Unreachable: There was no better solution than getting 0 outAmount');
    }

    const { outAmount, portion, firstQuote, secondQuote } = bestSolution;
    const portion1 = portion;
    const portion2 = 100 - portion1;

    // For UI display
    this.portion1 = portion1;
    this.portion2 = portion2;

    let firstAmmFee = {
      amount: firstQuote.feeAmount,
      mint: firstQuote.feeMint,
    };
    let secondAmmFee = {
      amount: secondQuote.feeAmount,
      mint: secondQuote.feeMint,
    };

    if (firstAmmFee.mint !== secondAmmFee.mint) {
      // Then we convert destinationMint fee into a sourceMint, to please the current data structure
      // This will lead to inexact fees but this doesn't affect the user minimum out amount
      if (firstAmmFee.mint !== sourceMintString) {
        firstAmmFee = {
          amount: JSBI.divide(
            JSBI.divide(JSBI.multiply(firstAmmFee.amount, JSBI.multiply(amount, JSBI.BigInt(portion1))), HUNDRED),
            bestSolution.outAmount,
          ),
          mint: sourceMintString,
        };
      }
      if (secondAmmFee.mint !== sourceMintString) {
        secondAmmFee = {
          amount: JSBI.divide(
            JSBI.divide(JSBI.multiply(JSBI.multiply(secondAmmFee.amount, amount), JSBI.BigInt(portion2)), HUNDRED),
            bestSolution.outAmount,
          ),
          mint: sourceMintString,
        };
      }
    }

    const feePct = (portion1 * firstQuote.feePct + portion2 * secondQuote.feePct) / 100;
    const priceImpactPct = (portion1 * firstQuote.priceImpactPct + portion2 * secondQuote.priceImpactPct) / 100;

    return {
      notEnoughLiquidity: false,
      inAmount: quoteParams.amount,
      outAmount: outAmount,
      feeAmount: JSBI.add(firstAmmFee.amount, secondAmmFee.amount),
      feeMint: firstAmmFee.mint, // Guaranteed identical mint at this point
      feePct,
      priceImpactPct,
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    const inAmount = swapParams.amount;
    if (inAmount === null) {
      throw new Error('Split trade cannot be used with a null inAmount');
    }

    // We rely on the fact that this.portion1 is set, what if it isn't?
    const firstAmount = inAmount.mul(new BN(this.portion1)).div(new BN(HUNDRED.toString()));
    const secondAmount = inAmount.sub(firstAmount);

    return [
      ...this.firstAmm.createSwapInstructions({
        ...swapParams,
        amount: firstAmount,
        otherAmountThreshold: new BN(0),
        platformFee: undefined,
      }),
      ...this.secondAmm.createSwapInstructions({
        ...swapParams,
        amount: secondAmount,
        otherAmountThreshold: new BN(0),
        platformFee: undefined,
      }),
      createRiskCheckAndFeeInstruction(
        swapParams.userDestinationTokenAccount,
        swapParams.userTransferAuthority,
        new BN(swapParams.otherAmountThreshold.toString()),
        swapParams.tokenLedger,
        swapParams.platformFee,
      ),
    ];
  }
}
