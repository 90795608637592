import { TokenSwapAccountLayout } from '@jup-ag/crema-sdk';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';
import { createProgramAddressSyncUnsafe } from '../../utils/pda';

export interface CremaPoolState {
  programId: PublicKey;
  authority: PublicKey;
  version: number;
  isInitialized: boolean;
  nonce: number;
  ammId: PublicKey;
  tokenProgramId: PublicKey;
  tokenAAccount: PublicKey;
  tokenBAccount: PublicKey;
  ticksKey: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
  fee: Decimal;
  currentSqrtPrice: Decimal;
  currentLiquity: Decimal;
}

export const accountInfoToCremaPoolState = (address: PublicKey, accountInfo: AccountInfo<Buffer>): CremaPoolState => {
  const programId = accountInfo.owner;

  const decoded = TokenSwapAccountLayout.decode(accountInfo.data);
  const authority = createProgramAddressSyncUnsafe([address.toBuffer(), Buffer.from([decoded.nonce])], programId);

  return {
    programId,
    authority,
    version: decoded.version,
    isInitialized: Boolean(decoded.isInitialized),
    nonce: decoded.nonce,
    ammId: address,
    tokenProgramId: decoded.tokenProgramId,
    tokenAAccount: decoded.swapTokenA,
    tokenBAccount: decoded.swapTokenB,
    ticksKey: decoded.ticksKey,
    mintA: decoded.tokenAMint,
    mintB: decoded.tokenBMint,
    fee: decoded.fee,
    currentSqrtPrice: decoded.currentSqrtPrice,
    currentLiquity: decoded.currentLiquity,
  };
};
