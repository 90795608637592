import { AccountInfo, PublicKey } from '@solana/web3.js';
import Decimal from 'decimal.js';
import { AccountInfoMap, Amm, SwapMode, Quote, QuoteParams, SwapParams } from '../amm';
import { createWhirlpoolSwapInstruction, createWhirlpoolSwapExactOutputInstruction } from '../jupiterInstruction';
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { WHIRLPOOL_PROGRAM_ID } from '../../constants';
import {
  parseWhirlpool,
  parseTickArray,
  WhirlpoolData,
  getSwapQuote,
  getTickArrayPks,
  TickArrayData,
  getTickArrayPublicKeysForSwap,
  getDefaultSqrtPriceLimit,
} from '@jup-ag/whirlpool-sdk';
import BN from 'bn.js';
import JSBI from 'jsbi';

const FEE_RATE_MUL_VALUE = 1_000_000;

function fromX64(num: BN): Decimal {
  return new Decimal(num.toString()).mul(Decimal.pow(2, -64));
}

function parseWhirlpoolSafe(address: PublicKey, data: Buffer) {
  const whirlpoolData = parseWhirlpool(data);
  if (!whirlpoolData) throw new Error(`Failed to parse whirlpool ${address.toBase58()}`);
  return whirlpoolData;
}

export class WhirlpoolAmm implements Amm {
  id: string;
  label = 'Orca (Whirlpools)';
  shouldPrefetch = true;
  exactOutputSupported = true;

  private whirlpoolData: WhirlpoolData;
  private tickArrays: Map<string, TickArrayData> = new Map();
  private tickPks: PublicKey[];
  private oracle: PublicKey;
  private feePct: Decimal;

  constructor(private address: PublicKey, whirlpoolAccountInfo: AccountInfo<Buffer>) {
    this.id = address.toBase58();
    this.whirlpoolData = parseWhirlpoolSafe(address, whirlpoolAccountInfo.data);

    this.oracle = findProgramAddressSync([Buffer.from('oracle'), address.toBuffer()], WHIRLPOOL_PROGRAM_ID)[0];
    this.feePct = new Decimal(this.whirlpoolData.feeRate).div(FEE_RATE_MUL_VALUE);
    this.tickPks = getTickArrayPks(address, this.whirlpoolData);
  }

  getAccountsForUpdate(): PublicKey[] {
    // The tickCurrentIndex is technically behind here, belonging to the last refresh
    return [this.address, ...this.tickPks];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const whirlpoolAccountInfo = accountInfoMap.get(this.address.toBase58());
    if (!whirlpoolAccountInfo) throw new Error(`Missing ${this.address.toBase58()}`);
    this.whirlpoolData = parseWhirlpoolSafe(this.address, whirlpoolAccountInfo.data);
    this.tickPks = getTickArrayPks(this.address, this.whirlpoolData);

    this.tickArrays.clear();
    for (const tickArrayPk of this.tickPks) {
      const tickArrayAddress = tickArrayPk.toBase58();
      const tickArrayAccountInfo = accountInfoMap.get(tickArrayAddress);
      if (!tickArrayAccountInfo) {
        // This can happen if we reach an uninitialized tick, and it is likely to occur right now
        continue;
      }
      const tickArray = parseTickArray(tickArrayAccountInfo.data);
      if (!tickArray) throw new Error(`Could not parse tick array ${tickArrayAddress}`);
      this.tickArrays.set(tickArrayAddress, tickArray);
    }
  }

  getQuote({ sourceMint, destinationMint, amount, swapMode }: QuoteParams): Quote {
    const swapQuote = getSwapQuote({
      poolAddress: this.address,
      whirlpool: this.whirlpoolData,
      tickArrays: this.tickArrays,
      tokenMint: swapMode === SwapMode.ExactIn ? sourceMint : destinationMint,
      tokenAmount: new BN(amount.toString()),
      isInput: swapMode === SwapMode.ExactIn,
    });

    const inAmount = JSBI.BigInt(swapQuote.amountIn.toString());
    const outAmount = JSBI.BigInt(swapQuote.amountOut.toString());
    const feeAmount = JSBI.BigInt(this.feePct.mul(inAmount.toString()).floor().toString());
    const quotePrice = swapQuote.aToB
      ? new Decimal(swapQuote.amountOut.toString()).div(swapQuote.amountIn.toString())
      : new Decimal(swapQuote.amountIn.toString()).div(swapQuote.amountOut.toString());

    const currentPrice = fromX64(this.whirlpoolData.sqrtPrice).pow(2);
    const priceImpactPct = currentPrice.minus(quotePrice).div(currentPrice).abs().toNumber();

    return {
      notEnoughLiquidity: false,
      inAmount,
      outAmount,
      feeAmount,
      feeMint: sourceMint.toBase58(),
      feePct: this.feePct.toNumber(),
      priceImpactPct: Number(priceImpactPct),
    };
  }

  createSwapInstructions(swapParams: SwapParams) {
    const aToB = swapParams.sourceMint.equals(this.whirlpoolData.tokenMintA);
    const targetSqrtPrice = getDefaultSqrtPriceLimit(aToB);
    const [tickArray0, tickArray1, tickArray2] = getTickArrayPublicKeysForSwap(
      this.whirlpoolData.tickCurrentIndex,
      targetSqrtPrice,
      this.whirlpoolData.tickSpacing,
      this.address,
      this.tickArrays,
      WHIRLPOOL_PROGRAM_ID,
      aToB,
    );

    const ix =
      swapParams.swapMode === SwapMode.ExactIn
        ? createWhirlpoolSwapInstruction({
            additionalArgs: {
              aToB,
              whirlpool: this.address,
              tickArray0,
              tickArray1,
              tickArray2,
              oracle: this.oracle,
              ...this.whirlpoolData,
            },
            ...swapParams,
            inAmount: swapParams.amount,
            minimumOutAmount: swapParams.otherAmountThreshold,
          })
        : (() => {
            if (swapParams.amount === null) throw Error('amount cannot be null with exact output');

            return createWhirlpoolSwapExactOutputInstruction({
              additionalArgs: {
                aToB,
                whirlpool: this.address,
                tickArray0,
                tickArray1,
                tickArray2,
                oracle: this.oracle,
                ...this.whirlpoolData,
              },
              ...swapParams,
              outAmount: swapParams.amount,
              maximumInAmount: swapParams.otherAmountThreshold,
            });
          })();

    return [ix];
  }

  get reserveTokenMints() {
    return [this.whirlpoolData.tokenMintA, this.whirlpoolData.tokenMintB];
  }
}
