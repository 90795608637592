import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Connection, FeeCalculator, PublicKey } from '@solana/web3.js';
import type { SerumOpenOrdersMap } from '..';
import { WRAPPED_SOL_MINT } from '../constants';
import { deserializeAccount } from '@mercurial-finance/optimist';
import { RouteInfo, TransactionFeeInfo } from './routes';
import { routeAtaInstructions } from './routeToInstructions';
import { getOrCreateOpenOrdersAddress } from './serum/openOrders';
import { SerumAmm } from './serum/serumAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { PlatformFeeAndAccounts, SetupInstructions } from './types';
import { Owner } from '../utils/Owner';

const SERUM_OPEN_ACCOUNT_LAMPORTS = 23_352_760;
const OPEN_TOKEN_ACCOUNT_LAMPORTS = 2_039_280;

function sum(values: number[]) {
  return values.reduce((value, acc) => {
    acc += value;
    return acc;
  }, 0);
}

const calculateTransactionDepositAndFee = ({
  intermediate,
  destination,
  openOrders,
  hasWrapUnwrapSOL,
  feeCalculator,
}: SetupInstructions & {
  hasWrapUnwrapSOL: boolean;
  feeCalculator: FeeCalculator;
}): TransactionFeeInfo => {
  const openOrdersDeposits = openOrders
    .filter((ooi) => ooi && ooi.instructions.length > 0)
    .map(() => SERUM_OPEN_ACCOUNT_LAMPORTS);
  const ataDeposits = [intermediate, destination]
    .filter((item) => item?.instructions.length && item.cleanupInstructions.length === 0)
    .map(() => OPEN_TOKEN_ACCOUNT_LAMPORTS);

  const signatureFee =
    ([...openOrders?.map((oo) => oo?.signers), intermediate?.signers, destination.signers].filter(Boolean).flat()
      .length +
      1) *
    feeCalculator.lamportsPerSignature;

  const totalFeeAndDeposits = sum([signatureFee, ...openOrdersDeposits, ...ataDeposits]);

  // We need to account for temporary wrapped SOL token accounts as intermediary or output
  const minimumSOLForTransaction = sum([
    signatureFee,
    ...openOrdersDeposits,
    ...[intermediate, destination]
      .filter((item) => (item?.instructions.length ?? 0) > 0)
      .map(() => OPEN_TOKEN_ACCOUNT_LAMPORTS),
    hasWrapUnwrapSOL ? OPEN_TOKEN_ACCOUNT_LAMPORTS : 0,
  ]);

  return {
    signatureFee,
    openOrdersDeposits,
    ataDeposits,
    totalFeeAndDeposits,
    minimumSOLForTransaction,
  };
};

export const getDepositAndFeeFromInstructions = async ({
  connection,
  owner,
  inputMint,
  marketInfos,
  feeCalculator,
  serumOpenOrdersPromise,
  wrapUnwrapSOL: unwrapSOL,
}: {
  connection: Connection;
  owner: Owner;
  inputMint: PublicKey;
  marketInfos: RouteInfo['marketInfos'];
  feeCalculator: FeeCalculator;
  /* promise because we can choose not to await it when we dont need it */
  serumOpenOrdersPromise: Promise<SerumOpenOrdersMap>;
  wrapUnwrapSOL: boolean;
}) => {
  const hasWrapUnwrapSOL = inputMint.equals(WRAPPED_SOL_MINT) && unwrapSOL;

  const openOrdersInstructionsPromise = Promise.all(
    marketInfos.map(async (marketInfo) => {
      const amm = marketInfo.amm;
      if (amm instanceof SerumAmm || amm instanceof SplitTradeAmm) {
        if (!amm.market) return;
        return await getOrCreateOpenOrdersAddress(
          connection,
          owner.publicKey,
          amm.market,
          await serumOpenOrdersPromise,
        );
      }
      return;
    }),
  );

  const promise = routeAtaInstructions({ connection, marketInfos, owner, unwrapSOL }).then(
    ({ userIntermediaryTokenAccountResult, userDestinationTokenAccountResult }) => {
      return openOrdersInstructionsPromise.then((openOrdersInstructions) => ({
        intermediate: userIntermediaryTokenAccountResult,
        destination: userDestinationTokenAccountResult,
        openOrders: openOrdersInstructions,
      }));
    },
  );

  const instructionResult = await promise;

  return calculateTransactionDepositAndFee({
    ...instructionResult,
    hasWrapUnwrapSOL,
    feeCalculator,
  });
};

export const NO_PLATFORM_FEE: PlatformFeeAndAccounts = {
  feeBps: 0,
  feeAccounts: new Map<string, PublicKey>(),
};

export async function getPlatformFeeAccounts(
  connection: Connection,
  feeAccountOwner: PublicKey,
): Promise<Map<string, PublicKey>> {
  const tokenAccounts = (
    await connection.getTokenAccountsByOwner(feeAccountOwner, {
      programId: TOKEN_PROGRAM_ID,
    })
  ).value;

  const feeAccounts = tokenAccounts.reduce((acc, tokenAccount) => {
    const deserializedtokenAccount = deserializeAccount(tokenAccount.account.data);
    if (deserializedtokenAccount) {
      acc.set(deserializedtokenAccount.mint.toBase58(), tokenAccount.pubkey);
    }
    return acc;
  }, new Map<string, PublicKey>());

  return feeAccounts;
}
