import * as anchor from "@project-serum/anchor";
import { PublicKey, Connection, Keypair } from "@solana/web3.js";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import type { RatioLending } from "./idls/ratio-lending-idl";
import { IDL } from "./idls/ratio-lending-idl";
import { RATIO_LENDING_PROGRAM_ID } from "../ids";
import { getPda } from "../../utils/web3";

export class RatioLendingProgram {
  program: anchor.Program<RatioLending>;
  conn: Connection;

  constructor(conn: Connection) {
    const provider = new anchor.AnchorProvider(
      conn,
      new NodeWallet(Keypair.generate()),
      anchor.AnchorProvider.defaultOptions()
    );
    const program = new anchor.Program(IDL, RATIO_LENDING_PROGRAM_ID, provider);
    this.program = program;
    this.conn = conn;
  }

  static getInstance(connection: Connection): RatioLendingProgram {
    return new RatioLendingProgram(connection);
  }

  /**
   *
   * @returns get publickey of global state account in ratio lending program.
   */
  static getStateKey(): PublicKey {
    const stateKey = getPda(
      [Buffer.from("GLOBAL_STATE_SEED")],
      RATIO_LENDING_PROGRAM_ID
    )[0];
    return stateKey;
  }

  /**
   *
   * @param stateKey The key of global state account in rato lending program
   * @returns state of ratio lending program
   */
  async getState(stateKey: PublicKey | null = null): Promise<RatioState> {
    if (!stateKey) stateKey = RatioLendingProgram.getStateKey();
    const stateInfo = await this.program.account.globalState.fetch(stateKey);
    return stateInfo as RatioState;
  }
}

type RatioState = anchor.IdlAccounts<RatioLending>["globalState"];
