import * as anchor from "@project-serum/anchor";
import {
  PublicKey,
  Connection,
  TransactionInstruction,
  Transaction,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Keypair,
  ComputeBudgetProgram,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

import { Spl } from "@raydium-io/raydium-sdk";
import { StableSwap } from "@saberhq/stableswap-sdk";

import JSBI from "jsbi";
import BN from "bn.js";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";

import type { RatioSdk } from "./idls/ratio-sdk-idl";
import { IDL } from "./idls/ratio-sdk-idl";

import { RATIO_SDK_PROGRAM_ID, SaberProgramIds } from "../ids";
import { getAta, getPda } from "../../utils/web3";

import * as Constants from "../../constants";
import { SaberDecimalsProgram } from "../saber/add-decimals";
import { toJSBI } from "../../utils/math-utils";
import { RatioLendingProgram } from "./ratio-lending";

export class RatioSdkProgram {
  program: anchor.Program<RatioSdk>;
  conn: Connection;

  constructor(conn: Connection) {
    const provider = new anchor.AnchorProvider(
      conn,
      new NodeWallet(Keypair.generate()),
      anchor.AnchorProvider.defaultOptions()
    );
    const program = new anchor.Program(IDL, RATIO_SDK_PROGRAM_ID, provider);
    this.program = program;
    this.conn = conn;
  }

  static getInstance(connection: Connection): RatioSdkProgram {
    return new RatioSdkProgram(connection);
  }

  /**
   *
   * @param connection Rpc connection
   * @param wallet Wallet (likely to be a walletAdapter)
   * @param stableSwap StableSwap info of Sbaer LP pair
   * @param originAmounts token amounts in wallet before jupiter swap
   * @returns
   */
  async makeSaberAddLiquidityTx(
    connection: Connection,
    userPublicKey: PublicKey,
    stableSwap: StableSwap,
    originAmounts: Array<JSBI>
  ): Promise<Transaction> {
    const tokenAMint = stableSwap.state.tokenA.mint;
    const tokenBMint = stableSwap.state.tokenB.mint;
    const lpMint = stableSwap.state.poolTokenMint;
    const {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    } = await this.ataTransactionsForAddLiquidity(
      connection,
      userPublicKey,
      tokenAMint,
      tokenBMint,
      lpMint
    );

    const transaction = new Transaction();
    if (instructions.length > 0) transaction.add(...instructions);

    const stateKey = RatioLendingProgram.getStateKey();
    transaction.add(
      await this.program.methods
        .addLiquidityToSaber(
          new BN(originAmounts[0].toString()),
          new BN(originAmounts[1].toString())
        )
        .accounts({
          authority: userPublicKey,
          globalState: stateKey,
          ataTokenATreasury,
          ataTokenBTreasury,
          ataUserTokenA,
          ataUserTokenB,
          ataUserTokenLp: ataUserLpToken,
          reserveTokenA: stableSwap.state.tokenA.reserve,
          reserveTokenB: stableSwap.state.tokenB.reserve,
          poolMint: stableSwap.state.poolTokenMint,
          tokenA: tokenAMint,
          tokenB: tokenBMint,
          swapAuthority: stableSwap.config.authority,
          swapAccount: stableSwap.config.swapAccount,
          saberStableProgram: new PublicKey(SaberProgramIds.StableSwap),
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .instruction()
    );
    return transaction;
  }

  async makeRaydiumAddLiquidityTx(
    connection: Connection,
    userPublicKey: PublicKey,
    poolKeys: any,
    originAmounts: Array<JSBI>
  ): Promise<Transaction> {
    const tokenAMint = poolKeys.baseMint;
    const tokenBMint = poolKeys.quoteMint;
    const lpMint = poolKeys.lpMint;

    const {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    } = await this.ataTransactionsForAddLiquidity(
      connection,
      userPublicKey,
      tokenAMint,
      tokenBMint,
      lpMint
    );

    const transaction = new Transaction();
    if (instructions.length > 0) transaction.add(...instructions);

    const stateKey = RatioLendingProgram.getStateKey();

    let modalDataKey = userPublicKey;
    if (poolKeys.version === 5) modalDataKey = poolKeys.modelDataAccount;

    transaction.add(
      ComputeBudgetProgram.requestUnits({
        units: 1400000,
        additionalFee: 0,
      })
    );

    const ix = await this.program.methods
      .addLiquidityToRaydium(
        poolKeys.version,
        new BN(originAmounts[0].toString()),
        new BN(originAmounts[1].toString())
      )
      .accounts({
        authority: userPublicKey,
        globalState: stateKey,
        ataTokenATreasury,
        ataTokenBTreasury,
        ammId: poolKeys.id,
        ammAuthority: poolKeys.authority,
        ammOpenOrders: poolKeys.openOrders,
        ammTargetOrders: poolKeys.targetOrders,
        reserveTokenA: poolKeys.baseVault,
        reserveTokenB: poolKeys.quoteVault,
        ataUserTokenA,
        ataUserTokenB,
        ataUserTokenLp: ataUserLpToken,
        poolMint: poolKeys.lpMint,
        tokenA: tokenAMint,
        tokenB: tokenBMint,
        modelData: modalDataKey,
        serumMarket: poolKeys.marketId,
        serumEventQueue: poolKeys.marketEventQueue,
        raydiumSwapProgram: poolKeys.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .instruction();
    transaction.add(ix);
    return transaction;
  }

  async ataTransactionsForAddLiquidity(
    conn: Connection,
    userPublicKey: PublicKey,
    tokenAMint: PublicKey,
    tokenBMint: PublicKey,
    lpMint: PublicKey
  ) {
    const instructions: TransactionInstruction[] = [];

    const ratioLendingProgram = RatioLendingProgram.getInstance(conn);
    const stateInfo = await ratioLendingProgram.getState();

    const ataUserTokenA = getAta(tokenAMint, userPublicKey);
    const ataUserTokenB = getAta(tokenBMint, userPublicKey);
    const ataUserLpToken = getAta(lpMint, userPublicKey);

    if (!(await conn.getAccountInfo(ataUserLpToken))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: lpMint,
          associatedAccount: ataUserLpToken,
          owner: userPublicKey,
          payer: userPublicKey,
        })
      );
    }

    const ataTokenATreasury = getAta(tokenAMint, stateInfo.treasury);
    const ataTokenBTreasury = getAta(tokenBMint, stateInfo.treasury);

    if (!(await conn.getAccountInfo(ataTokenATreasury))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenAMint,
          associatedAccount: ataTokenATreasury,
          owner: stateInfo.treasury,
          payer: userPublicKey,
        })
      );
    }
    if (!(await conn.getAccountInfo(ataTokenBTreasury))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenBMint,
          associatedAccount: ataTokenBTreasury,
          owner: stateInfo.treasury,
          payer: userPublicKey,
        })
      );
    }

    return {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    };
  }

  async ataInitTransactionsForRemoveLiquidity(
    conn: Connection,
    userPublicKey: PublicKey,
    tokenAMint: PublicKey,
    tokenBMint: PublicKey,
    lpMint: PublicKey
  ) {
    const instructions: TransactionInstruction[] = [];

    const ratioLendingProgram = RatioLendingProgram.getInstance(conn);
    const stateInfo = await ratioLendingProgram.getState();

    const ataUserTokenA = getAta(tokenAMint, userPublicKey);
    const ataUserTokenB = getAta(tokenBMint, userPublicKey);
    const ataUserLpToken = getAta(lpMint, userPublicKey);

    if (!(await conn.getAccountInfo(ataUserTokenA))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenAMint,
          associatedAccount: ataUserTokenA,
          owner: userPublicKey,
          payer: userPublicKey,
        })
      );
    }

    if (!(await conn.getAccountInfo(ataUserTokenB))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenAMint,
          associatedAccount: ataUserTokenB,
          owner: userPublicKey,
          payer: userPublicKey,
        })
      );
    }

    const ataTokenATreasury = getAta(tokenAMint, stateInfo.treasury);
    const ataTokenBTreasury = getAta(tokenBMint, stateInfo.treasury);

    if (!(await conn.getAccountInfo(ataTokenATreasury))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenAMint,
          associatedAccount: ataTokenATreasury,
          owner: stateInfo.treasury,
          payer: userPublicKey,
        })
      );
    }
    if (!(await conn.getAccountInfo(ataTokenBTreasury))) {
      instructions.push(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: tokenBMint,
          associatedAccount: ataTokenBTreasury,
          owner: stateInfo.treasury,
          payer: userPublicKey,
        })
      );
    }

    return {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    };
  }

  /**
   *
   * @param connection Rpc connection
   * @param wallet Wallet (likely to be a walletAdapter)
   * @param stableSwap StableSwap info of Saber LP pair
   * @param amount token amount to unwind
   * @returns
   */
  async makeSaberRemoveLiquidityTx(
    connection: Connection,
    userPublicKey: PublicKey,
    saberSwap: StableSwap,
    amountToUnwind: JSBI
  ): Promise<Transaction> {
    const tokenAMint = saberSwap.state.tokenA.mint;
    const tokenBMint = saberSwap.state.tokenB.mint;
    const lpMint = saberSwap.state.poolTokenMint;
    const {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    } = await this.ataInitTransactionsForRemoveLiquidity(
      connection,
      userPublicKey,
      tokenAMint,
      tokenBMint,
      lpMint
    );

    const transaction = new Transaction();
    if (instructions.length > 0) transaction.add(...instructions);

    const stateKey = RatioLendingProgram.getStateKey();
    transaction.add(
      await this.program.methods
        .removeLiquidityFromSaber(new BN(amountToUnwind.toString()))
        .accounts({
          authority: userPublicKey,
          globalState: stateKey,
          ataTreasuryA: ataTokenATreasury,
          ataTreasuryB: ataTokenBTreasury,
          ataUserLp: ataUserLpToken,
          ataUserA: ataUserTokenA,
          ataUserB: ataUserTokenB,
          saberSwapAccount: {
            ammId: saberSwap.config.swapAccount,
            authority: saberSwap.config.authority,
            reserveA: saberSwap.state.tokenA.reserve,
            reserveB: saberSwap.state.tokenB.reserve,
            lpMint: saberSwap.state.poolTokenMint,
            feeAccountA: saberSwap.state.tokenA.adminFeeAccount,
            feeAccountB: saberSwap.state.tokenB.adminFeeAccount,
          },
          saberStableProgram: saberSwap.config.swapProgramID,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    return transaction;
  }

  /**
   *
   * @param connection Rpc connection
   * @param wallet Wallet (likely to be a walletAdapter)
   * @param poolKeys raydium pool info. fetched using raydium api generally
   * @param amount token amount to unwind
   * @returns
   */
  async makeRaydiumRemoveLiquidityTx(
    connection: Connection,
    userPublicKey: PublicKey,
    poolKeys: any,
    amountToUnwind: JSBI
  ): Promise<Transaction> {
    const tokenAMint = poolKeys.baseMint;
    const tokenBMint = poolKeys.quoteMint;
    const lpMint = poolKeys.lpMint;

    const {
      instructions,
      ataUserTokenA,
      ataUserTokenB,
      ataUserLpToken,
      ataTokenATreasury,
      ataTokenBTreasury,
    } = await this.ataInitTransactionsForRemoveLiquidity(
      connection,
      userPublicKey,
      tokenAMint,
      tokenBMint,
      lpMint
    );

    const transaction = new Transaction();
    transaction.add(
      ComputeBudgetProgram.setComputeUnitLimit({
        units: 300000,
      })
    );

    if (instructions.length > 0) transaction.add(...instructions);
    const stateKey = RatioLendingProgram.getStateKey();
    if (poolKeys.version === 4) {
      transaction.add(
        await this.program.methods
          .removeLiquidityFromRaydiumV4(new BN(amountToUnwind.toString()))
          .accounts({
            authority: userPublicKey,
            globalState: stateKey,
            ataTreasuryA: ataTokenATreasury,
            ataTreasuryB: ataTokenBTreasury,
            ataUserLp: ataUserLpToken,
            ataUserA: ataUserTokenA,
            ataUserB: ataUserTokenB,
            ammId: new PublicKey(poolKeys.id),
            ammAuthority: new PublicKey(poolKeys.authority),
            ammOpenOrders: new PublicKey(poolKeys.openOrders),
            ammTargetOrders: new PublicKey(poolKeys.targetOrders),
            ammWithdrawQueue: new PublicKey(poolKeys.withdrawQueue),
            ammTempLp: new PublicKey(poolKeys.lpVault),
            ammLpMint: new PublicKey(poolKeys.lpMint),
            ammReserveA: new PublicKey(poolKeys.baseVault),
            ammReserveB: new PublicKey(poolKeys.quoteVault),
            serumMarket: new PublicKey(poolKeys.marketId),
            serumEventQueue: new PublicKey(poolKeys.marketEventQueue),
            serumBids: new PublicKey(poolKeys.marketBids),
            serumAsks: new PublicKey(poolKeys.marketAsks),
            serumCoinVault: new PublicKey(poolKeys.marketBaseVault),
            serumPcVault: new PublicKey(poolKeys.marketQuoteVault),
            serumVaultSigner: new PublicKey(poolKeys.marketAuthority),
            raydiumAmmProgram: new PublicKey(poolKeys.programId),
            serumProgram: new PublicKey(poolKeys.marketProgramId),
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction()
      );
    } else if (poolKeys.version === 5) {
      transaction.add(
        await this.program.methods
          .removeLiquidityFromRaydiumV5(new BN(amountToUnwind.toString()))
          .accounts({
            authority: userPublicKey,
            globalState: stateKey,
            ataTreasuryA: ataTokenATreasury,
            ataTreasuryB: ataTokenBTreasury,
            ataUserLp: ataUserLpToken,
            ataUserA: ataUserTokenA,
            ataUserB: ataUserTokenB,
            ammId: new PublicKey(poolKeys.id),
            ammAuthority: new PublicKey(poolKeys.authority),
            ammOpenOrders: new PublicKey(poolKeys.openOrders),
            ammTargetOrders: new PublicKey(poolKeys.targetOrders),
            modelData: new PublicKey(poolKeys.modelDataAccount),
            ammLpMint: new PublicKey(poolKeys.lpMint),
            ammReserveA: new PublicKey(poolKeys.baseVault),
            ammReserveB: new PublicKey(poolKeys.quoteVault),
            serumMarket: new PublicKey(poolKeys.marketId),
            serumEventQ: new PublicKey(poolKeys.marketEventQueue),
            serumBids: new PublicKey(poolKeys.marketBids),
            serumAsks: new PublicKey(poolKeys.marketAsks),
            serumCoinVault: new PublicKey(poolKeys.marketBaseVault),
            serumPcVault: new PublicKey(poolKeys.marketQuoteVault),
            serumVaultSigner: new PublicKey(poolKeys.marketAuthority),
            raydiumStableAmmProgram: new PublicKey(poolKeys.programId),
            serumProgram: new PublicKey(poolKeys.marketProgramId),
            tokenProgram: TOKEN_PROGRAM_ID,
          })
          .instruction()
      );
    }

    return transaction;
  }

  // add-decimals part
  static isSaberWrapped(mint: string) {
    const wrappedInfo = Constants.wrappedTokens.find((v) => v.wrapped === mint);
    if (wrappedInfo) {
      return true;
    }
    return false;
  }

  static getWrappedUnderlying(mint: string) {
    const wrappedInfo = Constants.wrappedTokens.find((v) => v.wrapped === mint);
    return wrappedInfo!.underlying;
  }

  async makeWrapTx(
    userPublicKey: PublicKey,
    underlyingMint: string | PublicKey,
    originAmount: JSBI
  ): Promise<Transaction | null> {
    const wrappedInfo = Constants.wrappedTokens.find(
      (v) => v.underlying === underlyingMint
    );
    if (!wrappedInfo) return null;
    return await this.getWrapTransaction(
      userPublicKey,
      new PublicKey(wrappedInfo.wrapped),
      new PublicKey(wrappedInfo.underlying),
      wrappedInfo.wrappedDecimals,
      wrappedInfo.underlyingDecimals,
      originAmount
    );
  }

  async makeUnwrapTx(
    userPublicKey: PublicKey,
    wrapMint: string | PublicKey,
    originAmount: JSBI
  ): Promise<Transaction | null> {
    const wrappedInfo = Constants.wrappedTokens.find(
      (v) => v.wrapped === wrapMint
    );
    if (!wrappedInfo) return null;
    return await this.getUnwrapTransaction(
      userPublicKey,
      new PublicKey(wrappedInfo.wrapped),
      new PublicKey(wrappedInfo.underlying),
      wrappedInfo.wrappedDecimals,
      originAmount
    );
  }

  async getUnwrapTransaction(
    userPublicKey: PublicKey,
    wrappedMint: PublicKey,
    underlyingMint: PublicKey,
    wrappedDecimals: number,
    originAmount: JSBI
  ): Promise<Transaction> {
    const [wrapperKey, nonce] = getPda(
      [
        Buffer.from("anchor"),
        underlyingMint.toBuffer(),
        Buffer.from([wrappedDecimals]),
      ],
      SaberProgramIds.AddDecimals
    );

    const decimalsProgram = SaberDecimalsProgram.getInstance(
      this.conn,
      userPublicKey
    );

    const wrapperData = await decimalsProgram.getWrapperData(wrapperKey);
    if (!wrapperData) throw new Error("Invalid mints and decimals");

    const userUnderlyingTokens = getAta(underlyingMint, userPublicKey);
    const userWrappedTokens = getAta(wrappedMint, userPublicKey);

    const tx = new Transaction();
    if (!(await this.conn.getAccountInfo(userUnderlyingTokens))) {
      tx.add(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: underlyingMint,
          associatedAccount: userUnderlyingTokens,
          owner: userPublicKey,
          payer: userPublicKey,
        })
      );
    }
    tx.add(
      await this.program.methods
        .unwrapDecimalsToken(new BN(originAmount.toString()))
        .accounts({
          authority: userPublicKey,
          ataUserUnderlyingToken: userUnderlyingTokens,
          ataUserWrappedToken: userWrappedTokens,
          wrapper: wrapperKey,
          wrapperMint: wrappedMint,
          underlyingMint: underlyingMint,
          wrapperUnderlyingTokens: wrapperData.wrapperUnderlyingTokens,
          saberDecimalsProgram: new PublicKey(SaberProgramIds.AddDecimals),
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    return tx;
  }

  async getWrapTransaction(
    userPublicKey: PublicKey,
    wrappedMint: PublicKey,
    underlyingMint: PublicKey,
    wrappedDecimals: number,
    underlyingDecimals: number,
    originAmount: JSBI
  ): Promise<Transaction> {
    const [wrapperKey, nonce] = getPda(
      [
        Buffer.from("anchor"),
        underlyingMint.toBuffer(),
        Buffer.from([wrappedDecimals]),
      ],
      SaberProgramIds.AddDecimals
    );

    const decimalsProgram = SaberDecimalsProgram.getInstance(
      this.conn,
      userPublicKey
    );

    console.log("wrapperKey =", wrapperKey.toBase58());
    const wrapperData = await decimalsProgram.getWrapperData(wrapperKey);
    if (!wrapperData) throw new Error("Invalid mints and decimals");

    const userUnderlyingTokens = getAta(underlyingMint, userPublicKey);
    const userWrappedTokens = getAta(wrappedMint, userPublicKey);

    const tx = new Transaction();
    if (!(await this.conn.getAccountInfo(userWrappedTokens))) {
      tx.add(
        Spl.makeCreateAssociatedTokenAccountInstruction({
          mint: wrappedMint,
          associatedAccount: userWrappedTokens,
          owner: userPublicKey,
          payer: userPublicKey,
        })
      );
    }
    tx.add(
      await this.program.methods
        .wrapDecimalsToken(new BN(originAmount.toString()))
        .accounts({
          authority: userPublicKey,
          ataUserUnderlyingToken: userUnderlyingTokens,
          ataUserWrappedToken: userWrappedTokens,
          wrapper: wrapperKey,
          wrapperMint: wrappedMint,
          underlyingMint: underlyingMint,
          wrapperUnderlyingTokens: wrapperData.wrapperUnderlyingTokens,
          saberDecimalsProgram: new PublicKey(SaberProgramIds.AddDecimals),
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .instruction()
    );
    return tx;
  }
}
