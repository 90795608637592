import {
  Cluster,
  Connection,
  PublicKey,
  SystemInstruction,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { Jupiter, RouteInfo } from "@jup-ag/core";
import { TokenListProvider, TokenInfo } from "@solana/spl-token-registry";
import JSBI from "jsbi";
import BigNumber from "bignumber.js";

import { LpTokenInfo } from "./utils/types";
import { getAta, getTokenBalance, simulateTransaction } from "./utils/web3";
import { toJSBI } from "./utils/math-utils";
import { LiquidityManagerFactory } from "./manage/LiquidityManagerFactory";
import { RATIO_MINT } from "./utils";
import { getAvailableLPs } from "./api";
import { BorshEventCoder } from "@project-serum/anchor";
import { IDL as SdkIDL } from "./programs/ratio/idls/ratio-sdk-idl";
import { RatioSdkProgram } from "./programs/ratio/ratio-sdk";
import { RatioLendingProgram } from "./programs/ratio/ratio-lending";
import { NATIVE_MINT } from "@solana/spl-token";
import {
  createSyncNativeInstruction,
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
} from "@solana/spl-token-v2";

export class InstaSwap {
  cluster: Cluster = "mainnet-beta";
  connection: Connection;
  jupiter!: Jupiter;
  lpTokens: Map<string, LpTokenInfo> = new Map();
  inputTokens: Map<string, TokenInfo> = new Map();
  availableTokenMints: Array<string> = [];
  availableLpMints: Array<string> = [];

  constructor(con: Connection) {
    this.connection = con;
  }

  async load() {
    this.jupiter = await Jupiter.load({
      connection: this.connection,
      cluster: "mainnet-beta",
      routeCacheDuration: 10000,
      wrapUnwrapSOL: false,
    });
    this.lpTokens = await getAvailableLPs();
    this.inputTokens = await this.getAvailableInputTokens();
    this.lpTokens.forEach((v, k) => this.availableLpMints.push(k));
    this.inputTokens.forEach((v, k) => this.availableTokenMints.push(k));
  }

  async getBuyTransactions(
    userPublicKey: PublicKey,
    inputToken: TokenInfo | string,
    inputAmount: number,
    lpToken: LpTokenInfo | string,
    slippage: number
  ): Promise<{
    jupiterTransactions: Array<Transaction>;
    platformTransactions: Array<Transaction>;
    allTransactions: Array<Transaction>;
  } | null> {
    const lpInfo = await this.getLpInfo(lpToken);
    const singleTokenInfo = await this.getTokenInfo(inputToken);
    const manager = InstaSwap.getLiquidityManager(lpInfo);
    const liquidityPairRatio = await manager.getLiquidityPairRatio(lpInfo);
    const _inputAmount = toJSBI(inputAmount, singleTokenInfo.decimals);
    const amountA = new BigNumber(_inputAmount.toString()).multipliedBy(
      liquidityPairRatio[0]
    );
    const amountB = new BigNumber(_inputAmount.toString()).multipliedBy(
      liquidityPairRatio[1]
    );

    const mints = this.getUnderlyingMints(lpInfo);

    const ratioProgram = RatioSdkProgram.getInstance(this.connection);
    const wrapInstructions: TransactionInstruction[] = [];

    const [tokenA, tokenB] = await Promise.all(
      mints.map(async (mint) => {
        if (RatioSdkProgram.isSaberWrapped(mint)) {
          const token = this.getTokenInfo(
            RatioSdkProgram.getWrappedUnderlying(mint)
          );
          let originAmount = await getTokenBalance(
            this.connection,
            userPublicKey,
            token.address
          );
          if (singleTokenInfo.address === token.address) {
            originAmount = JSBI.GE(originAmount, _inputAmount)
              ? JSBI.subtract(originAmount, _inputAmount)
              : toJSBI(0);
          }
          const tx = await ratioProgram.makeWrapTx(
            userPublicKey,
            token.address,
            originAmount
          );
          if (tx) wrapInstructions.push(...tx.instructions);
          return token;
        } else return this.getTokenInfo(mint);
      })
    );

    const { outputAmounts, bestRoutes } =
      await this.instaBuyGetJupiterOutAmounts(
        singleTokenInfo,
        [tokenA, tokenB],
        [toJSBI(amountA), toJSBI(amountB)],
        slippage
      );

    if (
      outputAmounts[0] === JSBI.BigInt(0) ||
      outputAmounts[1] === JSBI.BigInt(0)
    ) {
      console.log("Jupiter error. outputAmount is zero");
      return null;
    }

    const jupTransactions: Transaction[] = [];
    // if inputToken is NATIVE_MINT, we need to wrap SOL to wSOL
    if (singleTokenInfo.address === NATIVE_MINT.toBase58()) {
      const wSolAta = getAta(NATIVE_MINT, userPublicKey);
      const wrapTx = new Transaction();
      // create ata of WSOL if it doesn't exist
      if (!(await this.connection.getAccountInfo(wSolAta))) {
        wrapTx.add(
          createAssociatedTokenAccountInstruction(
            userPublicKey,
            wSolAta,
            userPublicKey,
            NATIVE_MINT
          )
        );
      }
      wrapTx.add(
        SystemProgram.transfer({
          fromPubkey: userPublicKey,
          toPubkey: wSolAta,
          lamports: parseInt(_inputAmount.toString()),
        }),
        // sync wrapped SOL balance
        createSyncNativeInstruction(wSolAta)
      );
      jupTransactions.push(wrapTx);
    }
    for (const routeInfo of bestRoutes) {
      if (routeInfo) {
        const { transactions } = await this.jupiter.exchange({
          routeInfo,
          userPublicKey,
          wrapUnwrapSOL: false,
        });
        if (transactions.setupTransaction)
          jupTransactions.push(transactions.setupTransaction);
        if (transactions.swapTransaction)
          jupTransactions.push(transactions.swapTransaction);
        if (transactions.cleanupTransaction)
          jupTransactions.push(transactions.cleanupTransaction);
      }
    }

    let originalAmountA = await getTokenBalance(
      this.connection,
      userPublicKey,
      mints[0]
    );
    let originalAmountB = await getTokenBalance(
      this.connection,
      userPublicKey,
      mints[1]
    );

    if (singleTokenInfo.address === mints[0]) {
      originalAmountA = JSBI.GE(originalAmountA, _inputAmount)
        ? JSBI.subtract(originalAmountA, _inputAmount)
        : toJSBI(0);
    } else if (singleTokenInfo.address === mints[1]) {
      originalAmountB = JSBI.GE(originalAmountB, _inputAmount)
        ? JSBI.subtract(originalAmountB, _inputAmount)
        : toJSBI(0);
    }

    const liquidityTxn = await manager.addLiquidityTransaction(
      this.connection,
      userPublicKey,
      lpInfo,
      [originalAmountA, originalAmountB],
      outputAmounts
    );

    let platformTransactions: Transaction[] = [liquidityTxn];
    if (wrapInstructions.length > 0) {
      const tx = new Transaction().add(...wrapInstructions);
      if (liquidityTxn.instructions.length > 0)
        tx.add(...liquidityTxn.instructions);
      platformTransactions = [tx];
    }

    return {
      jupiterTransactions: jupTransactions,
      platformTransactions,
      allTransactions: jupTransactions.concat(platformTransactions),
    };
  }

  async getSellTransactions(
    userPublicKey: PublicKey,
    inputLpToken: LpTokenInfo | string,
    inputAmount: number,
    outputToken: TokenInfo | string,
    slippage: number
  ): Promise<{
    jupiterTransactions: Array<Transaction>;
    platformTransactions: Array<Transaction>;
    allTransactions: Array<Transaction>;
  } | null> {
    const inputLpInfo = await this.getLpInfo(inputLpToken);
    const outputTokenInfo = this.getTokenInfo(outputToken);
    const manager = InstaSwap.getLiquidityManager(inputLpInfo);
    const _inputAmount = toJSBI(inputAmount, inputLpInfo.decimals);
    console.log("inputAmount =", _inputAmount.toString());
    const liquidityTxn = await manager.removeLiquidityTransaction(
      this.connection,
      userPublicKey,
      inputLpInfo,
      _inputAmount
    );

    const resultSimTx = (await simulateTransaction(
      this.connection,
      liquidityTxn,
      userPublicKey
    ))!;
    if (resultSimTx.value.err !== null) {
      console.log("Unwinding Transaction Simulation Failed");
      console.log(resultSimTx.value.err);
      return null;
    }
    // Parse Simulated Transaction Log and decode event data
    const instaswapReverseOutputEvent = new BorshEventCoder(SdkIDL).decode(
      resultSimTx.value.logs!.at(-3)!.substring(14)
    );
    const amountA = toJSBI(
      (instaswapReverseOutputEvent!.data as any).outputAAmount.toNumber()
    );
    const amountB = toJSBI(
      (instaswapReverseOutputEvent!.data as any).outputBAmount.toNumber()
    );

    console.log("amountA =", amountA.toString());
    console.log("amountB =", amountB.toString());

    const mints = this.getUnderlyingMints(inputLpInfo);
    const ratioProgram = RatioSdkProgram.getInstance(this.connection);
    const unwrapInstructions: TransactionInstruction[] = [];
    const [tokenA, tokenB] = await Promise.all(
      mints.map(async (mint) => {
        if (RatioSdkProgram.isSaberWrapped(mint)) {
          // mint is wrappedMint
          const token = this.getTokenInfo(
            RatioSdkProgram.getWrappedUnderlying(mint)
          );
          const wrappedOriginalAmount = await getTokenBalance(
            this.connection,
            userPublicKey,
            mint
          );
          const tx = await ratioProgram.makeUnwrapTx(
            userPublicKey,
            mint,
            wrappedOriginalAmount
          );
          if (tx) unwrapInstructions.push(...tx.instructions);
          return token;
        } else return this.getTokenInfo(mint);
      })
    );

    if (unwrapInstructions.length > 0) {
      liquidityTxn.add(...unwrapInstructions);
    }

    const { outputAmount, bestRoutes } =
      await this.instaSellGetJupiterOutAmounts(
        [tokenA, tokenB],
        [amountA, amountB],
        outputTokenInfo,
        slippage
      );
    if (outputAmount === JSBI.BigInt(0)) {
      console.log("Jupiter error. outputAmount is zero");
      return null;
    }

    // if liquidityTxn involves SOL token, it will create WSolAta if doesn't exist
    let isWsolAtaExists = false;
    if (
      tokenA.address === NATIVE_MINT.toBase58() ||
      tokenB.address === NATIVE_MINT.toBase58()
    ) {
      // to avoid wSolAta error, I need to close
      const wSolAta = getAta(NATIVE_MINT, userPublicKey);
      // create ata of WSOL if it doesn't exist, it will be created via liqudityTxn.
      // but the jupiter doesn't know this so they will create again.
      // to avoid this, we need to close wsolAta after liquidityTxn
      if (!(await this.connection.getAccountInfo(wSolAta))) {
        liquidityTxn.add(
          createCloseAccountInstruction(wSolAta, userPublicKey, userPublicKey)
        );
      } else isWsolAtaExists = true;
    }

    const jupTransactions: Transaction[] = [];

    for (let i = 0; i < bestRoutes.length; i++) {
      const routeInfo = bestRoutes[i];
      if (routeInfo) {
        let wrapUnwrapSOL = true;
        // if wSolAta exits, we should prevent closing wSolAta in first jupiter swap
        // if it is closed, the second jupiter swap will fail because it doesn't know the wSolAta is closed.
        if (
          outputTokenInfo.address === NATIVE_MINT.toBase58() &&
          i === 0 &&
          isWsolAtaExists
        ) {
          wrapUnwrapSOL = false;
        }
        const { transactions } = await this.jupiter.exchange({
          routeInfo,
          userPublicKey: userPublicKey,
          wrapUnwrapSOL,
        });
        if (transactions.setupTransaction)
          jupTransactions.push(transactions.setupTransaction);
        if (transactions.swapTransaction)
          jupTransactions.push(transactions.swapTransaction);
        if (transactions.cleanupTransaction)
          jupTransactions.push(transactions.cleanupTransaction);
      }
    }

    return {
      jupiterTransactions: jupTransactions,
      platformTransactions: [liquidityTxn],
      allTransactions: [liquidityTxn].concat(jupTransactions),
    };
  }

  async getLpOutUiAmount(
    inputToken: TokenInfo | string,
    inputAmount: number,
    lpToken: LpTokenInfo | string,
    slippage: number
  ) {
    if (inputAmount === 0) return 0;
    const lpInfo = await this.getLpInfo(lpToken);
    const singleTokenInfo = await this.getTokenInfo(inputToken);
    const _inputAmount = toJSBI(inputAmount, singleTokenInfo.decimals);
    const manager = InstaSwap.getLiquidityManager(lpInfo);

    const liquidityPairRatio = await manager.getLiquidityPairRatio(lpInfo);
    const amountA = new BigNumber(_inputAmount.toString()).multipliedBy(
      liquidityPairRatio[0]
    );
    const amountB = new BigNumber(_inputAmount.toString()).multipliedBy(
      liquidityPairRatio[1]
    );

    const mints = this.getUnderlyingMints(lpInfo);
    const tokenA = this.getTokenInfo(mints[0]);
    const tokenB = this.getTokenInfo(mints[1]);

    const { outputAmounts } = await this.instaBuyGetJupiterOutAmounts(
      inputToken,
      [tokenA, tokenB],
      [toJSBI(amountA), toJSBI(amountB)],
      slippage
    );
    const lpOutAmount = await manager.getEstimatedOutputLPAmount(
      this.connection,
      lpInfo,
      outputAmounts
    );
    const uiAmount = new BigNumber(lpOutAmount.toString())
      .dividedBy(10 ** lpInfo.decimals)
      .toNumber();
    return uiAmount;
  }

  async getWithdrawOutUiAmount(
    inputLpToken: LpTokenInfo | string,
    inputAmount: number,
    outputToken: TokenInfo | string,
    slippage: number
  ) {
    if (inputAmount === 0) return 0;
    const inputLpInfo = await this.getLpInfo(inputLpToken);
    const tokenA = this.getTokenInfo(inputLpInfo.underlyings[0].mint);
    const tokenB = this.getTokenInfo(inputLpInfo.underlyings[1].mint);
    const outputTokenInfo = this.getTokenInfo(outputToken);
    const manager = InstaSwap.getLiquidityManager(inputLpInfo);
    const _inputAmount = toJSBI(inputAmount, inputLpInfo.decimals);

    // Estimate how much underlying tokens would be withdrawn
    const withdrawAmounts = await manager.getEstimatedWithdrawAmount(
      this.connection,
      inputLpInfo,
      _inputAmount
    );

    // Estimate how much output Token would be returned
    const { outputAmount } = await this.instaSellGetJupiterOutAmounts(
      [tokenA, tokenB],
      withdrawAmounts,
      outputTokenInfo,
      slippage
    );

    //Cut fees from estimated output
    const program = RatioLendingProgram.getInstance(this.connection);
    const stateInfo = await program.getState();
    const instaswapFeeMultiplier =
      stateInfo.instaswapFeeNumer.toNumber() / stateInfo.feeDeno.toNumber();
    const instaswapFees = toJSBI(
      new BigNumber(outputAmount.toString().toString()).multipliedBy(
        instaswapFeeMultiplier
      )
    );
    const finalAmount = JSBI.subtract(outputAmount, instaswapFees);

    return new BigNumber(finalAmount.toString().toString())
      .dividedBy(10 ** outputTokenInfo.decimals)
      .toNumber();
  }

  async instaBuyGetJupiterOutAmounts(
    inputToken: TokenInfo | string,
    outputTokens: Array<TokenInfo>,
    swapAmounts: Array<JSBI>,
    slippage: number
  ): Promise<{ outputAmounts: Array<JSBI>; bestRoutes: any }> {
    const outputAmounts = [JSBI.BigInt(0), JSBI.BigInt(0)];
    const bestRoutes: any = [null, null];
    const singleTokenInfo = this.getTokenInfo(inputToken);
    if (this.jupiter) {
      await Promise.all(
        outputTokens.map(async (outToken, i) => {
          if (singleTokenInfo.address === outToken.address) {
            // in this case, don't need to swap
            outputAmounts[i] = swapAmounts[i];
          } else {
            const routes = await this.jupiter.computeRoutes({
              inputMint: new PublicKey(singleTokenInfo.address),
              outputMint: new PublicKey(outToken.address),
              amount: swapAmounts[i],
              slippage,
            });
            if (routes.routesInfos[0]) {
              outputAmounts[i] = routes.routesInfos[0].outAmount;
              bestRoutes[i] = routes.routesInfos[0];
            }
          }
        })
      );
    }
    return { outputAmounts, bestRoutes };
  }

  async instaSellGetJupiterOutAmounts(
    inputTokens: Array<TokenInfo>,
    withdrawnAmounts: Array<JSBI>,
    outputToken: TokenInfo,
    slippage: number
  ): Promise<{ outputAmount: JSBI; bestRoutes: Array<RouteInfo | null> }> {
    let outputAmount = JSBI.BigInt(0);
    const bestRoutes: Array<RouteInfo | null> = [null];

    for (const [i, inputToken] of inputTokens.entries()) {
      // if either of the input token mint is output mint, skip swapping, just add it to output amount
      if (inputToken === outputToken) {
        outputAmount = JSBI.add(outputAmount, withdrawnAmounts[i]);
      } else {
        const routes = await this.jupiter.computeRoutes({
          inputMint: new PublicKey(inputToken.address),
          outputMint: new PublicKey(outputToken.address),
          amount: withdrawnAmounts[i],
          slippage,
        });
        if (routes.routesInfos[0]) {
          outputAmount = JSBI.add(
            outputAmount,
            routes.routesInfos[0].outAmount
          );
          bestRoutes[i] = routes.routesInfos[0];
        }
      }
    }

    return { outputAmount, bestRoutes };
  }

  async getAvailableInputTokens() {
    const tokens = await new TokenListProvider().resolve();
    const tokenList = tokens.filterByChainId(101).getList();
    const routeMap = this.jupiter.getRouteMap();
    const tokenMap: Map<string, TokenInfo> = tokenList.reduce((map, item) => {
      if (item.address !== RATIO_MINT && routeMap.has(item.address)) {
        map.set(item.address, item);
      }
      return map;
    }, new Map());
    return tokenMap;
  }

  async getLpInfo(lpToken: LpTokenInfo | string): Promise<LpTokenInfo> {
    const lpAddr = typeof lpToken === "string" ? lpToken : lpToken.address;
    const lpInfo = this.lpTokens.get(lpAddr);
    if (!lpInfo) {
      throw new Error("Invalid Lp Info or address");
    }
    if (!lpInfo.swapInfo) {
      const swapInfo = await this.loadPoolSwapInfo(lpInfo);
      lpInfo.swapInfo = swapInfo;
      this.lpTokens.set(lpAddr, lpInfo);
    }
    return lpInfo;
  }

  getTokenInfo(token: TokenInfo | string): TokenInfo {
    const tokenAddr = typeof token === "string" ? token : token.address;
    const tokenInfo = this.inputTokens.get(tokenAddr);
    if (!tokenInfo) {
      throw new Error("Invalid TokenInfo or address");
    }
    return tokenInfo;
  }

  async loadPoolSwapInfo(lpToken: LpTokenInfo): Promise<LpTokenInfo> {
    const manager = InstaSwap.getLiquidityManager(lpToken);
    const info = await manager.loadSwapInfo(this.connection, lpToken);
    return info;
  }

  static getLiquidityManager(lpToken: LpTokenInfo) {
    return LiquidityManagerFactory.getLiquidityManager().get(lpToken.platform);
  }

  getUnderlyingMints(lpToken: LpTokenInfo): string[] {
    return InstaSwap.getLiquidityManager(lpToken).getUnderlyingMints(lpToken);
  }
}
