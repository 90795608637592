import { generateErrorMap } from "@saberhq/anchor-contrib";

export type LockupIDL = {
  version: "1.1.2";
  name: "lockup";
  instructions: [
    {
      name: "availableForWithdrawal";
      accounts: [
        {
          name: "release";
          isMut: false;
          isSigner: false;
        },
        {
          name: "clock";
          isMut: false;
          isSigner: false;
        }
      ];
      args: [];
    }
  ];
  state: {
    struct: {
      name: "Lockup";
      type: {
        kind: "struct";
        fields: [
          {
            name: "owner";
            type: "publicKey";
          },
          {
            name: "pendingOwner";
            type: "publicKey";
          }
        ];
      };
    };
    methods: [
      {
        name: "new";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "mintProxyState";
            isMut: false;
            isSigner: false;
          },
          {
            name: "mintProxyProgram";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "createRelease";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "minterInfo";
            isMut: false;
            isSigner: false;
          },
          {
            name: "beneficiary";
            isMut: false;
            isSigner: false;
          },
          {
            name: "release";
            isMut: true;
            isSigner: false;
          },
          {
            name: "mint";
            isMut: false;
            isSigner: false;
          },
          {
            name: "mintProxyProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "payer";
            isMut: true;
            isSigner: true;
          },
          {
            name: "systemProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "rent";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "releaseAmount";
            type: "u64";
          },
          {
            name: "startTs";
            type: "i64";
          },
          {
            name: "endTs";
            type: "i64";
          }
        ];
      },
      {
        name: "revokeRelease";
        accounts: [
          {
            name: "auth";
            accounts: [
              {
                name: "owner";
                isMut: false;
                isSigner: true;
              }
            ];
          },
          {
            name: "release";
            isMut: true;
            isSigner: false;
          },
          {
            name: "payer";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "transferOwnership";
        accounts: [
          {
            name: "owner";
            isMut: false;
            isSigner: true;
          }
        ];
        args: [
          {
            name: "nextOwner";
            type: "publicKey";
          }
        ];
      },
      {
        name: "acceptOwnership";
        accounts: [
          {
            name: "owner";
            isMut: false;
            isSigner: true;
          }
        ];
        args: [];
      },
      {
        name: "withdraw";
        accounts: [
          {
            name: "proxyMintAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "tokenMint";
            isMut: true;
            isSigner: false;
          },
          {
            name: "beneficiary";
            isMut: false;
            isSigner: true;
          },
          {
            name: "release";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenAccount";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "unusedClock";
            isMut: false;
            isSigner: false;
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          },
          {
            name: "mintProxyProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "mintProxyState";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [];
      },
      {
        name: "withdrawWithAmount";
        accounts: [
          {
            name: "proxyMintAuthority";
            isMut: false;
            isSigner: false;
          },
          {
            name: "tokenMint";
            isMut: true;
            isSigner: false;
          },
          {
            name: "beneficiary";
            isMut: false;
            isSigner: true;
          },
          {
            name: "release";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenAccount";
            isMut: true;
            isSigner: false;
          },
          {
            name: "tokenProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "unusedClock";
            isMut: false;
            isSigner: false;
          },
          {
            name: "minterInfo";
            isMut: true;
            isSigner: false;
          },
          {
            name: "mintProxyProgram";
            isMut: false;
            isSigner: false;
          },
          {
            name: "mintProxyState";
            isMut: false;
            isSigner: false;
          }
        ];
        args: [
          {
            name: "amount";
            type: "u64";
          }
        ];
      }
    ];
  };
  accounts: [
    {
      name: "Release";
      type: {
        kind: "struct";
        fields: [
          {
            name: "beneficiary";
            type: "publicKey";
          },
          {
            name: "mint";
            type: "publicKey";
          },
          {
            name: "mintProxyProgram";
            type: "publicKey";
          },
          {
            name: "minterInfo";
            type: "publicKey";
          },
          {
            name: "outstanding";
            type: "u64";
          },
          {
            name: "startBalance";
            type: "u64";
          },
          {
            name: "createdTs";
            type: "i64";
          },
          {
            name: "startTs";
            type: "i64";
          },
          {
            name: "endTs";
            type: "i64";
          },
          {
            name: "nonce";
            type: "u8";
          }
        ];
      };
    }
  ];
  events: [
    {
      name: "ReleaseCreatedEvent";
      fields: [
        {
          name: "beneficiary";
          type: "publicKey";
          index: true;
        },
        {
          name: "mint";
          type: "publicKey";
          index: true;
        },
        {
          name: "releaseAmount";
          type: "u64";
          index: false;
        },
        {
          name: "createdAt";
          type: "i64";
          index: false;
        },
        {
          name: "startAt";
          type: "i64";
          index: false;
        },
        {
          name: "endAt";
          type: "i64";
          index: false;
        }
      ];
    },
    {
      name: "WithdrawEvent";
      fields: [
        {
          name: "beneficiary";
          type: "publicKey";
          index: true;
        },
        {
          name: "mint";
          type: "publicKey";
          index: true;
        },
        {
          name: "outstandingAmount";
          type: "u64";
          index: false;
        },
        {
          name: "withdrawAmount";
          type: "u64";
          index: false;
        },
        {
          name: "timestamp";
          type: "i64";
          index: false;
        }
      ];
    }
  ];
  errors: [
    {
      code: 6000;
      name: "InvalidBeneficiary";
      msg: "The provided beneficiary was not valid.";
    },
    {
      code: 6001;
      name: "InvalidDepositAmount";
      msg: "The release deposit amount must be greater than zero.";
    },
    {
      code: 6002;
      name: "InvalidProgramAddress";
      msg: "The Whitelist entry is not a valid program address.";
    },
    {
      code: 6003;
      name: "InvalidSchedule";
      msg: "Invalid release schedule given.";
    },
    {
      code: 6004;
      name: "InvalidTokenMint";
      msg: "The provided token mint did not match the mint on the release account.";
    },
    {
      code: 6005;
      name: "InsufficientWithdrawalBalance";
      msg: "Insufficient withdrawal balance.";
    },
    {
      code: 6006;
      name: "Unauthorized";
      msg: "Unauthorized access.";
    },
    {
      code: 6007;
      name: "PendingOwnerMismatch";
      msg: "Pending owner mismatch.";
    },
    {
      code: 6008;
      name: "InvalidMintProxyProgram";
      msg: "The mint proxy program provided was not valid.";
    },
    {
      code: 6009;
      name: "MinterUnauthorized";
      msg: "The Release must be an authorized minter on the mint proxy.";
    },
    {
      code: 6010;
      name: "MinterInfoProgramMismatch";
      msg: "The minter info is not owned by the expected mint proxy.";
    },
    {
      code: 6011;
      name: "MinterAllowanceTooLow";
      msg: "The minter must have an allowance of at least the release amount.";
    },
    {
      code: 6012;
      name: "MinterInfoMismatch";
      msg: "Minter info mismatch";
    },
    {
      code: 6013;
      name: "ReleaseMismatch";
      msg: "Release mismatch";
    },
    {
      code: 6014;
      name: "ProxyMintAuthorityMismatch";
      msg: "Proxy mint authority mismatch";
    },
    {
      code: 6015;
      name: "MintProxyMintMismatch";
      msg: "Mint proxy mint mismatch";
    },
    {
      code: 6016;
      name: "DestinationMintMismatch";
      msg: "Withdraw destination mint mismatch";
    },
    {
      code: 6017;
      name: "TokenProgramMismatch";
      msg: "Token program mismatch";
    },
    {
      code: 6018;
      name: "ReleaseAlreadyRedeemedFrom";
      msg: "Release already redeemed from";
    },
    {
      code: 6019;
      name: "U64Overflow";
      msg: "U64 overflow.";
    }
  ];
};
export const LockupJSON: LockupIDL = {
  version: "1.1.2",
  name: "lockup",
  instructions: [
    {
      name: "availableForWithdrawal",
      accounts: [
        {
          name: "release",
          isMut: false,
          isSigner: false,
        },
        {
          name: "clock",
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
  ],
  state: {
    struct: {
      name: "Lockup",
      type: {
        kind: "struct",
        fields: [
          {
            name: "owner",
            type: "publicKey",
          },
          {
            name: "pendingOwner",
            type: "publicKey",
          },
        ],
      },
    },
    methods: [
      {
        name: "new",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "mintProxyState",
            isMut: false,
            isSigner: false,
          },
          {
            name: "mintProxyProgram",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "createRelease",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "minterInfo",
            isMut: false,
            isSigner: false,
          },
          {
            name: "beneficiary",
            isMut: false,
            isSigner: false,
          },
          {
            name: "release",
            isMut: true,
            isSigner: false,
          },
          {
            name: "mint",
            isMut: false,
            isSigner: false,
          },
          {
            name: "mintProxyProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "payer",
            isMut: true,
            isSigner: true,
          },
          {
            name: "systemProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "rent",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "releaseAmount",
            type: "u64",
          },
          {
            name: "startTs",
            type: "i64",
          },
          {
            name: "endTs",
            type: "i64",
          },
        ],
      },
      {
        name: "revokeRelease",
        accounts: [
          {
            name: "auth",
            accounts: [
              {
                name: "owner",
                isMut: false,
                isSigner: true,
              },
            ],
          },
          {
            name: "release",
            isMut: true,
            isSigner: false,
          },
          {
            name: "payer",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "transferOwnership",
        accounts: [
          {
            name: "owner",
            isMut: false,
            isSigner: true,
          },
        ],
        args: [
          {
            name: "nextOwner",
            type: "publicKey",
          },
        ],
      },
      {
        name: "acceptOwnership",
        accounts: [
          {
            name: "owner",
            isMut: false,
            isSigner: true,
          },
        ],
        args: [],
      },
      {
        name: "withdraw",
        accounts: [
          {
            name: "proxyMintAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "tokenMint",
            isMut: true,
            isSigner: false,
          },
          {
            name: "beneficiary",
            isMut: false,
            isSigner: true,
          },
          {
            name: "release",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenAccount",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "unusedClock",
            isMut: false,
            isSigner: false,
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
          {
            name: "mintProxyProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "mintProxyState",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [],
      },
      {
        name: "withdrawWithAmount",
        accounts: [
          {
            name: "proxyMintAuthority",
            isMut: false,
            isSigner: false,
          },
          {
            name: "tokenMint",
            isMut: true,
            isSigner: false,
          },
          {
            name: "beneficiary",
            isMut: false,
            isSigner: true,
          },
          {
            name: "release",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenAccount",
            isMut: true,
            isSigner: false,
          },
          {
            name: "tokenProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "unusedClock",
            isMut: false,
            isSigner: false,
          },
          {
            name: "minterInfo",
            isMut: true,
            isSigner: false,
          },
          {
            name: "mintProxyProgram",
            isMut: false,
            isSigner: false,
          },
          {
            name: "mintProxyState",
            isMut: false,
            isSigner: false,
          },
        ],
        args: [
          {
            name: "amount",
            type: "u64",
          },
        ],
      },
    ],
  },
  accounts: [
    {
      name: "Release",
      type: {
        kind: "struct",
        fields: [
          {
            name: "beneficiary",
            type: "publicKey",
          },
          {
            name: "mint",
            type: "publicKey",
          },
          {
            name: "mintProxyProgram",
            type: "publicKey",
          },
          {
            name: "minterInfo",
            type: "publicKey",
          },
          {
            name: "outstanding",
            type: "u64",
          },
          {
            name: "startBalance",
            type: "u64",
          },
          {
            name: "createdTs",
            type: "i64",
          },
          {
            name: "startTs",
            type: "i64",
          },
          {
            name: "endTs",
            type: "i64",
          },
          {
            name: "nonce",
            type: "u8",
          },
        ],
      },
    },
  ],
  events: [
    {
      name: "ReleaseCreatedEvent",
      fields: [
        {
          name: "beneficiary",
          type: "publicKey",
          index: true,
        },
        {
          name: "mint",
          type: "publicKey",
          index: true,
        },
        {
          name: "releaseAmount",
          type: "u64",
          index: false,
        },
        {
          name: "createdAt",
          type: "i64",
          index: false,
        },
        {
          name: "startAt",
          type: "i64",
          index: false,
        },
        {
          name: "endAt",
          type: "i64",
          index: false,
        },
      ],
    },
    {
      name: "WithdrawEvent",
      fields: [
        {
          name: "beneficiary",
          type: "publicKey",
          index: true,
        },
        {
          name: "mint",
          type: "publicKey",
          index: true,
        },
        {
          name: "outstandingAmount",
          type: "u64",
          index: false,
        },
        {
          name: "withdrawAmount",
          type: "u64",
          index: false,
        },
        {
          name: "timestamp",
          type: "i64",
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: "InvalidBeneficiary",
      msg: "The provided beneficiary was not valid.",
    },
    {
      code: 6001,
      name: "InvalidDepositAmount",
      msg: "The release deposit amount must be greater than zero.",
    },
    {
      code: 6002,
      name: "InvalidProgramAddress",
      msg: "The Whitelist entry is not a valid program address.",
    },
    {
      code: 6003,
      name: "InvalidSchedule",
      msg: "Invalid release schedule given.",
    },
    {
      code: 6004,
      name: "InvalidTokenMint",
      msg: "The provided token mint did not match the mint on the release account.",
    },
    {
      code: 6005,
      name: "InsufficientWithdrawalBalance",
      msg: "Insufficient withdrawal balance.",
    },
    {
      code: 6006,
      name: "Unauthorized",
      msg: "Unauthorized access.",
    },
    {
      code: 6007,
      name: "PendingOwnerMismatch",
      msg: "Pending owner mismatch.",
    },
    {
      code: 6008,
      name: "InvalidMintProxyProgram",
      msg: "The mint proxy program provided was not valid.",
    },
    {
      code: 6009,
      name: "MinterUnauthorized",
      msg: "The Release must be an authorized minter on the mint proxy.",
    },
    {
      code: 6010,
      name: "MinterInfoProgramMismatch",
      msg: "The minter info is not owned by the expected mint proxy.",
    },
    {
      code: 6011,
      name: "MinterAllowanceTooLow",
      msg: "The minter must have an allowance of at least the release amount.",
    },
    {
      code: 6012,
      name: "MinterInfoMismatch",
      msg: "Minter info mismatch",
    },
    {
      code: 6013,
      name: "ReleaseMismatch",
      msg: "Release mismatch",
    },
    {
      code: 6014,
      name: "ProxyMintAuthorityMismatch",
      msg: "Proxy mint authority mismatch",
    },
    {
      code: 6015,
      name: "MintProxyMintMismatch",
      msg: "Mint proxy mint mismatch",
    },
    {
      code: 6016,
      name: "DestinationMintMismatch",
      msg: "Withdraw destination mint mismatch",
    },
    {
      code: 6017,
      name: "TokenProgramMismatch",
      msg: "Token program mismatch",
    },
    {
      code: 6018,
      name: "ReleaseAlreadyRedeemedFrom",
      msg: "Release already redeemed from",
    },
    {
      code: 6019,
      name: "U64Overflow",
      msg: "U64 overflow.",
    },
  ],
};
export const LockupErrors = generateErrorMap(LockupJSON);
