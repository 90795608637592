import fetch from "cross-fetch";

import { JsonLpInfo, LpTokenInfo } from "../utils/types";
import { RATIO_API_ENDPOINT } from "../constants";

const PLATFORM_IDS: Record<string, string> = {
  "2": "RAYDIUM",
  "3": "SABER",
};

export const getAvailableLPs = async () => {
  const res = await fetch(`${RATIO_API_ENDPOINT}/lpswapairs`);
  const pairs: JsonLpInfo[] = await res.json();
  const lpTokens: Map<string, LpTokenInfo> = new Map();
  for (const pair of pairs) {
    lpTokens.set(pair.address_id, {
      address: pair.address_id,
      decimals: pair.decimals,
      name: pair.name,
      swapAccount: pair.swap_account,
      platform: PLATFORM_IDS[pair.platform],
      underlyings: [
        { mint: pair.tokenA, decimals: pair.decimalsA },
        { mint: pair.tokenB, decimals: pair.decimalsB },
      ],
    });
  }
  return lpTokens;
};
