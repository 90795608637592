import { struct, u8, blob } from '@solana/buffer-layout';
import { u64 } from '@solana/spl-token';
import { AccountInfo, PublicKey } from '@solana/web3.js';
import { STEP_TOKEN_SWAP_PROGRAM_ID } from '../../constants';
import { publicKey, uint64 } from '../../utils/layout';
import { createProgramAddressSyncUnsafe } from '../../utils/pda';

interface TokenSwapLayout {
  version: number;
  isInitialized: number;
  bumpSeed: number;
  tokenProgramId: PublicKey;
  tokenAccountA: PublicKey;
  tokenAccountB: PublicKey;
  tokenPool: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
  feeAccount: PublicKey;
  tradeFeeNumerator: u64;
  tradeFeeDenominator: u64;
  ownerTradeFeeNumerator: u64;
  ownerTradeFeeDenominator: u64;
  ownerWithdrawFeeNumerator: u64;
  ownerWithdrawFeeDenominator: u64;
  hostFeeNumerator: u64;
  hostFeeDenominator: u64;
  curveType: number;
  curveParameters: Uint8Array;
}

const TokenSwapLayout = struct<TokenSwapLayout>([
  u8('version'),
  u8('isInitialized'),
  u8('bumpSeed'),
  publicKey('tokenProgramId'),
  publicKey('tokenAccountA'),
  publicKey('tokenAccountB'),
  publicKey('tokenPool'),
  publicKey('mintA'),
  publicKey('mintB'),
  publicKey('feeAccount'),
  uint64('tradeFeeNumerator'),
  uint64('tradeFeeDenominator'),
  uint64('ownerTradeFeeNumerator'),
  uint64('ownerTradeFeeDenominator'),
  uint64('ownerWithdrawFeeNumerator'),
  uint64('ownerWithdrawFeeDenominator'),
  uint64('hostFeeNumerator'),
  uint64('hostFeeDenominator'),
  u8('curveType'),
  blob(32, 'curveParameters'),
]);

interface StepTokenSwapLayout extends TokenSwapLayout {
  poolNonce: u64;
}

const StepTokenSwapLayout = struct<StepTokenSwapLayout>([
  u8('version'),
  u8('isInitialized'),
  u8('bumpSeed'),
  publicKey('tokenProgramId'),
  publicKey('tokenAccountA'),
  publicKey('tokenAccountB'),
  publicKey('tokenPool'),
  publicKey('mintA'),
  publicKey('mintB'),
  publicKey('feeAccount'),
  uint64('tradeFeeNumerator'),
  uint64('tradeFeeDenominator'),
  uint64('ownerTradeFeeNumerator'),
  uint64('ownerTradeFeeDenominator'),
  uint64('ownerWithdrawFeeNumerator'),
  uint64('ownerWithdrawFeeDenominator'),
  u8('curveType'),
  blob(32, 'curveParameters'),
  u8('poolNonce'),
]);

export interface TokenSwapState {
  address: PublicKey;
  programId: PublicKey;
  tokenProgramId: PublicKey;
  poolToken: PublicKey;
  feeAccount: PublicKey;
  authority: PublicKey;
  tokenAccountA: PublicKey;
  tokenAccountB: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
  tradeFeeNumerator: u64;
  tradeFeeDenominator: u64;
  ownerTradeFeeNumerator: u64;
  ownerTradeFeeDenominator: u64;
  ownerWithdrawFeeNumerator: u64;
  ownerWithdrawFeeDenominator: u64;
  curveType: number;
  curveParameters: Uint8Array;
  poolNonce?: u64;
}

export function accountInfoToTokenSwapState(
  address: PublicKey,
  tokenSwapAccountInfo: AccountInfo<Buffer>,
): TokenSwapState {
  const programId = tokenSwapAccountInfo.owner;

  // The layout difference only affects fields we do not actively use
  const tokenSwapData = (
    programId.equals(STEP_TOKEN_SWAP_PROGRAM_ID)
      ? StepTokenSwapLayout.decode(tokenSwapAccountInfo.data)
      : TokenSwapLayout.decode(tokenSwapAccountInfo.data)
  ) as TokenSwapLayout | StepTokenSwapLayout;

  if (!tokenSwapData.isInitialized) {
    throw new Error(`Invalid token swap state`);
  }

  const authority = createProgramAddressSyncUnsafe(
    [address.toBuffer(), Buffer.from([tokenSwapData.bumpSeed])],
    programId,
  );

  const poolToken = new PublicKey(tokenSwapData.tokenPool);
  const feeAccount = new PublicKey(tokenSwapData.feeAccount);
  const tokenAccountA = new PublicKey(tokenSwapData.tokenAccountA);
  const tokenAccountB = new PublicKey(tokenSwapData.tokenAccountB);
  const mintA = new PublicKey(tokenSwapData.mintA);
  const mintB = new PublicKey(tokenSwapData.mintB);
  const tokenProgramId = new PublicKey(tokenSwapData.tokenProgramId);

  const tradeFeeNumerator = tokenSwapData.tradeFeeNumerator;
  const tradeFeeDenominator = tokenSwapData.tradeFeeDenominator;
  const ownerTradeFeeNumerator = tokenSwapData.ownerTradeFeeNumerator;
  const ownerTradeFeeDenominator = tokenSwapData.ownerTradeFeeDenominator;
  const ownerWithdrawFeeNumerator = tokenSwapData.ownerWithdrawFeeNumerator;
  const ownerWithdrawFeeDenominator = tokenSwapData.ownerWithdrawFeeDenominator;

  const curveType = tokenSwapData.curveType;
  const curveParameters = tokenSwapData.curveParameters;

  const poolNonce = 'poolNonce' in tokenSwapData ? tokenSwapData.poolNonce : undefined;

  return {
    address,
    programId,
    tokenProgramId,
    poolToken,
    feeAccount,
    authority,
    tokenAccountA,
    tokenAccountB,
    mintA,
    mintB,
    tradeFeeNumerator,
    tradeFeeDenominator,
    ownerTradeFeeNumerator,
    ownerTradeFeeDenominator,
    ownerWithdrawFeeNumerator,
    ownerWithdrawFeeDenominator,
    curveType,
    curveParameters,
    poolNonce,
  };
}
