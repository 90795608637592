export interface IndexedRouteMap {
  mintKeys: string[];
  indexedRouteMap: { [key: number]: number[] };
}

function getOrAddKeyToIndexMap(pk: string, mintToIndexMap: Map<string, number>): number {
  let keyIndex = mintToIndexMap.get(pk);
  if (keyIndex === undefined) {
    keyIndex = mintToIndexMap.size;
    mintToIndexMap.set(pk, keyIndex);
  }
  return keyIndex;
}

/** An indexed route map which is light (less data to compress/decompress) and can quickly be inflated into the full route map  */
export function routeMapToIndexedRouteMap(routeMap: Map<string, string[]>) {
  const { mintToIndexMap, indexedRouteMap: innerIndexedRouteMap } = Array.from(routeMap).reduce<{
    mintToIndexMap: Map<string, number>;
    indexedRouteMap: Record<number, number[]>;
  }>(
    ({ mintToIndexMap, indexedRouteMap }, [key, item]) => {
      const keyIndex = getOrAddKeyToIndexMap(key, mintToIndexMap);

      const indexedOutputMintCache = [];
      for (const routeOutputKey of item) {
        const routeOutputKeyIndex = getOrAddKeyToIndexMap(routeOutputKey, mintToIndexMap);
        indexedOutputMintCache.push(routeOutputKeyIndex);
      }
      indexedRouteMap[keyIndex] = indexedOutputMintCache;

      return { mintToIndexMap, indexedRouteMap };
    },
    { mintToIndexMap: new Map(), indexedRouteMap: {} },
  );

  const indexedRouteMap = {
    mintKeys: [...mintToIndexMap.keys()],
    indexedRouteMap: innerIndexedRouteMap,
  };

  return indexedRouteMap;
}

export function indexedRouteMapToRouteMap(indexedRouteMap: IndexedRouteMap) {
  const getMint = (index: number) => indexedRouteMap.mintKeys[index];

  // generate route map by replacing indexes with mint addresses
  const generatedRouteMap: Map<string, string[]> = new Map();
  Object.keys(indexedRouteMap.indexedRouteMap).forEach((key) => {
    generatedRouteMap.set(
      getMint(key as any as number),
      indexedRouteMap['indexedRouteMap'][key as any as number].map((index: number) => getMint(index)),
    );
  });

  return generatedRouteMap;
}
