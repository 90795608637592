export const SOL_MINT = "So11111111111111111111111111111111111111112";
export const BTC_MINT = "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E";
export const ETH_MINT = "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs";
export const USDC_MINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
export const USDT_MINT = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
export const USDR_MINT = "USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2";
export const RATIO_MINT = "ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J";
export const RAYDIUM_MINT = "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R";
export const SERUM_MINT = "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt";
export const SABER_MINT = "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1";
export const SLIM_MINT = "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW";
export const ORCA_MINT = "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE";
