import * as anchor from "@project-serum/anchor";
import { PublicKey, Keypair, Connection, Transaction } from "@solana/web3.js";
import NodeWallet from "@project-serum/anchor/dist/cjs/nodewallet";
import type { AddDecimalsIDL } from "./idls/add-decimals-idl";
import { AddDecimalsJSON } from "./idls/add-decimals-idl";
import { SaberProgramIds } from "../ids";
export class SaberDecimalsProgram {
  program: anchor.Program<AddDecimalsIDL>;
  conn: Connection;
  userPublicKey: PublicKey;

  constructor(connection: Connection, userPublicKey: PublicKey) {
    this.conn = connection;
    this.userPublicKey = userPublicKey;
    const provider = new anchor.AnchorProvider(
      connection,
      new NodeWallet(Keypair.generate()),
      anchor.AnchorProvider.defaultOptions()
    );
    const program = new anchor.Program(
      AddDecimalsJSON,
      SaberProgramIds.AddDecimals,
      provider
    );
    this.program = program;
  }

  static getInstance(
    connection: Connection,
    userPublicKey: PublicKey
  ): SaberDecimalsProgram {
    return new SaberDecimalsProgram(connection, userPublicKey);
  }

  async getWrapperData(wrapperKey: PublicKey) {
    const wrapperData = await this.program.account.wrappedToken.fetchNullable(
      wrapperKey
    );
    return wrapperData;
  }
}
