import { AccountInfoMap, Amm, mapAddressToAccountInfos, Quote, QuoteParams, SwapParams } from '../amm';
import { AccountInfo, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { accountInfoToCremaPoolState, CremaPoolState } from './swapLayout';
import { createCremaSwapInstruction } from '../jupiterInstruction';
import { calculateSwapA2B, calculateSwapB2A, parseTicksAccount, Tick } from '@jup-ag/crema-sdk';
import Decimal from 'decimal.js';
import { ZERO } from '@jup-ag/math';
import JSBI from 'jsbi';

export class CremaAmm implements Amm {
  id: string;
  label = 'Crema';
  shouldPrefetch = false;
  exactOutputSupported = false;

  private ticks: Tick[] | undefined;
  private poolState: CremaPoolState;

  constructor(address: PublicKey, accountInfo: AccountInfo<Buffer>) {
    this.poolState = accountInfoToCremaPoolState(address, accountInfo);
    this.id = address.toBase58();
  }

  getAccountsForUpdate(): PublicKey[] {
    return [this.poolState.ammId, this.poolState.ticksKey];
  }

  update(accountInfoMap: AccountInfoMap): void {
    const [tokenSwapAccountInfo, ticksAccountInfo] = mapAddressToAccountInfos(
      accountInfoMap,
      this.getAccountsForUpdate(),
    );

    this.poolState = accountInfoToCremaPoolState(this.poolState.ammId, tokenSwapAccountInfo);

    const ticksInfo = parseTicksAccount(this.poolState.ticksKey, ticksAccountInfo);
    if (!ticksInfo) throw new Error(`Ticks account invalid: ${this.poolState.ticksKey.toBase58()}`);
    this.ticks = ticksInfo.data.ticks;
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    if (!this.ticks) {
      throw new Error('Unable to fetch accounts for ticks.');
    }

    // Crema SDK doesn't support 0 amount input
    if (JSBI.equal(amount, ZERO)) {
      return {
        notEnoughLiquidity: false,
        inAmount: amount,
        outAmount: ZERO,
        feeAmount: ZERO,
        feeMint: sourceMint.toBase58(),
        feePct: this.poolState.fee.toNumber(),
        priceImpactPct: 0,
      };
    }

    const result = this.poolState.mintA.equals(sourceMint)
      ? this.preSwapA(new Decimal(amount.toString()))
      : this.preSwapB(new Decimal(amount.toString()));

    if (result.revert) {
      throw new Error('Crema error: insufficient liquidity');
    }

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: JSBI.BigInt(result.amountOut.toString()),
      feeAmount: JSBI.BigInt(result.feeUsed.toString()),
      feeMint: sourceMint.toBase58(),
      feePct: this.poolState.fee.toNumber(),
      priceImpactPct: result.impact.toNumber(),
    };
  }

  preSwapA(amountIn: Decimal): {
    amountOut: Decimal;
    amountUsed: Decimal;
    feeUsed: Decimal;
    afterPrice: Decimal;
    afterLiquity: Decimal;
    impact: Decimal;
    revert: boolean;
  } {
    if (!this.ticks) {
      throw new Error('Unable to fetch accounts for ticks.');
    }

    const result = calculateSwapA2B(
      this.ticks,
      this.poolState.currentSqrtPrice,
      this.poolState.fee,
      this.poolState.currentLiquity,
      amountIn,
    );

    const currentPriceA = this.poolState.currentSqrtPrice.pow(2);
    const transactionPriceA = result.amountOut.div(result.amountUsed);
    const impact = transactionPriceA.sub(currentPriceA).div(currentPriceA).abs();
    const revert = result.amountUsed.lessThan(amountIn);

    return {
      ...result,
      impact,
      revert,
    };
  }

  preSwapB(amountIn: Decimal): {
    amountOut: Decimal;
    amountUsed: Decimal;
    feeUsed: Decimal;
    afterPrice: Decimal;
    afterLiquity: Decimal;
    impact: Decimal;
    revert: boolean;
  } {
    if (!this.ticks) {
      throw new Error('Unable to fetch accounts for ticks.');
    }

    const result = calculateSwapB2A(
      this.ticks,
      this.poolState.currentSqrtPrice,
      this.poolState.fee,
      this.poolState.currentLiquity,
      amountIn,
    );

    const currentPriceA = this.poolState.currentSqrtPrice.pow(2);
    const currentPriceB = new Decimal(1).div(currentPriceA);
    const transactionPriceB = result.amountOut.div(result.amountUsed);
    const impact = transactionPriceB.sub(currentPriceB).div(currentPriceB).abs();
    const revert = result.amountUsed.lessThan(amountIn);

    return {
      ...result,
      impact,
      revert,
    };
  }

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[] {
    return [
      createCremaSwapInstruction({
        poolState: this.poolState,
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.poolState.mintA, this.poolState.mintB];
  }
}
