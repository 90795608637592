import JSBI from 'jsbi';
import { abs, mulArray, ONE, sumArray, TWO, ZERO } from '../utils';
import Decimal from 'decimal.js';

export class Curve {
  constructor(private numberOfCurrencies: JSBI, private amplificationFactor: JSBI, private targetPrices: JSBI[]) {}

  public exchange(
    tokenAmounts: JSBI[],
    inputIndex: number,
    outputIndex: number,
    amount: JSBI,
    minusOne: boolean = true,
  ) {
    if (tokenAmounts.length !== JSBI.toNumber(this.numberOfCurrencies)) {
      throw new Error('Number of currencies does not match');
    }

    let xp = this.xp(tokenAmounts);
    let dx = JSBI.multiply(amount, this.targetPrices[inputIndex]);
    let x = JSBI.add(xp[inputIndex], dx);
    let y = this.computeY(tokenAmounts, inputIndex, outputIndex, x);
    let dy = JSBI.subtract(xp[outputIndex], y);

    // This is a special condition on Curve stable algo. For TokenSwap, they don't seem to apply this minus one.
    if (minusOne) {
      dy = JSBI.subtract(dy, ONE);
    }

    return JSBI.divide(dy, this.targetPrices[outputIndex]);
  }

  public computeBaseY(tokenAmounts: JSBI[], inputIndex: number, outputIndex: number, amount: JSBI) {
    let d = this.computeD(tokenAmounts);
    let xp = this.xp(tokenAmounts);
    let nn = JSBI.exponentiate(this.numberOfCurrencies, this.numberOfCurrencies);
    let sum = sumArray(xp);
    let product = mulArray(xp);
    let k = JSBI.subtract(
      JSBI.add(JSBI.multiply(JSBI.multiply(this.amplificationFactor, nn), sum), d),
      JSBI.multiply(JSBI.multiply(this.amplificationFactor, d), nn),
    );
    let b = JSBI.multiply(JSBI.multiply(JSBI.multiply(this.amplificationFactor, nn), nn), product);
    let c = JSBI.multiply(JSBI.multiply(nn, product), k);
    let numerator = JSBI.add(b, JSBI.divide(c, xp[inputIndex]));
    let denominator = JSBI.add(b, JSBI.divide(c, xp[outputIndex]));

    // Convert to number since JSBI doesn't support log10
    let inputFactor = Math.log10(JSBI.toNumber(this.targetPrices[inputIndex]));
    let outputFactor = Math.log10(JSBI.toNumber(this.targetPrices[outputIndex]));
    let factor = Math.abs(outputFactor - inputFactor);

    if (inputFactor >= outputFactor) {
      return JSBI.BigInt(
        new Decimal(numerator.toString())
          .mul(new Decimal(amount.toString()))
          .div(new Decimal(denominator.toString()))
          .mul(Math.pow(10, factor))
          .floor()
          .toString(),
      );
    } else {
      return JSBI.BigInt(
        new Decimal(numerator.toString())
          .mul(new Decimal(amount.toString()))
          .div(new Decimal(denominator.toString()))
          .div(Math.pow(10, factor))
          .floor()
          .toString(),
      );
    }
  }

  private computeY(tokenAmounts: JSBI[], inputIndex: number, outputIndex: number, newTotalAmount: JSBI) {
    let d = this.computeD(tokenAmounts);
    let xx = this.xp(tokenAmounts);
    xx[inputIndex] = newTotalAmount;
    xx.splice(outputIndex, 1);

    let ann = JSBI.multiply(this.amplificationFactor, this.numberOfCurrencies);
    let c = d;

    for (const y of xx) {
      c = JSBI.divide(JSBI.multiply(c, d), JSBI.multiply(y, this.numberOfCurrencies));
    }
    c = JSBI.divide(JSBI.multiply(c, d), JSBI.multiply(this.numberOfCurrencies, ann));

    let b = JSBI.subtract(JSBI.add(sumArray(xx), JSBI.divide(d, ann)), d);
    let yPrev = ZERO;
    let y = d;

    while (JSBI.greaterThan(abs(JSBI.subtract(y, yPrev)), ONE)) {
      yPrev = y;
      y = JSBI.divide(JSBI.add(JSBI.exponentiate(y, TWO), c), JSBI.add(JSBI.multiply(TWO, y), b));
    }

    return y;
  }

  private computeD(tokenAmounts: JSBI[]) {
    let dPrev = ZERO;
    let xp = this.xp(tokenAmounts);
    let sum = sumArray(xp);
    let d = sum;
    let ann = JSBI.multiply(this.amplificationFactor, this.numberOfCurrencies);

    while (JSBI.greaterThan(abs(JSBI.subtract(d, dPrev)), ONE)) {
      let dP = d;
      for (const x of xp) {
        dP = JSBI.divide(JSBI.multiply(dP, d), JSBI.multiply(this.numberOfCurrencies, x));
      }
      dPrev = d;
      let numerator = JSBI.multiply(JSBI.add(JSBI.multiply(ann, sum), JSBI.multiply(dP, this.numberOfCurrencies)), d);
      let denominator = JSBI.add(
        JSBI.multiply(JSBI.subtract(ann, ONE), d),
        JSBI.multiply(JSBI.add(this.numberOfCurrencies, ONE), dP),
      );
      d = JSBI.divide(numerator, denominator);
    }

    return d;
  }

  private xp(tokenAmounts: JSBI[]) {
    return tokenAmounts.map((tokenAmount, index) => {
      return JSBI.multiply(tokenAmount, this.targetPrices[index]);
    });
  }

  setAmplificationFactor(amplificationFactor: JSBI) {
    this.amplificationFactor = amplificationFactor;
  }
}
