/* eslint-disable */
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import JSBI from 'jsbi';
import { StableSwap, calculateEstimatedMintAmount, makeExchange, loadExchangeInfo, calculateEstimatedWithdrawAmount } from '@saberhq/stableswap-sdk';
import { Token as SaberToken, TokenAmount } from '@saberhq/token-utils';
import { LiquidityManager } from '../manage/ILiquidityManager';
import { RatioSdkProgram } from '../programs/ratio/ratio-sdk';
import { LpTokenInfo } from '../utils/types';
export class SaberLiquidityManager extends LiquidityManager {
  async getLiquidityPairRatio(lpData: any): Promise<number[]> {
    return [0.5, 0.5];
  }

  async loadSwapInfo(connection: Connection, lpData: LpTokenInfo) {
    let stableSwap = await StableSwap.load(connection, new PublicKey(lpData.swapAccount));
    return stableSwap;
  }

  async addLiquidityTransaction(
    connection: Connection,
    userPublicKey: PublicKey,
    lpData: LpTokenInfo,
    originAmounts: Array<JSBI>
  ): Promise<Transaction> {
    const stableSwapInfo = lpData.swapInfo;
    const ratioProgram: RatioSdkProgram = RatioSdkProgram.getInstance(connection);
    const tx = await ratioProgram.makeSaberAddLiquidityTx(
      connection,
      userPublicKey,
      stableSwapInfo,
      originAmounts
    );
    return tx;
  }

  async removeLiquidityTransaction(
    connection: Connection,
    userPublicKey: PublicKey,
    lpData: LpTokenInfo,
    amountToUnwind: JSBI
  ): Promise<Transaction> {
    const stableSwapInfo = lpData.swapInfo;
    const ratioProgram: RatioSdkProgram = RatioSdkProgram.getInstance(connection);
    const tx = await ratioProgram.makeSaberRemoveLiquidityTx(
      connection,
      userPublicKey,
      stableSwapInfo,
      amountToUnwind
    );
    return tx;
  }

  async getEstimatedOutputLPAmount(connection: Connection, lpData: any, amounts: Array<JSBI>): Promise<JSBI | null> {
    const stableSwap = lpData.swapInfo;
    const theTokenA = (await SaberToken.load(connection, stableSwap.state.tokenA.mint))?.info;
    if (!theTokenA) {
      throw new Error(`Token ${stableSwap.state.tokenA.mint.toString()} not found`);
    }
    const theTokenB = (await SaberToken.load(connection, stableSwap.state.tokenB.mint))?.info;
    if (!theTokenB) {
      throw new Error(`Token ${stableSwap.state.tokenB.mint.toString()} not found`);
    }
    const exchange = makeExchange({
      swapAccount: stableSwap.config.swapAccount,
      lpToken: stableSwap.state.poolTokenMint,
      tokenA: theTokenA,
      tokenB: theTokenB,
    });
    if (exchange === null) {
      return null;
    }
    let exchangeInfo = await loadExchangeInfo(connection, exchange, stableSwap);
    let { mintAmountBeforeFees, mintAmount, fees } = calculateEstimatedMintAmount(exchangeInfo, amounts[0], amounts[1]);
    return JSBI.BigInt(mintAmount.toU64().toString());
  }

  getUnderlyingMints(lpData: any): string[] {
    const stableSwap = lpData.swapInfo;
    return [
      stableSwap.state.tokenA.mint.toString(),
      stableSwap.state.tokenB.mint.toString()
    ];
  } 

  async getEstimatedWithdrawAmount(connection: Connection, lpData: any, amount: JSBI): Promise<JSBI[]> {
    const stableSwap = lpData.swapInfo;
    const theTokenLp = (await SaberToken.load(connection, stableSwap.state.poolTokenMint));
    if (!theTokenLp) {
      throw new Error(`Token ${stableSwap.state.tokenA.mint.toString()} not found`);
    }
    const theTokenA = (await SaberToken.load(connection, stableSwap.state.tokenA.mint))?.info;
    if (!theTokenA) {
      throw new Error(`Token ${stableSwap.state.tokenA.mint.toString()} not found`);
    }
    const theTokenB = (await SaberToken.load(connection, stableSwap.state.tokenB.mint))?.info;
    if (!theTokenB) {
      throw new Error(`Token ${stableSwap.state.tokenB.mint.toString()} not found`);
    }
    const exchange = makeExchange({
      swapAccount: stableSwap.config.swapAccount,
      lpToken: stableSwap.state.poolTokenMint,
      tokenA: theTokenA,
      tokenB: theTokenB,
    });

    let exchangeInfo = await loadExchangeInfo(connection, exchange!, stableSwap);
    const { withdrawAmounts } = calculateEstimatedWithdrawAmount({ poolTokenAmount: new TokenAmount(theTokenLp, amount) as any , fees: exchangeInfo.fees, lpTotalSupply: exchangeInfo.lpTotalSupply, reserves: exchangeInfo.reserves});
    const withdrawAmountsJSBI = withdrawAmounts.map((tokenAmount) => {
      return JSBI.BigInt(tokenAmount.toU64().toString())
    })
    return withdrawAmountsJSBI;
  }
}
