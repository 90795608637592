import { AccountInfo, AccountMeta, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import JSBI from 'jsbi';
import * as anchor from '@project-serum/anchor';
import { AccountInfoMap, Amm, Quote, QuoteParams, SwapParams } from '../amm';
import { Pool as CykuraPool, CyclosCore, IDL, OBSERVATION_SEED, u32ToSeed, u16ToSeed } from '@jup-ag/cykura-sdk';
import { CurrencyAmount, Token } from '@jup-ag/cykura-sdk-core';
import { IdlAccounts, Wallet } from '@project-serum/anchor';
import { SolanaTickDataProvider } from './solanaTickDataProvider';
import { CYKURA_PROGRAM_ID } from '../../constants';
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey';
import { createCykuraSwapInstruction } from '../jupiterInstruction';
import { toDecimal } from '@jup-ag/math';

export type PoolState = IdlAccounts<CyclosCore>['poolState'];

const FEE_DENOMINATOR = JSBI.BigInt(1_000_000);

const provider = new anchor.AnchorProvider(null as unknown as Connection, null as unknown as Wallet, {
  skipPreflight: false,
});
const CYCLOS_CORE = new anchor.Program<CyclosCore>(IDL, CYKURA_PROGRAM_ID, provider);

export class CykuraAmm implements Amm {
  label = 'Cykura' as const;
  id: string;
  shouldPrefetch = true;
  exactOutputSupported = false;

  private poolState: PoolState;
  private pool: CykuraPool;
  private tickDataProvider: SolanaTickDataProvider;
  private tokens: { token0: Token; token1: Token };
  public vaults: { vault0: PublicKey; vault1: PublicKey };
  private feePct: number;
  private fee: JSBI;

  constructor(private address: PublicKey, accountInfoOrPoolState: AccountInfo<Buffer> | PoolState) {
    this.id = address.toBase58();
    let poolState: PoolState;
    if ('data' in accountInfoOrPoolState) {
      poolState = CYCLOS_CORE.coder.accounts.decode<PoolState>('poolState', accountInfoOrPoolState.data);
    } else {
      poolState = accountInfoOrPoolState;
    }

    this.poolState = poolState;

    const { token0, token1, fee, sqrtPriceX32, liquidity, tick } = this.poolState;

    this.tickDataProvider = new SolanaTickDataProvider(CYCLOS_CORE, {
      token0,
      token1,
      fee,
    });

    this.tokens = {
      token0: new Token(101, token0, 0, '', ''),
      token1: new Token(101, token1, 0, '', ''),
    };

    this.pool = new CykuraPool(
      this.tokens.token0,
      this.tokens.token1,
      fee,
      JSBI.BigInt(sqrtPriceX32.toString()),
      JSBI.BigInt(liquidity.toString()),
      tick,
      this.tickDataProvider,
    );

    this.vaults = {
      vault0: findProgramAddressSync(
        [this.address.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), token0.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID,
      )[0],
      vault1: findProgramAddressSync(
        [this.address.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), token1.toBuffer()],
        ASSOCIATED_TOKEN_PROGRAM_ID,
      )[0],
    };

    this.fee = JSBI.BigInt(this.poolState.fee);
    this.feePct = this.poolState.fee / JSBI.toNumber(FEE_DENOMINATOR);
  }

  getAccountsForUpdate(): PublicKey[] {
    return [
      this.address,
      ...this.tickDataProvider.lazyLoadAccountsToCache(this.pool.tickCurrent, this.pool.tickSpacing),
    ];
  }

  update(accountInfoMap: AccountInfoMap) {
    const poolAccountInfo = accountInfoMap.get(this.address.toBase58());
    if (!poolAccountInfo) {
      throw new Error(`Could not find poolAccountInfo ${this.address.toBase58()}`);
    }
    this.poolState = CYCLOS_CORE.coder.accounts.decode<PoolState>('poolState', poolAccountInfo.data);
    const { fee, sqrtPriceX32, liquidity, tick } = this.poolState;
    this.pool = new CykuraPool(
      this.tokens.token0,
      this.tokens.token1,
      fee,
      JSBI.BigInt(sqrtPriceX32.toString()),
      JSBI.BigInt(liquidity.toString()),
      tick,
      this.tickDataProvider,
    );

    this.tickDataProvider.updateCachedAccountInfos(accountInfoMap);
  }

  getQuote({ sourceMint, amount }: QuoteParams): Quote {
    const inputToken = sourceMint.equals(this.poolState.token0) ? this.tokens.token0 : this.tokens.token1;
    const [currentOutAmount, newPool, swapAccountMetas] = this.pool.getOutputAmount(
      CurrencyAmount.fromRawAmount(inputToken, amount),
    );

    const priceImpactDecimal = toDecimal(JSBI.subtract(this.pool.sqrtRatioX32, newPool.sqrtRatioX32)).div(
      this.pool.sqrtRatioX32.toString(),
    );

    return {
      notEnoughLiquidity: false,
      inAmount: amount,
      outAmount: currentOutAmount.quotient,
      // Might not be spot on but avoids many conversions
      feeAmount: JSBI.divide(JSBI.multiply(amount, this.fee), FEE_DENOMINATOR),
      feeMint: sourceMint.toBase58(),
      feePct: this.feePct,
      priceImpactPct: priceImpactDecimal.toNumber(),
    };
  }

  createSwapInstructions(swapParams: SwapParams): TransactionInstruction[] {
    const [inputVault, outputVault] = swapParams.sourceMint.equals(this.poolState.token0)
      ? [this.vaults.vault0, this.vaults.vault1]
      : [this.vaults.vault1, this.vaults.vault0];

    const lastObservationState = findProgramAddressSync(
      [
        OBSERVATION_SEED,
        this.poolState.token0.toBuffer(),
        this.poolState.token1.toBuffer(),
        u32ToSeed(this.poolState.fee),
        u16ToSeed(this.poolState.observationIndex),
      ],
      CYKURA_PROGRAM_ID,
    )[0];

    const inputToken = swapParams.sourceMint.equals(this.poolState.token0) ? this.tokens.token0 : this.tokens.token1;

    const [, , swapAccountMetas] = this.pool.getOutputAmount(
      CurrencyAmount.fromRawAmount(inputToken, swapParams.inAmount),
    );

    const nextObservationState = findProgramAddressSync(
      [
        OBSERVATION_SEED,
        this.poolState.token0.toBuffer(),
        this.poolState.token1.toBuffer(),
        u32ToSeed(this.poolState.fee),
        u16ToSeed((this.poolState.observationIndex + 1) % this.poolState.observationCardinalityNext),
      ],
      CYKURA_PROGRAM_ID,
    )[0];

    const additionalArgs = {
      poolAddress: this.address,
      inputVault,
      outputVault,
      nextObservationState,
      lastObservationState,
      swapAccountMetas: swapAccountMetas,
    };
    return [
      createCykuraSwapInstruction({
        ...swapParams,
        inAmount: swapParams.amount,
        minimumOutAmount: swapParams.otherAmountThreshold,
        additionalArgs,
      }),
    ];
  }

  get reserveTokenMints() {
    return [this.poolState.token0, this.poolState.token1];
  }
}
