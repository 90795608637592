// The package is missing cjs. Apache License 2.0
// https://github.com/ehmicky/fast-cartesian/blob/619a497dffd978fb4a09a83f2ca047915579774f/src/main.js

// Validate 'array()' input
const validateInput = function <T>(arrays: T[][]) {
  if (!Array.isArray(arrays)) {
    throw new TypeError('Argument must be an array of arrays');
  }

  arrays.forEach(validateArray);
  validateDimensions(arrays);
  validateCombinations(arrays);
};

const validateArray = function <T>(array: T[]) {
  if (!Array.isArray(array)) {
    throw new TypeError(`Argument must be an array: ${array}`);
  }
};

// Maximum number of nested `for` loops. In my machine, it's 604 but it is
// engine-specific so we use a safe number. Above the limit, a max call stack
// error is thrown by the engine.
const validateDimensions = function <T>({ length }: T[]) {
  if (length >= MAX_DIMENSIONS) {
    throw new TypeError(
      `Too many arrays (${length}): please use the 'big-cartesian' library instead of 'fast-cartesian'`,
    );
  }
};

const MAX_DIMENSIONS = 1e2;

// Max array size in JavaScript. This is the limit of the final return value.
const validateCombinations = function <T>(arrays: T[][]) {
  const size = arrays.reduce(multiplySize, 1);

  if (size >= MAX_SIZE) {
    const sizeStr = Number.isFinite(size) ? ` (${size.toExponential(0)})` : '';
    throw new TypeError(
      `Too many combinations${sizeStr}: please use the 'big-cartesian' library instead of 'fast-cartesian'`,
    );
  }
};

const multiplySize = function <T>(size: number, array: T[]) {
  return size * array.length;
};

const MAX_SIZE = 4294967296; // 2 ** 32 = 4,294,967,296

// Does a cartesian product on several arrays.
// Returns an array with the results.
// Optimized to be the fastest implementation in JavaScript.
export default function fastCartesian<T>(arrays: T[][]): T[][] {
  validateInput(arrays);

  if (arrays.length === 0) {
    return [];
  }

  const loopFunc = getLoopFunc(arrays.length);
  const result: T[][] = [];
  loopFunc(arrays, result);
  return result;
}

const getLoopFunc = function (length: number) {
  const cachedLoopFunc = cache[length];

  if (cachedLoopFunc !== undefined) {
    return cachedLoopFunc;
  }

  const loopFunc = mGetLoopFunc(length);
  // eslint-disable-next-line fp/no-mutation
  cache[length] = loopFunc;
  return loopFunc;
};

const cache: Record<number, any> = {};

// Create a function with `new Function()` that does:
//   function(arrays, results) {
//     for (const value0 of arrays[0]) {
//       for (const value1 of arrays[1]) {
//         // and so on
//         results.push([value0, value1])
//       }
//     }
//   }
const mGetLoopFunc = function (length: number) {
  const indexes = Array.from({ length }, getIndex);
  const start = indexes.map((index) => `for (const value${index} of arrays[${index}]) {`).join('\n');
  const middle = indexes.map((index) => `value${index}`).join(', ');
  const end = '}\n'.repeat(length);

  // eslint-disable-next-line no-new-func
  return new Function('arrays', 'result', `${start}\nresult.push([${middle}])\n${end}`);
};

const getIndex = function (value: any, index: number) {
  return String(index);
};
